import React, { Component, useState, useRef } from "react";
import { Switch, Route } from "react-router-dom";
import Login from "./components/login";
import "./css/style.css";
import Dashboard from "./components/dashboard";
import Register from "./components/register";
import { observer } from "mobx-react";
import Axios from "axios";
import { useMst } from "./models/store";
import { LinearProgress } from "@material-ui/core";
import { Confirm } from "./components/confirm";
import Cookies from "universal-cookie";
import { Reviews } from "./components/reviews";
import { Overview } from "./components/overview";
import Navbar from "./components/navbar";
import { Layout } from "./common/layout";
import { LocationLayout } from "./components/layouts/locationLayout";

const App = () => {
  const store = useMst();
  const loadingBar = useRef(null);
  Axios.interceptors.request.use((config) => {
    const accessToken = new Cookies().get("token");
    if(accessToken){
      config.headers['Authorization'] = "Bearer " + accessToken;
    }
    config.headers["x-api-identifier"] = "UDKZF5UONLV8XGR";
    try {
      loadingBar.current.style.visibility = "visible";
    } catch (error) {
      loadingBar.current.style.visibility = "hidden";
    }
    return config;
  });
  Axios.interceptors.response.use((config) => {
    try {
      loadingBar.current.style.visibility = "hidden";
    } catch (error) {
    }
    return config;
  });
  return (
    <React.Fragment>
      <LinearProgress
        color="secondary"
        style={{ visibility: "hidden", zIndex: 999999, position: 'absolute', width: '100%', top: 0 }}
        ref={loadingBar}
      />
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/confirm" component={Confirm} />
        <Route path="/" component={Layout} />
        {/* <Route path="/" component={Dashboard} /> */}
      </Switch>
    </React.Fragment>
  );
};

export default App;
