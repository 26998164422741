import React, { Component, useEffect, useLayoutEffect, useRef } from "react";
import { useMst } from "../models/store";
import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { formatDateInTimeZone } from "../common/formatDate";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Rating, Skeleton } from "@material-ui/lab";
import { getPriorityColor, getPriorityType } from "../common/getPriority";
import { StyledMenu, StyledMenuItem } from "../common/dropdownMenu";
import ReplyIcon from "@material-ui/icons/Reply";
import { updateQueryString } from "../common/getQueryString";
import { Editor } from "@tinymce/tinymce-react";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import { ticketResponseType } from "../enums/ticketResponseType";
import { ticketResponseStatus } from "../enums/ticketResponseStatus";
import { getTicketStatus } from "../common/getTicketStatus";
import {
  PostResponse,
  UpdateTicket,
  UpdateTicketStatus,
} from "../services/appService";
import { ticketPriority } from "../enums/ticketPriority";
import { ticketStatus } from "../enums/ticketStatus";
import { TicketView } from "./tickets/ticketView";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    height: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  leftPanel: {
    overflow: "hidden",
    position: "relative",
  },
  subItem: {
    fontSize: "1em",
  },
  scrollBar: {
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  statusButton: {
    padding: "none",
  },
  margin: {
    margin: theme.spacing(1),
  },
  showMore: {
    "&::before": {
      content: "''",
      position: "absolute",
      height: "1px",
      width: "100%",
      backgroundColor: "#ebeff3",
      top: "50%",
      zIndex: 1,
    },
  },
  showMoreButton: {
    position: "relative",
    display: "flex",
    margin: "auto",
    padding: "2px 12px 2px 6px",
    boxShadow: "0 0 0 8px #fff",
    borderRadius: "20px",
    minHeight: "24px",
    zIndex: "2",
    alignItems: "center",
    "justify-content": "space-between",
    border: "1px solid #cfd7df",
    background: "linear-gradient(180deg,#fff 2.56%,#f3f5f7 95.75%)",
  },
  rating: {
    position: "relative",
    verticalAlign: "text-bottom",
  },
  searchBar: {
    border: "none",
  },
}));
export const Tickets = observer(({ ...props }) => {
  const classes = useStyles();
  const store = useMst();
  const [localStore, setLocalStore] = useState({
    isLoading: !store.tickets.data ? true : false,
    pageIndex: 1,
    selectedTicket: null,
    messageIndex: 0,
    messagesHidden: true,
    resetCompose: function () {
      this.selectedTicket.composeReply = null;
    },
    reset: function () {
      this.localLogs = null;
      this.messagesHidden = true;
      this.messageIndex = 0;
    },
  });
  const lastMessageBox = useRef(null);
  const [selectedPriorityMenu, setSelectedPriorityMenu] = React.useState(null);
  const [selectedStatusMenu, setSelectedStatusMenu] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [editReply, setEditReply] = useState(false);
  const loadData = async () => {
    await store.loadTickets(localStore.pageIndex, 50);
    const getLocalStore = { ...localStore };
    getLocalStore.isLoading = false;
    setLocalStore(getLocalStore);
  };
  const [domHeight, setDOMHeight] = useState(0);

  useLayoutEffect(() => {
    function resizeWindow() {
      try {
        var navBarHeight = document.getElementsByClassName(
          "makeStyles-toolbar-6"
        )[0].clientHeight;
        var contentDivHeight = document.getElementsByClassName("MuiBox-root")[0]
          .clientHeight;
        var totalDOMHeight = window.innerHeight;
        var calcHeight = window.innerHeight - navBarHeight - 16 - 16;
        setDOMHeight(calcHeight);
      } catch (error) {
        setDOMHeight(564);
      }
    }
    window.addEventListener("resize", resizeWindow);
    resizeWindow();
  });

  const selectTicket = async (t) => {
    props.history.push({
      pathname: "/tickets",
      search: updateQueryString("id", t.id, props.location.search),
    });

    const getLocalStore = { ...localStore };
    getLocalStore.reset();
    getLocalStore.selectedTicket = t;
    setLocalStore(getLocalStore);
    // setEditReply(false);
  };

  const loadThreadConversation = async () => {
    if (!localStore.localLogs) {
      var getLocalStore = { ...localStore };
      var data = await localStore.selectedTicket.loadLogs();
      getLocalStore.localLogs = [];
      if (data.length > 0) {
        data.map((t) => {
          getLocalStore.localLogs.push({
            text: t.text,
            createdOn: t.createdOn,
            responseType: t.responseType,
            userId: t.userId,
            html: t.html,
            status: t.status,
          });
        });
      }
      setLocalStore(getLocalStore);
    }
  };

  const handleTicketPriorityMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedPriorityMenu(id);
  };

  const handleTicketStatusMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatusMenu(id);
  };

  const handleTicketStatusClose = () => {
    setAnchorEl(null);
    setSelectedStatusMenu(null);
  };

  const handleTicketPriorityClose = () => {
    setAnchorEl(null);
    setSelectedPriorityMenu(null);
  };
  const getTicketStatusDropDownMenu = (id) => {
    return (
      <React.Fragment>
        <Button
          aria-controls={`ticket-status-${id}`}
          variant="outlined"
          color="primary"
          onClick={(e) =>
            handleTicketStatusMenu(e, localStore.selectedTicket.id)
          }
          endIcon={<ArrowDropDownIcon />}
        >
          {getTicketStatus(localStore.selectedTicket.status)}
        </Button>
        <StyledMenu
          id={`ticket-status-${id}`}
          anchorEl={anchorEl}
          open={selectedStatusMenu == id}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration="auto"
          onClose={handleTicketStatusClose}
        >
          <StyledMenuItem>
            <ListItemText
              primary="Open"
              onClick={() => updateTicketStatus(ticketStatus.open)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Pending"
              onClick={() => updateTicketStatus(ticketStatus.pending)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Resolved"
              onClick={() => updateTicketStatus(ticketStatus.resolved)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Waiting on client"
              onClick={() => updateTicketStatus(ticketStatus.waitingOnClient)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Closed"
              onClick={() => updateTicketStatus(ticketStatus.open)}
            />
          </StyledMenuItem>
        </StyledMenu>
      </React.Fragment>
    );
  };

  const updateTicketStatus = (status) => {
    UpdateTicketStatus(localStore.selectedTicket.id, status).then((t) => {
      if (t.data) {
        store.toggleAlert(true, "Ticket status updated");
      }
    });
    localStore.selectedTicket.set("status", status);
    handleTicketStatusClose();
  };

  const updateTicket = (priority) => {
    UpdateTicket(localStore.selectedTicket.id, priority).then((t) => {
      if (t.data) {
        store.toggleAlert(true, "Ticket priority updated");
      }
    });
    localStore.selectedTicket.set("priority", priority);
    handleTicketPriorityClose();
  };

  const getPriorityDropDown = () => {
    return (
      <React.Fragment>
        <Button
          aria-controls={`ticket-${localStore.selectedTicket.id}`}
          onClick={(e) =>
            handleTicketPriorityMenu(e, localStore.selectedTicket.id)
          }
          endIcon={<ArrowDropDownIcon />}
          className={classes.statusButton}
          style={{ padding: "none" }}
        >
          {getPriorityType(localStore.selectedTicket.priority)}
        </Button>
        <StyledMenu
          id={`ticket-${localStore.selectedTicket.id}`}
          anchorEl={anchorEl}
          open={selectedPriorityMenu == localStore.selectedTicket.id}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration="auto"
          onClose={handleTicketPriorityClose}
        >
          <StyledMenuItem>
            <ListItemText
              primary="Highest"
              onClick={() => updateTicket(ticketPriority.highest)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="High"
              onClick={() => updateTicket(ticketPriority.high)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Medium"
              onClick={() => updateTicket(ticketPriority.medium)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Low"
              onClick={() => updateTicket(ticketPriority.low)}
            />
          </StyledMenuItem>
        </StyledMenu>
      </React.Fragment>
    );
  };

  const handleEditorChange = (content, editor) => {
    var getLocalStore = { ...localStore };
    getLocalStore.selectedTicket.composeReply = content;
    setLocalStore(getLocalStore);
  };

  const handleResponseType = (e) => {
    var elem = document.getElementById("toField");
    if (e.target.value == ticketResponseType.email) {
      elem.setAttribute("placeholder", "Recipient email address(es)");
      elem.value = localStore.selectedTicket.emailAddress;
    } else if (e.target.value == ticketResponseType.sms) {
      elem.setAttribute("placeholder", "Recipient sms number");
      elem.value = localStore.selectedTicket.smsNumber;
    }
  };

  const postReply = () => {
    const getLocalStore = { ...localStore };
    getLocalStore.messageIndex++;
    const emailTo = document.getElementById("toField").value;
    if (localStore.selectedTicket.composeReply) {
      getLocalStore.localLogs.push({
        text: localStore.selectedTicket.composeReply,
        // createdOn: t.optional(t.string, ""),
        userId: 99999,
        html: localStore.selectedTicket.composeReply,
        messageIndex: getLocalStore.messageIndex,
      });
      getLocalStore.selectedTicket.set("emailAddress", emailTo);
      sendReply(getLocalStore);
      setLocalStore(getLocalStore);
      setEditReply(false);
    }
  };

  const handleScroll = (ref) => {
    ref.current.scrollTop = ref.current.scrollHeight;
  };

  const sendReply = async (store) => {
    const responseType = document.getElementById("sendMessageVia").value;
    const response = await PostResponse(
      !responseType ? ticketResponseType.email : responseType,
      store.selectedTicket.emailAddress,
      store.selectedTicket.composeReply,
      store.selectedTicket.id,
      store.messageIndex
    );
    if (response) {
      setReplyStatus(response.data);
      localStore.resetCompose();
    }
  };

  const setReplyStatus = (response) => {
    const getLocalStore = { ...localStore };
    for (var i in getLocalStore.localLogs) {
      if (getLocalStore.localLogs[i].messageIndex == response.messageIndex) {
        if (response.success) {
          getLocalStore.localLogs[i].status = ticketResponseStatus.sent;
        } else {
          getLocalStore.localLogs[i].status = ticketResponseStatus.failed;
        }

        getLocalStore.localLogs[i].createdOn = response.createdOn;
      }
    }
    setLocalStore(getLocalStore);
  };

  const toggleMessages = () => {
    const getLocalStore = { ...localStore };
    if (getLocalStore.messagesHidden) {
      getLocalStore.messagesHidden = false;
    } else {
      getLocalStore.messagesHidden = true;
    }
    setLocalStore(getLocalStore);
  };
  const addShowMoreButton = (count) => {
    return (
      <div style={{ position: "relative" }}>
        <Box className={classes.showMore}>
          <Button className={classes.showMoreButton} onClick={toggleMessages}>
            {localStore.messagesHidden ? "Show" : "Hide"} +{count} messages
          </Button>
        </Box>
      </div>
    );
  };

  const showMessage = (t, isHidden) => {
    return (
      <React.Fragment>
        <Box
          m={3}
          p={3}
          style={{
            background: t.userId > 0 ? "#fef1e1" : "#f5f7f9",
            borderRadius: "5px",
            display: isHidden && localStore.messagesHidden ? "none" : "block",
          }}
        >
          <Typography component="p" gutterBottom>
            <Typography variant="caption" style={{ fontStyle: "italic" }}>
              {t.userId == 0
                ? `${localStore.selectedTicket.name} says:`
                : "You said:"}
            </Typography>
            {t.status == 2 ? (
              <Typography variant="caption" component="p" gutterBottom>
                {formatDateInTimeZone(t.createdOn)}
              </Typography>
            ) : (
              <React.Fragment>
                <Typography variant="caption" component="p">
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    // className={classes.bottom}
                    size={10}
                    thickness={2}
                  />{" "}
                  Sending
                </Typography>
              </React.Fragment>
            )}
          </Typography>
          <Typography component="p" gutterBottom>
            <div
              dangerouslySetInnerHTML={{
                __html: t.html ? t.html : t.text ?? "No review provided",
              }}
            />
          </Typography>
        </Box>
      </React.Fragment>
    );
  };

  if (!store.tickets.data) {
    loadData();
  }
  return (
    <React.Fragment>
      <Box p={2}>
        {/* <StyledHeader heading="Tickets" /> */}
        <Paper
          variant="elevation"
          elevation={1}
          style={{ height: `${domHeight ?? 564}px` }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              style={{ position: "relative", borderRight: "1px solid #e9e9e9" }}
            >
              <Box
                p={1}
                style={{
                  borderBottom: "1px solid #e9e9e9",
                  height: "70px",
                  position: "absolute",
                  width: "100%",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
              >
                <TextField
                  id="outlined-start-adornment"
                  placeholder="Search Tickets"
                  margin="dense"
                  fullWidth={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    classes: {
                      notchedOutline: classes.searchBar,
                    },
                  }}
                  variant="outlined"
                />
              </Box>
              <Box
                mt={8}
                className={`${classes.leftPanel} ${classes.scrollBar}`}
                style={{ height: `${domHeight - 66}px` }}
              >
                <List>
                  {localStore.isLoading &&
                    [...Array(10)].map((t) => (
                      <ListItem>
                        <ListItemAvatar>
                          <Skeleton variant="circle">
                            <Avatar>T</Avatar>
                          </Skeleton>
                        </ListItemAvatar>
                        <ListItemText>
                          <Skeleton width="100%">
                            <Typography>.</Typography>
                          </Skeleton>
                        </ListItemText>
                      </ListItem>
                    ))}
                  {store.tickets.data != null &&
                    !localStore.isLoading &&
                    store.tickets.data.map((t) => (
                      <React.Fragment>
                        <ListItem
                          button
                          onClick={() => selectTicket(t)}
                          selected={
                            localStore.selectedTicket &&
                            localStore.selectedTicket.id == t.id
                          }
                          style={{
                            borderLeft: `5px ${getPriorityColor(
                              t.priority
                            )} solid`,
                          }}
                        >
                          <ListItemAvatar>
                            <Avatar>{t.name[0].toUpperCase()}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={
                              <React.Fragment>
                                {t.name}{" "}
                                <Rating
                                  className={classes.rating}
                                  value={t.starRating}
                                  readOnly
                                  size="small"
                                />
                              </React.Fragment>
                            }
                            secondary={formatDateInTimeZone(t.createdOn)}
                          />
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  {!localStore.isLoading && store.tickets.data.length == 0 && (
                    <Box p={2}>
                      <p>Yay, no tickets so far</p>
                    </Box>
                  )}
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={8}
              style={{ height: "545px", overflow: "auto" }}
              className={`${classes.scrollBar}`}
            >
              <TicketView {...props} />
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
});
