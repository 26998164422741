import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link } from "react-router-dom";
import { Home } from "./home";
import { Switch, Route } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { Navbar } from "./navbar";
import { useMst } from "../models/store";
import { observer } from "mobx-react";
import { ThirdPartyIntegration } from "./integrations/thirdpartyintegration";
import { Presence } from "./presence";
import { Reviews } from "./reviews";
import { PageView } from "./apps/pageView";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import {
  Snackbar,
  Collapse,
  ListItemIcon,
  LinearProgress,
} from "@material-ui/core";
import { Settings, BasicSettings } from "./settings";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ReviewRequests } from "./reviewRequests";
import { CreateReviewRequest } from "./createReviewRequest";
import { Setup } from "./setup";
import { Tickets } from "./tickets";
import { Campaigns } from "./campaigns";
import { TicketView } from "./tickets/ticketView";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import StarIcon from "@material-ui/icons/Star";
import EmojiObjectsIcon from "@material-ui/icons/EmojiObjects";
import PublicIcon from "@material-ui/icons/Public";
import TuneIcon from "@material-ui/icons/Tune";
import HomeIcon from "@material-ui/icons/Home";
import { CreateCampaign } from "./campaigns/create";
import { Audiences } from "./campaigns/audiences";
import { AudienceInfo } from "./campaigns/audienceInfo";
import { EditCampaign } from "./campaigns/edit";
import { CampaignView } from "./campaigns/view";
import SettingsInputCompositeIcon from "@material-ui/icons/SettingsInputComposite";
import { LocationView } from "./locationView";
import SearchIcon from "@material-ui/icons/Search";
import { Keywords } from "./keywords/keywords";
import { KeywordAnalysis } from "./keywords/analysis";
import { Insights } from "./insights";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import { Reports } from "./reports";
import { CrmIntegration } from "./integrations/crmintegration";
import FaceIcon from "@material-ui/icons/Face";
import { Customers } from "./customers";
import { CustomerView } from "./customers/view";
import { CreateCustomer } from "./customers/create";
import DescriptionIcon from "@material-ui/icons/Description";
import { Templates } from "./templates";
import { CreateTemplate } from "./templates/create";
import { Requests } from "./requests";
import { CreateRequest } from "./requests/create";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  scrollBar: {
    overflow: "hidden",
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));
const Dashboard = observer(({ ...props }) => {
  const classes = useStyles();
  const store = useMst();
  const [reviewOpen, setReviewOpen] = React.useState(false);
  const [customersOpen, setCustomersOpen] = React.useState(false);
  const [campaignOpen, setCampaignOpen] = React.useState(false);
  const [insightsOpen, setInsightsOpen] = React.useState(false);
  const [integrationsOpen, setIntegrationsOpen] = useState(false);
  const [templatesOpen, setTemplatesOpen] = useState(false);
  const handleReviewsToggle = () => {
    props.history.push({
      pathname: "/reviews",
    });
    setReviewOpen((prev) => !prev);
  };
  const handleInsightsToggle = () => {
    setInsightsOpen((prev) => !prev);
  };

  const handleCampaignToggle = () => {
    setCampaignOpen((prev) => !prev);
  };
  const handleIntegrationsToggle = () => {
    setIntegrationsOpen((prev) => !prev);
  };
  const handleAlertClose = () => {
    store.toggleAlert(false);
  };

  const handleCustomersToggle = () => {
    setCustomersOpen((prev) => !prev);
  };

  if (!store.isAuthenticated) {
    store.authenticate();
  } else {
    return (
      <React.Fragment>
        <Navbar />
        <div className={classes.root}>
          <CssBaseline />
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <List className={classes.scrollBar}>
              <ListItem
                button
                key="Overview"
                component={Link}
                to="/"
                selected={document.location.pathname == "/"}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItem>
              <Divider />
              <ListItem
                button
                key="presence"
                component={Link}
                to="/presence"
                selected={document.location.pathname == "/presence"}
              >
                <ListItemIcon>
                  <PublicIcon />
                </ListItemIcon>
                <ListItemText primary="Presence" />
              </ListItem>
              <ListItem
                button
                key="requests"
                component={Link}
                to="/requests"
                selected={document.location.pathname.indexOf("/requests") > -1}
              >
                <ListItemIcon>
                  <RotateLeftIcon />
                </ListItemIcon>
                <ListItemText primary="Requests" />
              </ListItem>
              <ListItem
                button
                key="reviews"
                component={Link}
                to="/reviews"
                selected={document.location.pathname == "/reviews"}
              >
                <ListItemIcon>
                  <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Reviews" />
              </ListItem>
              <ListItem button onClick={handleCustomersToggle}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary="Customers" />
                {customersOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={customersOpen} timeout="auto">
                <List>
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/customers"
                    selected={
                      document.location.pathname.indexOf("/customers") > -1
                    }
                  >
                    <ListItemText primary="All Customers" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/customers/create"
                    selected={document.location.pathname == "/customers/create"}
                  >
                    <ListItemText primary="Add a Customer" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="insights"
                component={Link}
                to="/insights"
                selected={document.location.pathname == "/insights"}
              >
                <ListItemIcon>
                  <SearchIcon />
                </ListItemIcon>
                <ListItemText primary="Insights" />
              </ListItem>
              <ListItem
                button
                key="reports"
                component={Link}
                to="/reports"
                selected={document.location.pathname == "/reports"}
              >
                <ListItemIcon>
                  <EqualizerIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
              <ListItem
                button
                key="tickets"
                component={Link}
                to="/tickets"
                selected={document.location.pathname == "/tickets"}
              >
                <ListItemIcon>
                  <ConfirmationNumberIcon />
                </ListItemIcon>
                <ListItemText primary="Tickets" />
              </ListItem>
              <ListItem button onClick={handleCampaignToggle} name="campaigns">
                <ListItemIcon>
                  <EmojiObjectsIcon />
                </ListItemIcon>
                <ListItemText primary="Campaigns" />
                {campaignOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={campaignOpen} timeout="auto">
                <List>
                  <ListItem
                    button
                    className={classes.nested}
                    key="campaigns"
                    component={Link}
                    to="/campaigns"
                    selected={document.location.pathname == "/campaigns"}
                  >
                    <ListItemText primary="See All" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/campaigns/create"
                    selected={document.location.pathname == "/campaigns/create"}
                  >
                    <ListItemText primary="Create New" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    component={Link}
                    to="/audience"
                    selected={document.location.pathname == "/audience"}
                  >
                    <ListItemText primary="Audience" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                component={Link}
                to="/templates"
                selected={document.location.pathname == "/templates"}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary="Templates" />
              </ListItem>
              <ListItem
                button
                onClick={handleIntegrationsToggle}
                name="integrations"
              >
                <ListItemIcon>
                  <SettingsInputCompositeIcon />
                </ListItemIcon>
                <ListItemText primary="Integrations" />
                {integrationsOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={integrationsOpen} timeout="auto">
                <List>
                  <ListItem
                    button
                    className={classes.nested}
                    key="thirdParty"
                    component={Link}
                    to="/integrations/thirdparty"
                    selected={
                      document.location.pathname == "/integrations/thirdparty"
                    }
                  >
                    <ListItemText primary="Third Party" />
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    key="crm"
                    component={Link}
                    to="/integrations/crm"
                    selected={document.location.pathname == "/integrations/crm"}
                  >
                    <ListItemText primary="Crms" />
                  </ListItem>
                </List>
              </Collapse>
              <ListItem
                button
                key="setup"
                component={Link}
                to="/setup"
                selected={document.location.pathname == "/setup"}
              >
                <ListItemIcon>
                  <TuneIcon />
                </ListItemIcon>
                <ListItemText primary="Setup" />
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path="/customers/view" component={CustomerView} />
              <Route
                exact
                path="/customers/create"
                component={CreateCustomer}
              />
              <Route exact path="/customers" component={Customers} />
              <Route
                exact
                path="/integrations/thirdparty"
                component={ThirdPartyIntegration}
              />
              <Route
                exact
                path="/integrations/crm"
                component={CrmIntegration}
              />
              {/* <Route exact path="/presence" component={Presence} />
              <Route exact path="/presence/:id" component={PageView} /> */}
              {/* <Route exact path="/reviews" component={Reviews} />
              <Route exact path="/reviews/request" component={ReviewRequests} />
              <Route
                exact
                path="/reviews/request/create"
                component={CreateReviewRequest}
              /> */}
              <Route exact path="/tickets/:id" component={TicketView} />
              <Route exact path="/tickets" component={Tickets} />
              {/* <Route exact path="/campaigns" component={Campaigns} /> */}
              {/* <Route
                exact
                path="/campaigns/view/:id"
                component={CampaignView}
              /> */}
              {/* <Route
                exact
                path="/campaigns/edit/:id"
                component={EditCampaign}
              /> */}
              <Route exact path="/audience" component={Audiences} />
              <Route exact path="/audience/:id" component={AudienceInfo} />
              {/* <Route
                exact
                path="/campaigns/create"
                component={CreateCampaign}
              /> */}
              <Route exact path="/settings" component={Settings} />
              <Route exact path="/settings/basic" component={BasicSettings} />
              <Route exact path="/setup" component={Setup} />
              <Route path="/location" component={LocationView} />
              <Route exact path="/templates" component={Templates} />
              <Route
                eaxct
                path="/templates/create"
                component={CreateTemplate}
              />
              <Route exact path="/requests/create" component={CreateRequest} />
              <Route exact path="/requests" component={Requests} />
              <Route
                exact
                path="/keywords/analysis/:word"
                component={KeywordAnalysis}
              />
              <Route
                exact
                path="/insights/sentiments/:word"
                component={Insights}
              />
              <Route exact path="/insights" component={Insights} />
              <Route path="/keywords" component={Keywords} />
              <Route path="/reports" component={Reports} />
              <Route exact path="/" component={LocationView} />
            </Switch>
          </main>
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          autoHideDuration={6000}
          key="bottom, right"
          open={store.isAlertOpen}
          onClose={handleAlertClose}
          message={store.alertMessage}
        />
      </React.Fragment>
    );
  }
});
export default Dashboard;
