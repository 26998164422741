import React, { Component, useState, createRef, useEffect } from "react";
import { formatDate, formatDateInTimeZone } from "./formatDate";
import Pagination from "@material-ui/lab/Pagination";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { observer } from "mobx-react";
import {
  Paper,
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Box,
  TextField,
  Grid,
  IconButton,
  Tabs,
  Tab,
  ListItemText,
  Button,
  Chip,
  FormControl,
  Select,
  MenuItem,
  ListItemIcon,
  withStyles,
  Slider,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { PagedResult } from "../models/pagedResult";
import { useMst } from "../models/store";
import {
  LoadReviews,
  CreateTicket,
  PostReply,
  LoadAllReviews,
} from "../services/appService";
import { applySnapshot } from "mobx-state-tree";
import { CreateTicketButton } from "./createTicket";
import { StyledMenuItem, StyledMenu } from "./dropdownMenu";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { ticketPriority } from "../enums/ticketPriority";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { getIcon } from "./getIcon";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import { SquidilyFilters } from "./getFilters";
import StarIcon from "@material-ui/icons/Star";
import {
  getQueryString,
  getQueryStringFromUrl,
  updateQueryString,
} from "./getQueryString";
import { Skeleton } from "@material-ui/lab";
import { SmallSelectMenu } from "./select";
import { getIntegrationTypeById } from "./getIntegrationTypeById";
import { LoadingSpinner } from "./loadingSpinner";
import InfiniteScroll from "react-infinite-scroller";

const useStyles = makeStyles((theme) => ({
  page: {
    margin: "15px 0",
  },
  rating: {
    verticalAlign: "middle",
  },
  title: {
    fontWeight: 600,
  },
  comment: {
    padding: "5px 16px 16px 16px",
  },
  input: {
    height: 50,
    background: "#fff",
  },
  tabs: {
    backgroundColor: "#efefef",
  },
  mainContent: {
    backgroundColor: "none",
  },
  smallAvatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  commentCardHeader: {
    paddingBottom: 0,
  },
  commentCardContent: {
    paddingTop: 0,
  },
  formControl: {
    width: "200px",
    paddingRight: "10px",
  },
}));
var isLoading = false;
export const ReviewTemplate = observer(({ reviews, pageId, props }) => {
  const classes = useStyles();
  const store = useMst();
  const [data, setData] = useState(null);
  const [reply, setReply] = useState("");
  const [selectedTicketMenu, setSelectedTicketMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [activateReplyLoading, setactivateReplyLoading] = useState(0);
  const stars = getQueryString(props.location.search, "stars");
  const postReply = async (e, data, id) => {
    debugger;
    try {
      setactivateReplyLoading(data.id);
      var isChildComment = false;
      if (id && id.indexOf("comment") > -1) {
        isChildComment = true;
      }
      const inputElement = document.getElementById(id);
      const response = await PostReply(
        data.socialPageId,
        data.reviewId,
        inputElement.value,
        isChildComment
      );
      if (response && response.data) {
        data.pushComment(inputElement.value);
        inputElement.value = "";
        setactivateReplyLoading(0);
      }
    } catch (error) {
      store.toggleAlert(true, "There is an error while posting your response");
    }
  };

  useEffect(() => {
    if (!data) {
      loadReviews();
    }
  });

  const loadAllReviews = async (index) => {
    store.loadAllReviews(index, 50);
  };
  const loadPageReviews = async (index) => {
    try {
      const page = store.findPage(pageId);
      const response = await LoadReviews(pageId, index);
      if (response && response.data) {
        const _pagedResult = PagedResult.create();
        applySnapshot(_pagedResult, response.data);
        page.set("reviews", _pagedResult);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const loadReviews = async (index) => {
    debugger;
    if (!index) {
      index = "";
    }

    var locationId = getQueryStringFromUrl(document.URL, "locationId") ?? 0;
    if (locationId) {
      locationId = parseInt(locationId);
    }
    var sourceId = getQueryStringFromUrl(document.URL, "sourceId") ?? 0;
    if (sourceId) {
      sourceId = parseInt(sourceId);
    }
    var periodId = getQueryStringFromUrl(document.URL, "months") ?? 0;
    if (periodId) {
      periodId = parseInt(periodId);
    }
    var stars = getQueryStringFromUrl(document.URL, "stars") ?? 0;
    if (stars) {
      stars = parseInt(stars);
    }
    var response = null;
    response = await LoadAllReviews(
      index,
      15,
      locationId,
      sourceId,
      periodId,
      stars
    );
    if (!data) {
      var _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      setData(_pagedResult);
    } else {
      if (response.data.data.length) {
        response.data.data.map((t) => {
          data.pushData(t);
        });
        data.set("awsPaginationIndex", response.data.awsPaginationIndex);
      }
    }
    isLoading = false;
  };

  const chip = (label, type) => {
    const response = getIcon(type);
    let color = "";
    if (type == 0) {
      color = "secondary";
    }
    return <Chip icon={response} label={label} color={color} />;
  };

  const handlePagination = (event, value) => {
    if (!isLoading) {
      debugger;
      loadReviews(data.awsPaginationIndex);
      isLoading = true;
    }
  };

  const createTicketButton = (review) => {
    if (review.ticketId) {
      return (
        <Button
          variant="outlined"
          aria-controls={`ticket-menu-${review.ticketId}`}
          component={Link}
          to={`/tickets/${review.ticketId}`}
          color="primary"
        >
          Open Ticket{" "}
          <OpenInNewIcon style={{ fontSize: "16px", marginLeft: "5px" }} />
        </Button>
      );
    } else {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            aria-controls={`ticket-menu-${review.id}`}
            onClick={(e) => handleTicketMenu(e, review)}
          >
            Create Ticket <ExpandMore />
          </Button>
          <StyledMenu
            id={`ticket-menu-${review.id}`}
            open={selectedTicketMenu == review.id}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            onClose={handleTicketClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transitionDuration="auto"
          >
            <StyledMenuItem>
              <ListItemText
                primary="Highest Priority"
                onClick={(e) => createTicket(e, review, ticketPriority.high)}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                primary="Medium Priority"
                onClick={(e) => createTicket(e, review, ticketPriority.medium)}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                primary="Low Priority"
                onClick={(e) => createTicket(e, review, ticketPriority.low)}
              />
            </StyledMenuItem>
          </StyledMenu>
        </React.Fragment>
      );
    }
  };

  const createTicket = async (e, review, priority) => {
    if (review) {
      const request = {
        reviewId: review.id,
        name: review.reviewerName,
        priority: priority,
      };
      //make a request;
      try {
        setSelectedTicketMenu(null);
        const response = await CreateTicket(request);
        if (response.data.id) {
          review.ticketId = response.data.id;
          store.toggleAlert(true, "Successfully created a ticket");
        }
      } catch (error) {
        store.toggleAlert(
          true,
          "Cannot create a ticket. Please try again later"
        );
      }
    }
  };

  const handleTicketMenu = (event, review) => {
    if (review.ticketId) {
      //go to ticket
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedTicketMenu(review.id);
    }
  };

  const handleTicketClose = () => {
    setAnchorEl(null);
    setSelectedTicketMenu(null);
  };

  const renderComment = (comment) => {
    return (
      <React.Fragment>
        <Box pl={4}>
          <Card
            elevation={0}
            style={{
              backgroundColor: "#f8f8f8",
              marginTop: "10px",
              borderRadius: "10px",
            }}
          >
            <CardHeader
              className={classes.commentCardHeader}
              classes={{ title: classes.title }}
              avatar={
                <Avatar
                  alt={comment.commentedBy}
                  className={classes.smallAvatar}
                >
                  {comment.commentedBy[0]}
                </Avatar>
              }
              title={comment.commentedBy}
              subheader={
                <React.Fragment>
                  <Box>{comment.message}</Box>
                </React.Fragment>
              }
            />
            <CardContent className={classes.commentCardContent}>
              <Box pl={2}>
                {comment.subComments &&
                  comment.subComments.map((t) => (
                    <Card elevation={0} style={{ backgroundColor: "#f8f8f8" }}>
                      <CardHeader
                        className={classes.commentCardHeader}
                        classes={{ title: classes.title }}
                        avatar={
                          <Avatar
                            alt={t.commentedBy}
                            className={classes.smallAvatar}
                          >
                            {t.commentedBy[0]}
                          </Avatar>
                        }
                        title={t.commentedBy}
                        subheader={
                          <React.Fragment>
                            <Box>{t.message}</Box>
                          </React.Fragment>
                        }
                      />
                    </Card>
                  ))}
                {renderCommentReply(comment, `comment-${comment.id}`)}
              </Box>
            </CardContent>
          </Card>
        </Box>
      </React.Fragment>
    );
  };

  const renderCommentReply = (comment, id) => {
    return (
      <Box pt={2}>
        <Grid container>
          <Grid item xs={11}>
            <TextField
              multiline
              rowsMax="4"
              variant="outlined"
              fullWidth
              placeholder="Write your response here"
              InputProps={{
                className: classes.input,
              }}
              disabled={comment.id == activateReplyLoading}
              id={id}
              // onChange={(e) => {
              //   setReply(e.target.value);
              // }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton
              onClick={(e) => {
                postReply(e, comment, id);
              }}
              disabled={comment.id == activateReplyLoading}
            >
              {comment.id == activateReplyLoading ? (
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  size={25}
                  thickness={4}
                />
              ) : (
                <SendRoundedIcon />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    );
  };
  const renderComments = (review, integrationType) => {
    debugger;
    switch (integrationType) {
      case 2: //facebook
        var elements = [];
        if (review.comments && review.comments.length > 0) {
          for (let x = 0; x < review.comments.length; x++) {
            elements.push(renderComment(review.comments[x]));
          }
          elements.push(renderCommentReply(review, `review-${review.id}`));
        }
        return elements;
      default:
        return (
          <React.Fragment>
            {review.reviewReply && (
              <Box
                pl={1.5}
                pr={1.5}
                pb={1}
                pt={1}
                bgcolor="#f3f3f3"
                borderRadius={5}
                mt={2}
              >
                <Typography
                  variant="body2"
                  color="textPrimary"
                  component="p"
                  className={classes.title}
                  pb={1}
                >
                  You responded
                </Typography>
                <Typography variant="body2" color="textPrimary" component="p">
                  {review.reviewReply}
                </Typography>
              </Box>
            )}
            {!review.reviewReply && review.integrationType > 0 && (
              <Box mt={2}>
                <Grid container>
                  <Grid item xs={11}>
                    <TextField
                      multiline
                      rowsMax="4"
                      variant="outlined"
                      fullWidth
                      placeholder="Write your response here"
                      InputProps={{
                        className: classes.input,
                      }}
                      onChange={(e) => {
                        setReply(e.target.value);
                      }}
                      id={review.reviewId}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      onClick={(e) => {
                        postReply(e, review, review.reviewId);
                      }}
                    >
                      <SendRoundedIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            )}
          </React.Fragment>
        );
        break;
    }
  };

  const handleLocationChange = (locationId) => {
    props.history.push({
      pathname: "/reviews",
      search: `?locationId=${locationId}`,
    });
    setData(null);
    isLoading = true;
    loadReviews();
  };

  const handleSourceChange = (sourceId) => {
    props.history.push({
      pathname: "/reviews",
      search: updateQueryString("sourceId", sourceId, props.location.search),
    });
    setData(null);
    isLoading = true;
    loadReviews();
  };

  const handlePeriodChange = (periodId) => {
    props.history.push({
      pathname: "/reviews",
      search: updateQueryString("months", periodId, props.location.search),
    });
    setData(null);
    isLoading = true;
    loadReviews();
  };

  const filterByStars = (stars) => {
    props.history.push({
      pathname: "/reviews",
      search: updateQueryString("stars", stars, props.location.search),
    });
    setData(null);
    isLoading = true;
    loadReviews();
  };

  const PrettoSlider = withStyles({
    root: {
      color: "#00adff",
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "2px solid currentColor",
      marginTop: -8,
      marginLeft: -12,
      "&:focus, &:hover, &$active": {
        boxShadow: "inherit",
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% + 4px)",
    },
    track: {
      height: 8,
      borderRadius: 4,
    },
    rail: {
      height: 8,
      borderRadius: 4,
    },
  })(Slider);

  const filter = () => {
    return (
      <SquidilyFilters
        props={props}
        locationChange={handleLocationChange}
        sourceChange={handleSourceChange}
        periodChange={handlePeriodChange}
        extraFilter={
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              native
              onChange={(e) => filterByStars(e.target.value)}
              value={stars}
              defaultValue={0}
              input={<SmallSelectMenu />}
            >
              <option aria-label="4 Stars" value={0}>
                All stars
              </option>
              <option aria-label="4 Stars" value={5}>
                5 stars
              </option>
              <option aria-label="4 Stars" value={4}>
                4 stars
              </option>
              <option aria-label="4 Stars" value={3}>
                3 stars
              </option>
              <option aria-label="4 Stars" value={2}>
                2 stars
              </option>
              <option aria-label="4 Stars" value={1}>
                1 stars
              </option>
            </Select>
          </FormControl>
        }
      />
    );
  };

  var elementsRef;
  if (data && data.data && data.data.length > 0) {
    return (
      <Box>
        <Box pb={2}>{filter()}</Box>
        {data.data.map((t) => (
          <Box pb={2}>
            <Paper elevation={0}>
              <Card variant="outlined">
                <CardHeader
                  classes={{ title: classes.title }}
                  avatar={
                    <Avatar alt={t.reviewerName} src={t.reviewerPhoto}>
                      {t.reviewerName[0]}
                    </Avatar>
                  }
                  title={
                    <Typography>
                      {t.reviewerName}
                      {"    "}
                      <span style={{ float: "right" }}>
                        {getIcon(t.integrationType, null, "48px", "16px", true)}
                      </span>
                    </Typography>
                  }
                  subheader={
                    <Box>
                      <Rating
                        className={classes.rating}
                        value={t.starRating}
                        readOnly
                        size="small"
                      />{" "}
                      | {formatDate(t.createTime)}
                    </Box>
                  }
                />
                <CardContent className={classes.comment}>
                  <Box p={2}>
                    <Typography
                      variant="body2"
                      color="textPrimary"
                      component="p"
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t.reviewerComment,
                        }}
                      />
                    </Typography>
                  </Box>
                  {renderComments(t, t.integrationType)}
                  <Box pt={2} component="div">
                    {createTicketButton(t)}
                  </Box>
                </CardContent>
              </Card>
            </Paper>

            {t.getSentiments && t.getSentiments()}
          </Box>
        ))}
        {data.data.length > 0 &&
          data.awsPaginationIndex &&
          data.awsPaginationIndex != "{}" && (
            <InfiniteScroll
              pageStart={data.awsPaginationIndex}
              loadMore={handlePagination}
              hasMore={data.awsPaginationIndex == "{}" ? false : true}
              loader={
                <Typography align="center">
                  <LoadingSpinner />
                </Typography>
              }
            />
          )}
      </Box>
    );
  } else if (data && data.data && data.data.length == 0) {
    return (
      <React.Fragment>
        {filter()}
        <Typography>
          No reviews available to review.{" "}
          <Box component={Link} to="/reviews/request">
            Request
          </Box>{" "}
          a review now.
        </Typography>
      </React.Fragment>
    );
  } else {
    return <LoadingSpinner />;
  }
});
