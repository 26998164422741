import React, { Component } from "react";
import {
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  makeStyles,
  Typography,
  Button,
  Box,
  StepContent,
  Card,
  CardContent,
  TextField,
  Grid,
  TextareaAutosize,
  Select,
  FormControl,
  MenuItem,
  Chip,
  Avatar,
  Icon,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Modal,
  Backdrop,
  Switch,
  Fade,
  CardHeader,
} from "@material-ui/core";
import { useMst } from "../../models/store";
import { useState } from "react";
import {
  SaveCampaign,
  GetAllAudiences,
  GetCampaign,
  ProcessCampaign,
  GetAvailableTags,
  LoadTemplate,
  UploadFile,
} from "../../services/appService";
import { Campaign } from "../../models/campaign";
import { Link, useParams } from "react-router-dom";
import { getSnapshot, applySnapshot } from "mobx-state-tree";
import { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import emailIcon from "../../images/email.png";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { observer } from "mobx-react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { GetMessageBubble } from "../../common/getMessageBubble";
import { CampaignType } from "../../enums/campaignType";
import { GetTemplatesCombo } from "../../common/getTemplatesCombo";
import { SendType } from "../../enums/sendType";
import { Skeleton } from "@material-ui/lab";
import { SmallSelectMenu } from "../../common/select";
import { CSVUpload } from "../../common/getCSVUpload";
const getSteps = () => {
  return [
    "Campaign Name",
    "Campaign Type",
    "Select your audience",
    "Add your tags",
    "Compose Email",
    "Compose SMS",
    "Finish",
  ];
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    border: "none",
    borderRadius: "6px",
  },
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    maxWidth: 800,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  formControl: {
    width: "200px",
  },
  gap: {
    marginTop: "15px",
  },
}));
var loading = false;
export const EditCampaign = observer(({ ...props }) => {
  const store = useMst();
  const { id } = useParams();
  const [audienceList, setAudienceList] = useState(null);
  const [availableTagsList, setAvailableTagsList] = useState(null);
  const [campaignData, setCampaignData] = useState(null);
  const [smsCustomization, setSmsCustomization] = useState(false);
  const [emailCustomization, setEmailCustomization] = useState(false);
  const [openCSVModal, setOpenCSVModal] = useState(false);
  const [localStore, setLocalStore] = useState({
    campaignData: null,
    audienceList: null,
    availableTags: null,
    addedTags: [],
    activeStep: 0,
    nextStep: function () {
      this.activeStep = this.activeStep + 1;
      this.campaignData.set("stage", this.activeStep);
    },
    prevStep: function () {
      if (this.activeStep == 1) {
        return;
      }
      this.activeStep = this.activeStep - 1;
      this.campaignData.set("stage", this.activeStep);
    },
    addTag: function (tag) {
      this.addedTags.push(tag);
    },
    removeTag: function (tag) {
      const tagIndex = this.addedTags.indexOf(tag);
      if (tagIndex > -1) {
        this.addedTags.splice(tagIndex, 1);
      }
    },
    set: function (key, value) {
      this[key] = value;
    },
  });
  const steps = getSteps();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [tags, setTags] = useState([]);
  const [addedTags, setAddedTags] = useState([]);
  const [openModal, setOpenModal] = React.useState(
    localStore.campaignData && localStore.campaignData.body ? false : true
  );

  useEffect(() => {
    if (!localStore.campaignData) {
      loadCampaign(id).then((t) => {
        if (!store.locations) {
          store.loadPages();
          loading = true;
        }
      });
    } else if (localStore.activeStep == 2 && !store.audienceListForCombo) {
      store.loadAudienceListForCombo();
    } else if (localStore.activeStep == 3) {
      if (!store.availableTags) {
        store.loadAvailableTags();
      } else {
        if (localStore.campaignData.tags.length > 0 && !addedTags) {
          var arrayList = [];
          localStore.campaignData.tags.forEach((x) => {
            var selectedTag = null;
            store.availableTags &&
              store.availableTags.forEach((t) => {
                if (x == t.name) {
                  selectedTag = t;
                }
              });
            arrayList.push(selectedTag);
          });
          setAddedTags(arrayList);
        }
      }
    }
    //  else if (localStore.activeStep == 4) {
    //   !store.templatesForCombo && store.loadTemplatesForCombo();
    // }
  });

  const loadCampaign = async (id) => {
    debugger;
    if (id) {
      if (!localStore.campaignData) {
        GetCampaign(id).then((t) => {
          if (t && t.data) {
            const _campaign = Campaign.create();
            applySnapshot(_campaign, t.data);
            var localData = { ...localStore };
            localData.campaignData = _campaign;
            localData.activeStep = _campaign.stage;
            setLocalStore(localData);
          }
        });
      }
    } else {
      var s = Campaign.create();
      var getLocalStore = { ...localStore };
      getLocalStore.campaignData = s;
      setLocalStore(getLocalStore);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleBack = () => {
    localStore.prevStep();
    setActiveStep(localStore.activeStep);
  };
  const handleNext = async () => {
    //reload availableTags store value
    if (localStore.activeStep == 3) {
      store.loadAvailableTags();
    }
    localStore.nextStep();
    // localStore.set("activeStep", localStore.activeStep + 1);
    var response = await SaveCampaign(localStore.campaignData);
    if (response && response.data) {
      var _campaign = Campaign.create();
      applySnapshot(_campaign, response.data);
      window.history.replaceState(
        null,
        _campaign.name,
        `/campaigns/edit/${_campaign.id}`
      );
      localStore.set("campaignData", _campaign);
    }
    setActiveStep(localStore.activeStep);
  };

  const handleEditorChange = (content, editor) => {
    localStore.campaignData.set("body", parseBody(content));
  };

  const handleData = (e, key) => {
    var value = e.target.value;
    if (!value) {
      e.preventDefault();
      return;
    }
    if (key == "audienceId") {
      value = parseInt(value);
    }
    localStore.campaignData.set(key, value);
  };
  const process = async () => {
    try {
      const response = await ProcessCampaign(localStore.campaignData.id);
      if (response.data) {
        store.loadCampaigns(1);
        window.location = "/campaigns";
      }
    } catch (error) {
      store.toggleAlert(true, "There is an error executing your request");
    }
  };

  const getSelectedTags = () => {
    var arrayList = [];
    tags.forEach((x) => {
      var selectedTag = null;
      availableTagsList.forEach((t) => {
        if (x == t.name) {
          selectedTag = t;
        }
      });
      if (!selectedTag) {
        arrayList.push({ id: 0, name: x });
      } else {
        arrayList.push(selectedTag);
      }
    });

    return arrayList;
  };

  const onTagSelect = (e, val) => {
    setAddedTags(val);
    localStore.campaignData.set("tags", []);
    val.forEach((t) => {
      localStore.campaignData.addTag(t.name);
    });
  };

  const parseBody = (value) => {
    if (value.indexOf("[BusinessName]") > -1) {
      value = value.replace(
        "[BusinessName]",
        store.accountInfo.account.businessName
      );
    }
    if (value.indexOf("[ContactEmail]") > -1) {
      value = value.replace(
        "[ContactEmail]",
        store.accountInfo.account.emailAddress
      );
    }
    if (value.indexOf("[RatingWidget]") > -1) {
    }

    return value;
  };

  const handleMouseOut = (e) => {
    let prevTags = [...addedTags];
    if (e.target.value) {
      prevTags.push({ name: e.target.value, id: 0 });
      setAddedTags(prevTags);
      localStore.campaignData.addTag(e.target.value);
    }
  };

  const handleKeyPress = (e) => {
    let prevTags = [...addedTags];
    if (e.keyCode == 13 || e.keyCode == 32) {
      if (e.target.value) {
        prevTags.push({ name: e.target.value, id: 0 });
        setAddedTags(prevTags);
        localStore.campaignData.addTag(e.target.value);
      }
    } else if (e.keyCode == 8) {
      prevTags.pop();
      setAddedTags(prevTags);

      localStore.campaignData.set(
        "tags",
        prevTags.map((t) => t.name)
      );
    }
  };

  const handleCampaignType = (e) => {
    localStore.campaignData.set("campaignType", parseInt(e.target.value));
  };

  const handleTemplateSelect = (e) => {
    LoadTemplate(e.target.value).then((t) => {
      var getData = { ...localStore };
      getData.campaignData.set("body", t.data.html);
      setLocalStore(getData);
    });
  };

  const handleCustomTemplate = () => {
    setOpenModal(false);
  };

  const renderSmsTemplate = (value) => {
    var renderedText = value;
    if (value) {
      if (value.indexOf("[ShortLink]") > -1) {
        renderedText = renderedText.replace(
          "[ShortLink]",
          "rebrand.ly/ni878sklj"
        );
      }

      if (value.indexOf("[QuickReply]") > -1) {
        if (localStore.campaignData.campaignType == CampaignType.review) {
          renderedText = renderedText.replace(
            "[QuickReply]",
            "<br><br>Please reply with number <br> 1 - One star <br> 2 - Two stars <br> 3 - Three stars <br> 4 - Four stars <br> 5 - Five stars"
          );
        } else {
          renderedText = renderedText.replace(
            "[QuickReply]",
            "<br><br>Please reply with a score between 1 (very unsatisfied) and 10 (very satisfied) "
          );
        }
      }
      if (
        value.indexOf("[ShortLink]") == -1 &&
        value.indexOf("[QuickReply]") == -1
      ) {
        store.toggleAlert(
          true,
          "Sms Template requires atleast one of these shortcodes [ShortLink] [QuickReply]"
        );
      }
      renderedText = renderedText.replace(
        "[BusinessName]",
        store.accountInfo.account.businessName
      );
    }

    return renderedText ?? "";
  };

  const handleSmsTemplate = (e) => {
    var getData = { ...localStore };
    getData.campaignData.set("smsBody", e.currentTarget.value);
  };

  const closeCSVModal = () => {
    setOpenCSVModal(false);
  };

  const defaultSmsBody =
    "How was your experience at [BusinessName]? Please take a moment to leave a review [ShortLink]";
  const getAndUploadCSV = (content) => {
    UploadFile(content, id ?? localStore.campaignData.id).then((t) => {
      var getStore = { ...localStore };
      getStore.campaignData.set("attachment", t.data);
      setLocalStore(getStore);
      setOpenCSVModal(false);
    });
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <Box pr={2} pl={2}>
              <Typography variant="h2" component="h1">
                Name your campaign
              </Typography>
              <Typography component="p" gutterBottom>
                Use unique name. Ex: Chat bot feature
              </Typography>
              <TextField
                variant="outlined"
                fullWidth
                placeholder="Campaign Name"
                defaultValue={localStore.campaignData.name}
                onChange={(e) => handleData(e, "name")}
              />
            </Box>
          </React.Fragment>
        );
      case 1:
        return (
          <React.Fragment>
            <Card elevation={0}>
              <CardContent>
                <Box pb={2}>
                  <Typography variant="h2" component="h1">
                    Campaign Type
                  </Typography>
                  <Typography component="p" gutterBottom>
                    Please select a type for the campaign
                  </Typography>
                </Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="campaignType"
                    name="campaignType"
                    value={localStore.campaignData.campaignType}
                    onChange={handleCampaignType}
                  >
                    <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Review Request"
                    />
                    <FormControlLabel
                      value={2}
                      control={<Radio />}
                      label="Feedback Request"
                    />
                  </RadioGroup>
                </FormControl>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      case 2:
        return (
          <React.Fragment>
            <Card elevation={0}>
              <CardContent>
                <Box>
                  {/* <Typography component="h1" variant="h2">
                    Select your audience
                  </Typography>
                  <Typography component="p" gutterBottom>
                    Select your audience you are addressing or{" "}
                    <Box component={Link} to="/audience">
                      Create
                    </Box>{" "}
                    new audience list.
                  </Typography> */}
                  <Card variant="outlined">
                    <CardContent>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                      >
                        <Grid item xs={6}>
                          <Box pb={1}>
                            <Typography variant="overline" component="h4">
                              <strong>Select a location</strong>
                            </Typography>
                            <Typography variant="caption">
                              Select the business location that the campaign
                              with be sent from.
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box style={{ float: "right" }}>
                            <FormControl
                              variant="outlined"
                              className={classes.formControl}
                            >
                              {!store.locations ? (
                                <Skeleton style={{ paddingBottom: "30px" }} />
                              ) : (
                                <Select
                                  native
                                  // onChange={(e) => locationChange(e.target.value)}
                                  // value={locationId}
                                  defaultValue={0}
                                  input={<SmallSelectMenu />}
                                >
                                  {store.locations &&
                                    store.locations.length > 0 &&
                                    store.locations.map((t) => {
                                      return (
                                        <option value={t.id}>
                                          {t.locationName}
                                        </option>
                                      );
                                    })}
                                </Select>
                              )}
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card variant="outlined" className={classes.gap}>
                    <CardContent>
                      <Grid container direction="row" justify="space-between">
                        <Grid item xs={6}>
                          <Box pb={1}>
                            <Typography variant="overline" component="h4">
                              <strong>Select the audience</strong>
                            </Typography>
                            <Typography variant="caption">
                              Select your audience whom you are addressing to.
                              You can also create a new audience list.{" "}
                              <Box component={Link} to="/audience">
                                Create
                              </Box>{" "}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Box style={{ float: "right" }}>
                            <FormControl className={classes.formControl}>
                              <Select
                                displayEmpty
                                variant="outlined"
                                fullWidth
                                defaultValue={
                                  localStore.campaignData.audienceId
                                }
                                onChange={(e) => handleData(e, "audienceId")}
                                input={<SmallSelectMenu />}
                              >
                                {(!store.audienceListForCombo ||
                                  !store.audienceListForCombo.length) && (
                                  <MenuItem disabled>
                                    No audience present
                                  </MenuItem>
                                )}
                                {store.audienceListForCombo &&
                                  store.audienceListForCombo.length > 0 &&
                                  store.audienceListForCombo.map((t) => (
                                    <MenuItem value={t.id}>{t.name}</MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        className={classes.gap}
                      >
                        <Grid item>
                          <Typography variant="caption" align="center">
                            You can also upload your audience list.{" "}
                          </Typography>
                        </Grid>
                        <Grid item>
                          {localStore.campaignData.attachment && (
                            <Box display="inline" pr={2}>
                              <Typography component="span">
                                <a
                                  href={
                                    localStore.campaignData.getAttachmentInfo()
                                      .link
                                  }
                                  target="_blank"
                                >
                                  {
                                    localStore.campaignData.getAttachmentInfo()
                                      .name
                                  }
                                </a>
                              </Typography>
                            </Box>
                          )}
                          <Button
                            variant="outlined"
                            onClick={() => setOpenCSVModal(true)}
                          >
                            Choose CSV
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      case 3:
        return (
          <React.Fragment>
            <Box pb={2}>
              <Typography component="h1" variant="h2">
                Tag your campaign
              </Typography>
              <Typography component="p" gutterBottom>
                Ex: Product name, service name, feature name etc. This tag is
                useful for insights.
              </Typography>
            </Box>
            <Autocomplete
              multiple
              id="tags-outlined"
              getOptionLabel={(option) => option.name}
              options={store.availableTags ?? []}
              // defaultValue={[availableTagsList[0]]}
              value={addedTags ?? []}
              onChange={onTagSelect}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  onKeyDown={handleKeyPress}
                  onBlur={handleMouseOut}
                />
              )}
            />
          </React.Fragment>
        );
      case 4:
        return (
          <React.Fragment>
            <Modal
              disableEnforceFocus
              disableBackdropClick
              open={
                localStore.campaignData && localStore.campaignData.body
                  ? false
                  : openModal
              }
              className={classes.modal}
              onClose={handleModalClose}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Paper style={{ width: "480px" }}>
                <Box p={4} textAlign="center">
                  <Box>
                    <EditOutlinedIcon fontSize="large" />
                    <Typography
                      style={{
                        fontSize: "24px",
                        display: "inline-block",
                        verticalAlign: "top",
                      }}
                    >
                      Create a template
                    </Typography>
                  </Box>
                  <Box pb={2}>
                    <Typography>
                      Lets start writing an email to your customer(s). You can
                      either use saved template or start from scratch
                    </Typography>
                  </Box>
                  <GetTemplatesCombo
                    handleChange={handleTemplateSelect}
                    templateType={localStore.campaignData.campaignType}
                    sendType={SendType.email}
                  />
                  <Box pt={2} pb={2}>
                    <Typography>Or</Typography>
                  </Box>

                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleCustomTemplate}
                  >
                    Create from scratch
                  </Button>
                </Box>
              </Paper>
            </Modal>
            <Card elevation={0} variant="outlined">
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    <Box p={2} className={classes.root}>
                      <Typography variant="body">
                        Please use the following short codes to insert
                        information dynamically.
                      </Typography>
                      <Chip
                        variant="outlined"
                        label="[CustomerName]"
                        size="small"
                        className={classes.chip}
                      />
                      <Chip
                        variant="outlined"
                        label="[BusinessName]"
                        size="small"
                        className={classes.chip}
                      />
                      <Chip
                        variant="outlined"
                        label="[ContactEmail]"
                        size="small"
                        className={classes.chip}
                      />
                      <Chip
                        variant="outlined"
                        label="[RatingWidget]"
                        size="small"
                        className={classes.chip}
                      />
                    </Box>
                    <Box p={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder="Email Subject"
                        label="Subject"
                        defaultValue={localStore.campaignData.subject}
                        onChange={(e) => handleData(e, "subject")}
                      />
                    </Box>
                    {(localStore.campaignData.body || !openModal) && (
                      <Box mr={2} ml={2}>
                        <Editor
                          apiKey="eq6m05jebhzzr8fq6xyy0box83j41rk6mow3woyeufloa84p"
                          initialValue={parseBody(localStore.campaignData.body)}
                          init={{
                            height: 500,
                            width: "100%",
                            menubar: false,
                            plugins: [
                              "advlist autolink lists link image charmap print preview anchor",
                              "searchreplace visualblocks code fullscreen",
                              "insertdatetime media table paste code help wordcount",
                            ],
                            toolbar:
                              "undo redo | fontsizeselect formatselect forecolor | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat image | help",
                            branding: false,
                          }}
                          onEditorChange={handleEditorChange}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </React.Fragment>
        );
      case 5:
        return (
          <React.Fragment>
            {/* <Box pb={4}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography component="h2" variant="subtitle1">
                    <strong>Enable Customization</strong>
                  </Typography>
                  <Typography component="p" variant="subtitle2">
                    By default it will use the default template.
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Switch
                    checked={
                      localStore.campaignData.smsBody ?? smsCustomization
                    }
                    onChange={handleSmsCustomization}
                    name="checkedA"
                    inputProps={{ "aria-label": "secondary checkbox" }}
                  />
                </Grid>
              </Grid>
            </Box> */}
            <Box>
              <Box pt={2} pb={2} className={classes.root}>
                <Typography variant="body">
                  Please use the following short codes to insert information
                  dynamically.
                </Typography>
                <Chip
                  variant="outlined"
                  label="[CustomerName]"
                  size="small"
                  className={classes.chip}
                />
                <Chip
                  variant="outlined"
                  label="[BusinessName]"
                  size="small"
                  className={classes.chip}
                />
                <Chip
                  variant="outlined"
                  label="[ShortLink]"
                  size="small"
                  className={classes.chip}
                />
                <Chip
                  variant="outlined"
                  label="[QuickReply]"
                  size="small"
                  className={classes.chip}
                />
              </Box>
              <Grid container>
                <Grid item xs={5}>
                  <TextareaAutosize
                    rowsMin={8}
                    style={{
                      width: "100%",
                      overflowY: "auto",
                    }}
                    onChange={handleSmsTemplate}
                    value={localStore.campaignData.smsBody ?? defaultSmsBody}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Box
                    style={{
                      width: "400px",
                      height: "150px",
                      margin: "0 auto",
                    }}
                  >
                    <Box style={{ float: "right", display: "block" }} pb={3}>
                      <GetMessageBubble
                        text={renderSmsTemplate(
                          localStore.campaignData.smsBody ?? defaultSmsBody
                        )}
                        position="right"
                      />
                    </Box>
                    <Box style={{ float: "left", display: "block" }}>
                      <GetMessageBubble text="....." position="left" />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        );
      case 6:
        return (
          <React.Fragment>
            <Typography align="center">
              <Icon>
                <img src={emailIcon} alt="email" height={128} width={128} />
              </Icon>
            </Typography>
            <Typography align="center" variant="h2">
              Almost there!
            </Typography>
            <Box
              component="div"
              width={400}
              style={{ marginRight: "auto", marginLeft: "auto" }}
              pb={2}
            >
              <Typography align="center">
                You are ready to send email to your customers. Make sure you
                have reviewed all your changes before sending
              </Typography>
            </Box>

            <Typography align="center">
              <Button color="secondary" variant="contained" onClick={process}>
                PROCESS NOW
              </Button>
            </Typography>
          </React.Fragment>
        );
      default:
        break;
    }
  };
  if (localStore.campaignData) {
    return (
      <React.Fragment>
        <Box pt={6}>
          <Container className={classes.container}>
            <Stepper
              orientation="horizontal"
              activeStep={localStore.activeStep}
              alternativeLabel
              style={{ backgroundColor: "transparent" }}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Box pt={2}>
              <Paper className={classes.paper}>
                <Box pt={2} pb={2} variant="outlined">
                  {getStepContent(localStore.activeStep)}
                  <Box pt={2}>
                    <Button
                      disabled={localStore.activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                    {localStore.activeStep < steps.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        Next
                      </Button>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Box>
            <CSVUpload
              onClose={closeCSVModal}
              open={openCSVModal}
              transferData={getAndUploadCSV}
            />
          </Container>
        </Box>
      </React.Fragment>
    );
  } else {
    return <React.Fragment>Loading....</React.Fragment>;
  }
});
