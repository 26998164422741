import React, { Component } from "react";
export const GetAverageRating = (totalReviewsByStar, totalReviewsByPage) => {
  if (totalReviewsByStar == null) {
    return 0;
  }
  var calculatedValue =
    (5 * totalReviewsByStar[0] +
      4 * totalReviewsByStar[1] +
      3 * totalReviewsByStar[2] +
      2 * totalReviewsByStar[3] +
      1 * totalReviewsByStar[4]) /
    totalReviewsByPage.reduce((a, b) => a + b, 0);
  if (!calculatedValue) {
    return 0;
  }
  return Number(calculatedValue.toFixed(1));
};
