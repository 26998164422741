import React, { Component } from "react";
// import { Bar, Line as LineChart } from "react-chartjs-2";
import { useParams } from "react-router-dom";
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  makeStyles,
  Divider,
  Fade,
  Button,
  Tooltip,
  Paper,
} from "@material-ui/core";
import { useMst } from "../../models/store";
import { observer } from "mobx-react";
import { getSnapshot } from "mobx-state-tree";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TrendingDownIcon from "@material-ui/icons/TrendingDown";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import StarIcon from "@material-ui/icons/Star";
import { StyledCardHeader } from "../../common/styledCardHeader";
import Rating from "@material-ui/lab/Rating";
import { Page } from "../../models/page";
const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #e9e9e9",
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: "16px",
    },
  },
  statsCard: {
    borderRadius: 0,
  },
}));

const metricsSwitch = (page) => {
  if (page.integrationType == 1) {
    return <GoogleMetrics data={page.pageOverview} />;
  } else {
    return <React.Fragment></React.Fragment>;
  }
};
const data1 = {
  labels: ["Oct", "Nov", "Dec", "Jan", "Feb", "Mar", "Apr"],
  datasets: [
    {
      label: "Views",
      fillColor: "rgb(237,247,249)",
      pointColor: "rgb(0,0,0)",
      // pointStrokeColor: "#fff",
      // pointHighlightFill: "#fff",
      // pointHighlightStroke: "#edf7f9",
      borderColor: "rgb(245,167,181, 0.8)",
      backgroundColor: "rgb(245,167,181, 0.8)",
      data: [200, 346, 155, 700, 110, 139, 107, 195, 239, 290],
    },
  ],
};

const options = {
  scaleShowGridLines: true,
  scaleGridLineColor: "rgb(237,247,249)",
  scaleGridLineWidth: 1,
  scaleShowHorizontalLines: true,
  scaleShowVerticalLines: true,
  bezierCurve: true,
  bezierCurveTension: 0.4,
  pointDot: true,
  pointDotRadius: 4,
  pointDotStrokeWidth: 1,
  pointHitDetectionRadius: 20,
  datasetStroke: true,
  datasetStrokeWidth: 2,
  datasetFill: true,
  legendTemplate:
    '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<datasets.length; i++){%><li><span style="background-color:<%=datasets[i].strokeColor%>"></span><%if(datasets[i].label){%><%=datasets[i].label%><%}%></li><%}%></ul>',
};

const trendUi = (value) => {
  if (value == 1) {
    return <TrendingUpIcon fontSize="large" style={{ color: "#6fd026" }} />;
  } else if (value == 2) {
    return <TrendingDownIcon style={{ color: "#d02626" }} fontSize="large" />;
  } else if (value == 3) {
    return <TrendingFlat fontSize="large" style={{ color: "#2691d0" }} />;
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

export const Overview = observer(() => {
  const store = useMst();
  const classes = useStyles();
  const { id } = useParams();
  const page = store.pages.data.find((e) => {
    return e.id == id;
  });

  if (!page.pageOverview) {
    page.loadOverview();
    return <p>Loading</p>;
  } else {
    const data = JSON.parse(
      JSON.stringify(getSnapshot(page.pageOverview.ratingsGraph))
    );
    return (
      <React.Fragment>
        <Box pt={2} pb={2}>
          <Box component="div" pt={3} pb={3}>
            <Grid container>
              <Grid item xs={8}>
                {/* {data && <Bar data={data} />} */}
              </Grid>
              <Grid item xs={4}>
                <Box pt={4}>
                  <Card elevation={0} variant="outlined">
                    <StyledCardHeader text="Summary" />
                    <CardContent style={{ paddingBottom: "16px" }}>
                      <Grid container style={{ paddingBottom: "16px" }}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" component="h2">
                            Total Reviews
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="h3"
                            color="textSecondary"
                          >
                            This month
                          </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: "right" }}>
                          <Typography variant="h4" component="span">
                            {page.pageOverview.totalReviewsThisMonth.count}
                          </Typography>
                          <Typography variant="h4" component="span">
                            {trendUi(
                              page.pageOverview.totalReviewsThisMonth.trend
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container style={{ paddingTop: "16px" }}>
                        <Grid item xs={6}>
                          <Typography variant="subtitle1" component="h2">
                            <Rating
                              value={5}
                              readOnly
                              style={{ fontSize: "12px" }}
                            />{" "}
                            5-stars
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            component="h3"
                            color="textSecondary"
                          >
                            This month
                          </Typography>
                        </Grid>
                        <Grid item xs={5} style={{ textAlign: "right" }}>
                          <Typography variant="h4" component="span">
                            {
                              page.pageOverview.totalFiveStarReviewsThisMonth
                                .count
                            }
                          </Typography>
                          <Typography variant="h4" component="span">
                            {trendUi(
                              page.pageOverview.totalFiveStarReviewsThisMonth
                                .trend
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box component="div" pt={3} pb={3}>
            <Grid container>
              <Grid item xs={8}>
                {/* <LineChart
                  data={data1}
                  width={600}
                  height={400}
                  options={options}
                /> */}
              </Grid>
              <Grid item xs={4}>
                {metricsSwitch(page)}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
});

const GoogleMetrics = ({ data }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const handleToggle = () => {
    setChecked((prev) => !prev);
  };
  const createData = (data) => {
    let d = [];
    for (let index = 0; index < data.length; index++) {
      d.push(metricDesign(data[index]));
    }
    return d;
  };

  const metricDesign = (data) => {
    return (
      <Tooltip title={data.toolTip} placement="bottom">
        <Card elevation={0} className={classes.statsCard}>
          <CardContent className={classes.cardContent}>
            <Typography component="p" variant="subtitle1">
              {data.name}
            </Typography>
            <Typography component="span" variant="h6">
              <strong>{data.count}</strong>
              {trendUi(data.trend)}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    );
  };
  if (data.trends.length > 0) {
    return (
      <React.Fragment>
        <Grid container direction="column">
          <Card elevation={0} variant="outlined">
            <StyledCardHeader
              text="This month"
              style={{ fontWeight: "bold" }}
            />
            {createData(data.trends)}
          </Card>
        </Grid>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};
