import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";
export const AreaGraph = ({
  name,
  data,
  minima,
  maxima,
  backgroundColor,
  lineColor,
}) => {
  const options = {
    series: [
      {
        name: name,
        data: data.dataValues,
      },
    ],
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
    },
    labels: data.dataLabels ?? [],
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      opposite: true,
      max: maxima,
      min: minima,
      decimalsInFloat: 1,
    },
    legend: {
      horizontalAlign: "left",
    },
  };
  if (backgroundColor) {
    options.fill = {
      colors: [backgroundColor],
    };
  }
  if (lineColor) {
    options.colors = [lineColor];
  }
  if (minima) {
    options.yaxis.min = minima;
  }
  if (maxima) {
    options.yaxis.max = maxima;
  }
  return (
    <ReactApexChart
      options={options}
      series={options.series}
      height={300}
      type="area"
      style={{ width: "98%" }}
    />
  );
};

export const BarGraph = (data, labels, evts) => {
  const d = {
    series: [
      {
        data: data,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "bar",
        events: {
          click: function (chart, w, e) {
            // console.log(chart, w, e)
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "65%",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: labels,
        labels: {
          style: {
            fontSize: "12px",
          },
        },
      },
    },
  };

  if (evts) {
    d.events = evts;
  }

  return (
    <ReactApexChart
      options={d.options}
      series={d.series}
      type="bar"
      height={350}
      style={{ width: "98%" }}
    />
  );
};

export const DonutGraph = () => {
  const d = {
    series: [1, 59],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Google", "Facebook"],
      plotOptions: {
        pie: {
          donut: {
            size: "60%",
          },
          customScale: 0.95,
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200,
      //       },
      //       legend: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      // ],
    },
  };

  return (
    <ReactApexChart
      options={d.options}
      series={d.series}
      type="donut"
      height={250}
    />
  );
};

export const getLineGraph = (dataName, xData, yData, minima, maxima, diff) => {
  var data = {
    series: [
      {
        name: dataName,
        data: yData,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
        width: 1.5,
      },
      noData: {
        text: "Not enough data",
        align: "center",
        verticalAlign: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          color: undefined,
          fontSize: "14px",
          fontFamily: undefined,
        },
      },
      markers: {
        size: 2,
        strokeColors: "#34a6fc",
        strokeWidth: 0,
      },
      xaxis: {
        categories: xData,
        type: "datetime",
      },
      yaxis: {
        min: minima,
        max: maxima,
        tickAmount: diff,
      },
    },
  };

  return (
    <ReactApexChart
      options={data.options}
      series={data.series}
      type="line"
      height={250}
    />
  );
};
