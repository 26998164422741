import React, { Component } from "react";
import { useState } from "react";
import { useMst } from "../models/store";
import { CreateTicket } from "../services/appService";
import { ticketPriority } from "../enums/ticketPriority";
import { ListItemText, Box, Button } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { StyledMenuItem, StyledMenu } from "./dropdownMenu";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

export const CreateTicketButton = ({ data, isCampaign }) => {
  const [selectedTicketMenu, setSelectedTicketMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const store = useMst();
  const handleTicketMenu = (event, data) => {
    if (data.ticketId) {
      //go to ticket
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedTicketMenu(data.id);
    }
  };

  const handleTicketClose = () => {
    setAnchorEl(null);
    setSelectedTicketMenu(null);
  };

  const createTicket = async (e, data, priority) => {
    if (data) {
      let request = {};
      if (!isCampaign) {
        request = {
          reviewId: data.id,
          name: data.reviewerName,
          priority: priority,
        };
      } else {
        request = {
          campaignRequestId: data.id,
          name: data.name,
          priority: priority,
        };
      }

      //make a request;
      try {
        setSelectedTicketMenu(null);
        const response = await CreateTicket(request);
        if (response.data.id) {
          store.toggleAlert(true, "Successfully created a ticket");
          data.set("ticketId", response.data.id);
        }
      } catch (error) {
        store.toggleAlert(
          true,
          "Cannot create a ticket. Please try again later"
        );
      }
    }
  };
  if (data.ticketId) {
    return (
      <Button
        variant="outlined"
        aria-controls={`ticket-menu-${data.id}`}
        component={Link}
        to={`/tickets/${data.ticketId}`}
        color="primary"
      >
        Ticket
        <OpenInNewIcon style={{ fontSize: "16px", marginLeft: "5px" }} />
      </Button>
    );
  } else {
    return (
      <React.Fragment>
        <Button
          variant="outlined"
          aria-controls={`ticket-menu-${data.id}`}
          onClick={(e) => handleTicketMenu(e, data)}
        >
          Create Ticket
        </Button>
        <Box pt={2} component="div">
          <StyledMenu
            id={`ticket-menu-${data.id}`}
            open={selectedTicketMenu == data.id}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            onClose={handleTicketClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            transitionDuration="auto"
          >
            <StyledMenuItem>
              <ListItemText
                primary="Highest Priority"
                onClick={(e) => createTicket(e, data, ticketPriority.high)}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                primary="Medium Priority"
                onClick={(e) => createTicket(e, data, ticketPriority.medium)}
              />
            </StyledMenuItem>
            <StyledMenuItem>
              <ListItemText
                primary="Low Priority"
                onClick={(e) => createTicket(e, data, ticketPriority.low)}
              />
            </StyledMenuItem>
          </StyledMenu>
        </Box>
      </React.Fragment>
    );
  }
};
