import React, { Component } from "react";
import { useMst } from "../../models/store";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import {
  Paper,
  makeStyles,
  Typography,
  Grid,
  Tabs,
  Tab,
  Container,
} from "@material-ui/core";
import { useState } from "react";
import { ReviewTemplate } from "../../common/reviewTemplate";
import { Overview } from "./overview";
import { TabPanel } from "../../common/tabPanel";

const useStyles = makeStyles((theme) => ({
  page: {
    margin: "15px 0",
  },
  rating: {
    verticalAlign: "middle",
  },
  title: {
    fontWeight: 600,
  },
  comment: {
    padding: "5px 16px 16px 16px",
  },
  input: {
    height: 50,
  },
  tabs: {
    backgroundColor: "#fafafa",
    marginTop: "25px",
    borderBottom: "1px solid #e9e9e9",
  },
  mainContent: {
    backgroundColor: "none",
  },
}));

export const PageView = observer(() => {
  const store = useMst();
  const { id } = useParams();
  const classes = useStyles();
  const page = store.pages.data.find((e) => {
    return e.id == id;
  });

  const [reply, setReply] = useState("");
  const [pageIndex, setPageIndex] = useState(
    page.reviews.pageIndex != 0 ? page.reviews.pageIndex : 1
  );

  const [tabValue, setTabValue] = useState(0);
  const postReply = async (e, review) => {
    try {
      await review.postReply(reply);
    } catch (error) {
      store.toggleAlert(true, "There is an error while posting your response");
    }
  };
  const handlePagination = (event, value) => {
    setPageIndex(value);
    page.loadReviews(value);
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };
  return (
    <React.Fragment>
      <Container>
        <Grid container>
          <Grid item xs={6}>
            <Typography color="textPrimary" component="h1" variant="h2">
              {page.name}
            </Typography>
            <Typography color="textPrimary" component="h2" variant="subtitle1">
              Here's what's happening in your page
            </Typography>
          </Grid>
        </Grid>
        <Paper elevation={0}>
          <Tabs
            value={tabValue}
            className={classes.tabs}
            onChange={handleTabChange}
          >
            <Tab label="Overview" id="tab-0" />
            <Tab label="Settings" id="tab-1" />
            <Tab label="Reviews" id="tab-2" />
          </Tabs>
        </Paper>
        <TabPanel value={tabValue} index={0}>
          <Overview />
        </TabPanel>
        <TabPanel value={tabValue} index={1} text="there" />
        <TabPanel value={tabValue} index={2}>
          <ReviewTemplate pageId={page.id} reviews={page.reviews} />
        </TabPanel>
      </Container>
    </React.Fragment>
  );
});
