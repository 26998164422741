export const CampaignStatus = {
  draft: 0,
  sent: 1,
  closed: 2,
  all: 3,
};

export const getCampaignStatus = (status) => {
  if (status == CampaignStatus.draft) {
    return "Draft";
  } else if (status == CampaignStatus.sent) {
    return "Sent";
  } else if (status == CampaignStatus.closed) {
    return "Completed";
  }
};

export const getCampaignStatusByName = (name) => {
  var id = 0;
  if (!name || name == "all") {
    id = CampaignStatus.all;
  } else if (name.toLowerCase() == "drafts") {
    id = CampaignStatus.draft;
  } else if (
    name.toLowerCase() == "sent" ||
    name.toLowerCase() == "inprogress"
  ) {
    id = CampaignStatus.sent;
  } else if (name.toLowerCase() == "completed") {
    id = CampaignStatus.closed;
  } else {
    id = 0;
  }

  return id;
};
