import {
  Backdrop,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { StyledChip } from "../../common/chip";
import { ColorGradient } from "../../common/getColorGradient";
import { BarGraph, SingleBarGraph } from "../../common/getGraph";
import { StyledHeader } from "../../common/getHeader";
import {
  getQueryStringFromUrl,
  insertParam,
  updateQueryString,
} from "../../common/getQueryString";
import { keyword } from "../../enums/keyword";
import { useMst } from "../../models/store";
import {
  GetAdjectiveTimeline,
  GetAnalysisKeywords,
  GetKeywordAnalysis,
  GetKeywords,
  RejectAdjective,
} from "../../services/appService";
import CloseIcon from "@material-ui/icons/Close";
import { observer } from "mobx-react";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { BubbleChart } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  adjective: {
    "& .remove": {
      display: "none",
      position: "relative",
    },
    "&:hover": {
      backgroundColor: "#efefef",
    },
    "&:hover .remove": {
      display: "block",
      position: "relative",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  icon: {
    fontSize: "16px",
    position: "absolute",
    top: "3px",
    cursor: "pointer",
  },
  selected: {
    backgroundColor: "#cccccc",
  },
  pulse: {
    animation: "$pulse 2s infinite",
  },
  "@keyframes pulse": {
    "0%": {
      "-webkit-box-shadow": "0 0 0 0 rgb(255 0 0 / 40%)",
    },
    "70%": {
      "-webkit-box-shadow": "0 0 0 10px rgba(204,169,44, 0)",
    },
    "100%": {
      "-webkit-box-shadow": "0 0 0 0 rgba(204,169,44, 0)",
    },
  },
}));

export const KeywordAnalysis = observer(({ refresh, props }) => {
  const store = useMst();
  const classes = useStyles();
  const [modalSettings, setModalSettings] = useState({
    openModal: false,
    selectedAdjective: null,
    selectedPeriod: 6,
  });
  const [openModal, setOpenModal] = React.useState(false);
  const [localStore, setLocalStore] = useState({
    keywords: null,
  });
  const [analysis, setAnalysis] = useState(null);
  const [selectedChip, setSelectedChip] = useState(
    props.match.params.word ?? 0
  );
  const [timelineData, setTimelineData] = useState(null);
  const getValuesFromQueryString = () => {
    return {
      locationId: getQueryStringFromUrl(document.URL, "locationId") ?? 0,
      sourceId: getQueryStringFromUrl(document.URL, "sourceId") ?? 0,
      periodId: getQueryStringFromUrl(document.URL, "months") ?? 0,
    };
  };

  const getData = async (forceReset) => {
    var queryValues = getValuesFromQueryString();
    const s = await GetAnalysisKeywords(
      queryValues.locationId,
      queryValues.sourceId,
      queryValues.periodId,
      1,
      0
    );
    const storeObj = { ...localStore };
    storeObj.keywords = s;
    setLocalStore(storeObj);
    if (forceReset) {
      setAnalysis(null);
      setSelectedChip(0);
    }
  };
  useEffect(() => {
    if (!localStore.keywords) {
      getData();
    }
    if (props.match.params.word && !analysis) {
      handleChipSelect(null, selectedChip);
    }
  });

  const handleChipSelect = async (e, word) => {
    var queryValues = getValuesFromQueryString();
    var response = await GetKeywordAnalysis(
      word,
      queryValues.locationId,
      queryValues.sourceId,
      queryValues.periodId
    );
    var s = props;
    if (queryValues.locationId) {
      props.history.push({
        pathname: "/insights",
        search: updateQueryString(
          "locationId",
          queryValues.locationId,
          props.location.search
        ),
      });
    }
    if (queryValues.sourceId) {
      props.history.push({
        pathname: "/insights",
        search: updateQueryString(
          "sourceId",
          queryValues.sourceId,
          props.location.search
        ),
      });
    }
    if (queryValues.periodId) {
      props.history.push({
        pathname: "/insights",
        search: updateQueryString(
          "months",
          queryValues.periodId,
          props.location.search
        ),
      });
    }
    var data = response.data;
    if (data.positiveAnalysis) {
      data.positiveAnalysis.pageIndex = 0;
    }
    if (data.negativeAnalysis) {
      data.negativeAnalysis.pageIndex = 0;
    }
    data.pageSize = 10;
    setAnalysis(data);
    setSelectedChip(word);
  };

  const rejectAdjective = async (e, adj, sentimentType) => {
    e.currentTarget.parentNode.parentElement.setAttribute(
      "style",
      "display:none;"
    );
    var response = await RejectAdjective(adj, sentimentType);
  };

  const handleModalClose = () => {
    var settings = { ...modalSettings };
    settings.openModal = false;
    settings.selectedAdjective = null;
    setModalSettings(settings);
  };

  const shouldAlert = (keywordInfo) => {
    var result = false;
    if (keywordInfo.negativeOccurances > keywordInfo.positiveOccurances) {
      var positivePercentage =
        (keywordInfo.positiveOccurances / keywordInfo.count) * 100;
      var negativePercentage =
        (keywordInfo.negativeOccurances / keywordInfo.count) * 100;
      var diff = negativePercentage - positivePercentage;
      if (diff > 20) {
        result = true;
      }
    }
    return result;
  };

  const calculateDominance = (keywordInfo) => {
    var colorCode = "";
    if (keywordInfo.positiveOccurances > keywordInfo.negativeOccurances) {
      //calculate how much percentage dominance it is against negative occurances
      var positivePercentage =
        (keywordInfo.positiveOccurances / keywordInfo.count) * 100;
      var negativePercentage =
        (keywordInfo.negativeOccurances / keywordInfo.count) * 100;
      var diff = positivePercentage - negativePercentage;
      if (diff > 0 && diff <= 10) {
        colorCode = "#658e67";
      } else if (diff > 10 && diff <= 20) {
        colorCode = "#b2d2b4";
      } else if (diff > 20 && diff <= 30) {
        colorCode = "#99c39b";
      } else if (diff > 30 && diff <= 40) {
        colorCode = "#7fb482";
      } else if (diff > 40 && diff <= 50) {
        colorCode = "#66a569";
      } else if (diff > 50 && diff <= 60) {
        colorCode = "#4c9650";
      } else if (diff > 60 && diff <= 70) {
        colorCode = "#328736";
      } else if (diff > 70 && diff <= 80) {
        colorCode = "#19781d";
      } else if (diff > 80 && diff <= 90) {
        colorCode = "#006905";
      } else if (diff > 90 && diff <= 100) {
        colorCode = "#005404";
      }
    } else if (
      keywordInfo.negativeOccurances > keywordInfo.positiveOccurances
    ) {
      var positivePercentage =
        (keywordInfo.positiveOccurances / keywordInfo.count) * 100;
      var negativePercentage =
        (keywordInfo.negativeOccurances / keywordInfo.count) * 100;
      var diff = negativePercentage - positivePercentage;
      if (diff > 0 && diff <= 10) {
        colorCode = "#ECCACA";
      } else if (diff > 10 && diff <= 20) {
        colorCode = "#E7BDBD";
      } else if (diff > 20 && diff <= 30) {
        colorCode = "#E1ADAD";
      } else if (diff > 30 && diff <= 40) {
        colorCode = "#DA9898";
      } else if (diff > 40 && diff <= 50) {
        colorCode = "#D17E7E";
      } else if (diff > 50 && diff <= 60) {
        colorCode = "#C55E5E";
      } else if (diff > 60 && diff <= 70) {
        colorCode = "#B73636";
      } else if (diff > 70 && diff <= 80) {
        colorCode = "#A50404";
      } else if (diff > 80 && diff <= 90) {
        colorCode = "#840303";
      } else if (diff > 90 && diff <= 100) {
        colorCode = "#6A0202";
      }
    }

    return colorCode;
  };

  const seeWordTimeline = async (adj) => {
    var settings = { ...modalSettings };
    settings.openModal = true;
    settings.selectedAdjective = adj;
    settings.selectedPeriod = 3;
    setModalSettings(settings);
    loadTimelineData(adj, 3);
  };

  const loadTimelineData = (adj, period) => {
    GetAdjectiveTimeline(selectedChip, adj, period).then((t) => {
      setTimelineData(t.data);
    });
  };

  const changeTimelinePeriod = (period) => {
    var settings = { ...modalSettings };
    settings.selectedPeriod = period;
    setModalSettings(settings);
    loadTimelineData(modalSettings.selectedAdjective, period);
  };

  const getPositiveAnalysis = () => {
    if (analysis.positiveAnalysis && analysis.positiveAnalysis.length > 0) {
      var array = [];
      var nextFetch =
        (analysis.positiveAnalysis.pageIndex + 1) * analysis.pageSize;
      var range =
        nextFetch < analysis.positiveAnalysis.length
          ? nextFetch
          : analysis.positiveAnalysis.length;
      const colors = ColorGradient("#357938", "#a8ecac", range);

      for (let index = 0; index < range; index++) {
        array.push(
          <React.Fragment>
            <Box
              onClick={() =>
                seeWordTimeline(analysis.positiveAnalysis[index].adjective)
              }
            >
              <Grid container className={classes.adjective}>
                <Grid item xs={3}>
                  {analysis.positiveAnalysis[index].adjective}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ paddingTop: "5px", paddingBottom: "5px" }}
                >
                  <div
                    style={{
                      width: "100%",
                      background: "#efefef",
                      height: "12px",
                    }}
                  >
                    <div
                      style={{
                        width: `${
                          (analysis.positiveAnalysis[index].occurances /
                            analysis.positiveAnalysis[0].occurances) *
                          100
                        }%`,
                        background: `${colors[index]}`,
                        height: "12px",
                      }}
                    ></div>
                  </div>
                </Grid>
                <Grid item xs={2}>
                  <Box pl={2}>
                    <Typography color="textSecondary" variant="caption">
                      {analysis.positiveAnalysis[index].occurances}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Box
                    class="remove"
                    onClick={(e) =>
                      rejectAdjective(
                        e,
                        analysis.positiveAnalysis[index].adjective,
                        keyword.positive
                      )
                    }
                  >
                    <CloseIcon
                      className={classes.icon}
                      style={{ fontSize: "12px !important" }}
                      color="textSecondary"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </React.Fragment>
        );
      }

      if (
        (analysis.positiveAnalysis.pageIndex + 1) * analysis.pageSize <
        analysis.positiveAnalysis.length
      ) {
        array.push(
          <Box pt={2} onClick={() => showMoreAnalysis(keyword.positive)}>
            See more
          </Box>
        );
      }

      return array;
    }

    return (
      <Typography variant="caption" color="textSecondary">
        No positive reviews found
      </Typography>
    );
  };

  const getNegativeAnalysis = () => {
    if (analysis.negativeAnalysis && analysis.negativeAnalysis.length > 0) {
      var array = [];
      var nextFetch =
        (analysis.negativeAnalysis.pageIndex + 1) * analysis.pageSize;
      var range =
        nextFetch < analysis.negativeAnalysis.length
          ? nextFetch
          : analysis.negativeAnalysis.length;
      const colors = ColorGradient("#843030", "#f1caca", range);

      for (let index = 0; index < range; index++) {
        array.push(
          <React.Fragment>
            <Grid container className={classes.adjective}>
              <Grid item xs={3}>
                {analysis.negativeAnalysis[index].adjective}
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingTop: "5px", paddingBottom: "5px" }}
              >
                <div
                  style={{
                    width: "100%",
                    background: "#efefef",
                    height: "12px",
                  }}
                >
                  <div
                    style={{
                      width: `${
                        (analysis.negativeAnalysis[index].occurances /
                          analysis.negativeAnalysis[0].occurances) *
                        100
                      }%`,
                      background: `${colors[index]}`,
                      height: "12px",
                    }}
                  ></div>
                </div>
              </Grid>
              <Grid item xs={2}>
                <Box pl={2}>
                  <Typography color="textSecondary" variant="caption">
                    {analysis.negativeAnalysis[index].occurances}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box
                  class="remove"
                  onClick={(e) =>
                    rejectAdjective(
                      e,
                      analysis.negativeAnalysis[index].adjective,
                      keyword.negative
                    )
                  }
                >
                  <CloseIcon
                    className={classes.icon}
                    style={{ fontSize: "12px !important" }}
                    color="textSecondary"
                  />
                </Box>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      }

      if (
        (analysis.negativeAnalysis.pageIndex + 1) * analysis.pageSize <
        analysis.negativeAnalysis.length
      ) {
        array.push(
          <Box pt={2} onClick={() => showMoreAnalysis(keyword.negative)}>
            See more
          </Box>
        );
      }

      return array;
    }

    return (
      <Typography variant="caption" color="textSecondary">
        No negative reviews found
      </Typography>
    );
  };

  const showMoreAnalysis = (type) => {
    var obj = { ...analysis };
    if (type == keyword.positive) {
      obj.positiveAnalysis.pageIndex++;
    } else {
      obj.negativeAnalysis.pageIndex++;
    }

    setAnalysis(obj);
  };

  const getGraph = () => {
    var dataPoints = [];
    var maxValue = 0;
    timelineData.map((t) => {
      if (t.occurances > maxValue) {
        maxValue = t.occurances;
      }
      dataPoints.push([new Date(t.createTime).getTime(), t.occurances]);
    });

    var data = {
      series: [
        {
          name: "Count",
          data: dataPoints,
        },
      ],
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        yaxis: {
          min: 0,
          max: maxValue < 10 ? 10 : maxValue,
          decimalsInFloat: 1,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        noData: {
          text: "Not enough data",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "14px",
            fontFamily: undefined,
            backgroundColor: "#e9e9e9",
          },
        },
      },
    };

    if (dataPoints.length > 0) {
      data.options.xaxis.min = dataPoints[0][0];
    }

    return (
      <Box>
        <Button
          onClick={() => changeTimelinePeriod(2)}
          style={{
            backgroundColor:
              modalSettings.selectedPeriod == 2 ? "#e9e9e9" : "#fff",
          }}
        >
          1M
        </Button>
        <Button
          onClick={() => changeTimelinePeriod(3)}
          style={{
            backgroundColor:
              modalSettings.selectedPeriod == 3 ? "#e9e9e9" : "#fff",
          }}
        >
          6M
        </Button>
        <Button
          onClick={() => changeTimelinePeriod(4)}
          style={{
            backgroundColor:
              modalSettings.selectedPeriod == 4 ? "#e9e9e9" : "#fff",
          }}
        >
          1Y
        </Button>
        <Button
          onClick={() => changeTimelinePeriod(5)}
          style={{
            backgroundColor:
              modalSettings.selectedPeriod == 5 ? "#e9e9e9" : "#fff",
          }}
        >
          2Y
        </Button>
        <Button
          onClick={() => changeTimelinePeriod(0)}
          style={{
            backgroundColor:
              modalSettings.selectedPeriod == 0 ? "#e9e9e9" : "#fff",
          }}
        >
          ALL
        </Button>
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="area"
          height={250}
        />
      </Box>
    );
  };

  const bubble = () => {
    return (
      <BubbleChart
        width={800}
        height={800}
        fontFamily="Arial"
        nodes={[
          { label: "CRM", value: 1 },
          { label: "API", value: 1 },
          { label: "Data", value: 1 },
          { label: "Commerce", value: 1 },
          { label: "AI", value: 3 },
          { label: "Management", value: 5 },
          { label: "Testing", value: 6 },
          { label: "Mobile", value: 9 },
          { label: "Conversion", value: 9 },
          { label: "Misc", value: 21 },
          { label: "Databases", value: 22 },
          { label: "DevOps", value: 22 },
          { label: "Javascript", value: 23 },
          { label: "Languages / Frameworks", value: 25 },
          { label: "Front End", value: 26 },
          { label: "Content", value: 26 },
        ]}
      />
    );
  };
  if (refresh) {
    getData(true);
    store.forceRefresh(false);
  }

  return (
    <Container component={Paper}>
      <Box pb={2} pt={2}>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <StyledHeader heading="Keywords" subHeading="Popular mentions" />
            <Divider />
            <Box pt={2}>
              {!localStore.keywords && (
                <React.Fragment>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </React.Fragment>
              )}
              {localStore.keywords &&
                localStore.keywords.data.length > 0 &&
                localStore.keywords.data.map((t) => {
                  return (
                    <StyledChip
                      icon={
                        <span
                          style={{
                            height: "10px",
                            width: "10px",
                            borderRadius: "50%",
                            display: "inline-block",
                            backgroundColor: calculateDominance(t),
                          }}
                          className={shouldAlert(t) ? classes.pulse : null}
                        ></span>
                      }
                      label={t.word}
                      avatar={t.count}
                      onClick={(e) => {
                        handleChipSelect(e, t.word);
                      }}
                      classList={t.word == selectedChip ? classes.selected : ""}
                      // style={{
                      //   borderLeft: `5px solid `,
                      // }}
                    />
                  );
                })}
            </Box>
          </Grid>
          <Grid item xs={8}>
            {analysis && (
              <React.Fragment>
                <StyledHeader
                  heading={`"${selectedChip}" sentiments`}
                  subHeading={`See what people say about their ${selectedChip} experiences`}
                />
                <Divider />
                <Box pt={2}>{analysis && getPositiveAnalysis()}</Box>
                <Box pt={4}>{analysis && getNegativeAnalysis()}</Box>
              </React.Fragment>
            )}
          </Grid>
        </Grid>
      </Box>
      <Modal
        disableEnforceFocus={true}
        open={modalSettings.openModal}
        className={classes.modal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper style={{ width: "480px", outline: 0 }}>
          <Box p={3}>
            {timelineData == null && <LoadingSpinner />}
            {timelineData && getGraph()}
          </Box>
        </Paper>
      </Modal>
    </Container>
  );
});
