import Axios from "axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export const LoadPages = async (intType) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/`,
  });
};

export const LoadReviews = async (
  locationId,
  sourceId,
  periodId,
  pageIndex,
  stars
) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/reviewsbypage/`,
    params: {
      pageIndex: pageIndex,
      offset: offset,
      locationId: locationId,
      sourceId: sourceId,
      periodId: periodId,
      pageSize: 15,
      stars: stars,
    },
  });
};

export const LoadAllReviews = async (
  pageIndex,
  pageSize,
  locationId,
  sourceId,
  periodId,
  stars
) => {
  if (!pageSize) {
    pageSize = 15;
  }
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    params: {
      pageIndex,
      offset,
      pageSize,
      locationId,
      sourceId,
      periodId,
      stars,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/app/reviews/`,
  });
};

export const LoadReviewRequests = async (pageIndex, pageSize) => {
  if (!pageSize) {
    pageSize = 50;
  }
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    params: {
      pageIndex: pageIndex,
      pageSize: pageSize,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/app/requests/`,
  });
};

export const PostReply = async (pageId, reviewId, comment, isChildComment) => {
  const accessToken = new Cookies().get("token");
  debugger;
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/postreply/`,
    params: {
      pageId,
      reviewId,
      comment,
      isChildComment,
    },
  });
};

export const LoadPageOverview = async (pageId, offset) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/overview/`,
    params: {
      pageId: pageId,
      offset: offset,
    },
  });
};

export const PostReviewRequest = async (data) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/request/create/`,
    data: qs.stringify(data),
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const GetReviewRequest = async (uuid, rating) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/review/request/`,
    params: {
      uuid: uuid,
      rating: rating,
    },
  });
};

export const GetReviewRequestLogs = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/review/request/logs/`,
    params: {
      id: id,
    },
  });
};

export const LoadAccountSetup = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/accountsetup/`,
  });
};

export const SaveAccountSetup = async (data) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    data: data,
    url: `${process.env.REACT_APP_ApiUrl}/api/app/accountsetup/`,
  });
};

export const PostRating = async (params) => {
  return await Axios({
    method: "post",
    params: params,
    url: `${process.env.REACT_APP_ApiUrl}/api/review/rate/`,
  });
};

export const PostComment = async (params) => {
  return await Axios({
    method: "post",
    params: params,
    url: `${process.env.REACT_APP_ApiUrl}/api/review/comment/`,
  });
};

export const GetTickets = async (pageIndex, pageSize) => {
  const accessToken = new Cookies().get("token");
  if (!pageSize) {
    pageSize = 50;
  }
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/`,
    params: {
      pageIndex: pageIndex,
      pageSize: pageSize,
    },
  });
};

export const GetTicket = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/view/`,
    params: {
      id: id,
    },
  });
};

export const CreateTicket = async (data) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    data: data,
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/`,
  });
};

export const UpdateTicket = async (ticketId, priority) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    params: {
      ticketId,
      priority,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/update/priority/`,
  });
};

export const GetLogs = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/logs/`,
    params: {
      ticketId: id,
    },
  });
};

export const GetSmsLogs = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/smslogs/`,
    params: {
      ticketId: id,
    },
  });
};

export const PostResponse = async (
  type,
  to,
  message,
  ticketId,
  messageIndex
) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    params: {
      type: type,
      to: to,
      message: message,
      ticketId: ticketId,
      messageIndex: messageIndex,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/`,
  });
};

export const GetAllAudiences = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/audience/`,
  });
};

export const GetAudiences = async (pageIndex, pageSize) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    params: {
      pageIndex: pageIndex,
      pageSize: pageSize,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/audience/`,
  });
};

export const GetAudienceContacts = async (id, pageIndex, pageSize) => {
  const accessToken = new Cookies().get("token");
  if (!pageSize) {
    pageSize = 50;
  }
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/audience/`,
    params: {
      id: id,
      pageIndex: pageIndex,
      pageSize: pageSize,
    },
  });
};

export const PostAudienceContacts = async (id, audienceContacts) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/audience/`,
    params: {
      id: id,
    },
    data: audienceContacts,
  });
};

export const CreateAudience = async (name) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/audience/`,
    params: {
      name: name,
    },
  });
};

export const GetCampaigns = async (type, pageIndex, pageSize) => {
  debugger;
  const accessToken = new Cookies().get("token");
  if (!pageSize) {
    pageSize = 50;
  }
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/`,
    params: {
      type,
      pageIndex,
      pageSize,
    },
  });
};

export const GetCampaign = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/`,
    params: {
      id: id,
    },
  });
};

export const SaveCampaign = async (data) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/`,
    data: data,
  });
};

export const ProcessCampaign = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/process/`,
    params: {
      id: id,
    },
  });
};

export const GetCampaignResult = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/result/`,
    params: {
      id: id,
    },
  });
};

export const GetOverview = async (locationId, sourceId, period) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/`,
    params: {
      locationId: locationId,
      sourceId: sourceId,
      period: period,
      offset: offset,
    },
  });
};

export const GetAverageRating = async (sourceId, period) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/avgRating/`,
    params: {
      sourceId: sourceId,
      period: period,
      offset: offset,
    },
  });
};

export const GetReviewsDistribution = async (sourceId, period) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/distribution/`,
    params: {
      sourceId: sourceId,
      period: period,
      offset: offset,
    },
  });
};

export const GetReviewInvites = async (period) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/reviewInvites/`,
    params: {
      period: period,
      offset: offset,
    },
  });
};

export const GetFeedbackInvites = async (period) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/feedbackInvites/`,
    params: {
      period: period,
      offset: offset,
    },
  });
};

export const GetCampaignsOverview = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/dashboard/campaigns/`,
  });
};

export const GetAvailableTags = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/tags/availableTags/`,
  });
};

export const GetTagMetrics = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/tags/tagmetric/`,
    params: {
      id: id,
    },
  });
};

export const GetLocations = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/location/`,
  });
};

export const GetLocationApps = async (id) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/location/`,
    params: {
      locationId: id,
    },
  });
};

export const SaveLocation = async (data) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/saveLocation/`,
    data: data,
  });
};

export const AssignLocationToPages = async (locationId, pageIds) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/app/assignpages/`,
    params: {
      locationId: locationId,
    },
    data: pageIds,
  });
};

export const GetAnalysisKeywords = async (
  locationId,
  sourceId,
  periodId,
  pageIndex
) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/analysiskeywords/`,
    params: {
      locationId: locationId,
      sourceId: sourceId,
      periodId: periodId,
      offset: offset,
      pageIndex: pageIndex,
      pageSize: 100,
    },
  });
};

export const GetKeywords = async (
  locationId,
  sourceId,
  periodId,
  pageIndex,
  keywordType
) => {
  var offset = -new Date().getTimezoneOffset();
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/`,
    params: {
      locationId: locationId,
      sourceId: sourceId,
      periodId: periodId,
      offset: offset,
      pageIndex: pageIndex,
      pageSize: 20,
      keywordType: keywordType,
    },
  });
};

export const GetKeywordsInfo = async (
  words,
  locationId,
  sourceId,
  periodId,
  sentimentType,
  pageIndex
) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/`,
    data: words,
    params: {
      sentimentType: sentimentType,
      locationId: locationId,
      sourceId: sourceId,
      periodId: periodId,
      offset: offset,
      pageIndex: pageIndex,
      pageSize: 10,
    },
  });
};

export const GetKeywordAnalysis = async (
  keyword,
  locationId,
  sourceId,
  periodId
) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/analysis/`,
    params: {
      keyword,
      locationId: locationId ?? 0,
      sourceId: sourceId ?? 0,
      periodId: periodId ?? 0,
      offset,
    },
  });
};

export const RejectAdjective = async (adj, sentimentType) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/reject/`,
    params: {
      sentimentType: sentimentType,
      adjective: adj,
    },
  });
};

export const GetAdjectiveTimeline = async (word, adj, periodId) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/keyword/timeline/`,
    params: {
      word: word,
      adj: adj,
      periodId: periodId,
      offset: offset,
    },
  });
};

export const GetReview = async (id) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/review/`,
    params: {
      id: id,
    },
  });
};

export const UpdateTicketStatus = async (id, status) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/ticket/update/status/`,
    params: {
      ticketId: id,
      status,
    },
  });
};

export const GetNPSTags = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/tags/`,
  });
};

export const GetNPSTagsStats = async (ids) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/tags/stat/`,
    data: ids,
  });
};
export const GetNPSStatsByMonth = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/tags/stat/`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const GetReviewsStatsByTime = async (periodId, sourceId) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/reviews/bytime/`,
    params: {
      periodId,
      sourceId,
      offset,
    },
  });
};

export const GetReviewsStatsByLocation = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/reviews/bylocation/`,
  });
};

export const GetReviewsStatsBySource = async (periodId) => {
  const accessToken = new Cookies().get("token");
  var offset = -new Date().getTimezoneOffset();
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/report/reviews/bysource/`,
    params: {
      periodId,
      offset,
    },
  });
};

export const GetCustomers = async (pageIndex, pageSize) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/`,
    params: {
      pageIndex,
      pageSize,
    },
  });
};

export const GetCustomer = async (id) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/`,
    params: {
      id,
    },
  });
};

export const AddCustomer = async (customer) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/create/`,
    data: customer,
  });
};

export const GetCustomerComments = async (customerId, pageIndex, pageSize) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/comments/`,
    params: {
      customerId,
      pageIndex,
      pageSize,
    },
  });
};

export const GetTemplate = async (type, sendType) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/`,
    params: {
      type,
      sendType,
    },
  });
};

export const SaveTemplate = async (template) => {
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/`,
    data: template,
  });
};

export const GetTemplates = async (pageIndex) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/all/`,
    params: {
      pageIndex,
    },
  });
};

export const LoadTemplate = async (templateId) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/load/`,
    params: {
      templateId,
    },
  });
};

export const LoadTemplatesForCombo = async (templateType, sendType) => {
  debugger;
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/combo/`,
    params: {
      templateType,
      sendType,
    },
  });
};

export const LoadCustomersCombo = async (filter) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/combo/`,
    params: {
      filter,
    },
  });
};

export const GetRequests = async (pageIndex, pageSize) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/request/`,
    params: {
      pageIndex,
      pageSize,
    },
  });
};

export const PublishTemplate = async (templateId) => {
  return await Axios({
    method: "POST",
    url: `${process.env.REACT_APP_ApiUrl}/api/template/publish/`,
    params: {
      templateId,
    },
  });
};

export const CreateReviewOrFeedbackRequest = async (request) => {
  return await Axios({
    method: "POST",
    url: `${process.env.REACT_APP_ApiUrl}/api/request/`,
    data: qs.stringify(request),
  });
};

export const GetFeedbacks = async (type, pageIndex, pageSize) => {
  debugger;
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/feedback/`,
    params: {
      type,
      pageIndex,
      pageSize,
    },
  });
};

export const GetFeedbackStats = async () => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/feedback/stats/`,
  });
};

export const SaveReviewSite = async (link, typeId, locationId) => {
  return await Axios({
    method: "POST",
    url: `${process.env.REACT_APP_ApiUrl}/api/location/addsite/`,
    params: {
      link,
      typeId,
      locationId,
    },
  });
};

export const UploadFile = async (file, campaignId) => {
  const formData = new FormData();
  formData.append("file", file);
  return await Axios({
    method: "POST",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/upload/`,
    headers: {
      "content-type": "multipart/form-data",
    },
    params: {
      campaignId,
    },
    data: formData,
  });
};

export const GetConversations = async (pageIndex, pageSize) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/conversation/`,
    params: {
      pageIndex,
      pageSize,
    },
  });
};

export const GetConversation = async (id) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/conversation/`,
    params: {
      id,
    },
  });
};

export const SendMessage = async (conversationId, text) => {
  return await Axios({
    method: "POST",
    url: `${process.env.REACT_APP_ApiUrl}/api/conversation/message/`,
    params: {
      conversationId,
      text,
    },
  });
};
