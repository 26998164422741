import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const ReviewSite = t
  .model("reviewSite", {
    id: t.optional(t.integer, 0),
    title: t.optional(t.string, ""),
    description: t.optional(t.string, ""),
    tipScreenshot: t.optional(t.string, ""),
    icon: t.optional(t.string, ""),
    placeholder: t.optional(t.string, ""),
    tipText: t.optional(t.string, ""),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
