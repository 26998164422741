import React, { Component, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import {
  Grid,
  Paper,
  Container,
  makeStyles,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  Grow,
  Icon,
} from "@material-ui/core";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { formatDateInTimeZone } from "../../common/formatDate";
import { useMst } from "../../models/store";
import { CreateTicketButton } from "../../common/createTicket";
import { observer } from "mobx-react";
import MoodIcon from "@material-ui/icons/Mood";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { StyledChip } from "../../common/chip";
import { CampaignType } from "../../enums/campaignType";
import { BarGraph } from "../../common/getGraph";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  grid: {
    textDecoration: "none",
  },
  card: {
    margin: "10px",
    border: "1px solid #e9e9e9",
  },
  cardTitle: {
    paddingLeft: "10px",
  },
  cardHeader: {
    fontWeight: "bold",
  },
  nps: {
    border: "1px solid #e9e9e9",
    marginRight: "10px",
    "align-self": "center",
    "-webkit-align-self": "center",
    display: "inline-block",
  },
  sm: {
    width: "148px",
  },
  npsCol: {
    color: "#728200",
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "48px 10px 0",
    textAlign: "center",
    boxSizing: "border-box",
  },
  promoter: {
    borderColor: "#63ce03",
    color: "#63ce03",
  },
  passive: {
    borderColor: "#ff8d00",
    color: "#ff8d00",
  },
  detractor: {
    borderColor: "#ef4919",
    color: "#ef4919",
  },
  npsIconDiv: {
    position: "absolute",
    margin: "0 auto",
    left: 0,
    right: 0,
    top: "-24px",
  },
  npsIcon: {
    fontSize: "48px",
    backgroundColor: "#fff",
  },
  npsGroupTotals: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  npsGroupTotal: {
    marginRight: "10px",
    marginBottom: "24px",
    fontSize: "18px",
  },
  noRightMargin: {
    marginRight: "0px !important",
  },
  npsGroupTotalValue: {
    border: "1px solid #bebeb8",
    borderRadius: "3px",
    padding: "10px 15px",
    color: "#000",
  },
}));
export const CampaignView = observer(() => {
  const classes = useStyles();
  const store = useMst();
  const { id } = useParams();
  const [scoreFilter, setScoreFilter] = useState(null);
  const [selectedTicketMenu, setSelectedTicketMenu] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const loadResult = async () => {
    store.loadCampaignResult(id);
  };

  useEffect(() => {
    if (store.campaignResult) {
      if (store.campaignResult.campaignType == CampaignType.feedback) {
        getGaugeGraph();
      }
    }
  });

  const npsUI = () => {
    return (
      <React.Fragment>
        <Box pt={4} style={{ textAlign: "center", height: "268px" }}>
          <Box className={classes.nps}>
            <Box className={`${classes.npsCol} ${classes.promoter}`}>
              <Box className={classes.npsGroupTotals}>
                <Box className={classes.npsIconDiv}>
                  <MoodIcon className={classes.npsIcon} />
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>10</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(10)}
                  </Box>
                </Box>
                <Box
                  className={`${classes.npsGroupTotal} ${classes.noRightMargin}`}
                >
                  <Box>
                    <strong>9</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(9)}
                  </Box>
                </Box>
              </Box>
              <Box style={{ position: "absolute", paddingTop: "10px" }}>
                <Typography variant="h3">
                  <strong>Promoters:</strong> {getNPSPercentages().promoter}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.nps}>
            <Box className={`${classes.npsCol} ${classes.passive}`}>
              <Box className={classes.npsGroupTotals}>
                <Box className={classes.npsIconDiv}>
                  <SentimentDissatisfiedIcon className={classes.npsIcon} />
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>8</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(8)}
                  </Box>
                </Box>
                <Box
                  className={`${classes.npsGroupTotal} ${classes.noRightMargin}`}
                >
                  <Box>
                    <strong>7</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(7)}
                  </Box>
                </Box>
              </Box>
              <Box style={{ position: "absolute", paddingTop: "10px" }}>
                <Typography variant="h3">
                  <strong>Passive:</strong> {getNPSPercentages().passive}%
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.nps}>
            <Box className={`${classes.npsCol} ${classes.detractor}`}>
              <Box className={classes.npsGroupTotals}>
                <Box className={classes.npsIconDiv}>
                  <SentimentVeryDissatisfiedIcon className={classes.npsIcon} />
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>6</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(6)}
                  </Box>
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>5</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(5)}
                  </Box>
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>4</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(4)}
                  </Box>
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>3</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(3)}
                  </Box>
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>2</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(2)}
                  </Box>
                </Box>
                <Box className={classes.npsGroupTotal}>
                  <Box>
                    <strong>1</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(1)}
                  </Box>
                </Box>
                <Box
                  className={`${classes.npsGroupTotal} ${classes.noRightMargin}`}
                >
                  <Box>
                    <strong>0</strong>
                  </Box>
                  <Box className={classes.npsGroupTotalValue}>
                    {getCountByScore(0)}
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  position: "absolute",
                  paddingTop: "10px",
                  left: "50%",
                }}
              >
                <Typography
                  variant="h3"
                  style={{ position: "relative", left: "-50%" }}
                >
                  <strong>Detractors:</strong> <br></br>{" "}
                  {getNPSPercentages().detractors}%
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box p={1} alignContent="center" textAlign="center">
          <Grid container alignItems="center" justify="center">
            <Grid xs={6}>
              <Box
                textAlign="center"
                style={{
                  position: "relative",
                  top: "30%",
                }}
              >
                <Typography variant="h2" gutterBottom>
                  Your NPS Score:{" "}
                  <span
                    className={`${
                      getNPSScore() < 0
                        ? classes.detractor
                        : getNPSScore() >= 0 && getNPSScore() < 30
                        ? classes.passive
                        : classes.promoter
                    }`}
                  >
                    {getNPSScore().toFixed(2)}
                  </span>
                </Typography>
                <Typography variant="h3">
                  {getNPSScore() < 0 && (
                    <span className={classes.detractor}>
                      It needs improvement
                    </span>
                  )}
                  {getNPSScore() >= 0 && getNPSScore() < 30 && (
                    <span className={classes.passive}>
                      Good. Still needs improvement
                    </span>
                  )}
                  {getNPSScore() >= 30 && getNPSScore() < 70 && (
                    <span className={classes.promoter}>Great</span>
                  )}
                  {getNPSScore() >= 70 && getNPSScore() <= 100 && (
                    <span className={classes.promoter}>Excellent</span>
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={6}>
              <Box textAlign="center" alignContent="center">
                <Box style={{ width: "250px" }} id="gaugeArea"></Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    );
  };

  const getScoresInfo = () => {
    if (!scoreFilter) {
      return store.campaignResult.campaignRequests;
    } else {
      var s = store.campaignResult.campaignRequests.filter((t) => {
        return t.rating == scoreFilter;
      });
      return s;
    }
  };

  const getGaugeGraph = () => {
    let element = document.querySelector("#gaugeArea");
    element.innerHTML = "";

    // Properties of the gauge
    let gaugeOptions = {
      hasNeedle: true,
      needleColor: "black",
      arcColors: ["rgb(255, 84, 84)", "#ff8d00", "rgb(61, 204, 91)"],
      arcDelimiters: [50, 70],
      rangeLabel: ["-100", "100"],
      needleStartValue: 0,
    };

    // Drawing and updating the chart
    window.GaugeChart.gaugeChart(element, 300, gaugeOptions).updateNeedle(
      getNPSScoreValue()
    );
  };

  const getCountByScore = (number) => {
    return store.campaignResult.totalScores[number];
  };

  const getPromoterTotal = () => {
    return (
      store.campaignResult.totalScores[10] + store.campaignResult.totalScores[9]
    );
  };

  const getPassiveTotal = () => {
    return (
      store.campaignResult.totalScores[8] + store.campaignResult.totalScores[7]
    );
  };

  const getDetractorTotal = () => {
    return (
      store.campaignResult.totalScores[6] +
      store.campaignResult.totalScores[5] +
      store.campaignResult.totalScores[4] +
      store.campaignResult.totalScores[3] +
      store.campaignResult.totalScores[2] +
      store.campaignResult.totalScores[1] +
      store.campaignResult.totalScores[0]
    );
  };

  const getNPSPercentages = () => {
    var total = getPromoterTotal() + getPassiveTotal() + getDetractorTotal();
    if (!total) {
      total = 1;
    }
    return {
      promoter: ((getPromoterTotal() / total) * 100).toFixed(2),
      passive: ((getPassiveTotal() / total) * 100).toFixed(2),
      detractors: ((getDetractorTotal() / total) * 100).toFixed(2),
    };
  };

  const getNPSScoreValue = () => {
    var result = getNPSPercentages();
    var score = result.promoter - result.detractors;
    return (0.5 - (-score * 0.5) / 100) * 100;
  };

  const getNPSScore = () => {
    var result = getNPSPercentages();
    return result.promoter - result.detractors;
  };

  const getReviewGraph = () => {
    return BarGraph(store.campaignResult.totalScores, [
      "5 stars",
      "4 stars",
      "3 stars",
      "2 stars",
      "1 stars",
    ]);
  };

  const handleFilter = (e) => {
    setScoreFilter(e.target.value);
  };
  if (!store.campaignResult) {
    loadResult();
    return <React.Fragment>Loading....</React.Fragment>;
  } else {
    return (
      <React.Fragment>
        <Box pt={6}>
          <Container className={classes.container}>
            <Card variant="outlined">
              <CardContent>
                <Box pb={2}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Card elevation={0} className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid item className={classes.cardTitle} xs={8}>
                              <Typography component="h2" variant="h5">
                                <strong>Total Sent</strong>
                              </Typography>
                              <Typography component="p" variant="subtitle2">
                                People participating in this campaign
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="h2" align="right">
                                {store.campaignResult.totalSent}
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card elevation={0} className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid item className={classes.cardTitle} xs={8}>
                              <Typography component="h2" variant="h5">
                                <strong>Total Clicked</strong>
                              </Typography>
                              <Typography component="p" variant="subtitle2">
                                People participating in this campaign
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="h2" align="right">
                                {store.campaignResult.totalClicked}
                                <Typography variant="caption">
                                  {(
                                    (store.campaignResult.totalClicked /
                                      store.campaignResult.totalSent) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Card elevation={0} className={classes.card}>
                        <CardContent>
                          <Grid container>
                            <Grid item className={classes.cardTitle} xs={8}>
                              <Typography component="h2" variant="h5">
                                <strong>Total Comments</strong>
                              </Typography>
                              <Typography component="p" variant="subtitle2">
                                People participating in this campaign
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="h2" align="right">
                                {store.campaignResult.totalComments}
                                <Typography variant="caption">
                                  {(
                                    (store.campaignResult.totalClicked /
                                      store.campaignResult.totalSent) *
                                    100
                                  ).toFixed(2)}
                                  %
                                </Typography>
                              </Typography>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
                <Box p={1}>
                  {store.campaignResult.linkedTags &&
                    store.campaignResult.linkedTags.length > 0 && (
                      <React.Fragment>
                        <Box display="inline">Tags: </Box>
                        {store.campaignResult.linkedTags &&
                          store.campaignResult.linkedTags.map((t) => {
                            return <StyledChip label={t} />;
                          })}
                      </React.Fragment>
                    )}
                </Box>
                {store.campaignResult.campaignType == CampaignType.feedback && (
                  <Box>{npsUI()}</Box>
                )}
                {store.campaignResult.campaignType == CampaignType.review &&
                  getReviewGraph()}
                {/* <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Box p={2}>{constructBar()}</Box>
                </Grid>
                <Grid item xs={6}>
                  <Box p={2}>{constructPie()}</Box>
                </Grid>
              </Grid> */}
                <Box pt={2}>
                  {/* <Box p={1} textAlign="right">
                  <FormControl className={classes.formControl}>
                    <Select
                      displayEmpty
                      className={classes.selectEmpty}
                      variant="outlined"
                      onChange={handleFilter}
                    >
                      <MenuItem>Filter by</MenuItem>
                      <MenuItem value={0}>All</MenuItem>
                      <MenuItem value={1}>Score 1</MenuItem>
                      <MenuItem value={2}>Score 2</MenuItem>
                      <MenuItem value={3}>Score 3</MenuItem>
                      <MenuItem value={4}>Score 4</MenuItem>
                      <MenuItem value={5}>Score 5</MenuItem>
                      <MenuItem value={6}>Score 6</MenuItem>
                      <MenuItem value={7}>Score 7</MenuItem>
                      <MenuItem value={8}>Score 8</MenuItem>
                      <MenuItem value={9}>Score 9</MenuItem>
                      <MenuItem value={10}>Score 10</MenuItem>
                    </Select>
                  </FormControl>
                </Box> */}
                  <Grow in={true}>
                    <Box>
                      {store.campaignResult.campaignRequests &&
                        getScoresInfo().map((t) => {
                          if (t.status > 0) {
                            return (
                              <Box p={1}>
                                <Card variant="outlined">
                                  <Grid container>
                                    <Grid item xs={10}>
                                      <CardHeader
                                        classes={{ title: classes.title }}
                                        title={t.name}
                                        avatar={<Avatar>{t.rating}</Avatar>}
                                        subheader={
                                          <React.Fragment>
                                            <Box>
                                              {formatDateInTimeZone(
                                                t.modifiedOn
                                              )}
                                            </Box>
                                          </React.Fragment>
                                        }
                                      />
                                      {t.comment && (
                                        <CardContent style={{ paddingTop: 0 }}>
                                          <Box pl={6}>{t.comment}</Box>
                                        </CardContent>
                                      )}
                                    </Grid>
                                    <Grid item xs={2}>
                                      <Box p={2} style={{ textAlign: "right" }}>
                                        <CreateTicketButton
                                          data={t}
                                          isCampaign={true}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Card>
                              </Box>
                            );
                          }
                        })}
                    </Box>
                  </Grow>
                </Box>
              </CardContent>
            </Card>
          </Container>
        </Box>
      </React.Fragment>
    );
  }
});
