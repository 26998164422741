import { types, flow, applySnapshot } from "mobx-state-tree";
import { GetCustomers } from "../services/customerService";
export const Customer = types.model("customer", {
  id: types.integer,
  name: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  createdOn: types.maybeNull(types.string),
});

export const customerStore = types
  .model("Customers", {
    customers: types.optional(types.array(Customer), []),
  })
  .actions((self) => ({
    getCustomers: flow(function* () {
      const response = yield GetCustomers();
      if (response && response.data) {
        response.data.map((customer) => {
          const _customer = Customer.create();
          applySnapshot(_customer, customer);
          self.customers.push(_customer);
        });
      }
    }),
  }));
