import React, { Component } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Link, Card, CardContent, Box, Grid } from "@material-ui/core";
import { register, validateEmail } from "../services/authService";
class Register extends Component {
  state = {
    user: {
      name: "",
      businessName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    errors: {
      name: "",
      email: "",
      businessName: "",
      password: "",
    },
    validated: false,
    success: false,
  };
  handleChange = (e) => {
    const user = { ...this.state.user };
    user[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ user });
  };
  handleValidation = (e) => {
    const { user } = this.state;
    const errors = { ...this.state.errors };
    let isValidated = false;
    let errorText = "";
    if (!e.currentTarget.value) {
      errorText = "Field cannot be empty";
    } else if (
      e.currentTarget.name.toLowerCase().indexOf("confirmpassword") > -1
    ) {
      if (e.currentTarget.value.length < 5) {
        errorText = "Password cannot be less than 5 digits";
      } else if (user.password !== user.confirmPassword) {
        errorText = "Passwords do not match.";
      }
      errors["password"] = errorText;
    } else if (e.currentTarget.name.toLowerCase().indexOf("password") > -1) {
      if (e.currentTarget.value.length < 5) {
        errorText = "Password cannot be less than 5 digits";
      }
    }
    errors[e.currentTarget.name] = errorText;
    const { name, email, businessName, password } = this.state.errors;
    if (
      !name &&
      user.name &&
      !email &&
      user.email &&
      !businessName &&
      user.businessName &&
      !password &&
      user.password &&
      user.confirmPassword
    ) {
      isValidated = true;
    }
    this.setState({ errors, validated: isValidated });
  };
  handleEmailValidation = async (e) => {
    e.preventDefault();
    try {
      const response = await validateEmail(e.currentTarget.value);
      const errors = { ...this.state.errors };
      if (response) {
        if (!response.data) {
          errors.email = "Email address already present";
        } else {
          errors.email = "";
        }
        this.setState({ errors });
      }
    } catch (error) {}
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await register(this.state.user).then((t) => {
        this.props.history.push("/");
      });
    } catch (error) {}
  };
  render() {
    const { user, errors, success } = this.state;
    return (
      <Grid container>
        <Grid
          item
          xs={6}
          style={{ backgroundColor: "#e9e9e9", height: "100vh" }}
        ></Grid>
        <Grid item xs={6}>
          <Box p={6}>
            <Card elevation={0}>
              <CardContent>
                <Typography component="h1" variant="h5">
                  Register
                </Typography>
                <form
                  onSubmit={this.handleSubmit}
                  style={{ display: success ? "none" : "" }}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={user.name}
                    required
                    fullWidth
                    id="name"
                    label="Your name"
                    name="name"
                    autoComplete="name"
                    onChange={this.handleChange}
                    onBlur={this.handleValidation}
                    autoFocus
                    helperText={errors.name}
                    error={errors.name ? true : false}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={user.businessName}
                    required
                    fullWidth
                    id="businessName"
                    label="Business Name"
                    name="businessName"
                    autoComplete="businessName"
                    onChange={this.handleChange}
                    onBlur={this.handleValidation}
                    helperText={errors.businessName}
                    error={errors.businessName ? true : false}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={user.email}
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    onChange={this.handleChange}
                    onBlur={this.handleEmailValidation}
                    helperText={errors.email}
                    error={errors.email ? true : false}
                    type="email"
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={user.password}
                    onChange={this.handleChange}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    onBlur={this.handleValidation}
                    helperText={errors.password}
                    error={errors.password ? true : false}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    value={user.confirmPassword}
                    onChange={this.handleChange}
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="confirm-password"
                    onBlur={this.handleValidation}
                    helperText={errors.password}
                    error={errors.password ? true : false}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    disabled={!this.state.validated}
                  >
                    REGISTER
                  </Button>
                </form>
                <div
                  style={{
                    display: success ? "block" : "none",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <CheckCircleIcon color="primary" style={{ fontSize: 50 }} />
                  <Typography variant="h5" component="h2" gutterBottom>
                    Your account has been successfully created.
                  </Typography>
                  <Link component="a" href="/login" color="primary">
                    Click here to log in
                  </Link>
                </div>
                <Box component={Link} to="/login">
                  <Typography variant="caption">
                    Have an account? Sign In Now.
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default Register;
