import {
  Box,
  Container,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
} from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import { SquidilyFilters } from "../common/getFilters";
import { updateQueryString } from "../common/getQueryString";
import { TabPanel } from "../common/tabPanel";
import { useMst } from "../models/store";
import { KeywordAnalysis } from "./keywords/analysis";
import { Keywords } from "./keywords/keywords";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
  },
}));
export const Insights = observer((props) => {
  const classes = useStyles();
  const store = useMst();
  const [refresh, setRefresh] = useState(false);
  const [tabIndex, setTabIndex] = useState(
    props.location.pathname.indexOf("sentiments") > -1 ? 1 : 0
  );
  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });

  const handleLocationChange = (locationId) => {
    props.history.push({
      pathname: "/insights",
      search: `?locationId=${locationId}`,
    });
    store.forceRefresh(true);
  };
  const handleSourceChange = (sourceId) => {
    props.history.push({
      pathname: "/insights",
      search: updateQueryString("sourceId", sourceId, props.location.search),
    });
    store.forceRefresh(true);
  };
  const handlePeriodChange = (periodId) => {
    props.history.push({
      pathname: "/insights",
      search: updateQueryString("months", periodId, props.location.search),
    });
    store.forceRefresh(true);
  };
  debugger;
  return (
    <Container className={classes.container}>
      <SquidilyFilters
        locationChange={handleLocationChange}
        sourceChange={handleSourceChange}
        periodChange={handlePeriodChange}
        props={props}
      />
      <Box pt={2}>
        <KeywordAnalysis refresh={store.refresh} props={props} />
      </Box>
    </Container>
  );
});
