import {
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  InputBase,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useMst } from "../models/store";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  table: {
    fontSize: "12px",
  },
}));

export const CSVUpload = ({ open, onClose, transferData }) => {
  const classes = useStyles();
  const store = useMst();
  const [content, setContent] = useState(null);
  const uploadCSV = (e) => {
    if (!e.target.files.length) {
      return;
    }
    if (/\.(csv)$/i.test(e.target.files[0].name) === false) {
      alert("Only csv formats are allowed");
    } else {
      //update the name in field
      document.getElementById("csv_file_name").innerHTML =
        e.target.files[0].name;
      //start a reader
      // var reader = new FileReader();
      // reader.addEventListener("load", function (e) {
      //   let csvdata = e.target.result;
      //   setContent(parseDataToJSON(csvdata));
      // });

      // reader.readAsBinaryString(e.target.files[0]);
      setContent(e.target.files[0]);
    }
  };

  const parseDataToJSON = (data) => {
    debugger;
    if (data.length) {
      //split into array
      var arrayList = data.split("\r\n");
      let fnameIdx, lnameIdx, emailIdx, phoneIdx;
      //check if first line is the column definition
      if (arrayList[0].toLowerCase().indexOf("phone") > -1) {
        var splitDef = arrayList[0].split(",");
        //get the column positions
        splitDef.map((t, i) => {
          if (t.toLowerCase().indexOf("name") > -1) {
            if (t.toLowerCase().indexOf("first") > -1) {
              fnameIdx = i;
            } else if (t.toLowerCase().indexOf("last") > -1) {
              lnameIdx = i;
            }
          } else if (t.toLowerCase().indexOf("email") > -1) {
            emailIdx = i;
          } else if (t.toLowerCase().indexOf("phone") > -1) {
            phoneIdx = i;
          }
        });
        arrayList.splice(0, 1);
      }
      var jsonData = [];
      arrayList.map((t) => {
        var dataSplit = t.split(",");
        jsonData.push({
          firstName: dataSplit[fnameIdx ?? 0],
          lastName: dataSplit[lnameIdx ?? 1],
          emailAddress: dataSplit[emailIdx ?? 2],
          phone: dataSplit[phoneIdx ?? 3],
        });
      });

      return jsonData;
    }

    return data;
  };

  const handleUpload = () => {
    if (!content) {
      store.alertMessage("Please attach your file before upload", true);
      return;
    }
    transferData(content);
  };
  return (
    <React.Fragment>
      <Modal
        disableEnforceFocus={true}
        open={open}
        closeAfterTransition
        onClose={onClose}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
          style={{
            width: "550px",
            height: "546px",
            outline: 0,
          }}
        >
          <Box
            style={{
              overflow: "auto",
              height: "493.5px",
              borderBottom: "1px solid #e9e9e9",
            }}
          >
            <Box p={4}>
              <Box style={{ float: "right" }} p={1}>
                <IconButton onClick={onClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box pt={1} pb={1}>
                <Typography variant="h3">Upload a CSV file</Typography>
                <Typography variant="caption">
                  Upload your audience via CSV file that includes First Name,
                  Last Name, Email Address and Phone Number columns. File name
                  will be used as a audience list name. If the name already
                  exists, this CSV data will merge into the existing list
                </Typography>
              </Box>
              <Box style={{ background: "#fafafa" }} mt={2}>
                <Box p={2}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button variant="outlined" component="label">
                        Upload File
                        <input type="file" hidden onChange={uploadCSV} />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Box id="csv_file_name">No file attached</Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Box pt={4}>
                <Typography variant="h5">Example CSV file</Typography>
                <TableContainer>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.table} align="right">
                          <strong>First Name</strong>
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          <strong>Last Name</strong>
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          <strong>Email</strong>
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          <strong>Phone</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.table} align="right">
                          Jason
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          Derulo
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          jason.derulo<br></br>@example.com
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          1-234-567-7890
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell className={classes.table} align="right">
                          Michael
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          Jackson
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          m.jackson<br></br>@example.com
                        </TableCell>
                        <TableCell className={classes.table} align="right">
                          1-234-567-7890
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
          <Box textAlign="right" p={1}>
            <Box mr={1} display="inline">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
            <Box mr={1} display="inline">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
