import React, { Component, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  Grow,
  TextField,
  Card,
  CardContent,
  Button,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import qs from "querystring";
import {
  GetReviewRequest,
  PostRating,
  PostComment,
} from "../services/appService";
import { rateRequestStatus } from "../enums/rateRequestStatus";
import { Link } from "react-router-dom";
import { getQueryString } from "../common/getQueryString";
export const RateRequest = ({ ...props }) => {
  const [store, setStore] = useState({
    backDrop: true,
    data: null,
    status: 0,
    isValid: false,
    starValue: 0,
    comment: "",
    showBackDrop: function () {
      this.backDrop = true;
    },
    hideBackDrop: function () {
      this.backDrop = false;
    },
  });
  const splitValue = props.location.pathname.split("/");
  const uuid = splitValue[2];
  const rating = splitValue[3];

  const loadData = async () => {
    const getStore = { ...store };
    const response = await GetReviewRequest(uuid, rating ?? "");
    debugger;
    getStore.data = response.data;
    getStore.isValid = true;
    getStore.backDrop = false;
    getStore.status = response.data.status;
    setStore(getStore);
  };

  const handleRate = async (e, value) => {
    //post it
    const getStore = { ...store };
    getStore.backDrop = true;
    setStore(getStore);
    postRating(value);
    this.setState({ backDrop: false });
  };

  const postRating = async (value) => {
    const getStore = { ...store };
    const response = await PostRating({
      uuid: getStore.data.uuid,
      stars: value,
    });
    getStore.backDrop = false;
    getStore.starValue = value;
    if (value > store.data.moderateBelow) {
      getStore.status = rateRequestStatus.socialLinks;
    } else {
      getStore.status = rateRequestStatus.comment;
    }
  };

  const handleComment = async (e) => {
    store.showBackDrop();
    await PostComment({ uuid: store.data.uuid, comment: store.comment });
    const getStore = { ...store };
    getStore.status = rateRequestStatus.closed;
    getStore.backDrop = false;
    setStore(getStore);
  };
  const checkStatus = (value) => {
    return store.status === value;
  };
  const handleCommentChange = (e) => {
    const getStore = { ...store };
    getStore.comment = e.target.value;
    setStore(getStore);
  };
  const getUi = () => {
    if (store.isValid) {
      return (
        <React.Fragment>
          <Grow
            in={checkStatus(0)}
            style={{ display: checkStatus(0) ? "block" : "none" }}
          >
            <Box>
              <Typography variant="h2" component="h1">
                How happy are you with our service?
              </Typography>
              <Rating
                value={store.starValue}
                style={{ fontSize: "48px" }}
                onChange={handleRate}
                name="starRating"
              />
            </Box>
          </Grow>
          <Grow
            in={checkStatus(1)}
            style={{ display: checkStatus(1) ? "block" : "none" }}
          >
            <Box>
              <Typography
                variant="h2"
                component="h1"
                style={{ paddingBottom: "16px" }}
              >
                <strong>You can leave us a comment here</strong>
              </Typography>
              <TextField
                multiline
                rows={4}
                fullWidth={true}
                placeholder="Write your comments here"
                variant="outlined"
                onChange={handleCommentChange}
              />
              <Box pt={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  fullWidth
                  onClick={handleComment}
                >
                  POST NOW
                </Button>
              </Box>
            </Box>

            {/* <Box>
                <Typography
                  variant="h6"
                  component="h1"
                  style={{ paddingBottom: "16px" }}
                >
                  Please write your concerns
                </Typography>
                <TextField
                  multiline
                  rows={4}
                  fullWidth={true}
                  placeholder="Write your comments here"
                  variant="outlined"
                />
              </Box> */}
          </Grow>
          <Grow
            in={checkStatus(2)}
            style={{ display: checkStatus(2) ? "block" : "none" }}
          >
            <Box>
              <Card elevation={0}>
                <CardContent>
                  <Typography
                    variant="h2"
                    component="h1"
                    style={{ paddingBottom: "16px" }}
                  >
                    Thank you!
                  </Typography>
                  {store.data.pageLinks.length > 0 && (
                    <Typography variant="body1" component="p">
                      Please take a moment to leave us a review. Your responses
                      will help us serve you better in the future
                    </Typography>
                  )}

                  {store.data.pageLinks.map((t) => (
                    <Box pb={1}>
                      <Button
                        style={{ width: "350px" }}
                        variant="outlined"
                        href={t.url}
                      >
                        Review us on {t.title}
                      </Button>
                    </Box>
                  ))}
                </CardContent>
              </Card>
            </Box>
          </Grow>
          <Grow in={checkStatus(3)}>
            <Box>
              <Typography
                variant="h2"
                component="h1"
                style={{ paddingBottom: "16px" }}
              >
                You have successfully submitted your response.
              </Typography>
            </Box>
          </Grow>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Typography variant="h6" component="h1">
            The session has been expired. You can no longer post your review
          </Typography>
        </React.Fragment>
      );
    }
  };

  if (!uuid) {
    return <React.Fragment>Invalid Request</React.Fragment>;
  }
  return (
    <Box>
      <div
        style={{
          position: "absolute",
          width: "500px",
          height: "500px",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          display: "table",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <div style={{ display: "table-cell", "vertical-align": "middle" }}>
          {!store.data && loadData() && <React.Fragment></React.Fragment>}
          {store.data && getUi()}
        </div>
      </div>
      <Backdrop open={store.backDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
// class RateRequest extends Component {
//   state = {
//     isValid: false,
//     status: 0,
//     starValue: 0,
//     dataLoaded: false,
//     backDrop: false,
//     comment: "",
//   };
//   async componentWillMount() {
//     const splitValue = this.props.location.pathname.split("/");
//     const uuid = splitValue[2];
//     const rating = splitValue[3];
//     if (!uuid) {
//       this.setState({ isValid: false });
//     } else {
//       try {
//         this.setState({ backDrop: true });
//         const response =
//         this.setState({ backDrop: false });
//         if (response) {
//           this.setState({ dataLoaded: true });
//           if (response.data) {
//             this.setState({
//               isValid: true,
//               data: response.data,
//               status: response.data.status,
//             });
//           }
//         }
//       } catch (error) {}
//     }
//   }
//   render() {
//     const { data } = this.state;

//     return (

//     );
//   }
// }
// export default RateRequest;
