import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Grow,
  makeStyles,
  Modal,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import { SquidilyFilters } from "../../common/getFilters";
import { StyledHeader } from "../../common/getHeader";
import {
  getQueryString,
  getQueryStringFromUrl,
  updateQueryString,
} from "../../common/getQueryString";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { ReviewTemplate } from "../../common/reviewTemplate";
import { TabPanel } from "../../common/tabPanel";
import { keyword } from "../../enums/keyword";
import { GetKeywordsInfo, GetKeywords } from "../../services/appService";
import WarningIcon from "@material-ui/icons/Warning";
import { Skeleton } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: "200px",
    paddingRight: "10px",
  },
  positiveKeywords: {
    borderLeft: "4px solid #00e396",
  },
  negativeKeywords: {
    borderLeft: "4px solid red",
  },
  rating: {
    fontSize: "48px",
    fontWeight: "100",
  },
  ratingCount: {
    fontSize: "14px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  checkbox: {
    padding: "5px",
  },
  activeTab: {
    backgroundColor: "#f7f7f7",
  },
}));
export const Keywords = ({ refresh, ...props }) => {
  const classes = useStyles();
  const [store, setStore] = useState({
    tabIndex: 0,
    positiveKeywords: {
      keywords: null,
      reviews: [],
      reviewsIndex: 1,
      keywordsIndex: 1,
      keywordsEnded: false,
      reviewsEnded: false,
      selected: [],
    },
    negativeKeywords: {
      keywords: null,
      reviews: [],
      reviewsIndex: 1,
      keywordsIndex: 1,
      keywordsEnded: false,
      reviewsEnded: false,
      selected: [],
    },
  });

  useEffect(() => {
    async function getData(keywordType) {
      var queryValues = getValuesFromQueryString();
      const s = await GetKeywords(
        queryValues.locationId,
        queryValues.sourceId,
        queryValues.periodId,
        1,
        keywordType
      );
      const storeObj = { ...store };
      if (keywordType == keyword.positive) {
        storeObj.positiveKeywords.keywords = s;
      }
      setStore(storeObj);
    }
    if (store.positiveKeywords.keywords == null) {
      getData(keyword.positive);
    }
  });

  const loadKeywords = async (e) => {
    const keywordType = e.currentTarget.getAttribute("keywordType");
    var queryValues = getValuesFromQueryString();
    let s = null;
    const storeObj = { ...store };
    var keywordsType =
      keywordType == keyword.positive ? "positiveKeywords" : "negativeKeywords";
    if (!storeObj[keywordsType].keywordsEnded) {
      //get the current page index value and increment it
      storeObj[keywordsType].keywordsIndex++;
      s = await GetKeywords(
        queryValues.locationId,
        queryValues.sourceId,
        queryValues.periodId,
        storeObj[keywordsType].keywordsIndex ?? 1,
        keywordType
      );

      if (!s.data.length) {
        storeObj[keywordsType].keywordsEnded = true;
        return;
      }

      let keywords = { ...storeObj[keywordsType].keywords };
      s.data.map((t) => {
        keywords.data.push(t);
      });
      storeObj[keywordsType].keywords = keywords;
      setStore(storeObj);
    }
  };

  const loadReviews = async (keywordType, keywords, loadNextPage) => {
    var queryValues = getValuesFromQueryString();
    var keywordsType =
      keywordType == keyword.positive ? "positiveKeywords" : "negativeKeywords";
    const storeObj = { ...store };
    storeObj[keywordsType].reviewsIndex = loadNextPage
      ? storeObj[keywordsType].reviewsIndex + 1
      : 1;
    if (keywords) {
      storeObj[keywordsType].selected = keywords;
    }
    var data = await GetKeywordsInfo(
      keywords ?? storeObj[keywordsType].selected,
      queryValues.locationId,
      queryValues.sourceId,
      queryValues.periodId,
      keywordType,
      storeObj[keywordsType].reviewsIndex
    );

    if (!data.data.reviews.length) {
      storeObj[keywordsType].reviewsEnded = true;
      if (!loadNextPage) {
        storeObj[keywordsType].reviews = [];
      }
      setStore(storeObj);
      return;
    }
    var reviews = loadNextPage ? [...storeObj[keywordsType].reviews] : [];
    var allPstvKeywords;
    if (storeObj[keywordsType].keywords) {
      allPstvKeywords = storeObj[keywordsType].keywords.data.map((t) => t.word);
    }

    data.data.reviews.map((t) => {
      if (t.keywords || allPstvKeywords) {
        t.stringArray = separateKeywordsFromComment(
          keywordType,
          t.reviewerComment,
          t.keywords != null && t.keywords.length > 0
            ? t.keywords
            : allPstvKeywords
        );

        //   t.reviewerComment = highlightKeyword(
        //   t.reviewerComment,
        //   t.keywords != null && t.keywords.length > 0
        //     ? t.keywords
        //     : allPstvKeywords,
        //   keywordType
        // );
      }

      reviews.push(t);
    });
    storeObj[keywordsType].reviews = reviews;
    setStore(storeObj);
  };

  const separateKeywordsFromComment = (sentimentType, comment, keywords) => {
    var stringArray = [];
    var para = comment.split(" ");
    var text = "";

    for (var i in keywords) {
      comment = comment.replace(
        keywords[i],
        "&&&com)keyword>>>" + keywords[i] + "<<<keyword&&&com)"
      );
    }

    var splitComment = comment.split("&&&com)");

    for (var i in splitComment) {
      if (splitComment[i].indexOf("keyword>>>") > -1) {
        var txt = splitComment[i]
          .replace("keyword>>>", "")
          .replace("<<<keyword", "");
        stringArray.push({
          text: txt,
          sentimentType: sentimentType,
          isKeyword: true,
        });
      } else {
        stringArray.push({
          text: splitComment[i],
          sentimentType: sentimentType,
          isKeyword: false,
        });
      }
    }

    return stringArray;
  };

  const getValuesFromQueryString = () => {
    return {
      locationId: getQueryStringFromUrl(document.URL, "locationId") ?? 0,
      sourceId: getQueryStringFromUrl(document.URL, "sourceId") ?? 0,
      periodId: getQueryStringFromUrl(document.URL, "months") ?? 0,
    };
  };

  const handleTabChange = async (e, value) => {
    var queryValues = getValuesFromQueryString();
    const storeObj = { ...store };
    storeObj.tabIndex = value;
    if (value == 1 && !storeObj["negativeKeywords"].keywords) {
      const s = await GetKeywords(
        queryValues.locationId ?? 0,
        queryValues.sourceId ?? 0,
        queryValues.periodId ?? 0,
        1,
        2
      );
      if (!s.data.length) {
        storeObj["negativeKeywords"].reviewsEnded = true;
      }
      storeObj["negativeKeywords"].keywords = s;
    }
    setStore(storeObj);
  };

  const handleSelection = (event, keywordType) => {
    var keywordsType =
      keywordType == keyword.positive ? "positiveKeywords" : "negativeKeywords";
    var storeObj = { ...store };

    if (event.target.checked) {
      storeObj[keywordsType].selected.push(event.target.name);
    } else {
      for (let i = 0; i < storeObj[keywordsType].selected.length; i++) {
        if (storeObj[keywordsType].selected[i] == event.target.name) {
          storeObj[keywordsType].selected.splice(i, 1);
        }
      }
    }
    setStore(storeObj);
    loadReviews(keywordType, storeObj[keywordsType].selected);
  };

  const loadKeywordsAndReviews = async () => {
    var queryValues = getValuesFromQueryString();
    const storeObj = { ...store };
    var keywordsType =
      storeObj.tabIndex == 0 ? "positiveKeywords" : "negativeKeywords";
    var keywordsTypeId =
      storeObj.tabIndex == 0 ? keyword.positive : keyword.negative;
    var s = await GetKeywords(
      queryValues.locationId,
      queryValues.sourceId,
      queryValues.periodId,
      1,
      keywordsTypeId
    );
    storeObj[keywordsType].keywords = s;
    setStore(storeObj);
    loadReviews(keywordsTypeId);
  };

  const handleLocationChange = async (locationId) => {
    props.history.push({
      pathname: "/keywords",
      search: `?locationId=${locationId}`,
    });
    prepareForReloading();
    loadKeywordsAndReviews();
  };

  const handleSourceChange = (sourceId) => {
    props.history.push({
      pathname: "/keywords",
      search: updateQueryString("sourceId", sourceId, props.location.search),
    });
    prepareForReloading();
    loadKeywordsAndReviews();
  };
  const handlePeriodChange = (periodId) => {
    props.history.push({
      pathname: "/keywords",
      search: updateQueryString("months", periodId, props.location.search),
    });
    prepareForReloading();
    loadKeywordsAndReviews();
  };

  const prepareForReloading = () => {
    if (store) {
      const storeObj = { ...store };
      storeObj.positiveKeywords.keywordsEnded = false;
      storeObj.positiveKeywords.keywords = null;
      storeObj.positiveKeywords.reviews = [];
      setStore(storeObj);
    }
  };

  const loadReviewsMore = () => {
    if (store.tabIndex == 0) {
      loadReviews(keyword.positive, null, true);
    } else {
      loadReviews(keyword.negative, null, true);
    }
  };

  const constructKeywordsGrid = (keywordType) => {
    var keywordsType =
      keywordType == keyword.positive ? "positiveKeywords" : "negativeKeywords";
    if (store[keywordsType].keywords == null) {
      return <React.Fragment>Loading</React.Fragment>;
    }
    return (
      <Container>
        <Grid container>
          <Grid item xs={4}>
            <Box>
              {store[keywordsType].keywords &&
                store[keywordsType].keywords.data != null &&
                store[keywordsType].keywords.data.length > 0 &&
                store[keywordsType].keywords.data.map(
                  (t) =>
                    t.sentimentType == keywordType && (
                      <Typography>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                store[keywordsType].selected &&
                                store[keywordsType].selected.indexOf(t.word) >
                                  -1
                                  ? true
                                  : false
                              }
                              onChange={(e) => handleSelection(e, keywordType)}
                              name={t.word}
                              color="primary"
                              className={classes.checkbox}
                            />
                          }
                          label={t.word}
                        />{" "}
                        ({t.count}){" "}
                      </Typography>
                    )
                )}
              {store[keywordsType].keywords &&
                store[keywordsType].keywords.data.length > 0 && (
                  <Typography align="left">
                    <Button
                      component={Link}
                      onClick={loadKeywords}
                      keywordType={keywordType}
                      style={{
                        display: store[keywordsType].keywordsEnded
                          ? "none"
                          : "",
                      }}
                    >
                      See more
                    </Button>
                  </Typography>
                )}
              {!store[keywordsType].keywords ||
                (!store[keywordsType].keywords.data.length && (
                  <Box pt={2}>
                    <Typography align="center" color="textSecondary">
                      <WarningIcon />
                    </Typography>
                    <Typography align="center" color="textSecondary">
                      Not enough data
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={8}>
            {!store[keywordsType].reviews.length &&
              !store[keywordsType].reviewsEnded &&
              loadReviews(keywordType) && <LoadingSpinner />}
            {store[keywordsType].reviews.length > 0 &&
              store[keywordsType].reviews.map((t) => (
                <Grow in={store[keywordsType].reviews.length > 0}>
                  <Box mb={1}>
                    <Card variant="outlined">
                      <CardContent>
                        <Typography variant="caption">
                          <strong>{t.reviewerName}</strong>
                        </Typography>
                        <Box>
                          {t.stringArray.map((u) => {
                            if (u.isKeyword) {
                              return (
                                <Box
                                  style={{
                                    color: `${
                                      u.sentimentType == 1
                                        ? "#47d247"
                                        : "#ff3c3c"
                                    }`,
                                    borderBottom: `1px dashed ${
                                      u.sentimentType == 1
                                        ? "#47d247"
                                        : "#ff3c3c"
                                    }`,
                                    display: "inline",
                                  }}
                                  component={Link}
                                  to={`/keywords/analysis/${u.text}`}
                                >
                                  {u.text}
                                </Box>
                              );
                            } else {
                              return (
                                <Box style={{ display: "inline" }}>
                                  {u.text}
                                </Box>
                              );
                            }
                          })}
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grow>
              ))}
            {store[keywordsType].reviews.length > 0 && (
              <InfiniteScroll
                pageStart={store[keywordsType].reviewsIndex}
                loadMore={loadReviewsMore}
                hasMore={!store[keywordsType].reviewsEnded}
                loader={
                  <Typography align="center">
                    <LoadingSpinner />
                  </Typography>
                }
              />
            )}
            {!store[keywordsType].reviews.length &&
              store[keywordsType].reviewsEnded && (
                <Box pt={2}>
                  <Typography align="center" color="textSecondary">
                    <WarningIcon />
                  </Typography>
                  <Typography align="center" color="textSecondary">
                    Not enough data
                  </Typography>
                </Box>
              )}
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <Box>
      {/* <SquidilyFilters
          locationChange={handleLocationChange}
          sourceChange={handleSourceChange}
          periodChange={handlePeriodChange}
          props={props}
        /> */}
      <Card elevation={1} variant="elevation">
        <CardContent>
          {store.positiveKeywords.keywords == null && (
            <Grid container>
              <Grid item xs={4}>
                {[...Array(10)].map((t) => (
                  <Typography variant="h5" gutterBottom={true}>
                    <Skeleton />
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={8}>
                <Box pl={8} pr={2} pt={1}>
                  {[...Array(4)].map((t) => (
                    <Box pb={2}>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="250px"
                        style={{ marginTop: "5px" }}
                      >
                        <div style={{ paddingTop: "14px" }} />
                      </Skeleton>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        style={{ marginTop: "5px" }}
                      >
                        <div style={{ paddingTop: "14px" }} />
                      </Skeleton>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        style={{ marginTop: "5px" }}
                      >
                        <div style={{ paddingTop: "14px" }} />
                      </Skeleton>
                      <Skeleton
                        animation="wave"
                        variant="rect"
                        width="100%"
                        style={{ marginTop: "5px" }}
                      >
                        <div style={{ paddingTop: "14px" }} />
                      </Skeleton>
                    </Box>
                  ))}
                </Box>
              </Grid>
            </Grid>
          )}
          {store.positiveKeywords.keywords != null && (
            <Box p={1}>
              <Tabs
                value={store.tabIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleTabChange}
                aria-label="disabled tabs example"
              >
                <Tab
                  label="Positive"
                  className={store.tabIndex == 0 ? classes.activeTab : ""}
                />
                <Tab
                  label="Negative"
                  className={store.tabIndex == 1 ? classes.activeTab : ""}
                />
              </Tabs>
              <Box pt={2}>
                {console.log(store.tabIndex)}
                <TabPanel value={store.tabIndex} index={0}>
                  {constructKeywordsGrid(keyword.positive)}
                </TabPanel>
                <TabPanel value={store.tabIndex} index={1}>
                  {constructKeywordsGrid(keyword.negative)}
                </TabPanel>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};
