import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { Review } from "./review";
import { ReviewRequest } from "./reviewRequest";
import { Ticket } from "./ticket";
import { Audience } from "./audience";
import { AudienceContact } from "./audienceContact";
import { Campaign } from "./campaign";
import { Customer } from "./customer";
import { CustomerComment } from "./customerComment";
import { Template } from "./template";
import { Request } from "./request";
import { FeedbackRequest } from "./feedbackRequest";
import Conversation from "./conversation";
export const anyModel = t.union({
  dispatcher(snapshot) {
    switch (snapshot.type) {
      case "audienceContact":
        return AudienceContact;
      case "review":
        return Review;
      case "reviewRequest":
        return ReviewRequest;
      case "ticket":
        return Ticket;
      case "campaign":
        return Campaign;
      case "audience":
        return Audience;
      case "customer":
        return Customer;
      case "customerComment":
        return CustomerComment;
      case "template":
        return Template;
      case "request":
        return Request;
      case "feedbackRequest":
        return FeedbackRequest;
      case "conversation":
        return Conversation;
      default:
        throw new Error();
    }
  },
  AudienceContact,
  Review,
  ReviewRequest,
  Ticket,
  Audience,
  Campaign,
  Customer,
  CustomerComment,
  Template,
  Request,
  FeedbackRequest,
  Conversation,
});

export const PagedResult = t
  .model("pagedResult", {
    data: t.maybeNull(t.array(t.maybe(anyModel)), []),
    pageIndex: t.optional(t.integer, 0),
    pageSize: t.optional(t.integer, 0),
    totalSize: t.optional(t.integer, 0),
    pageCount: t.optional(t.integer, 0),
    awsPaginationIndex: t.maybeNull(t.optional(t.string, "")),
  })
  .actions((self) => ({
    unshiftData: function (record) {
      self.data.unshift(record);
    },
    pushData: function (record) {
      self.data.push(record);
    },
    set(key, value) {
      self[key] = value;
    },
  }));
