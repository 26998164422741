import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Container,
  makeStyles,
  Paper,
  Box,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  Modal,
  Button,
  Backdrop,
  List,
  ListItem,
  ListItemText,
  Divider,
} from "@material-ui/core";
import ReactApexChart from "react-apexcharts";
import StarRateIcon from "@material-ui/icons/StarRate";
import glogo from "../images/g-icon.png";
import fblogo from "../images/facebook.png";
import {
  GetOverview,
  GetCampaignsOverview,
  GetCampaignResult,
  GetAvailableTags,
  GetTagMetrics,
} from "../services/appService";
import { useState } from "react";
import { getIntegrationTypeById } from "../common/getIntegrationTypeById";
import { formatDateInTimeZoneInHrs } from "../common/formatDate";
import { TabPanel } from "../common/tabPanel";
import { Redirect, Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  tagsSection: {
    height: "500px",
    overflow: "auto",
    borderRight: "1px solid #e9e9e9",
    "&::-webkit-scrollbar": {
      width: "0.5em",
      backgroundColor: "#FFF",
    },
    "&::-webkit-scrollbar-track": {
      border: "1px solid #e9e9e9",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#e9e9e9",
    },
  },
  listItemText: {
    fontSize: "0.2em", //Insert your required size
  },
}));

export const Home = () => {
  const classes = useStyles();
  const [overview, setOverview] = useState({
    campaignData: null,
    reviewData: null,
    selectedTagMetrics: null,
    tags: null,
  });
  const [activeTab, setActiveTab] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTagMetric, setSelectedTagMetric] = useState(0);
  const [stat, setStat] = useState(null);
  const loadReviewsData = async () => {
    try {
      // const response = await GetOverview();
      const response = null;
      const getOverview = { ...overview };
      getOverview.reviewData = response.data;
      setOverview(getOverview);
    } catch (error) {}
  };
  const loadCampaignData = async () => {
    try {
      const response = await GetCampaignsOverview();
      const getOverview = { ...overview };
      getOverview.campaignData = response.data;
      setOverview(getOverview);
    } catch (error) {}
  };
  const constructReviewGraph = () => {
    const data = {
      series: [
        {
          name: "Total Count",
          data: overview.reviewData.totalReviewsByStar,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: "150px",
          events: {
            click: function (event, chartContext, config) {},
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "65%",
            distributed: true,
          },
        },
        fill: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          y: {
            formatter: function (
              value,
              { series, seriesIndex, dataPointIndex, w }
            ) {
              return value;
            },
          },
        },
        xaxis: {
          labels: {
            formatter: function (value, timestamp, index) {
              return value + "★";
            },
          },
          categories: ["1 ", "2 ", "3 ", "4 ", "5 "],
        },
      },
    };

    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={300}
      />
    );
  };

  const constructScatterGraph = () => {
    let s = overview;
    let series = [];
    for (
      let index = 0;
      index < overview.reviewData.reviewsByPages.length;
      index++
    ) {
      let typeData = {};
      typeData["name"] = getIntegrationTypeById(
        overview.reviewData.reviewsByPages[index]["type"]
      );

      let data = [];
      for (
        let j = 0;
        j < overview.reviewData.reviewsByPages[index].dataPoints.length;
        j++
      ) {
        const sa = formatDateInTimeZoneInHrs(
          overview.reviewData.reviewsByPages[index].dataPoints[j].createdOn
        );
        const ssss = new Date(sa).getTime();
        console.log(ssss);
        data.push([
          ssss,
          overview.reviewData.reviewsByPages[index].dataPoints[j].star,
        ]);
      }
      typeData["data"] = data;
      series.push(typeData);
    }
    const data = {
      series: series,
      options: {
        chart: {
          height: 350,
          type: "scatter",
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 10,
        },
        fill: {
          type: "image",
          opacity: 1,
          image: {
            src: [glogo, fblogo],
            width: 20,
            height: 20,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          max: 5,
          forceNiceScale: true,
          labels: {
            formatter: function (value) {
              return value + " ★";
            },
          },
        },
      },
    };
    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="scatter"
        height={350}
      />
    );
  };

  const handleActiveTab = (e, value) => {
    setActiveTab(value == 0 ? 1 : value);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const constructPieChart = () => {
    let arrayList = [];
    let labels = [];
    for (
      let index = 0;
      index < overview.reviewData.totalReviewsByPage.length;
      index++
    ) {
      arrayList.push(overview.reviewData.totalReviewsByPage[index].total);
      labels.push(
        getIntegrationTypeById(
          overview.reviewData.totalReviewsByPage[index].integrationType
        )
      );
    }
    const data = {
      series: arrayList,
      options: {
        chart: {
          type: "donut",
        },
        labels: labels,
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
            customScale: 0.95,
          },
        },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      },
    };
    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="donut"
        height={250}
      />
    );
  };

  const constructCampaignBarGraph = () => {
    if (activeTab == 2) {
      //get the data
      var dataPoints = [];
      var dataLabels = [];
      overview.campaignData.campaignInfos.map((t) => {
        dataPoints.push(t.percentage);
        dataLabels.push(t.name);
      });
      const data = {
        series: [
          {
            data: dataPoints,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 550,
            events: {
              dataPointSelection: function (e, chart, opts) {
                showCampaignStats(
                  overview.campaignData.campaignInfos[opts.dataPointIndex]
                );
              },
            },
          },
          noData: {
            text: "No data present for visuals",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#000",
              fontSize: "14px",
              fontFamily: undefined,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              distributed: true,
              barHeight: "80%",
              columnWidth: "90%",
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return val + "%";
            },
          },
          legend: {
            show: false,
          },
          xaxis: {
            max: 100,
            categories: dataLabels,
            labels: {
              formatter: function (value) {
                return value + "%";
              },
            },
          },
          yaxis: {
            opposite: true,
          },
        },
      };

      return (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          height={350}
          width={850}
        />
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const showCampaignStats = (obj) => {
    getCampaignStats(obj.id);
  };

  const getCampaignStats = async (id) => {
    const response = await GetCampaignResult(id);
    if (response.data) {
      setStat(response.data);
    }
    setOpenModal(true);
  };

  const constructCampaignResultGraph = () => {
    if (stat) {
      const data = {
        series: [
          {
            name: "Total Count",
            data: stat.totalScores,
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: "150px",
            events: {
              click: function (event, chartContext, config) {},
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "65%",
              distributed: true,
            },
          },
          fill: {},
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            y: {
              formatter: function (
                value,
                { series, seriesIndex, dataPointIndex, w }
              ) {
                return value;
              },
            },
          },
          xaxis: {
            categories:
              stat.campaignType == 1
                ? ["1", "2", "3", "4", "5"]
                : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            title: {
              text: "Scores",
            },
          },
          yaxis: {
            title: {
              text: "No of responses",
            },
          },
        },
      };

      return (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          height={300}
          width={660}
        />
      );
    }
  };

  const getCampaignTab = () => {
    if (activeTab == 2) {
      if (overview.campaignData) {
        return (
          <React.Fragment>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Card elevation={0} className={classes.card} variant="outlined">
                  <CardContent>
                    <Box pr={2} pl={2}>
                      <Grid container>
                        <Grid item className={classes.cardTitle} xs={8}>
                          <Typography component="h2" variant="h6">
                            <strong>In Progress</strong>
                          </Typography>
                          <Typography component="p" variant="subtitle2">
                            People participating in this campaign
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h2" align="right">
                            {overview.campaignData.totalInProgress}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card elevation={0} className={classes.card} variant="outlined">
                  <CardContent>
                    <Box pr={2} pl={2}>
                      <Grid container>
                        <Grid item className={classes.cardTitle} xs={8}>
                          <Typography component="h2" variant="h6">
                            <strong>In Draft</strong>
                          </Typography>
                          <Typography component="p" variant="subtitle2">
                            People participating in this campaign
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h2" align="right">
                            {overview.campaignData.totalDrafts}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={4}>
                <Card elevation={0} className={classes.card} variant="outlined">
                  <CardContent>
                    <Box pl={2} pr={2}>
                      <Grid container>
                        <Grid item className={classes.cardTitle} xs={8}>
                          <Typography component="h2" variant="h6">
                            <strong>Completed</strong>
                          </Typography>
                          <Typography component="p" variant="subtitle2">
                            People participating in this campaign
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="h2" align="right">
                            {overview.campaignData.totalCompleted}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Box pr={2} pt={2} pl={2}>
                      <Typography variant="h6">
                        <strong>Campaign Progress</strong>
                      </Typography>
                      <Typography variant="p">
                        Shows top 10 most active campaigns stats. Click on bar
                        to see the details
                      </Typography>
                      <Box p={2}>{constructCampaignBarGraph()}</Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      } else {
        loadCampaignData();
        return <React.Fragment>Loading....</React.Fragment>;
      }
    }
  };

  const loadTags = async () => {
    const response = await GetAvailableTags();
    const getOverview = { ...overview };
    if (response && response.data) {
      getOverview.tags = response.data;
      setOverview(getOverview);
    }
  };
  const handleTagMetrics = (e, obj) => {
    setSelectedTagMetric(obj.id);
    if (obj.id == selectedTagMetric) {
      return;
    } else {
      const getOverview = { ...overview };
      getOverview.selectedTagMetrics = null;
      loadTagMetric(obj.id);
    }
  };

  const loadTagMetric = async (id) => {
    const response = await GetTagMetrics(id);
    if (response && response.data) {
      const getOverview = { ...overview };
      getOverview.selectedTagMetrics = response.data;
      setOverview(getOverview);
    }
  };

  const getPerformanceTab = () => {
    if (activeTab == 4) {
      if (overview.tags) {
        return (
          <Grid container direction="row">
            <Grid item xs={12}>
              <Grid container direction="row">
                <Grid item xs={3} className={classes.tagsSection}>
                  <List>
                    {overview.tags.map((t) => (
                      <ListItem
                        button
                        key={t.name}
                        onClick={(e) => handleTagMetrics(e, t)}
                        component={Link}
                        selected={selectedTagMetric == t.id}
                      >
                        <Typography variant="subtitle2">{t.name}</Typography>
                      </ListItem>
                    ))}
                  </List>
                </Grid>
                <Grid item xs={9}>
                  {getTagPerformance()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      } else {
        loadTags();
        return <React.Fragment>....Loading</React.Fragment>;
      }
    }
  };

  const getTagPerformance = () => {
    if (overview.selectedTagMetrics) {
      return (
        <React.Fragment>
          <Box p={2}>
            <Card variant="outlined" elevation={0}>
              <CardContent>
                <Grid container direction="row">
                  <Grid item xs={6}>
                    {constructRadialChart(
                      "NPS Score",
                      overview.selectedTagMetrics.nps
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    {constructRadialChart(
                      "CSAT Score",
                      overview.selectedTagMetrics.csat
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box pt={2} pb={2}>
              <Typography variant="h6">
                <strong>Metrics trend comparison</strong>
              </Typography>
            </Box>
            <Card variant="outlined" elevation={0}>
              <CardContent>
                <Grid container>
                  <Grid item xs={12}>
                    {constructPerformanceChart()}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </React.Fragment>
      );
    } else if (selectedTagMetric > 0) {
      return <React.Fragment>...Loading</React.Fragment>;
    } else {
      return <React.Fragment>Select a tag to show the metrics</React.Fragment>;
    }
  };

  const constructPerformanceChart = () => {
    const data = {
      seriesArea: [
        {
          data: overview.selectedTagMetrics.npsTrend.dataPoints,
        },
      ],
      optionsArea: {
        chart: {
          id: "yt",
          group: "social",
          type: "area",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        colors: ["#00E396"],
        yaxis: {
          labels: {
            minWidth: 40,
          },
        },
      },
      seriesArea2: [
        {
          data: overview.selectedTagMetrics.csatTrend.dataPoints,
        },
      ],
      optionsArea2: {
        chart: {
          id: "yt",
          group: "social",
          type: "area",
          height: 160,
          toolbar: {
            show: false,
          },
        },
        colors: ["#00E396"],
        yaxis: {
          labels: {
            minWidth: 40,
          },
        },
      },
    };

    return (
      <React.Fragment>
        <Typography variant="caption">Net Promoter Score</Typography>
        <ReactApexChart
          options={data.optionsArea}
          series={data.seriesArea}
          type="area"
          height={160}
        />
        <Typography variant="caption">Customer Satisfaction Score</Typography>
        <ReactApexChart
          options={data.optionsArea2}
          series={data.seriesArea2}
          type="area"
          height={160}
        />
      </React.Fragment>
    );
  };
  const constructRadialChart = (title, value) => {
    const data = {
      series: [value],
      options: {
        chart: {
          height: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "65%",
            },
          },
        },
        labels: [title],
      },
    };

    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="radialBar"
        height={200}
      />
    );
  };

  const getReviewTab = () => {
    if (activeTab == 1) {
      if (overview.reviewData) {
        return (
          <Grid container direction="row" spacing={2}>
            <Grid item xs={6}>
              <Card variant="outlined" style={{ height: "400px" }}>
                <CardContent>
                  <Box pr={2} pt={2} pl={2}>
                    <Typography variant="h6">
                      <strong>Total Reviews</strong>
                    </Typography>
                    {constructReviewGraph()}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <Card variant="outlined" style={{ height: "400px" }}>
                <CardContent style={{ height: "400px" }}>
                  <Box pr={2} pt={2} pl={2}>
                    <Typography variant="h6">
                      <strong>Reviews Distribution</strong>
                    </Typography>
                    {constructPieChart()}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card variant="outlined">
                <CardContent>
                  <Box pr={2} pt={2} pl={2}>
                    <Typography variant="h6">
                      <strong>Review Stats</strong>
                    </Typography>
                    <Typography variant="p">In past 30 days</Typography>
                    {constructScatterGraph()}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      } else {
        loadReviewsData();
        return <React.Fragment>Loading....</React.Fragment>;
      }
    }
  };

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Box pb={2}>
          <Paper elevation={0} variant="outlined">
            <Tabs
              value={activeTab}
              className={classes.tabs}
              onChange={handleActiveTab}
            >
              <Tab label="Overview" id="tab-0" style={{ fontWeight: "bold" }} />
              <Tab label="Reviews" id="tab-1" />
              <Tab label="Campaigns" id="tab-2" />
              <Tab label="Tickets" id="tab-2" />
              <Tab label="Performance" id="tab-3" />
            </Tabs>
          </Paper>
        </Box>
        <TabPanel value={activeTab} index={1}>
          {getReviewTab()}
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          {getCampaignTab()}
        </TabPanel>
        <TabPanel value={activeTab} index={4}>
          {getPerformanceTab()}
        </TabPanel>
      </Container>
      <Modal
        disableEnforceFocus
        open={openModal}
        className={classes.modal}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableEnforceFocus
        disableAutoFocus
      >
        <Paper>
          <Box p={4}>
            {constructCampaignResultGraph()}
            <Grid container justify="flex-end">
              <Grid item>
                <Box pt={2}>
                  <Button variant="outlined" onClick={handleModalClose}>
                    Ok
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
