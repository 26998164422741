import React, { Component } from "react";
import { getQueryStringFromUrl } from "../common/getQueryString";
class OAuth2 extends Component {
  constructor(props) {
    super(props);
    //get the query string
    var code = getQueryStringFromUrl(document.URL, "code");
    window.opener.integrate(code);
    window.close();
  }
  render() {
    return <p></p>;
  }
}

export default OAuth2;
