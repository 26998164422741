import {
  Backdrop,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { Route, Switch } from "react-router";
import { Link } from "react-router-dom";
import { Navbar } from "../navbar";
import AddIcon from "@material-ui/icons/Add";
import { StyledHeader } from "../../common/getHeader";
import { SaveLocation } from "../../services/appService";
import { useMst } from "../../models/store";
import { Location } from "../../models/location";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { observer } from "mobx-react";
import { View } from "../locations/view";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  scrollBar: {
    overflow: "hidden",
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
}));
export const LocationLayout = observer(({ ...props }) => {
  const classes = useStyles();
  const store = useMst();
  const [newLocationInfo, setNewLocationInfo] = useState(Location.create());
  const [openModal, setOpenModal] = React.useState(false);
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleNewLocationChanges = (key, value) => {
    var locationInfo = newLocationInfo;
    locationInfo.set(key, value);
    setNewLocationInfo(locationInfo);
  };
  const handleNewLocation = async () => {
    const response = await SaveLocation(newLocationInfo);
    if (response && response.data) {
      store.addItem("locations", response.data);
      handleModalClose();
    }
  };
  useEffect(() => {
    if (!store.locations) {
      store.loadLocations();
    }
  });

  return (
    <React.Fragment>
      <Navbar {...props} />
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar} />
          <List className={classes.scrollBar}>
            <ListItem
              button
              key="Create Location"
              onClick={() => setOpenModal(true)}
            >
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="Create Location" />
            </ListItem>
            <Box pt={3}>
              {!store.locations && <LoadingSpinner />}
              {store.locations &&
                store.locations.length > 0 &&
                store.locations.map((t) => (
                  <ListItem
                    button
                    key={t.locationName}
                    component={Link}
                    to={`/locations/view?id=${t.id}`}
                    selected={
                      document.location.href.indexOf(
                        `/locations/view?id=${t.id}`
                      ) > -1
                    }
                  >
                    <ListItemText primary={t.locationName} />
                  </ListItem>
                ))}
            </Box>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/locations/view" component={View} />
          </Switch>
        </main>
        <Modal
          disableEnforceFocus
          open={openModal}
          className={classes.modal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "480px" }}>
            <Box pt={4} pb={4} style={{ background: "#fafafa" }}>
              <Box p={2}>
                <Typography align="center"></Typography>
                <StyledHeader heading="Add a location" alignText="center" />
                <Typography variant="subtitle1">
                  Location Information
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Location name"
                  margin="dense"
                  name="locationName"
                  onChange={(e) =>
                    handleNewLocationChanges("locationName", e.target.value)
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Location identifier"
                  margin="dense"
                  name="locationIdentifier"
                  onChange={(e) =>
                    handleNewLocationChanges(
                      "locationIdentifier",
                      e.target.value
                    )
                  }
                />
              </Box>
              <Box p={2}>
                <Typography variant="subtitle1">Contact Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Address"
                      margin="dense"
                      name="address"
                      onChange={(e) =>
                        handleNewLocationChanges("address", e.target.value)
                      }
                    />

                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="City"
                      margin="dense"
                      name="city"
                      onChange={(e) =>
                        handleNewLocationChanges("city", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="State"
                      margin="dense"
                      name="state"
                      onChange={(e) =>
                        handleNewLocationChanges("state", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Zip/Postal"
                      margin="dense"
                      name="zip"
                      onChange={(e) =>
                        handleNewLocationChanges("zip", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Country"
                      margin="dense"
                      name="country"
                      onChange={(e) =>
                        handleNewLocationChanges("country", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Email"
                      margin="dense"
                      name="email"
                      onChange={(e) =>
                        handleNewLocationChanges("email", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Phone"
                      margin="dense"
                      name="phone"
                      onChange={(e) =>
                        handleNewLocationChanges("phone", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pr={2}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewLocation}
                    >
                      <AddIcon /> Create
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </div>
    </React.Fragment>
  );
});
