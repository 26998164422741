import { Switch } from "react-router-dom";

export const getTicketStatus = (status) => {
  switch (status) {
    case 1:
      return "Open";
    case 2:
      return "Pending";
    case 3:
      return "Resolved";
    case 4:
      return "Waiting on client";
    case 5:
      return "Closed";
    default:
      break;
  }
};
