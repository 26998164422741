import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { SquidilyFilters } from "../../common/getFilters";
import { getQueryString, updateQueryString } from "../../common/getQueryString";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { GetReviewInvites } from "../../services/appService";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));
export const ReviewInvites = ({ ...props }) => {
  const classes = useStyles();
  const [loadedData, setLoadedData] = useState(null);
  let months = getQueryString(props.location.search, "months");
  useEffect(() => {
    if (!loadedData) {
      GetReviewInvites(months ?? 2).then((t) => {
        setLoadedData(t.data);
      });
    }
  });

  const getBarGraph = () => {
    var invites = [];
    var received = [];
    var dates = [];
    if (loadedData.length > 0) {
      loadedData.map((t) => {
        invites.push(t.count);
        received.push(t.totalReceived);
        if (!months || months == 2) {
          const date = new Date(t.year, t.month - 1, t.day);
          const month = date.toLocaleString("default", { month: "short" });
          dates.push(t.day + "-" + month);
        } else {
          const date = new Date(t.year, t.month, t.day);
          const month = date.toLocaleString("default", { month: "short" });
          dates.push(month + "-" + t.year);
        }
      });
    } else {
      return <React.Fragment></React.Fragment>;
    }
    var data = {
      series: [
        {
          name: "Invites",
          data: invites,
        },
        {
          name: "Received",
          data: received,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: dates,
        },
        fill: {
          opacity: 1,
        },
      },
    };

    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    );
  };

  const handlePeriodChange = (days) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/overview/review-invites",
      search: updateQueryString("months", days, props.location.search),
    });
  };

  const getPeriodRange = () => {
    const startDate = new Date(
      loadedData[0].year,
      loadedData[0].month - 1,
      loadedData[0].day
    );
    const startMonth = startDate.toLocaleString("default", { month: "short" });

    const endDate = new Date(
      loadedData[loadedData.length - 1].year,
      loadedData[loadedData.length - 1].month - 1,
      loadedData[loadedData.length - 1].day
    );
    const endMonth = endDate.toLocaleString("default", { month: "short" });

    if (!months || months == 2) {
      return (
        loadedData[0].day +
        "-" +
        startMonth +
        " to " +
        loadedData[loadedData.length - 1].day +
        "-" +
        endMonth
      );
    } else {
      return (
        startMonth +
        "-" +
        loadedData[0].year +
        " to " +
        endMonth +
        "-" +
        loadedData[loadedData.length - 1].year
      );
    }
  };

  if (!loadedData) {
    return <LoadingSpinner />;
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Box pb={1}>
                      <Typography variant="overline" component="h4">
                        <strong>Review Invites</strong>
                      </Typography>
                      <Typography variant="caption">
                        {getPeriodRange()}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <SquidilyFilters
                      props={props}
                      periodChange={handlePeriodChange}
                      hideHeading={true}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent>{getBarGraph()}</CardContent>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
};
