import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { CreateTicketButton } from "../common/createTicket";
import { formatDateInTimeZone } from "../common/formatDate";
import { GetNPSEmotion, getNPSEmotionColor } from "../common/getNPSEmotion";
import { LoadingSpinner } from "../common/loadingSpinner";
import { GetFeedbacks, GetFeedbackStats } from "../services/appService";
import MoodIcon from "@material-ui/icons/Mood";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import { SmallSelectMenu } from "../common/select";
import InfiniteScroll from "react-infinite-scroller";
import { PagedResult } from "../models/pagedResult";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import {
  getQueryStringFromUrl,
  updateQueryString,
} from "../common/getQueryString";
import { observer } from "mobx-react";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
  },
  nps: {
    border: "1px solid #e9e9e9",
    marginRight: "10px",
    "align-self": "center",
    "-webkit-align-self": "center",
    display: "inline-block",
  },
  sm: {
    width: "148px",
  },
  npsCol: {
    color: "#728200",
    width: "100%",
    position: "relative",
    backgroundColor: "#fff",
    borderWidth: "1px",
    borderStyle: "solid",
    padding: "15px",
    textAlign: "center",
    boxSizing: "border-box",
  },
  promoter: {
    borderColor: "#63ce03",
    color: "#63ce03",
  },
  passive: {
    borderColor: "#ff8d00",
    color: "#ff8d00",
  },
  detractor: {
    borderColor: "#ef4919",
    color: "#ef4919",
  },
  npsIconDiv: {
    position: "absolute",
    margin: "0 auto",
    left: 0,
    right: 0,
    top: "-24px",
  },
  npsIcon: {
    fontSize: "36px",
    backgroundColor: "#fff",
  },
  npsGroupTotals: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  npsGroupTotal: {
    fontSize: "18px",
    color: "#000",
  },
  noRightMargin: {
    marginRight: "0px !important",
  },
  npsGroupTotalValue: {
    border: "1px solid #bebeb8",
    borderRadius: "3px",
    padding: "10px 15px",
    color: "#000",
  },
  formControl: {
    width: "200px",
  },
}));

var isLoading = false;
export const Feedbacks = observer(({ ...props }) => {
  const classes = useStyles();
  const [loadedData, setLoadedData] = useState(null);
  const [stats, setStats] = useState(null);
  const getTypeByName = (name) => {
    var id = 0;
    switch (name) {
      case "promoters":
        id = 1;
        break;
      case "passive":
        id = 2;
        break;
      case "detractors":
        id = 3;
        break;
      default:
        id = 0;
        break;
    }

    return id;
  };
  useEffect(() => {
    if (!loadedData) {
      loadData(1).then(() => {
        if (!stats) {
          GetFeedbackStats().then((t) => {
            setStats(t.data);
          });
        }
      });
    }
  });

  const loadData = async (pageIndex, reset) => {
    var response = await GetFeedbacks(
      getTypeByName(getQueryStringFromUrl(document.URL, "type") ?? "all"),
      pageIndex ?? 1,
      10
    );
    if (!loadedData || reset) {
      var _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      setLoadedData(_pagedResult);
    } else {
      response.data.data.map((t) => {
        loadedData.pushData(t);
      });
      loadedData.set("pageIndex", parseInt(pageIndex));
    }

    isLoading = false;
  };

  const loadMoreData = () => {
    if (!isLoading) {
      isLoading = true;
      loadData(loadedData.pageIndex + 1);
    }
  };

  const getStats = (type) => {
    if (stats) {
      var scores = stats[type];
      var totalScore =
        stats["promoters"] + stats["passive"] + stats["detractors"];
      return ((scores / totalScore) * 100).toFixed(1);
    }

    return 0;
  };

  const getTypeName = (type) => {
    var name = "";
    switch (type) {
      case 1:
        name = "promoters";
        break;
      case 2:
        name = "passive";
        break;
      case 3:
        name = "detractors";
        break;
      default:
        name = "all";
        break;
    }
    return name;
  };

  const typeChange = (type) => {
    props.history.push({
      pathname: "/feedbacks",
      search: updateQueryString(
        "type",
        getTypeName(parseInt(type)),
        props.location.search
      ),
    });
    loadData(1, true);
  };

  const filter = () => {
    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          onChange={(e) => typeChange(e.target.value)}
          inputProps={{
            name: "period",
          }}
          value={getTypeByName(
            getQueryStringFromUrl(document.URL, "type") ?? "all"
          )}
          input={<SmallSelectMenu />}
        >
          <option value={0}>All</option>
          <option value={1}>Promoters</option>
          <option value={2}>Passives</option>
          <option value={3}>Detractors</option>
        </Select>
      </FormControl>
    );
  };

  if (!loadedData) {
    return <LoadingSpinner />;
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Box pt={1}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Box pb={1} textAlign="right">
                  {filter()}
                </Box>
                {loadedData.data.map((t) => (
                  <Box pb={2}>
                    <Paper elevation={0}>
                      <Card variant="outlined">
                        <CardContent className={classes.comment}>
                          <Grid container spacing={2}>
                            <Grid item xs={1}>
                              <Box textAlign="center">
                                <Typography
                                  style={{
                                    fontSize: "48px",
                                    fontWeight: "bold",
                                    color: getNPSEmotionColor(t.rating),
                                  }}
                                >
                                  {t.rating}
                                </Typography>
                                <Box>
                                  <GetNPSEmotion score={t.rating} size="24px" />
                                </Box>
                              </Box>
                            </Grid>
                            <Grid item xs={11}>
                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-end"
                              >
                                <Grid item>
                                  <Typography variant="h4">{t.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography
                                    variant="caption"
                                    color="textSecondary"
                                  >
                                    {formatDateInTimeZone(t.createdOn)}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Box pt={2}>
                                <Typography
                                  variant="body2"
                                  color="textPrimary"
                                  component="p"
                                >
                                  {t.comment}
                                </Typography>
                              </Box>
                              {/* {renderComments(t, t.integrationType)} */}
                              <Box pt={2} component="div">
                                {/* {CreateTicketButton(t)} */}
                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Paper>

                    {t.getSentiments && t.getSentiments()}
                  </Box>
                ))}
                {loadedData.data.length == 0 && (
                  <Typography>No feedback found</Typography>
                )}
                {loadedData.data.length > 0 && loadedData.pageCount > 1 && (
                  <InfiniteScroll
                    pageStart={loadedData.pageIndex}
                    loadMore={loadMoreData}
                    hasMore={loadedData.pageIndex < loadedData.pageCount}
                    loader={
                      <Typography align="center">
                        <LoadingSpinner />
                      </Typography>
                    }
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <Card variant="elevation" elevation={1}>
                  <CardContent>
                    <Box p={1} alignContent="center" textAlign="center">
                      <Typography variant="h3">Overall Stats</Typography>
                      {!stats && <LoadingSpinner />}
                      {stats && (
                        <Box pt={4}>
                          <Box className={classes.nps}>
                            <Box
                              className={`${classes.npsCol} ${classes.promoter}`}
                            >
                              <Box className={classes.npsGroupTotals}>
                                <Box className={classes.npsIconDiv}>
                                  <MoodIcon className={classes.npsIcon} />
                                </Box>
                                <Box className={classes.npsGroupTotal}>
                                  <Box>{getStats("promoters")}%</Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.nps}>
                            <Box
                              className={`${classes.npsCol} ${classes.passive}`}
                            >
                              <Box className={classes.npsGroupTotals}>
                                <Box className={classes.npsIconDiv}>
                                  <SentimentDissatisfiedIcon
                                    className={classes.npsIcon}
                                  />
                                </Box>
                                <Box className={classes.npsGroupTotal}>
                                  <Box>{getStats("passive")}%</Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.nps}>
                            <Box
                              className={`${classes.npsCol} ${classes.detractor}`}
                            >
                              <Box className={classes.npsGroupTotals}>
                                <Box className={classes.npsIconDiv}>
                                  <SentimentVeryDissatisfiedIcon
                                    className={classes.npsIcon}
                                  />
                                </Box>
                                <Box className={classes.npsGroupTotal}>
                                  <Box>{getStats("detractors")}%</Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                          <Box pt={2}>
                            <Button variant="outlined">
                              Request a Feedback
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </React.Fragment>
    );
  }
});
