import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useMst } from "../models/store";
import { observer } from "mobx-react";
import Cookies from "universal-cookie";
import SettingsIcon from "@material-ui/icons/Settings";
import { StyledMenu, StyledMenuItem } from "../common/dropdownMenu";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { BrowserRouter, Link } from "react-router-dom";
import { NavDropDown, NavLink } from "../common/getNavLink";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  horizontalMenu: {
    textDecoration: "none",
    marginRight: "30px",
    color: "#fff",
  },
  activeMenu: {
    padding: "5px",
    background: "#ffffff2e",
    borderRadius: "3px",
  },
}));

export const Navbar = observer((props) => {
  const classes = useStyles();
  const store = useMst();
  const handleManageMenu = (event) => {
    setManageAnchorEl(event.currentTarget);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleManageClose = () => {
    debugger;
    setManageAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    const cookies = new Cookies();
    cookies.remove("token");
    window.location = "/login";
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [manageAnchorEl, setManageAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const manageOpen = Boolean(manageAnchorEl);
  const handleSwitch = (to) => {
    props.history.push({
      pathname: to,
    });
  };
  return (
    <div>
      <AppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar>
          <Box style={{ flex: 1 }}>
            <Box pr={4} display="inline">
              <Typography variant="subtitle1" display="inline">
                Squidily
              </Typography>
            </Box>
            <Box display="inline">
              <NavLink to="/">Overview</NavLink>
              <NavLink to="/conversations">Conversations</NavLink>
              <NavLink to="/reviews">Reviews</NavLink>
              <NavLink to="/feedbacks">Feedback</NavLink>
              <NavLink to="/campaigns">Campaigns</NavLink>
              <NavLink to="/insights">Insights</NavLink>
              <NavLink onClick={handleManageMenu}>Manage</NavLink>
              <StyledMenu
                id="menu-appbar"
                anchorEl={manageAnchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                open={manageOpen}
                onClose={handleManageClose}
              >
                <StyledMenuItem
                  component={Link}
                  to="/customers"
                  onClick={handleManageClose}
                >
                  <ListItemIcon>
                    <PeopleAltIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Customers" />
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to="/locations"
                  onClick={handleManageClose}
                >
                  <ListItemIcon>
                    <LocationOnIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Locations" />
                </StyledMenuItem>
                <StyledMenuItem
                  component={Link}
                  to="/templates"
                  onClick={handleManageClose}
                >
                  <ListItemIcon>
                    <FormatAlignJustifyIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Templates" />
                </StyledMenuItem>
              </StyledMenu>
            </Box>
          </Box>
          <div>
            <Typography variant="subtitle1" onClick={handleMenu}>
              {store.user.displayName}
              <IconButton
                aria-label="Profile"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={handleMenu}
              >
                <AccountCircle />
              </IconButton>
            </Typography>
            <StyledMenu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              onClose={handleClose}
            >
              <StyledMenuItem component={Link} to="/settings">
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </StyledMenuItem>
              <StyledMenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </StyledMenuItem>
            </StyledMenu>
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Settings</MenuItem>
              <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
            </Menu> */}
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
});
