import {
  Button,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { StyledMenu, StyledMenuItem } from "./dropdownMenu";
const useStyles = makeStyles((theme) => ({
  horizontalMenu: {
    textDecoration: "none",
    marginRight: "30px",
    color: "#fff",
  },
  activeMenu: {
    padding: "5px",
    background: "#ffffff2e",
    borderRadius: "3px",
  },
}));
export const NavLink = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Button
      display="inline"
      className={`${classes.horizontalMenu} ${
        document.location.pathname == props.to ? classes.activeMenu : ""
      }`}
      component={Link}
      variant="text"
      to={props.to}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};

export const NavDropDown = ({ ...props }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <React.Fragment>
      <Button
        display="inline"
        className={`${classes.horizontalMenu} ${
          document.location.pathname == props.to ? classes.activeMenu : ""
        }`}
        component={Link}
        onClick={handleMenu}
        variant="text"
        to={props.to}
      >
        {props.text}
      </Button>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        onClose={handleClose}
      >
        {props.children}
      </StyledMenu>
    </React.Fragment>
  );
};
