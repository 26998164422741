import React, { Component, useEffect } from "react";
import {
  Container,
  makeStyles,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Divider,
  Button,
  Collapse,
  IconButton,
  CardHeader,
  Avatar,
} from "@material-ui/core";
import { useState } from "react";
import { useMst } from "../models/store";
import { GetLocations, GetOverview } from "../services/appService";
import { observer } from "mobx-react";
import ReactApexChart from "react-apexcharts";
import { AreaGraph, BarGraph, DonutGraph } from "../common/getGraph";
import { SmallSelectMenu } from "../common/select";
import { getQueryString, updateQueryString } from "../common/getQueryString";
import Rating from "@material-ui/lab/Rating";
import { StyledBreadcrumb } from "../common/breadcrumbs";
import { StyledChip } from "../common/chip";
import { Link } from "react-router-dom";
import { GetAverageRating } from "../common/getAverageRating";
import { getIntegrationTypeById } from "../common/getIntegrationTypeById";
import { SquidilyFilters } from "../common/getFilters";
import { formatDateInTimeZone, shortDate } from "../common/formatDate";
import { BarAlert } from "../common/getBarAlert";
import CloseIcon from "@material-ui/icons/Close";
import { resendConfirmEmail } from "../services/accountService";
import { getPriorityColor } from "../common/getPriority";
import { LoadingSpinner } from "../common/loadingSpinner";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: "200px",
    paddingRight: "10px",
  },
  positiveKeywords: {
    borderLeft: "4px solid #00e396",
  },
  negativeKeywords: {
    borderLeft: "4px solid red",
  },
  rating: {
    fontSize: "48px",
    fontWeight: "100",
  },
  ratingCount: {
    fontSize: "14px",
  },
  ratingStar: {
    position: "relative",
    verticalAlign: "text-bottom",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  cardSubContent: {
    background: "#fafafa",
  },
}));
export const LocationView = observer(({ ...props }) => {
  const classes = useStyles();
  const [optionSelected, setOptionSelected] = useState(0);
  const [loadedData, setLoadedData] = useState(null);
  const [sendConfirmEmail, setSendConfirmEmail] = useState(false);
  let sources = [];
  const store = useMst();
  useEffect(() => {
    async function getData() {
      const locationId = getQueryString(props.location.search, "locationId");
      let sourceId = getQueryString(props.location.search, "sourceId");
      let months = getQueryString(props.location.search, "months");
      const s = await GetOverview(
        locationId == "all" || !locationId ? 0 : locationId,
        sourceId == "all" || !sourceId ? 0 : sourceId,
        months ?? 0
      );
      setLoadedData(s);
    }
    if (loadedData == null) {
      getData();
    }
  });

  const handleSourceChange = (sourceId) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/",
      search: updateQueryString(
        "sourceId",
        sourceId == 0 ? "all" : sourceId,
        props.location.search
      ),
    });
  };

  const getAverageRating = () => {
    return GetAverageRating(
      loadedData.data.totalReviewsByStar,
      loadedData.data.totalReviewsByStar
    );
  };

  const getTotalReviews = () => {
    debugger;
    if (loadedData && loadedData.data) {
      return loadedData.data.totalReviewsByStar.reduce((a, b) => a + b, 0);
    }
    return 0;
  };

  const reviewsDistributionChart = () => {
    var seriesData = [];
    var labels = [];
    loadedData.data.totalReviewsByPage.map((t) => {
      seriesData.push(t.total);
      labels.push(getIntegrationTypeById(t.integrationType));
    });
    var options = {
      series: seriesData,
      chart: {
        type: "polarArea",
      },
      labels: labels,
      stroke: {
        colors: ["#fff"],
      },
      fill: {
        opacity: 1,
      },
      yaxis: {
        show: false,
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    return (
      <ReactApexChart
        options={options}
        series={options.series}
        height={250}
        type="polarArea"
      />
    );
  };

  const handlePeriodChange = (days) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/location",
      search: updateQueryString("months", days, props.location.search),
    });
  };

  const resendConfirmationEmail = async (e) => {
    setSendConfirmEmail(true);
    var response = await resendConfirmEmail();
    if (response.data) {
      setSendConfirmEmail(false);
    }
  };

  const handleLocationChange = (locationId) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/",
      search: `?locationId=${locationId == 0 ? "all" : locationId}`,
    });
    if (!isNaN(locationId)) {
      //search location and select its sources
      const location = store.locations.find((t) => t.id == locationId);
      if (location && location.linkedPages && location.linkedPages.length) {
        sources = location.linkedPages;
      } else {
        sources = [];
      }
    }
    setOptionSelected(locationId);
  };

  const handleKeywordClick = (word) => {
    props.history.push({
      pathname: `/insights/sentiments/${word}`,
    });
  };

  if (loadedData == null) {
    return (
      <React.Fragment>
        <LoadingSpinner />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          {store.loadingPages == false && store.pages.length == 0 && (
            <Box pb={1}>
              <BarAlert
                icon={<CloseIcon />}
                text={
                  <Box>
                    You haven't connected any social accounts with Squidily.{" "}
                    <Box component={Link} to="/locations">
                      Integrate Now
                    </Box>
                  </Box>
                }
                type="warning"
              />
            </Box>
          )}

          {!store.accountInfo.account.emailConfirmed && (
            <Box pt={1} pb={1}>
              <BarAlert
                icon={<CloseIcon />}
                text={
                  <Box>
                    Your account has limited access. Please check your email to
                    confirm{" "}
                    <Button
                      variant="outlined"
                      onClick={resendConfirmationEmail}
                    >
                      {sendConfirmEmail ? "Sent. Resend?" : "Resend"}
                    </Button>
                  </Box>
                }
                type="warning"
              />
            </Box>
          )}
          <Box pb={2}>
            <Box pb={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="flex-end"
                        >
                          <Grid item>
                            <Box pb={1}>
                              <Typography variant="overline" component="h4">
                                <strong>Reviews</strong>
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item>
                            <SquidilyFilters
                              sourceChange={handleSourceChange}
                              props={props}
                              hideHeading={true}
                            />
                          </Grid>
                        </Grid>
                      }
                    />
                    <CardContent
                      className={classes.cardContent}
                      style={{ paddingBottom: "0" }}
                    >
                      <Box className={classes.cardSubContent}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Avg Review Rating
                                    </Typography>
                                  }
                                  subheader={"To Date"}
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {getAverageRating()}
                                  </Typography>
                                  <Typography align="center">
                                    <Rating
                                      value={getAverageRating()}
                                      precision={0.5}
                                      readOnly
                                      size="medium"
                                      style={{ verticalAlign: "text-bottom" }}
                                    />
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Total Reviews
                                    </Typography>
                                  }
                                  subheader="To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {getTotalReviews()}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Reviews
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Review Invites
                                    </Typography>
                                  }
                                  subheader="Month To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalReviewsInvite}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Reviews
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                {/* <Grid item xs={6}>
                  <Card elevation={1} variant="elevation">
                    <CardContent>
                      <Box style={{ height: "300px" }}>
                        <Typography variant="overline" component="h4">
                          <strong>Review Summary</strong>
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          You have received{" "}
                          {loadedData.data.reviewsCountInAMonth} reviews in past
                          30 days
                        </Typography>
                        <Grid container>
                          <Grid item xs={8}>
                            {starRatingGraph()}
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              className={classes.rating}
                              align="center"
                            >
                              {getAverageRating()}
                            </Typography>
                            <Typography align="center">
                              <Rating
                                value={getAverageRating()}
                                precision={0.5}
                                readOnly
                                size="medium"
                                style={{ verticalAlign: "text-bottom" }}
                              />
                            </Typography>
                            <Typography
                              align="center"
                              className={classes.ratingCount}
                            >
                              {getTotalReviews()} Reviews
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={6}>
                  <Card elevation={1} variant="elevation">
                    <CardContent>
                      <Box style={{ height: "300px" }}>
                        <Typography
                          variant="overline"
                          component="h4"
                          align="right"
                        >
                          <strong>Reviews Distribution</strong>
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="right"
                        >
                          99% of your reviews are from Google.
                        </Typography>
                        <Box pt={2}>{reviewsDistributionChart()}</Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid> */}
              </Grid>
            </Box>
            <Box pt={2} pb={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Typography variant="overline" component="h4">
                          <strong>Feedback</strong>
                        </Typography>
                      }
                    />
                    <CardContent
                      className={classes.cardContent}
                      style={{ paddingBottom: "0" }}
                    >
                      <Box className={classes.cardSubContent}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Avg NPS Score{" "}
                                    </Typography>
                                  }
                                  subheader={"Month To Date"}
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.avgFeedbackNPSScore.toFixed(
                                      1
                                    )}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Score
                                  </Typography>
                                  {/* <Typography align="center">
                                    <Rating
                                      value={getAverageRating()}
                                      precision={0.5}
                                      readOnly
                                      size="medium"
                                      style={{ verticalAlign: "text-bottom" }}
                                    />
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    {getTotalReviews()} Reviews
                                  </Typography> */}
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Total Reviews
                                    </Typography>
                                  }
                                  subheader="Month To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalFeedbacksReceived}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Reviews
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Feedback Invites
                                    </Typography>
                                  }
                                  subheader="Month To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalFeedbacksInvite}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Reviews
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <Box pt={2} pb={2}>
              <Grid container>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardHeader
                      title={
                        <Typography variant="overline" component="h4">
                          <strong>Tickets</strong>
                        </Typography>
                      }
                    />
                    <CardContent
                      className={classes.cardContent}
                      style={{ paddingBottom: "0" }}
                    >
                      <Box className={classes.cardSubContent}>
                        <Grid container>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      New Tickets{" "}
                                    </Typography>
                                  }
                                  subheader={"Month To Date"}
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalTicketsCreated}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Tickets
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Open Tickets
                                    </Typography>
                                  }
                                  subheader="To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalTicketsOpen}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Tickets
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <Box p={2}>
                              <Card variant="outlined">
                                <CardHeader
                                  title={
                                    <Typography variant="h3">
                                      Tickets Resolved
                                    </Typography>
                                  }
                                  subheader="To Date"
                                />
                                <CardContent style={{ height: "158px" }}>
                                  <Typography
                                    className={classes.rating}
                                    align="center"
                                  >
                                    {loadedData.data.totalTicketsResolved}
                                  </Typography>
                                  <Typography
                                    align="center"
                                    className={classes.ratingCount}
                                  >
                                    Tickets
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {/* <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="overline" component="h6">
                    <strong>Keywords</strong>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Keywords that frequently appeard in the reviews
                  </Typography>
                  <Box pt={2}>
                    <Card className={classes.positiveKeywords}>
                      <CardContent>
                        <Typography variant="h6">Positive Keywords</Typography>
                        <div>
                          {loadedData.data.positiveKeywords.length > 0 &&
                            loadedData.data.positiveKeywords.map((t) => {
                              return (
                                <StyledChip
                                  label={t.word}
                                  avatar={t.count}
                                  onClick={() => handleKeywordClick(t.word)}
                                />
                              );
                            })}
                        </div>
                        {!loadedData.data.positiveKeywords.length && (
                          <Typography variant="body2" color="textSecondary">
                            No keywords present
                          </Typography>
                        )}
                        {loadedData.data.positiveKeywords.length > 0 && (
                          <Typography align="right">
                            <Button
                              variant="outlined"
                              component={Link}
                              to="/insights"
                            >
                              See all
                            </Button>
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                  <Box pt={2}>
                    <Card className={classes.negativeKeywords}>
                      <CardContent>
                        <Typography variant="h6">Negative Keywords</Typography>
                        <div>
                          {loadedData.data.negativeKeywords.length > 0 &&
                            loadedData.data.negativeKeywords.map((t) => {
                              return (
                                <StyledChip
                                  label={t.word}
                                  avatar={t.count}
                                  onClick={() => handleKeywordClick(t.word)}
                                />
                              );
                            })}
                        </div>
                        {!loadedData.data.negativeKeywords.length && (
                          <Typography variant="body2" color="textSecondary">
                            No keywords present
                          </Typography>
                        )}
                        {loadedData.data.negativeKeywords.length > 0 && (
                          <Typography align="right">
                            <Button
                              variant="outlined"
                              component={Link}
                              to="/insights"
                            >
                              See all
                            </Button>
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Typography variant="overline" component="h6">
                    <strong>Tickets</strong>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    Tickets that need your attention
                  </Typography>
                  <Box pt={2}>
                    {!loadedData.data.recentTickets ||
                      (loadedData.data.recentTickets.length == 0 && (
                        <Card>
                          <CardContent>Yay, No tickets created</CardContent>
                        </Card>
                      ))}
                    {loadedData.data.recentTickets &&
                      loadedData.data.recentTickets.length > 0 &&
                      loadedData.data.recentTickets.map((t) => {
                        return (
                          <Box pb={1}>
                            <Box
                              component={Link}
                              to={`/tickets/${t.id}`}
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              <Card
                                variant="outlined"
                                style={{
                                  borderLeft: `5px solid ${getPriorityColor(
                                    t.priority
                                  )}`,
                                }}
                              >
                                <CardHeader
                                  title={
                                    <Typography variant="body1" component="p">
                                      {t.name}{" "}
                                      <Rating
                                        className={classes.ratingStar}
                                        value={t.starRating}
                                        max={t.starRating}
                                        readOnly
                                        size="small"
                                      />
                                    </Typography>
                                  }
                                  subheader={
                                    <Typography
                                      variant="caption"
                                      color="textSecondary"
                                    >
                                      {formatDateInTimeZone(t.createdOn)}
                                    </Typography>
                                  }
                                  avatar={<Avatar>{t.name[0]}</Avatar>}
                                />
                              </Card>
                            </Box>
                          </Box>
                        );
                      })}
                    {loadedData.data.recentTickets &&
                      loadedData.data.recentTickets.length > 0 && (
                        <Typography align="right">
                          <Box
                            component={Link}
                            to="/tickets"
                            style={{ textDecoration: "none" }}
                          >
                            See more tickets
                          </Box>
                        </Typography>
                      )}
                  </Box>
                </Box> 
              </Grid>
            </Grid>
          </Box> */}
        </Container>
      </React.Fragment>
    );
  }
});
