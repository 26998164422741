import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const Campaign = t
  .model("campaign", {
    id: t.optional(t.integer, 0),
    name: t.maybeNull(t.string, ""),
    subject: t.maybeNull(t.string, ""),
    body: t.maybeNull(t.string, ""),
    status: t.maybeNull(t.integer, 0),
    stage: t.optional(t.integer, 0),
    audienceId: t.optional(t.integer, 0),
    modifiedOn: t.maybeNull(t.string, ""),
    tags: t.maybeNull(t.array(t.string), []),
    campaignType: t.optional(t.integer, 0),
    smsBody: t.maybeNull(t.string, ""),
    attachment: t.maybeNull(t.string, ""),
  })
  .actions((self) => ({
    addTag: function (name) {
      if (!self.tags) {
        self.tags = [];
      }
      self.tags.push(name);
    },
    getAttachmentInfo() {
      if (self.attachment) {
        var link = self.attachment
          .substr(self.attachment.indexOf("("))
          .replace("(", "")
          .replace(")", "");
        var name = self.attachment.substr(0, self.attachment.indexOf("("));
        return {
          name: name,
          link: link,
        };
      } else {
        return null;
      }
    },
    set(key, value) {
      self[key] = value;
    },
  }));
