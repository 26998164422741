import { types as t, flow, applySnapshot } from "mobx-state-tree";
import React from "react";
import User from "./user";
import { useContext } from "react";
import { AccountInfo } from "./accountInfo";
import { Page } from "./page";
import {
  LoadPages,
  LoadAllReviews,
  LoadReviewRequests,
  PostReviewRequest,
  LoadAccountSetup,
  GetTickets,
  GetAudiences,
  GetAudienceContacts,
  GetCampaigns,
  GetCampaignResult,
  GetLocations,
  GetOverview,
  GetAllAudiences,
  GetAvailableTags,
  LoadTemplatesForCombo,
} from "../services/appService";
import { PagedResult } from "./pagedResult";
import { AccountSetup } from "./accountSetup";
import { Audience } from "./audience";
import { CampaignResult } from "./campaignResult";
import { Location } from "./location";
import { Overview } from "./overview";
import { AccountTag } from "./accountTag";
import { Template } from "./template";
const Store = t
  .model("store", {
    user: t.optional(User, {}),
    accountInfo: t.optional(AccountInfo, {}),
    isAuthenticated: false,
    pages: t.array(Page, []),
    isAlertOpen: false,
    alertMessage: t.optional(t.string, ""),
    allReviews: t.optional(PagedResult, {}),
    reviewRequests: t.optional(PagedResult, {}),
    accountSetup: t.maybeNull(AccountSetup, {}),
    tickets: t.optional(PagedResult, {}),
    audiences: t.maybeNull(PagedResult, null),
    progressBar: t.optional(t.boolean, false),
    customers: t.maybeNull(PagedResult, null),
    campaigns: t.maybeNull(PagedResult, null),
    locations: t.maybeNull(t.array(Location, null), null),
    overview: t.maybeNull(Overview, null),
    audienceListForCombo: t.maybeNull(t.array(Audience, null), null),
    availableTags: t.maybeNull(t.array(AccountTag, null), null),
    loadingPages: true,
    refresh: false,
    campaignResult: t.maybeNull(CampaignResult, null),
    templatesForCombo: t.maybeNull(t.array(Template, null), null),
    reset: false,
  })
  .actions((self) => ({
    authenticate: flow(function* () {
      try {
        yield self.user.getUserInfo();
        yield self.accountInfo.getAccountInfo();
        self.set("isAuthenticated", true);
        self.loadPages();
      } catch (error) {
        window.location = "/login";
      }
    }),
    loadPages: flow(function* () {
      const locations = yield GetLocations();
      if (locations && locations.data) {
        self.locations = locations.data;
      }
      const response = yield LoadPages();
      debugger;
      if (response && response.data.length > 0) {
        response.data.map((t) => {
          const _page = Page.create();
          applySnapshot(_page, t);
          self.pages.push(_page);
        });
      } else {
        self.set("pages", []);
      }
      self.set("loadingPages", false);
    }),
    loadTemplates: flow(function* () {}),
    loadLocations: flow(function* () {
      const locations = yield GetLocations();
      if (locations && locations.data) {
        self.locations = locations.data;
      }
    }),
    loadAllReviews: flow(function* (pageIndex, pageSize) {
      const response = yield LoadAllReviews(pageIndex, pageSize);
      const _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      self.set("allReviews", _pagedResult);
    }),
    loadReviewRequests: flow(function* (pageIndex, pageSize) {
      const response = yield LoadReviewRequests(pageIndex, pageSize);
      const _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      self.set("reviewRequests", _pagedResult);
    }),
    loadAccountSetup: flow(function* () {
      const response = yield LoadAccountSetup();
      const _setup = AccountSetup.create();
      applySnapshot(_setup, response.data);
      self.set("accountSetup", _setup);
    }),
    createReviewRequest: flow(function* (model) {
      const response = yield PostReviewRequest(model);
      if (self.reviewRequests.data) {
        self.reviewRequests.data.splice(0, 0, response.data);
        window.location = "/reviews/request";
      }
    }),
    loadTickets: flow(function* (pageIndex, pageSize) {
      const response = yield GetTickets(pageIndex, pageSize);
      const _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      self.set("tickets", _pagedResult);
      return response.data;
    }),
    loadAudiences: flow(function* (pageIndex, pageSize) {
      const response = yield GetAudiences(pageIndex, pageSize);
      const _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      self.set("audiences", _pagedResult);
    }),
    loadCampaigns: flow(function* (pageIndex, pageSize) {
      const response = yield GetCampaigns(pageIndex, pageSize);
      const _pagedResult = PagedResult.create();
      applySnapshot(_pagedResult, response.data);
      self.set("campaigns", _pagedResult);
    }),
    loadCampaignResult: flow(function* (id) {
      const response = yield GetCampaignResult(id);
      const _campaignResult = CampaignResult.create();
      debugger;
      applySnapshot(_campaignResult, response.data);
      self.set("campaignResult", _campaignResult);
    }),
    loadOverview: flow(function* (locationId, sourceId, months) {
      const response = yield GetOverview(locationId, sourceId, months);
    }),
    loadAudienceListForCombo: flow(function* () {
      const response = yield GetAllAudiences();
      var data = [];
      if (response && response.data) {
        response.data.map((t) => {
          const _audience = Audience.create();
          applySnapshot(_audience, t);
          data.push(_audience);
        });
      }
      self.audienceListForCombo = data;
    }),
    loadTemplatesForCombo: flow(function* (templateType, sendType) {
      const response = yield LoadTemplatesForCombo(templateType, sendType);
      var data = [];
      if (response && response.data) {
        response.data.map((t) => {
          const _template = Template.create();
          applySnapshot(_template, t);
          data.push(_template);
        });
      }
      self.templatesForCombo = data;
    }),
    loadAvailableTags: flow(function* () {
      const response = yield GetAvailableTags();
      var data = [];
      if (response && response.data) {
        response.data.map((t) => {
          const _accountTag = AccountTag.create();
          applySnapshot(_accountTag, t);
          data.push(_accountTag);
        });
      }
      self.availableTags = data;
    }),
    toggleAlert(show, message) {
      if (show) {
        self.set("alertMessage", message);
      }
      self.set("isAlertOpen", show);
    },
    addItem(key, obj) {
      self[key].push(obj);
    },
    findPage(id) {
      if (self.pages.length > 0) {
        return self.pages.find((t) => t.id == id);
      }
    },
    forceRefresh(value) {
      self["refresh"] = value;
    },
    set(key, value) {
      self[key] = value;
    },
  }));
export const store = Store.create();
const storeContext = React.createContext(store);
export const Provider = storeContext.Provider;
export default () => useContext(storeContext);

export function useMst() {
  const store = useContext(storeContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
