import React, { Component, useEffect } from "react";
import { useMst } from "../../models/store";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  InputAdornment,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Container,
  withStyles,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Chip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { observer } from "mobx-react";
import { formatDateInTimeZone } from "../../common/formatDate";
import { StyledTableHead } from "../../common/table";
import { StyledHeader } from "../../common/getHeader";
import {
  getCampaignStatus,
  getCampaignStatusByName,
} from "../../enums/campaignStatus";
import { SmallSelectMenu } from "../../common/select";
import {
  getQueryString,
  getQueryStringFromUrl,
} from "../../common/getQueryString";
import { GetCampaigns } from "../../services/appService";
import { PagedResult } from "../../models/pagedResult";
import { applySnapshot } from "mobx-state-tree";
import InfiniteScroll from "react-infinite-scroller";
import { LoadingSpinner } from "../../common/loadingSpinner";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    background: "#fff",
  },
}));
var isLoading = false;

export const All = () => {
  return <AllCampaigns type="all" />;
};
export const Drafts = () => {
  return <AllCampaigns type="drafts" />;
};

export const Completed = () => {
  return <AllCampaigns type="completed" />;
};

export const InProgress = () => {
  return <AllCampaigns type="inprogress" />;
};

export const AllCampaigns = observer(({ type }) => {
  const store = useMst();
  const classes = useStyles();
  const [loadedData, setLoadedData] = useState(null);
  const page = getQueryStringFromUrl(document.URL, "page") ?? 1;
  debugger;
  useEffect(() => {
    debugger;
    if (!loadedData) {
      loadData(1);
    }
  });

  const loadData = async (pageIndex) => {
    GetCampaigns(getCampaignStatusByName(type), pageIndex, 20).then((t) => {
      if (!loadedData) {
        var _pagedResult = PagedResult.create();
        applySnapshot(_pagedResult, t.data);
        setLoadedData(_pagedResult);
      } else {
        if (t.data.data.length) {
          t.data.data.map((t) => {
            loadedData.pushData(t);
          });
          loadedData.set("pageIndex", parseInt(pageIndex));
        }
      }
    });
  };

  // const handleAllCheck = (e) => {
  //   if (e.target.checked) {
  //     setSelected(store.campaigns.data.map((t) => t.id));
  //     setTableActionButtons(false);
  //   } else {
  //     setSelected([]);
  //     setTableActionButtons(true);
  //   }
  // };

  const loadMoreData = () => {
    debugger;
    if (!isLoading) {
      isLoading = true;
      loadData(loadedData.pageIndex + 1);
    }
  };

  if (loadedData) {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <StyledHeader
            heading="Campaigns"
            subHeading={`${loadedData.totalSize} Records found.`}
            actionButton={
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 500 }}
                to="/campaigns/create"
                component={Link}
              >
                <AddIcon /> Create New
              </Button>
            }
          />
          <Box>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="Table">
                <TableBody>
                  {loadedData.data.map((x) => (
                    <TableRow
                      hover
                      component={Link}
                      to={
                        x.status == 0
                          ? `/campaigns/edit/${x.id}`
                          : `/campaigns/view/${x.id}`
                      }
                      style={{ textDecoration: "none" }}
                      onClick={() => store.set("campaignResult", null)}
                    >
                      <TableCell>
                        <Typography variant="h4">{x.name}</Typography>
                        <Typography variant="caption" color="textSecondary">
                          {formatDateInTimeZone(x.modifiedOn)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip
                          variant="outlined"
                          label={getCampaignStatus(x.status)}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {loadedData.data.length > 0 && loadedData.pageCount > 1 && (
              <InfiniteScroll
                pageStart={loadedData.pageIndex}
                loadMore={loadMoreData}
                hasMore={loadedData.pageIndex < loadedData.pageCount}
                loader={
                  <Typography align="center">
                    <LoadingSpinner />
                  </Typography>
                }
              />
            )}
          </Box>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={100}
          /> */}
        </Container>
      </React.Fragment>
    );
  } else {
    return <React.Fragment>Loading...</React.Fragment>;
  }
});
