export const getQueryString = (qs, name) => {
  qs = qs.replace("?", "");
  if (qs && name) {
    const x = qs.split("&");
    if (x && x.length > 0) {
      for (let i = 0; i < x.length; i++) {
        if (x[i].indexOf("=") > -1) {
          const keyValuePair = x[i].split("=");
          if (keyValuePair[0] == name) {
            return keyValuePair[1];
          }
        }
      }
    }
  }
  return null;
};

export const getQueryStringFromUrl = (qs, name) => {
  if (qs.indexOf("?") < 0) {
    return null;
  }
  qs = qs.split("?")[1];
  if (qs && name) {
    const x = qs.split("&");
    if (x && x.length > 0) {
      for (let i = 0; i < x.length; i++) {
        if (x[i].indexOf("=") > -1) {
          const keyValuePair = x[i].split("=");
          if (keyValuePair[0] == name) {
            return keyValuePair[1];
          }
        }
      }
    }
  }
  return null;
};

export const updateQueryString = (key, value, url) => {
  // if (!url) url = window.location.href;
  var re = new RegExp("([?&])" + key + "=.*?(&|#|$)(.*)", "gi"),
    hash;

  if (re.test(url)) {
    if (typeof value !== "undefined" && value !== null) {
      return url.replace(re, "$1" + key + "=" + value + "$2$3");
    } else {
      hash = url.split("#");
      url = hash[0].replace(re, "$1$3").replace(/(&|\?)$/, "");
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
      return url;
    }
  } else {
    if (typeof value !== "undefined" && value !== null) {
      var separator = url.indexOf("?") !== -1 ? "&" : "?";
      hash = url.split("#");
      url += separator + key + "=" + value;
      if (typeof hash[1] !== "undefined" && hash[1] !== null) {
        url += "#" + hash[1];
      }
      return url;
    } else {
      return url;
    }
  }
};

export const insertParam = (key, value) => {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);

  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location.search.substr(1).split("&");
  let i = 0;

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + "=")) {
      let pair = kvp[i].split("=");
      pair[1] = value;
      kvp[i] = pair.join("=");
      break;
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join("=");
  }

  // can return this or...
  let params = kvp.join("&");

  // reload page with new params
  document.location.search = params;
};
