import React, { Component } from "react";
import { observer } from "mobx-react";
import { useMst } from "../models/store";
import {
  Card,
  CardContent,
  Grid,
  Paper,
  makeStyles,
  Typography,
  Button,
  Container,
  LinearProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Modal,
  Backdrop,
  TextField,
  Checkbox,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Rating from "@material-ui/lab/Rating";
import { formatDateInTimeZone } from "../common/formatDate";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import { useState } from "react";
import { Location } from "../models/location";
import { SaveLocation, AssignLocationToPages } from "../services/appService";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CloseIcon from "@material-ui/icons/Close";
import { getIcon } from "../common/getIcon";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { StyledHeader } from "../common/getHeader";

const useStyles = makeStyles((theme) => ({
  page: {
    margin: "15px 0",
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: 0,
    },
  },
  grid: {
    wordWrap: "break-word",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  tableCell: {
    fontWeight: "bold",
  },
}));

export const Presence = observer(() => {
  const store = useMst();
  const [openModal, setOpenModal] = React.useState(false);
  const [openAssignModal, setOpenAssignModal] = React.useState(false);
  const [newLocationInfo, setNewLocationInfo] = useState(Location.create());
  const [selected, setSelected] = React.useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [tableActionButtons, setTableActionButtons] = React.useState(true);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCreate = () => {
    setOpenModal(true);
  };

  const handleAssignment = () => {
    setOpenAssignModal(true);
  };

  const saveAssignment = async (id) => {
    const response = await AssignLocationToPages(id, selected);
    var pageList = [];
    if (response && response.data) {
      store.pages.map((t) => {
        if (selected.indexOf(t.id) > -1) {
          t.set("locationId", id);
        }
      });

      const targetLocation = store.locations.find((obj) => obj.id == id);
      if (!targetLocation) {
        return;
      }
      targetLocation.assignPages(pageList);
    }
  };

  const handleNewLocationChanges = (key, value) => {
    var locationInfo = newLocationInfo;
    locationInfo.set(key, value);
    setNewLocationInfo(locationInfo);
  };

  const closeAssignModal = () => {
    setOpenAssignModal(false);
  };

  const handleCheck = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    //show table action buttons
    if (event.target.checked) {
      setTableActionButtons(false);
    } else if (newSelected.length == 0) {
      setTableActionButtons(true);
    }
    setSelected(newSelected);
  };

  const handleNewLocation = async () => {
    const response = await SaveLocation(newLocationInfo);
    if (response && response.data) {
      store.addItem("locations", response.data);
      handleModalClose();
    }
  };
  const classes = useStyles();
  if (store.pages.length == 0 && store.loadingPages) {
    store.loadPages();
    return <p>Loading...</p>;
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Box pb={4}>
            <TableContainer component={Paper}>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Location</TableCell>
                    <TableCell align="center">Avg Rating</TableCell>
                    <TableCell align="center">Total Reviews</TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="right">
                      <Button variant="outlined" onClick={handleCreate}>
                        <AddCircleOutlineIcon fontSize="small" />
                        &nbsp; Add Location
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {store.locations &&
                    store.locations.map((t) => (
                      <React.Fragment>
                        <TableRow
                          key={t.id}
                          style={{ backgroundColor: "#f5f5f5" }}
                        >
                          <TableCell align="left">
                            <Typography variant="body1" component="p">
                              <Link
                                to={{
                                  pathname: `/location/`,
                                  search: `?locationId=${t.id}`,
                                }}
                                style={{
                                  color: "#097ae6",
                                  fontWeight: "bold",
                                  textDecoration: "none",
                                }}
                              >
                                {t.locationName}
                              </Link>
                            </Typography>
                            <Typography variant="body2">
                              {t.address}, {t.city}, {t.state} {t.zip},{" "}
                              {t.country}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {t.calculateAvgReviews()}{" "}
                            <Rating
                              className={classes.rating}
                              value={t.calculateAvgReviews()}
                              precision={0.5}
                              readOnly
                              size="small"
                              style={{ verticalAlign: "text-bottom" }}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {t.calculateTotalReviews()}
                          </TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                        {store.pages.map((u) => {
                          console.log(u);
                          if (u.locationId == t.id) {
                            return (
                              <TableRow key={u.id}>
                                <TableCell
                                  component="th"
                                  scope="row"
                                  style={{
                                    paddingLeft: "50px",
                                  }}
                                >
                                  <Box
                                    component="div"
                                    style={{
                                      display: "inline-block",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    {getIcon(u.integrationType, null, 35, 35)}
                                  </Box>
                                  <Box
                                    component="div"
                                    style={{ display: "inline-block" }}
                                  >
                                    <Box>
                                      <strong>{u.name}</strong>
                                    </Box>
                                    <Box>
                                      {u.address ? u.address : "No address"}
                                    </Box>
                                  </Box>
                                </TableCell>
                                <TableCell align="center">
                                  {u.getRating()}{" "}
                                  <Rating
                                    className={classes.rating}
                                    value={u.getRating()}
                                    precision={0.5}
                                    readOnly
                                    size="small"
                                    style={{ verticalAlign: "text-bottom" }}
                                  />
                                </TableCell>
                                <TableCell align="center">
                                  {u.reviewsCount}
                                </TableCell>
                                {/* <TableCell align="right">
                      {formatDateInTimeZone(t.createTime)}
                    </TableCell> */}
                                <TableCell></TableCell>
                                <TableCell align="right">
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    component={Link}
                                    to={{
                                      pathname: `/location/`,
                                      search: `?locationId=${t.id}&sourceId=${u.id}`,
                                    }}
                                  >
                                    View
                                  </Button>
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </React.Fragment>
                    ))}
                  {(!store.locations || store.locations.length == 0) && (
                    <Box p={2}>
                      <Typography variant="p" align="center">
                        There is no location added
                      </Typography>
                    </Box>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Unassigned Accounts</TableCell>
                  <TableCell align="center">Rating</TableCell>
                  <TableCell align="center">Total Reviews</TableCell>
                  {/* <TableCell align="center">Last Review Date</TableCell> */}
                  <TableCell align="right">
                    <Box hidden={tableActionButtons}>
                      <Button variant="outlined" onClick={handleAssignment}>
                        <PlaylistAddIcon fontSize="small" />
                        &nbsp; Assign
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {store.pages.map((t) => {
                  if (t.locationId == 0) {
                    return (
                      <TableRow key={t.id} selected={isSelected(t.id)}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            onChange={(e) => handleCheck(e, t.id)}
                            checked={isSelected(t.id)}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Box
                            component="div"
                            style={{
                              display: "inline-block",
                              paddingRight: "10px",
                            }}
                          >
                            {getIcon(t.integrationType, null, 35, 35, false)}
                          </Box>
                          <Box
                            component="div"
                            style={{ display: "inline-block" }}
                          >
                            <Box>
                              <strong>{t.name}</strong>
                            </Box>
                            <Box>{t.address ? t.address : "No address"}</Box>
                          </Box>
                        </TableCell>
                        <TableCell align="center">
                          {t.getRating()}{" "}
                          <Rating
                            className={classes.rating}
                            value={t.getRating()}
                            precision={0.5}
                            readOnly
                            size="small"
                            style={{ verticalAlign: "text-bottom" }}
                          />
                        </TableCell>
                        <TableCell align="center">{t.reviewsCount}</TableCell>
                        {/* <TableCell align="right">
        {formatDateInTimeZone(t.createTime)}
      </TableCell> */}
                        <TableCell align="right">
                          <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/?sourceId=${t.id}`}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
            {(!store.pages || store.pages.length == 0) && (
              <Box p={2}>
                <Typography variant="p" align="center">
                  You haven't connected any social accounts with Squidily.{" "}
                  <Box component={Link} to="/integrations/thirdparty">
                    Go to Integrations
                  </Box>
                </Typography>
              </Box>
            )}
          </TableContainer>
        </Container>
        <Modal
          disableEnforceFocus={true}
          open={openAssignModal}
          closeAfterTransition
          onClose={closeAssignModal}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "400px", outline: 0 }}>
            <Box>
              <Box style={{ float: "right" }} p={1}>
                <Link onClick={closeAssignModal}>
                  <CloseIcon />
                </Link>
              </Box>
              <Box pt={2} pb={1}>
                <Typography variant="body1" component="p" align="center">
                  <strong style={{ color: "#097ae6" }}>
                    Select the location for assignment
                  </strong>
                </Typography>
              </Box>

              <Box
                style={{ height: "350px", overflow: "auto" }}
                pt={2}
                pb={2}
                pl={1}
                pr={1}
              >
                <TableContainer component={Paper} elevation={0}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      {store.locations &&
                        store.locations.map((t) => (
                          <Link
                            onClick={(e) => saveAssignment(t.id)}
                            style={{ textDecoration: "none" }}
                          >
                            <TableRow
                              hover
                              key={t.id}
                              style={{ display: "table", width: "100%" }}
                            >
                              <TableCell>
                                <Typography component="p">
                                  {t.locationName}
                                </Typography>
                                <Typography variant="caption">
                                  {t.address}, {t.city}, {t.state} {t.zip},{" "}
                                  {t.country}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </Link>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Paper>
        </Modal>
        <Modal
          disableEnforceFocus
          open={openModal}
          className={classes.modal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "480px" }}>
            <Box pt={4} pb={4} style={{ background: "#fafafa" }}>
              <Box p={2}>
                <Typography align="center"></Typography>
                <StyledHeader heading="Add a location" alignText="center" />
                <Typography variant="subtitle1">
                  Location Information
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Location name"
                  margin="dense"
                  name="locationName"
                  onChange={(e) =>
                    handleNewLocationChanges("locationName", e.target.value)
                  }
                />
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Location identifier"
                  margin="dense"
                  name="locationIdentifier"
                  onChange={(e) =>
                    handleNewLocationChanges(
                      "locationIdentifier",
                      e.target.value
                    )
                  }
                />
              </Box>
              <Box p={2}>
                <Typography variant="subtitle1">Contact Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Address"
                      margin="dense"
                      name="address"
                      onChange={(e) =>
                        handleNewLocationChanges("address", e.target.value)
                      }
                    />

                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="City"
                      margin="dense"
                      name="city"
                      onChange={(e) =>
                        handleNewLocationChanges("city", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="State"
                      margin="dense"
                      name="state"
                      onChange={(e) =>
                        handleNewLocationChanges("state", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Zip/Postal"
                      margin="dense"
                      name="zip"
                      onChange={(e) =>
                        handleNewLocationChanges("zip", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Country"
                      margin="dense"
                      name="country"
                      onChange={(e) =>
                        handleNewLocationChanges("country", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Email"
                      margin="dense"
                      name="email"
                      onChange={(e) =>
                        handleNewLocationChanges("email", e.target.value)
                      }
                    />
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Phone"
                      margin="dense"
                      name="phone"
                      onChange={(e) =>
                        handleNewLocationChanges("phone", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box pr={2}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNewLocation}
                    >
                      <AddIcon /> Create
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  }
});
