import { emphasize, withStyles, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Avatar, Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";
import React from "react";

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: "#e9e9e9",
  },
  redChip: {
    border: "2px solid #a3d8a3",
  },
  greenChip: {
    border: "2px solid red",
  },
}));

export const StyledChipBody = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(4),
    color: theme.palette.grey[800],
    marginBottom: "5px",
    marginRight: "5px",
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

export const StyledChip = ({
  label,
  link,
  avatar,
  style,
  onClick,
  classList,
  icon,
  deleteEvt,
  deleteIcon,
}) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <StyledChipBody
        icon={icon ?? null}
        label={`${label} ${avatar != null ? "(" + avatar + ")" : ""}`}
        component={Link}
        to={link}
        // avatar={<Avatar className={classes.avatar}>{avatar}</Avatar>}
        style={style ?? null}
        onClick={onClick ?? null}
        clickable={onClick != null ? true : false}
        className={classList ?? null}
      />
    </React.Fragment>
  );
};
