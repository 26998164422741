import Axios from "axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export const GetRequest = async (identifier, rating) => {
  if (!rating) {
    rating = 0;
  }
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/request/`,
    params: {
      identifier: identifier,
      rating: rating,
    },
  });
};

export const PostCampaignRating = async (identifier, rating) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/request/`,
    params: {
      identifier: identifier,
      rating: rating,
    },
  });
};

export const PostCampaignComment = async (identifier, comment) => {
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/campaign/request/`,
    params: {
      identifier: identifier,
      comment: comment,
    },
  });
};
