import {
  Box,
  FormControl,
  makeStyles,
  Select,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { observer } from "mobx-react";
import React, { Component, useState } from "react";
import { useMst } from "../models/store";
import { getQueryString } from "./getQueryString";
import { SmallSelectMenu } from "./select";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: "200px",
    paddingRight: "10px",
  },
  positiveKeywords: {
    borderLeft: "4px solid #00e396",
  },
  negativeKeywords: {
    borderLeft: "4px solid red",
  },
  rating: {
    fontSize: "48px",
    fontWeight: "100",
  },
  ratingCount: {
    fontSize: "14px",
  },
}));
export const SquidilyFilters = observer(
  ({
    locationChange,
    //   locationId,
    //   sourceId,
    sourceChange,
    //   periodId,
    periodChange,
    defaultPeriod,
    props,
    extraFilter,
    hideHeading,
  }) => {
    var sources = [];
    const store = useMst();
    const classes = useStyles();
    const locationId = getQueryString(props.location.search, "locationId");
    if (store.locations == null) {
      store.loadPages();
    } else {
      debugger;
      if (locationId > 0) {
        const locationObj = store.locations.find((t) => t.id == locationId);
        if (locationObj == null) {
          store.toggleAlert(true, "Unable to find your selected location");
        }
        if (locationObj.linkedPages && locationObj.linkedPages.length > 0) {
          sources = locationObj.linkedPages;
        }
      }
      if (locationId == "all" || locationId == null || locationId == 0) {
        //means we need to show data for all locations.
        //show all sources in source combo
        var arrayList = [];
        store.locations.map((t) => {
          if (t.linkedPages != null && t.linkedPages.length > 0) {
            t.linkedPages.map((x) => {
              arrayList.push(x);
            });
          }
        });
        var mergedArrayList = [];
        store.pages.map((t) => {
          var found = false;
          arrayList.map((u) => {
            if (u.id == t.id) {
              found = true;
              mergedArrayList.push(u);
            }
          });
          if (!found) {
            mergedArrayList.push(t);
          }
        });

        sources = mergedArrayList;
      }
    }

    let sourceId = getQueryString(props.location.search, "sourceId");
    if (sourceId == null || sourceId == 0) {
      sourceId = 0;
    }

    let periodId = getQueryString(props.location.search, "months");
    if (periodId == null) {
      periodId = defaultPeriod ?? 1;
    }
    const loading = store.locations != null && sources != null;
    return (
      <Box display="inline">
        {!hideHeading && (
          <Typography variant="overline" component="p" color="textSecondary">
            FILTERS
          </Typography>
        )}

        {locationChange && (
          <FormControl variant="outlined" className={classes.formControl}>
            {!loading ? (
              <Skeleton style={{ paddingBottom: "30px" }} />
            ) : (
              <Select
                native
                onChange={(e) => locationChange(e.target.value)}
                value={locationId}
                defaultValue={0}
                input={<SmallSelectMenu />}
              >
                <option aria-label="All Locations" value={0}>
                  All Locations
                </option>
                {store.locations.length > 0 &&
                  store.locations.map((t) => {
                    return <option value={t.id}>{t.locationName}</option>;
                  })}
              </Select>
            )}
          </FormControl>
        )}
        {sourceChange && (
          <FormControl variant="outlined" className={classes.formControl}>
            {!loading ? (
              <Skeleton style={{ paddingBottom: "30px" }} />
            ) : (
              <Select
                native
                onChange={(e) => sourceChange(e.target.value)}
                value={sourceId}
                defaultValue={0}
                input={<SmallSelectMenu />}
              >
                <option aria-label="All sources" value={0}>
                  All sources
                </option>
                {sources.length > 0 &&
                  sources.map((t) => {
                    return <option value={t.id}>{t.name}</option>;
                  })}
              </Select>
            )}
          </FormControl>
        )}
        {periodChange && (
          <FormControl variant="outlined" className={classes.formControl}>
            {!loading ? (
              <Skeleton style={{ paddingBottom: "30px" }} />
            ) : (
              <Select
                native
                onChange={(e) => periodChange(e.target.value)}
                inputProps={{
                  name: "period",
                }}
                value={periodId}
                defaultValue={defaultPeriod ?? 1}
                input={<SmallSelectMenu />}
              >
                <option aria-label="All time" value={0}>
                  All time
                </option>
                <option value={2}>Last 30 days</option>
                <option value={3}>Last 6 months</option>
                <option value={4}>Last 1 year</option>
              </Select>
            )}
          </FormControl>
        )}

        {loading && extraFilter != null ? (
          extraFilter
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Box>
    );
  }
);
