import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  InputBase,
  makeStyles,
  TextareaAutosize,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { StyledHeader } from "../../common/getHeader";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { Editor } from "@tinymce/tinymce-react";
import {
  GetTemplate,
  SaveTemplate,
  LoadTemplate,
  PublishTemplate,
} from "../../services/appService";
import { getQueryString } from "../../common/getQueryString";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { Template } from "../../models/template";
import { applySnapshot } from "mobx-state-tree";
import { observer } from "mobx-react";
import { SendType } from "../../enums/sendType";
import { useMst } from "../../models/store";
import { GetMessageBubble } from "../../common/getMessageBubble";
import { CampaignType } from "../../enums/campaignType";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  leftPanel: {
    height: "calc(100vh - 64px)",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  inputFont: {
    fontSize: "20px",
    width: "300px",
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    border: "none",
    borderRadius: "6px",
    marginRight: "5px",
  },
}));
export const CreateTemplate = observer((props) => {
  const typeId = getQueryString(props.location.search, "type");
  const sendType = getQueryString(props.location.search, "sendType");
  const templateId = getQueryString(props.location.search, "id");
  const classes = useStyles();
  const store = useMst();
  const [data, setData] = useState(null);
  const [name, setName] = useState("New Template Name");
  useEffect(() => {
    if (!data && templateId && typeId == "edit") {
      LoadTemplate(parseInt(templateId)).then((t) => {
        var _template = Template.create();
        applySnapshot(_template, t.data);
        setData(_template);
      });
    } else if (!data && typeId > 0 && sendType > 0) {
      GetTemplate(parseInt(typeId), parseInt(sendType)).then((t) => {
        var _template = Template.create();
        _template.set("name", "New Template Name");
        _template.set("html", t.data);
        _template.set("sendType", parseInt(sendType));
        _template.set("templateType", parseInt(typeId));
        setData(_template);
      });
    }
  });

  const handleEditorChange = (content, editor) => {
    data.set("html", content);
  };

  const handleHeadingChange = (e) => {
    var getData = { ...data };
    data.set("name", e.currentTarget.value);
  };

  const handleSave = async () => {
    SaveTemplate(data).then((t) => {
      var _template = Template.create();
      applySnapshot(_template, t.data);
      setData(_template);
    });
  };

  const publish = async () => {
    PublishTemplate(data.id).then((t) => {
      if (t) {
        data.set("isPublished", true);
        setData(data);
      }
    });
  };

  const renderSmsTemplate = (requestType, value) => {
    var renderedText = value;
    if (value) {
      if (value.indexOf("[ShortLink]") > -1) {
        renderedText = renderedText.replace(
          "[ShortLink]",
          "rebrand.ly/ni878sklj"
        );
      }

      if (value.indexOf("[QuickReply]") > -1) {
        if (requestType == CampaignType.review) {
          renderedText = renderedText.replace(
            "[QuickReply]",
            "<br><br>Please reply with number <br> 1 - One star <br> 2 - Two stars <br> 3 - Three stars <br> 4 - Four stars <br> 5 - Five stars"
          );
        } else {
          renderedText = renderedText.replace(
            "[QuickReply]",
            "<br><br>Please reply with a score between 1 (very unsatisfied) and 10 (very satisfied) "
          );
        }
      }
      if (
        value.indexOf("[ShortLink]") == -1 &&
        value.indexOf("[QuickReply]") == -1
      ) {
        store.toggleAlert(
          true,
          "Sms Template requires atleast one of these shortcodes [ShortLink] [QuickReply]"
        );
      }
      renderedText = renderedText.replace(
        "[BusinessName]",
        store.accountInfo.account.businessName
      );
    }

    return renderedText ?? "";
  };

  return (
    <React.Fragment>
      <Paper>
        <Container className={classes.container}>
          <Box pt={2}>
            <Grid container>
              <Grid item xs={3} className={classes.leftPanel}>
                <Typography variant="h2" gutterBottom>
                  {typeId == "edit" ? "Edit template" : "Create Template"}
                </Typography>
                <Box pt={1}>
                  <TextField
                    value={data ? data.name : "New Template Name"}
                    size="medium"
                    label="Template name"
                    className={classes.inputFont}
                    onChange={handleHeadingChange}
                    fullWidth
                  />
                </Box>
                <Box pt={4}>
                  <Box pb={2}>
                    <Typography variant="body" gutterBottom>
                      Please use the following short codes to insert information
                      dynamically.
                    </Typography>
                  </Box>
                  <Chip
                    variant="outlined"
                    label="[CustomerName]"
                    size="small"
                    className={classes.chip}
                  />
                  <Chip
                    variant="outlined"
                    label="[BusinessName]"
                    size="small"
                    className={classes.chip}
                  />
                  <Chip
                    variant="outlined"
                    label="[ContactEmail]"
                    size="small"
                    className={classes.chip}
                  />
                  <Chip
                    variant="outlined"
                    label="[RatingWidget]"
                    size="small"
                    className={classes.chip}
                  />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box textAlign="right">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ fontWeight: 500 }}
                    onClick={handleSave}
                  >
                    <SaveAltIcon /> Save
                  </Button>{" "}
                  {data && data.id > 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontWeight: 500 }}
                      onClick={() => publish(!data.isPublished)}
                    >
                      <SaveAltIcon />{" "}
                      {data.isPublished ? "Un-Publish" : "Publish"}
                    </Button>
                  )}
                </Box>
                <Box p={2}>
                  {!data && typeId > 0 && <LoadingSpinner />}
                  {((data && data.sendType == SendType.email) ||
                    (!typeId && sendType == SendType.email)) && (
                    <Editor
                      apiKey="eq6m05jebhzzr8fq6xyy0box83j41rk6mow3woyeufloa84p"
                      initialValue={data && data.html ? data.html : ""}
                      init={{
                        height: 400,
                        width: "100%",
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | fontsizeselect formatselect forecolor | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat image | help",
                        branding: false,
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  )}
                  {((data && data.sendType == SendType.sms) ||
                    (!typeId && sendType == SendType.sms)) && (
                    <Box pt={8}>
                      <Box pt={2}>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box
                              style={{
                                width: "400px",
                                height: "150px",
                                margin: "0 auto",
                              }}
                            >
                              <Box
                                style={{ float: "right", display: "block" }}
                                pb={3}
                              >
                                <GetMessageBubble
                                  text={
                                    <TextareaAutosize
                                      rowsMin={4}
                                      style={{
                                        width: "100%",
                                        overflowY: "auto",
                                        background: "transparent",
                                        border: "none",
                                        color: "#fff",
                                      }}
                                      onChange={(t) =>
                                        handleEditorChange(t.target.value)
                                      }
                                      value={data ? data.html : ""}
                                    />
                                  }
                                  position="right"
                                  isTextArea={true}
                                />
                              </Box>
                              <Box style={{ float: "left", display: "block" }}>
                                <GetMessageBubble
                                  text="....."
                                  position="left"
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Paper>
    </React.Fragment>
  );
});
