import { emphasize, withStyles, makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Breadcrumbs } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import React from "react";

export const StyledBreadcrumbBody = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip);

export const StyledBreadcrumb = ({ label, link }) => {
  return (
    <React.Fragment>
      <Breadcrumbs>
        <StyledBreadcrumbBody
          label={label}
          icon={
            <ArrowBackIosIcon
              style={{ fontSize: "12px", fontWeight: "bold" }}
            />
          }
          component={Link}
          to={link}
        />
      </Breadcrumbs>
    </React.Fragment>
  );
};
