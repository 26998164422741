import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  InputBase,
  InputLabel,
  makeStyles,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { GetCustomers } from "../services/appService";
import { formatDateInTimeZone } from "./formatDate";
import { LoadingSpinner } from "./loadingSpinner";
import SearchIcon from "@material-ui/icons/Search";
import { StyledTableHead } from "./table";
import { Link } from "react-router-dom";
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
export const GetCustomersTable = ({ action, actionText, disableHrefs }) => {
  const [loadedData, setLoadedData] = useState(null);
  const [selected, setSelected] = React.useState([]);
  const [tableActionButtons, setTableActionButtons] = React.useState(true);
  const loadData = async (pageIndex, pageSize) => {
    GetCustomers(pageIndex, pageSize).then((t) => {
      setLoadedData(t.data);
    });
  };
  const isSelected = (id) => {
    var isSelected = false;
    for (let i = 0; i < selected.length; i++) {
      if (selected[i].id === id) {
        isSelected = true;
      }
    }

    return isSelected;
  };
  const handleCheck = (customer) => {
    //show table action buttons
    // if (event.target.checked) {
    //   setTableActionButtons(false);
    // } else {
    //   setTableActionButtons(true);
    // }
    //check if its present within selected customers
    var newSelected = [...selected];
    var isAlreadySelected = false;
    var selectedIndex = 0;
    for (let i = 0; i < newSelected.length; i++) {
      if (newSelected[i].id == customer.id) {
        isAlreadySelected = true;
        selectedIndex = i;
      }
    }

    if (isAlreadySelected) {
      //means its an uncheck request
      newSelected.splice(selectedIndex, 1);
    } else {
      newSelected.push(customer);
    }

    setSelected(newSelected);
  };

  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setSelected(loadedData.data.map((t) => t));
    } else {
      setSelected([]);
    }
  };
  const triggerAction = () => {
    action(selected);
  };
  useEffect(() => {
    if (!loadedData) {
      loadData(1, 50);
    }
  });
  return (
    <React.Fragment>
      <Box p={2}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item hidden={tableActionButtons}>
            <Button variant="outlined">Delete</Button>
          </Grid>
          <Grid item hidden={!tableActionButtons}>
            <TextField
              size="small"
              placeholder="Search"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            {actionText && !selected.length && (
              <Typography>Select customer(s)</Typography>
            )}
            {actionText && selected.length > 0 && (
              <Button
                variant="contained"
                color="primary"
                onClick={triggerAction}
              >
                {actionText}
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControl>
              <NativeSelect input={<BootstrapInput />}>
                <option>Sort By</option>
                <option value={1}>Last Updated</option>
                <option value={2}>Creation Date</option>
              </NativeSelect>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="Table">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox onChange={handleAllCheck} />
              </TableCell>
              <StyledTableHead>Name</StyledTableHead>
              <StyledTableHead>Business</StyledTableHead>
              <StyledTableHead>Email</StyledTableHead>
              <StyledTableHead>Phone</StyledTableHead>
              <StyledTableHead>Created On</StyledTableHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loadedData && (
              <Typography align="center">
                <LoadingSpinner />
              </Typography>
            )}
            {loadedData &&
              loadedData.data.map((t) => (
                <TableRow
                  hover
                  selected={isSelected(t.id)}
                  to={disableHrefs ? null : `/customers/view?id=${t.id}`}
                  component={disableHrefs ? "tr" : Link}
                  style={{ textDecoration: "none" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      onChange={(e) => handleCheck(t)}
                      checked={isSelected(t.id)}
                    />
                  </TableCell>
                  <TableCell>{t.name}</TableCell>
                  <TableCell>{t.businessName}</TableCell>
                  <TableCell>{t.email}</TableCell>
                  <TableCell>{t.phone}</TableCell>
                  <TableCell>{formatDateInTimeZone(t.createdOn)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        {/* <TablePagination
rowsPerPageOptions={[5, 10, 25]}
component="div"
count={100}
/> */}
      </TableContainer>
    </React.Fragment>
  );
};
