import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { PageLink } from "./pageLink";
export const AccountSetup = t
  .model("accountSetup", {
    moderateBelow: t.optional(t.integer, 0),
    moderateCampaignBelow: t.optional(t.integer, 0),
    autoCreateReviewTicket: t.optional(t.boolean, false),
    autoCreateCampaignTicket: t.optional(t.boolean, false),
    pageLinks: t.optional(t.array(PageLink), []),
    emailTemplate: t.maybeNull(t.string, ""),
    smsTemplate: t.maybeNull(t.string, ""),
    longLivedToken: t.maybeNull(t.string, ""),
    defaultFeedbackEmailTemplateId: t.optional(t.integer, 0),
    defaultFeedbackSmsTemplateId: t.optional(t.integer, 0),
    defaultReviewEmailTemplateId: t.optional(t.integer, 0),
    defaultReviewSmsTemplateId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    addNew: function () {
      const _mdl = PageLink.create();
      self.pageLinks.push(_mdl);
    },
    set(key, value) {
      self[key] = value;
    },
  }));
