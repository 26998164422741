import Axios from "axios";
import Cookies from "universal-cookie";

export const pushIntegration = async (locationId, code, type) => {
  debugger;
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/integration/`,
    data: {
      code,
      type,
      locationId,
    },
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const initApp = async (type, locationId) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/integration/init/`,
    params: {
      type,
      locationId,
    },
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const saveSelectedPages = async (locationId, type, pages) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/integration/savepages/`,
    params: {
      locationId,
      type,
    },
    data: pages,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};
