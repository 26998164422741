import {
  Box,
  Button,
  Chip,
  Container,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { StyledChip } from "../../common/chip";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import {
  GetNPSStatsByMonth,
  GetNPSTags,
  GetNPSTagsStats,
} from "../../services/appService";
import { useMst } from "../../models/store";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));
export const NPSReport = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const store = useMst();
  const [tags, setTags] = useState({
    selectedTags: null,
    availableTags: null,
    set: function (key, value) {
      this[key] = value;
    },
    add: function (i) {
      this.selectedTags.push(i);
      var idx = this.availableTags.indexOf(i);
      this.availableTags.splice(idx, 1);
    },
    remove: function (i) {
      this.availableTags.push(i);
      var idx = this.selectedTags.indexOf(i);
      this.selectedTags.splice(idx, 1);
    },
  });

  const [dataByTags, setDataByTags] = useState(null);
  const [dataByMonth, setDataByMonth] = useState(null);
  useEffect(() => {
    const loadDataByTag = async () => {
      return await GetNPSTags();
    };
    const loadDataByMonth = async () => {
      return await GetNPSStatsByMonth();
    };
    if (tabIndex == 0 && tags.selectedTags == null) {
      loadDataByTag().then((response) => {
        var selectedTags = [];
        var availableTags = [];
        if (response.data) {
          for (let i = 0; i < response.data.length; i++) {
            if (i >= 9) {
              availableTags.push(response.data[i]);
            } else {
              selectedTags.push(response.data[i]);
            }
          }
        }
        var localState = { ...tags };
        localState.selectedTags = selectedTags;
        localState.availableTags = availableTags;
        setTags(localState);
        loadNPSScoreByTags(localState);
      });
    } else if (tabIndex == 1 && dataByMonth == null) {
      loadDataByMonth().then((t) => {
        setDataByMonth(t.data);
        getNPSGraph(t.data);
      });
    }
    getNPSGraph();
  });

  const loadNPSScoreByTags = async (localState) => {
    const ids = localState.selectedTags.map((t) => t.id);
    const response = await GetNPSTagsStats(ids);
    setDataByTags(response.data);
  };

  const getNPSGraph = (data) => {
    var dataArray = null;
    if (data) {
      dataArray = data;
    } else if (tabIndex == 0) {
      dataArray = dataByTags;
    } else if (tabIndex == 1) {
      dataArray = dataByMonth;
    }
    if (dataArray && dataArray.length > 0) {
      //get labels
      var labels =
        tabIndex == 0
          ? dataArray.map((t) => t.tagName)
          : dataArray.map((t) => t.period);
      if (labels.length < 10) {
        for (let i = labels.length; i < 10; i++) {
          labels.push("");
        }
      }
      var promoters = [];
      var passives = [];
      var detractors = [];
      var npsScore = [];
      dataArray.map((t) => {
        var pr = parseFloat(
          (
            (t.promoterScore /
              (t.promoterScore + t.passiveScore + t.detractorScore)) *
            100
          ).toFixed(5)
        );
        var d = parseFloat(
          (
            (t.detractorScore /
              (t.promoterScore + t.passiveScore + t.detractorScore)) *
            100
          ).toFixed(5)
        );
        var p = parseFloat(
          (
            (t.passiveScore /
              (t.promoterScore + t.passiveScore + t.detractorScore)) *
            100
          ).toFixed(5)
        );
        promoters.push(pr);
        passives.push(p);
        detractors.push(d);
        npsScore.push(pr - d);
      });
      var barChartData = {
        labels: labels,
        datasets: [
          {
            data: npsScore,
            type: "line",
            label: "NPS Score",
            fill: false,
            backgroundColor: "#fff",
            borderColor: "#737373",
            borderCapStyle: "butt",
            borderDash: [10, 10],
            borderDashOffset: 0.0,
            borderJoinStyle: "miter",
            lineTension: 0.3,
            pointBackgroundColor: "#fff",
            pointBorderColor: "#737373",
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: "#737373",
            pointHoverBorderColor: "#737373",
            pointHoverBorderWidth: 2,
            pointRadius: 4,
            pointHitRadius: 10,
          },
          {
            label: "Promoters",
            backgroundColor: "#60b884",
            yAxisID: "bar-y-axis",
            data: promoters,
          },
          {
            label: "Passives",
            backgroundColor: "#ffcc00",
            yAxisID: "bar-y-axis",
            data: passives,
          },
          {
            label: "Detractors",
            backgroundColor: "#ff2600",
            yAxisID: "bar-y-axis",
            data: detractors,
          },
        ],
      };
      new window.Chart(
        document
          .getElementById(tabIndex == 0 ? "npsGraph" : "npsGraphByMonth")
          .getContext("2d"),
        {
          type: "bar",
          data: barChartData,
          options: {
            title: {
              display: true,
              text: "NPS Score",
            },
            tooltips: {
              mode: "label",
            },
            responsive: true,
            scales: {
              xAxes: [
                {
                  stacked: true,
                },
              ],
              yAxes: [
                {
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                    min: -100,
                    max: 100,
                  },
                },
                {
                  id: "bar-y-axis",
                  stacked: true,
                  display: false, //optional
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                  },
                  type: "linear",
                },
              ],
            },
          },
        }
      );
    } else {
      return;
    }
  };

  const handleTabChange = (index) => {
    setTabIndex(index);
  };
  const addTag = (t) => {
    tags.add(t);
    loadNPSScoreByTags(tags);
  };

  const removeTag = (t) => {
    if (tags.selectedTags.length == 1) {
      store.toggleAlert(true, "Atleast one tag should be added for stats");
      return;
    }
    tags.remove(t);
    removeTagData(t);
  };

  const removeTagData = (t) => {
    var tagsData = [...dataByTags];
    for (let i = 0; i < tagsData.length; i++) {
      if (tagsData[i].tagName == t.name) {
        tagsData.splice(i, 1);
      }
    }
    setDataByTags(tagsData);
  };

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Paper variant="outlined">
          <Box p={2}>
            <Box>
              <Button
                style={{ background: tabIndex == 0 ? "#e9e9e9" : "" }}
                onClick={() => handleTabChange(0)}
              >
                By Tags
              </Button>
              <Button
                style={{ background: tabIndex == 1 ? "#e9e9e9" : "" }}
                onClick={() => handleTabChange(1)}
              >
                By Month
              </Button>
            </Box>
            <Box>
              {tabIndex == 0 && (
                <Box>
                  <Box>
                    <Box pt={4} pb={4} width={800} style={{ margin: "0 auto" }}>
                      {(!dataByTags || dataByTags.length == 0) && (
                        <Box
                          style={{
                            height: "350px",
                            backgroundColor: "#f7f7f7",
                            display: "table",
                            width: "800px",
                            textAlign: "center",
                            margin: "0 auto",
                          }}
                        >
                          <Typography
                            style={{
                              verticalAlign: "middle",
                              display: "table-cell",
                            }}
                            variant="h3"
                            color="textSecondary"
                          >
                            Add tags to show the stats
                          </Typography>
                        </Box>
                      )}
                      <canvas id="npsGraph"></canvas>
                    </Box>
                    <Typography variant="h4">Included Tags:</Typography>
                    <Box pt={2}>
                      {tags.selectedTags &&
                        tags.selectedTags.map((t) => (
                          <StyledChip
                            label={t.name}
                            icon={<CloseIcon />}
                            onClick={() => removeTag(t)}
                          />
                        ))}

                      {/* {tags.selectedTags && loadGraphData() && (
                        <React.Fragment></React.Fragment>
                      )} */}
                      {!tags.selectedTags && (
                        <React.Fragment>Loading</React.Fragment>
                      )}
                    </Box>
                  </Box>
                  <Box pt={2}>
                    <Typography variant="h4">Available Tags:</Typography>
                    <Box pt={2}>
                      {tags.availableTags &&
                        tags.availableTags.map((t) => (
                          <StyledChip
                            label={t.name}
                            icon={<AddIcon />}
                            onClick={() => addTag(t)}
                          />
                        ))}

                      {!tags.availableTags && (
                        <React.Fragment>Loading</React.Fragment>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            {tabIndex == 1 && (
              <Box pt={4} pb={4} width={800} style={{ margin: "0 auto" }}>
                <canvas id="npsGraphByMonth"></canvas>
              </Box>
            )}
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};
