export const ColorGradient = (startColor, endColor, steps) => {
  var start = {
    Hex: startColor,
    R: parseInt(startColor.slice(1, 3), 16),
    G: parseInt(startColor.slice(3, 5), 16),
    B: parseInt(startColor.slice(5, 7), 16),
  };
  var end = {
    Hex: endColor,
    R: parseInt(endColor.slice(1, 3), 16),
    G: parseInt(endColor.slice(3, 5), 16),
    B: parseInt(endColor.slice(5, 7), 16),
  };
  var diffR = end["R"] - start["R"];
  var diffG = end["G"] - start["G"];
  var diffB = end["B"] - start["B"];

  var stepsHex = new Array();
  var stepsR = new Array();
  var stepsG = new Array();
  var stepsB = new Array();

  for (var i = 0; i <= steps; i++) {
    stepsR[i] = start["R"] + (diffR / steps) * i;
    stepsG[i] = start["G"] + (diffG / steps) * i;
    stepsB[i] = start["B"] + (diffB / steps) * i;
    stepsHex[i] =
      "#" +
      Math.round(stepsR[i]).toString(16) +
      "" +
      Math.round(stepsG[i]).toString(16) +
      "" +
      Math.round(stepsB[i]).toString(16);
  }
  return stepsHex;
};
