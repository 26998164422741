export const getIntegrationTypeById = (type) => {
  switch (type) {
    case 1:
      return "Google";
    case 2:
      return "Facebook";
    default:
      break;
  }
};
