import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { DataTrend } from "./dataTrend";
export const Dataset = t.model("dataset", {
  label: t.maybeNull(t.string),
  backgroundColor: t.maybeNull(t.string),
  borderColor: t.maybeNull(t.string),
  data: t.optional(t.array(t.integer), []),
});

export const Chartjs = t.model("chartjs", {
  labels: t.array(t.string, []),
  datasets: t.optional(t.array(Dataset), []),
});

export const PageOverview = t.model("pageOverview", {
  ratingsGraph: t.optional(Chartjs, {}),
  totalReviewsThisMonth: t.optional(DataTrend, {}),
  totalFiveStarReviewsThisMonth: t.optional(DataTrend, {}),
  trends: t.optional(t.array(DataTrend), []),
});
