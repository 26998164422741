import { observer } from "mobx-react";
import React, { Component } from "react";
import { Route, Router, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { Campaigns } from "../components/campaigns";
import { CreateCampaign } from "../components/campaigns/create";
import { EditCampaign } from "../components/campaigns/edit";
import { CampaignView } from "../components/campaigns/view";
import { Conversations } from "../components/conversations";
import { Customers } from "../components/customers";
import { CreateCustomer } from "../components/customers/create";
import { CustomerView } from "../components/customers/view";
import { Feedbacks } from "../components/feedbacks";
import { Insights } from "../components/insights";
import { LocationLayout } from "../components/layouts/locationLayout";
import { Navbar } from "../components/navbar";
import { Overview } from "../components/overview";
import { Reviews } from "../components/reviews";
import { Templates } from "../components/templates";
import { CreateTemplate } from "../components/templates/create";
import { useMst } from "../models/store";
export const Layout = observer(({ ...props }) => {
  const store = useMst();
  if (!store.isAuthenticated) {
    store.authenticate();
  } else {
    return (
      <React.Fragment>
        <Navbar {...props} />
        <Switch>
          <Route path="/reviews" component={Reviews} />
          <Route path="/feedbacks" component={Feedbacks} />
          <Route path="/conversations" component={Conversations} />
          <Route path="/locations" component={LocationLayout} />
          <Route eaxct path="/templates/create" component={CreateTemplate} />
          <Route path="/templates" component={Templates} />
          <Route path="/campaigns/create" component={CreateCampaign} />
          <Route exact path="/campaigns/edit/:id" component={EditCampaign} />
          <Route exact path="/campaigns/view/:id" component={CampaignView} />
          <Route path="/campaigns" component={Campaigns} />
          <Route path="/insights" component={Insights} />
          <Route exact path="/customers/view" component={CustomerView} />
          <Route exact path="/customers/create" component={CreateCustomer} />
          <Route exact path="/customers" component={Customers} />
          <Route path="/" component={Overview} />
        </Switch>
      </React.Fragment>
    );
  }
});
