import React, { Component } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
export const StyledCardHeader = ({ text, style, children }) => {
  return (
    <Box component="div">
      <Box component="div" p={2}>
        <Typography variant="subtitle1" style={style}>
          {text}
        </Typography>
        {children}
      </Box>
      <Divider />
    </Box>
  );
};
