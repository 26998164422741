import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const ReviewRequest = t.model("reviewRequest", {
  id: t.optional(t.integer, 0),
  name: t.maybeNull(t.string, ""),
  smsNumber: t.maybeNull(t.string, ""),
  emailAddress: t.maybeNull(t.string, ""),
  status: t.maybeNull(t.integer, 0),
  clicked: t.maybeNull(t.boolean, false),
  createdOn: t.maybeNull(t.string, ""),
  emailSentTimeStamp: t.maybeNull(t.string, ""),
});
