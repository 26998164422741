import React, { Component } from "react";
import { useMst } from "../../models/store";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  InputAdornment,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Container,
  withStyles,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Badge,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { observer } from "mobx-react";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import {
  GetReviewRequestLogs,
  CreateAudience,
} from "../../services/appService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { formatDateInTimeZone } from "../../common/formatDate";
import { StyledTableHead } from "../../common/table";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import { StyledHeader } from "../../common/getHeader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export const Audiences = observer(() => {
  const store = useMst();
  const classes = useStyles();
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [tableActionButtons, setTableActionButtons] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [newAudienceName, setNewAudienceName] = useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedRowLogs, setSelectedRowLogs] = useState(null);
  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setSelected(store.reviewRequests.data.map((t) => t.id));
      setTableActionButtons(false);
    } else {
      setSelected([]);
      setTableActionButtons(true);
    }
  };
  const [pageIndex, setPageIndex] = useState(
    store.reviewRequests.pageIndex != 0 ? store.reviewRequests.pageIndex : 1
  );
  const handlePagination = (event, value) => {
    setPageIndex(value);
    store.loadAllReviews(value, 50);
  };
  const handleCheck = (event, id) => {
    //show table action buttons
    if (event.target.checked) {
      setTableActionButtons(false);
    } else {
      setTableActionButtons(true);
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleCreate = () => {
    setOpenModal(true);
  };

  const createAudience = async () => {
    try {
      const response = await CreateAudience(newAudienceName);
      store.audiences.unshiftData(response.data);
      store.set("audienceListForCombo", null);
      setNewAudienceName("");
      setOpenModal(false);
    } catch (error) {}
  };

  if (store.audiences) {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <StyledHeader
            heading="Audiences"
            subHeading="100 Records found."
            actionButton={
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 500 }}
                onClick={handleCreate}
              >
                <AddIcon /> Create New
              </Button>
            }
          />
          <Paper variant="outlined">
            <Box p={2}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item hidden={tableActionButtons}>
                  <Button variant="outlined">Delete</Button>
                </Grid>
                <Grid item hidden={!tableActionButtons}>
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <Select
                      displayEmpty
                      className={classes.selectEmpty}
                      variant="outlined"
                    >
                      <MenuItem>Sort By</MenuItem>
                      <MenuItem value={1}>Last Updated</MenuItem>
                      <MenuItem value={2}>Creation Date</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="Table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={handleAllCheck} />
                    </TableCell>
                    <StyledTableHead>Name</StyledTableHead>
                    <StyledTableHead>Total Added Contacts</StyledTableHead>
                    <StyledTableHead>Last Updated</StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {store.audiences.data.map((t) => (
                    <TableRow
                      hover
                      selected={isSelected(t.id)}
                      to={`/audience/${t.id}`}
                      component={Link}
                      style={{ textDecoration: "none" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{t.name}</TableCell>
                      <TableCell>{t.totalContacts}</TableCell>
                      <TableCell>
                        {formatDateInTimeZone(t.modifiedOn)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={100}
          /> */}
            </TableContainer>
            {!store.audiences.totalSize && (
              <React.Fragment>
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <Box pt={5} pb={5}>
                      <Typography align="center" gutterBottom>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleCreate}
                        >
                          Create New
                        </Button>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="h6"
                        align="center"
                      >
                        There is no audience present.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
            {store.audiences.pageCount > 1 && (
              <Box p={2}>
                <Pagination
                  count={store.allReviews.pageCount}
                  page={pageIndex}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                />
              </Box>
            )}
          </Paper>
        </Container>
        <Modal
          disableEnforceFocus={true}
          open={openModal}
          className={classes.modal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "480px", outline: "none" }}>
            <Box p={2}>
              <Box pb={2}>
                <Typography variant="h2">Name your audience</Typography>
                <Typography variant="p" gutterBottom>
                  Enter your unique audience name. This will help you bundle all
                  similar contacts under one umbrella.
                </Typography>
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Audience Name"
                value={newAudienceName}
                onChange={(e) => setNewAudienceName(e.target.value)}
              />
              <Grid container justify="flex-end">
                <Grid item>
                  <Box pt={2}>
                    <Button variant="outlined" onClick={handleModalClose}>
                      Cancel
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={createAudience}
                    >
                      Create
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  } else {
    store.loadAudiences(pageIndex, 50);
    return <React.Fragment></React.Fragment>;
  }
});
