import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Grid,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { StyledHeader } from "../common/getHeader";
import AddIcon from "@material-ui/icons/Add";
import { Link } from "react-router-dom";
import { StyledTableHead } from "../common/table";
import ForumIcon from "@material-ui/icons/Forum";
import StarIcon from "@material-ui/icons/Star";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { updateQueryString } from "../common/getQueryString";
import CreateIcon from "@material-ui/icons/Create";
import { GetTemplates } from "../services/appService";
import { PagedResult } from "../models/pagedResult";
import { applySnapshot } from "mobx-state-tree";
import { formatDateInTimeZone } from "../common/formatDate";
import DoneIcon from "@material-ui/icons/Done";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import TextsmsOutlinedIcon from "@material-ui/icons/TextsmsOutlined";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  card: {
    paddingBottom: "16px",
  },
}));
export const Templates = (props) => {
  const classes = useStyles();
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [templateType, setTemplateType] = useState(null);
  const [data, setData] = useState({
    pageIndex: 1,
    templates: null,
  });
  const createNew = (type) => {
    setTemplateType(type);
    handleModalClose();
  };

  const createTemplate = (sendType) => {
    props.history.push({
      pathname: "/templates/create",
      search: `?type=${templateType}&sendType=${sendType}`,
    });
  };

  useEffect(() => {
    if (!data.templates) {
      loadTemplates(1, false);
    }
  });

  const handleModalClose = () => {
    setOpenSelectionModal(false);
  };

  const loadTemplates = (pageIndex, insertData) => {
    GetTemplates(pageIndex).then((t) => {
      var getData = { ...data };
      if (insertData) {
        t.data.map((x) => {
          getData.templates.pushData(x);
        });
      } else {
        var _pagedResult = PagedResult.create();
        applySnapshot(_pagedResult, t.data);
        getData.templates = _pagedResult;
      }

      setData(getData);
    });
  };

  const getTemplates = () => {
    if (data.templates && data.templates.data.length) {
      var content = [];
      const startIndex = data.templates.pageSize * (data.pageIndex - 1);
      const endIndex =
        data.templates.data.length < data.templates.pageSize
          ? data.templates.data.length
          : data.templates.pageSize * (data.pageIndex - 1) +
            data.templates.pageSize;

      data.templates.data.map((t) => {
        if (startIndex < endIndex) {
          content.push(
            <TableRow
              hover
              // selected={isSelected(t.id)}
              to={`/templates/create?id=${t.id}&type=edit`}
              component={Link}
              style={{ textDecoration: "none" }}
            >
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell>{t.name}</TableCell>
              <TableCell>{formatDateInTimeZone(t.createdOn)}</TableCell>
              <TableCell>
                {t.isPublished ? <DoneIcon /> : <CloseIcon />}
              </TableCell>
            </TableRow>
          );
        }
      });
      return content;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const openModal = () => {
    setOpenSelectionModal(true);
  };
  return (
    <React.Fragment>
      <Container className={classes.container}>
        <StyledHeader
          heading="Templates"
          actionButton={
            <Button
              variant="contained"
              color="primary"
              style={{ fontWeight: 500 }}
              onClick={openModal}
            >
              <AddIcon /> Create New
            </Button>
          }
        />
        <Paper variant="outlined">
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="Table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                    // onChange={handleAllCheck}
                    />
                  </TableCell>
                  <StyledTableHead>Name</StyledTableHead>
                  <StyledTableHead>Created On</StyledTableHead>
                  <StyledTableHead>Published?</StyledTableHead>
                </TableRow>
              </TableHead>
              <TableBody>{getTemplates()}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Modal
          disableEnforceFocus={true}
          open={openSelectionModal}
          closeAfterTransition
          onClose={handleModalClose}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "400px", outline: 0 }}>
            <Box p={2}>
              <Box pt={1}>
                <Card variant="outlined" onClick={() => createNew(3)}>
                  <CardContent className={classes.card}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <ForumIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="h3">End Chat Template</Typography>
                        <Typography variant="caption">
                          Template for sending email to customer on "End Chat"
                          event
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box pt={1}>
                <Card variant="outlined" onClick={() => createNew(1)}>
                  <CardContent className={classes.card}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <StarIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="h3">
                          Review Request Template
                        </Typography>
                        <Typography variant="caption">
                          Template for requesting a 5-star review from customer
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box pt={1}>
                <Card variant="outlined" onClick={() => createNew(2)}>
                  <CardContent className={classes.card}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <ListAltIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="h3">
                          Feedback Request Template
                        </Typography>
                        <Typography variant="caption">
                          Template for requesting a feedback from customer
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
              <Box pt={1}>
                <Card variant="outlined" onClick={() => createNew(0)}>
                  <CardContent className={classes.card}>
                    <Grid container spacing={2}>
                      <Grid item xs={2}>
                        <CreateIcon fontSize="large" />
                      </Grid>
                      <Grid item xs={10}>
                        <Typography variant="h3">
                          Create a new template
                        </Typography>
                        <Typography variant="caption">
                          Template for requesting a feedback from customer
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Box>
          </Paper>
        </Modal>
        <Modal
          disableEnforceFocus={true}
          open={templateType}
          closeAfterTransition
          onClose={() => setTemplateType(null)}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "480px" }}>
            <Box p={4} textAlign="center">
              <Typography
                style={{
                  fontSize: "18px",
                  display: "inline-block",
                  verticalAlign: "top",
                }}
              >
                How would you like to send it?
              </Typography>
              <Box pt={2}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      onClick={() => createTemplate(1)}
                      style={{ cursor: "pointer" }}
                    >
                      <EmailOutlinedIcon style={{ fontSize: "64px" }} />
                      <Box>Email</Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      onClick={() => createTemplate(2)}
                      style={{ cursor: "pointer" }}
                    >
                      <TextsmsOutlinedIcon style={{ fontSize: "64px" }} />
                      <Box>Sms</Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Modal>
      </Container>
    </React.Fragment>
  );
};
