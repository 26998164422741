import {
  Box,
  Button,
  Grid,
  makeStyles,
  Typography,
  ListItemText,
  Divider,
  Paper,
  FormControl,
  Select,
  MenuItem,
  withStyles,
  CircularProgress,
  InputBase,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { Component, useState } from "react";
import { StyledMenu, StyledMenuItem } from "../../common/dropdownMenu";
import { getPriorityType } from "../../common/getPriority";
import { getQueryStringFromUrl } from "../../common/getQueryString";
import {
  GetTicket,
  PostResponse,
  UpdateTicket,
} from "../../services/appService";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { formatDateInTimeZone } from "../../common/formatDate";
import { Editor } from "@tinymce/tinymce-react";
import CloseIcon from "@material-ui/icons/Close";
import ReplyIcon from "@material-ui/icons/Reply";
import SendIcon from "@material-ui/icons/Send";
import { ticketResponseType } from "../../enums/ticketResponseType";
import { ticketResponseStatus } from "../../enums/ticketResponseStatus";
import { getTicketStatus } from "../../common/getTicketStatus";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Link } from "react-router-dom";
import { useMst } from "../../models/store";
import { ticketPriority } from "../../enums/ticketPriority";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    height: "auto",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);
const useStyles = makeStyles((theme) => ({
  leftPanel: {
    overflow: "hidden",
    position: "relative",
    height: "465px",
  },
  subItem: {
    fontSize: "1em",
  },
  scrollBar: {
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  statusButton: {
    padding: "none",
  },
  margin: {
    margin: theme.spacing(1),
  },
  showMore: {
    "&::before": {
      content: "''",
      position: "absolute",
      height: "1px",
      width: "100%",
      backgroundColor: "#ebeff3",
      top: "50%",
      zIndex: 1,
    },
  },
  showMoreButton: {
    position: "relative",
    display: "flex",
    margin: "auto",
    padding: "2px 12px 2px 6px",
    boxShadow: "0 0 0 8px #fff",
    borderRadius: "20px",
    minHeight: "24px",
    zIndex: "2",
    alignItems: "center",
    "justify-content": "space-between",
    border: "1px solid #cfd7df",
    background: "linear-gradient(180deg,#fff 2.56%,#f3f5f7 95.75%)",
  },
}));

export const TicketView = (props) => {
  const classes = useStyles();
  const ticketId = getQueryStringFromUrl(document.URL, "id");
  const store = useMst();
  const [localStore, setLocalStore] = useState({
    isLoading: true,
    pageIndex: 1,
    selectedTicket: null,
    messageIndex: 0,
    messagesHidden: true,
    resetCompose: function () {
      this.selectedTicket.composeReply = null;
    },
    reset: function () {
      this.messagesHidden = true;
      this.messageIndex = 0;
    },
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStatusMenu, setSelectedStatusMenu] = React.useState(null);
  const [selectedPriorityMenu, setSelectedPriorityMenu] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [editReply, setEditReply] = useState(false);
  const loadTicket = async (id) => {
    const getLocalStore = { ...localStore };
    const response = await GetTicket(parseInt(ticketId));

    getLocalStore.selectedTicket = response.data;
    setIsLoading(false);
    setLocalStore(getLocalStore);
  };

  const handleTicketPriorityMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedPriorityMenu(id);
  };

  const handleTicketPriorityClose = () => {
    setAnchorEl(null);
    setSelectedPriorityMenu(null);
  };

  const setReplyStatus = (response) => {
    const getLocalStore = { ...localStore };
    for (var i in getLocalStore.selectedTicket.ticketLogs) {
      if (
        getLocalStore.selectedTicket.ticketLogs[i].messageIndex ==
        response.messageIndex
      ) {
        if (response.success) {
          getLocalStore.selectedTicket.ticketLogs[i].status =
            ticketResponseStatus.sent;
        } else {
          getLocalStore.selectedTicket.ticketLogs[i].status =
            ticketResponseStatus.failed;
        }

        getLocalStore.selectedTicket.ticketLogs[i].createdOn =
          response.createdOn;
      }
    }
    setLocalStore(getLocalStore);
  };

  const sendReply = async (store) => {
    const responseType = document.getElementById("sendMessageVia").value;
    const response = await PostResponse(
      !responseType ? ticketResponseType.email : responseType,
      store.selectedTicket.emailAddress,
      store.selectedTicket.composeReply,
      store.selectedTicket.id,
      store.messageIndex
    );
    if (response) {
      setReplyStatus(response.data);
      localStore.resetCompose();
    }
  };

  const showMessage = (t, isHidden) => {
    return (
      <React.Fragment>
        <Box
          m={3}
          p={3}
          style={{
            background: t.userId > 0 ? "#fef1e1" : "#f5f7f9",
            borderRadius: "5px",
            display: isHidden && localStore.messagesHidden ? "none" : "block",
          }}
        >
          <Typography component="p" gutterBottom>
            <Typography variant="caption" style={{ fontStyle: "italic" }}>
              {t.userId == 0
                ? `${localStore.selectedTicket.name} says:`
                : "You said:"}
            </Typography>
            {t.status == 2 ? (
              <Typography variant="caption" component="p" gutterBottom>
                {formatDateInTimeZone(t.createdOn)}
              </Typography>
            ) : (
              <React.Fragment>
                <Typography variant="caption" component="p">
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    // className={classes.bottom}
                    size={10}
                    thickness={2}
                  />{" "}
                  Sending
                </Typography>
              </React.Fragment>
            )}
          </Typography>
          <Typography component="p" gutterBottom>
            <div
              dangerouslySetInnerHTML={{
                __html: t.html ? t.html : t.text ?? "No review provided",
              }}
            />
          </Typography>
        </Box>
      </React.Fragment>
    );
  };

  const handleResponseType = (e) => {
    var elem = document.getElementById("toField");
    if (e.target.value == ticketResponseType.email) {
      elem.setAttribute("placeholder", "Recipient email address(es)");
      elem.value = localStore.selectedTicket.emailAddress;
    } else if (e.target.value == ticketResponseType.sms) {
      elem.setAttribute("placeholder", "Recipient sms number");
      elem.value = localStore.selectedTicket.smsNumber;
    }
  };

  const handleTicketStatusMenu = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedStatusMenu(id);
  };
  const handleTicketStatusClose = () => {
    setAnchorEl(null);
    setSelectedStatusMenu(null);
  };

  const addShowMoreButton = (count) => {
    return (
      <div style={{ position: "relative" }}>
        <Box className={classes.showMore}>
          <Button className={classes.showMoreButton} onClick={toggleMessages}>
            {localStore.messagesHidden ? "Show" : "Hide"} +{count} messages
          </Button>
        </Box>
      </div>
    );
  };

  const postReply = () => {
    const getLocalStore = { ...localStore };
    getLocalStore.messageIndex++;
    const emailTo = document.getElementById("toField").value;
    if (localStore.selectedTicket.composeReply) {
      getLocalStore.selectedTicket.ticketLogs.push({
        text: localStore.selectedTicket.composeReply,
        // createdOn: t.optional(t.string, ""),
        userId: 99999,
        html: localStore.selectedTicket.composeReply,
        messageIndex: getLocalStore.messageIndex,
      });
      getLocalStore.selectedTicket.set("emailAddress", emailTo);
      sendReply(getLocalStore);
      setLocalStore(getLocalStore);
      setEditReply(false);
    }
  };

  const toggleMessages = () => {
    const getLocalStore = { ...localStore };
    if (getLocalStore.messagesHidden) {
      getLocalStore.messagesHidden = false;
    } else {
      getLocalStore.messagesHidden = true;
    }
    setLocalStore(getLocalStore);
  };

  const getTicketStatusDropDownMenu = (id) => {
    return (
      <React.Fragment>
        <Button
          aria-controls={`ticket-status-${id}`}
          variant="outlined"
          color="primary"
          onClick={(e) =>
            handleTicketStatusMenu(e, localStore.selectedTicket.id)
          }
          endIcon={<ArrowDropDownIcon />}
        >
          {getTicketStatus(localStore.selectedTicket.status)}
        </Button>
        <StyledMenu
          id={`ticket-status-${id}`}
          anchorEl={anchorEl}
          open={selectedStatusMenu == id}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration="auto"
          onClose={handleTicketStatusClose}
        >
          <StyledMenuItem>
            <ListItemText primary="Open" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText primary="Pending" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText primary="Resolved" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText primary="Waiting on client" />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText primary="Closed" />
          </StyledMenuItem>
        </StyledMenu>
      </React.Fragment>
    );
  };

  const handleEditorChange = (content, editor) => {
    var getLocalStore = { ...localStore };
    getLocalStore.selectedTicket.composeReply = content;
    setLocalStore(getLocalStore);
  };

  const updateTicket = (ticketId, priority) => {
    var response = UpdateTicket(ticketId, priority);
    if (response.data) {
      store.toggleAlert(true, "Successfully created a ticket");
    }

    handleTicketPriorityClose();
  };

  const getPriorityDropDown = (id) => {
    return (
      <React.Fragment>
        <Button
          aria-controls={`ticket-${id}`}
          onClick={(e) =>
            handleTicketPriorityMenu(e, localStore.selectedTicket.id)
          }
          endIcon={<ArrowDropDownIcon />}
          className={classes.statusButton}
          style={{ padding: "none" }}
        >
          {getPriorityType(localStore.selectedTicket.priority)}
        </Button>
        <StyledMenu
          id={`ticket-${id}`}
          anchorEl={anchorEl}
          open={selectedPriorityMenu == id}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration="auto"
          onClose={handleTicketPriorityClose}
        >
          <StyledMenuItem>
            <ListItemText
              primary="Highest"
              onClick={() => updateTicket(id, ticketPriority.highest)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="High"
              onClick={() => updateTicket(id, ticketPriority.high)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Medium"
              onClick={() => updateTicket(id, ticketPriority.medium)}
            />
          </StyledMenuItem>
          <StyledMenuItem>
            <ListItemText
              primary="Low"
              onClick={() => updateTicket(id, ticketPriority.low)}
            />
          </StyledMenuItem>
        </StyledMenu>
      </React.Fragment>
    );
  };
  if (localStore.selectedTicket == null && isLoading) {
    loadTicket();
    return <React.Fragment>Loading</React.Fragment>;
  } else if (localStore.selectedTicket == null && !isLoading) {
    return <React.Fragment>Not found</React.Fragment>;
  } else {
    return (
      <Grid container>
        <Grid
          item
          xs={12}
          style={{ height: "545px", overflow: "auto" }}
          className={`${classes.scrollBar}`}
        >
          <React.Fragment>
            <Box
              style={{
                height: "90px",
                borderBottom: "1px solid #e9e9e9",
              }}
              p={2}
            >
              <Grid container>
                <React.Fragment>
                  <Grid item xs={1}>
                    <Box
                      component={Link}
                      to="/tickets"
                      style={{ position: "relative", verticalAlign: "middle" }}
                    >
                      <ArrowBackIcon />
                    </Box>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h3">
                      {localStore.selectedTicket.name}
                    </Typography>
                    <Typography
                      variant="caption"
                      component="p"
                      color="textSecondary"
                    >
                      <Rating
                        value={localStore.selectedTicket.starRating}
                        readOnly
                        size="small"
                      />
                      <Typography component="p" variant="caption">
                        Ticket # 885
                      </Typography>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography className={classes.subItem} component="p">
                      <strong>Priority:</strong>
                      {getPriorityDropDown(localStore.selectedTicket.id)}
                      {/* {getPriorityType(localStore.selectedTicket.priority)} */}
                    </Typography>
                    <Typography component="p" className={classes.subItem}>
                      <strong>Created:</strong>{" "}
                      {formatDateInTimeZone(
                        localStore.selectedTicket.createTime
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Box textAlign="right">
                      {getTicketStatusDropDownMenu(
                        localStore.selectedTicket.id
                      )}
                    </Box>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Box>
            <Box p={3}>
              <Typography component="p" gutterBottom>
                {localStore.selectedTicket.reviewerComment ? (
                  <React.Fragment>
                    <Typography
                      variant="caption"
                      style={{ fontStyle: "italic" }}
                    >
                      {localStore.selectedTicket.name} says:
                    </Typography>
                    <Typography component="p">
                      {localStore.selectedTicket.reviewerComment}
                    </Typography>
                  </React.Fragment>
                ) : (
                  "No review provided"
                )}
              </Typography>
            </Box>
            <Divider />
            {localStore.selectedTicket &&
              localStore.selectedTicket.ticketLogs &&
              localStore.selectedTicket.ticketLogs.length == 0 && (
                <Box p={3}>
                  <Typography component="p" gutterBottom>
                    You haven't started any conversation with the reviewer.
                  </Typography>
                </Box>
              )}

            {localStore.selectedTicket &&
              localStore.selectedTicket.ticketLogs &&
              localStore.selectedTicket.ticketLogs.length > 0 &&
              localStore.selectedTicket.ticketLogs.map((t, i) => {
                var hidden = true;
                if (
                  i == 0 ||
                  i == localStore.selectedTicket.ticketLogs.length - 1
                ) {
                  hidden = false;
                }
                if (i == 1 && localStore.selectedTicket.ticketLogs.length > 3) {
                  {
                    return addShowMoreButton(
                      localStore.selectedTicket.ticketLogs.length
                    );
                  }
                }
                return showMessage(t, hidden);
              })}
            {/* {loadThreadConversation()} */}
            <Box p={3} style={{ display: !editReply ? "none" : "block" }}>
              <Paper
                variant="elevation"
                elevation={0}
                style={{ paddingBottom: "5px" }}
              >
                <FormControl className={classes.margin}>
                  <Select
                    id="sendMessageVia"
                    value={
                      localStore.selectedTicket.lastPreferredResponseType ?? 1
                    }
                    input={<BootstrapInput />}
                    onChange={handleResponseType}
                  >
                    <MenuItem value={1}>Email</MenuItem>
                    <MenuItem value={2}>Sms</MenuItem>
                  </Select>
                </FormControl>
                <FormControl className={classes.margin}>
                  <BootstrapInput
                    id="toField"
                    placeholder="Email address"
                    value={
                      localStore.selectedTicket.lastPreferredResponseType ==
                      ticketResponseType.sms
                        ? localStore.selectedTicket.smsNumber
                        : localStore.selectedTicket.emailAddress
                    }
                  />
                </FormControl>
                <FormControl className={classes.margin}>
                  <Button
                    variant="contained"
                    color="primary"
                    endIcon={<SendIcon />}
                    onClick={postReply}
                  >
                    Send
                  </Button>
                </FormControl>
                <FormControl className={classes.margin}>
                  <Button
                    variant="text"
                    color="primary"
                    endIcon={<CloseIcon />}
                    onClick={() => setEditReply(false)}
                  >
                    Cancel
                  </Button>
                </FormControl>
              </Paper>
              <Editor
                apiKey="eq6m05jebhzzr8fq6xyy0box83j41rk6mow3woyeufloa84p"
                // initialValue={parseBody(campaignData.body)}
                init={{
                  height: 300,
                  width: "100%",
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | fontsizeselect formatselect forecolor | bold italic backcolor | \
             alignleft aligncenter alignright alignjustify | \
             bullist numlist outdent indent | removeformat image | help",
                  branding: false,
                }}
                onEditorChange={handleEditorChange}
              />
            </Box>
            <Box p={3}>
              <Button
                color="primary"
                variant="outlined"
                startIcon={<ReplyIcon />}
                onClick={() => setEditReply(true)}
                style={{ display: editReply ? "none" : "" }}
              >
                Reply
              </Button>
            </Box>
          </React.Fragment>
        </Grid>
      </Grid>
    );
  }
};
