import { Box, Container, Paper, Tab, Tabs } from "@material-ui/core";
import React, { Component, useState } from "react";
import { TabPanel } from "../common/tabPanel";
import { NPSReport } from "./reports/npsReport";
import { ReviewsReport } from "./reports/reviews";
export const Reports = ({ ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (e, value) => {
    setTabIndex(value);
  };
  return (
    <React.Fragment>
      <Paper>
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          aria-label="disabled tabs example"
          onChange={handleTabChange}
        >
          <Tab label="NPS" />
          <Tab label="Reviews" />
        </Tabs>
      </Paper>
      <Box>
        <TabPanel value={tabIndex} index={0}>
          <NPSReport />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <ReviewsReport props={props} />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
};
