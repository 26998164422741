import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { StyledTableHead } from "../common/table";
import { useMst } from "../models/store";
import SearchIcon from "@material-ui/icons/Search";
import { formatDateInTimeZone } from "../common/formatDate";
import { StyledHeader } from "../common/getHeader";
import AddIcon from "@material-ui/icons/Add";
import { GetCustomers } from "../services/appService";
import { LoadingSpinner } from "../common/loadingSpinner";
import { GetCustomersTable } from "../common/getCustomers";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
  },
}));
export const Customers = (props) => {
  const store = useMst();
  const classes = useStyles();
  const [tableActionButtons, setTableActionButtons] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [loadedData, setLoadedData] = useState(null);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setSelected(store.reviewRequests.data.map((t) => t.id));
      setTableActionButtons(false);
    } else {
      setSelected([]);
      setTableActionButtons(true);
    }
  };

  const loadData = async (pageIndex, pageSize) => {
    GetCustomers(pageIndex, pageSize).then((t) => {
      setLoadedData(t.data);
    });
  };

  useEffect(() => {
    if (loadedData == null) {
      loadData(1, 50);
    }
  });

  const createNew = () => {
    props.history.push({
      pathname: "/customers/create",
    });
  };

  const getSelectedCustomers = (customers) => {};

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <StyledHeader
          heading="Customers"
          subHeading={`${
            loadedData != null ? loadedData.totalSize : 0
          } Records found.`}
          actionButton={
            <Button
              variant="contained"
              color="primary"
              style={{ fontWeight: 500 }}
              onClick={createNew}
            >
              <AddIcon /> Create New
            </Button>
          }
        />

        <Paper variant="outlined">
          <GetCustomersTable />
        </Paper>
      </Container>
    </React.Fragment>
  );
};
