import React, { Component } from "react";
import TableHead from "@material-ui/core/TableHead";
import {
  TableRow,
  TableCell,
  Checkbox,
  Table,
  TableBody,
  withStyles,
} from "@material-ui/core";

export const TableOptions = {
  isCheckBox: false,
  headValues: [],
  data: [],
};

const GetTableHead = (props) => {
  const { options } = props;
  return (
    <TableHead>
      <TableRow>
        {options.isCheckBox && (
          <TableCell>
            <Checkbox />
          </TableCell>
        )}
        {options.headValues.map((value, idx) => (
          <TableCell>{value}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
const GetTableBody = (props) => {
  const { options } = props;
  return (
    <TableBody>
      {options && options.data.map((value) => <TableCell>{value}</TableCell>)}
    </TableBody>
  );
};
export const CreateTable = (props) => {
  const { options } = props;
  return (
    <Table aria-label="Table">
      <GetTableHead options={options} />
      <GetTableBody options={options} />
    </Table>
  );
};

export const StyledTableHead = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
  },
}))(TableCell);
