import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { FeedbackRequest } from "./feedbackRequest";
export const CampaignResult = t.model("campaignResult", {
  totalSent: t.optional(t.integer, 0),
  totalClicked: t.optional(t.integer, 0),
  totalRated: t.optional(t.integer, 0),
  totalComments: t.optional(t.integer, 0),
  totalScores: t.array(t.integer, []),
  campaignRequests: t.array(FeedbackRequest, []),
  campaignType: t.optional(t.integer, 0),
  linkedTags: t.array(t.string, null),
});
