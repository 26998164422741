import React, { Component } from "react";
export const getPriorityType = (priority) => {
  switch (priority) {
    case 1:
      return "Low";
    case 2:
      return "Medium";
    case 3:
      return "High";
    case 4:
      return "Highest";
    default:
      break;
  }
};

export const getPriorityColor = (value) => {
  var color;
  switch (value) {
    case 1:
      color = "#3cce00";
      break;
    case 2:
      color = "#ff9438";
      break;
    case 3:
      color = "#ff3838";
      break;
    case 4:
      color = "#ce0000";
      break;
    default:
      break;
  }
  return color;
};
