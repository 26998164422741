import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const AudienceContact = t
  .model("audienceContact", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    emailAddress: t.maybeNull(t.string, ""),
    phone: t.maybeNull(t.string, ""),
    createdOn: t.optional(t.string, ""),
    isDeleted: t.optional(t.boolean, false),
    customerId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
