import { types as t, flow, applySnapshot } from "mobx-state-tree";
const Conversation = t.model("Conversation", {
  id: t.integer,
  name: t.maybeNull(t.string),
  email: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  text: t.maybeNull(t.string),
  modifiedOn: t.maybeNull(t.string),
  userId: t.maybeNull(t.integer),
});
export default Conversation;
