import { Grid, makeStyles } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import {
  Box,
  Typography,
  Button,
  InputBase,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import React, { Component } from "react";
import SendIcon from "@material-ui/icons/Send";
import { Divider } from "@material-ui/core";
import { GetMessageBubble } from "../../common/getMessageBubble";
import { useEffect } from "react";
import { useMst } from "../../models/store";
import { useState } from "react";
import { GetConversation, SendMessage } from "../../services/appService";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { formatDateInTimeZone } from "../../common/formatDate";
import { PusherService } from "../../services/pusherService";
import { useRef } from "react";
const useStyles = makeStyles((theme) => ({
  leftPanel: {
    overflow: "hidden",
    position: "relative",
    height: "calc(100vh - 64px)",
    paddingTop: "64px",
  },
  scrollBar: {
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.3)",
    },
  },
  messaging_container: {
    height: "calc(100vh - 62px)",
  },
  message_area: {
    position: "absolute",
    bottom: 12,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    border: "1px solid #e9e9e9",
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  textLeft: {
    textAlign: "left",
  },
  textRight: {
    textAlign: "right",
  },
  visitorMessage: {
    backgroundColor: "rgb(244, 246, 249)",
    borderRadius: "26px 26px 26px 3px",
  },
  userMessage: {
    backgroundColor: "rgb(76, 118, 224)",
    borderRadius: "26px 26px 3px",
  },
  bubble: {
    padding: "12px 20px",
    display: "inline-block",
    maxWidth: "54%",
    overflowWrap: "break-word",
    lineHeight: 1.4,
    fontSize: "14px",
    position: "relative",
    marginBottom: "3px",
  },
  bubble_container: {
    position: "relative",
    width: "100%",
    marginBottom: "10px",
    paddingTop: "10px",
  },
  textWhite: {
    color: "#fff",
  },
  textBlack: {
    color: "#000",
  },
  timestamp: {
    fontSize: "12px",
    color: "#63727d",
  },
}));
export const Messaging = ({ id, name, contactInfo }) => {
  const classes = useStyles();
  const store = useMst();
  const pusherClient = PusherService();
  const [loadedData, setLoadedData] = useState(null);
  const dataRef = useRef();
  var channel = pusherClient.subscribe(
    `${store.accountInfo.account.id}-conversation-log-${id}`
  );
  const scrollMessageArea = () => {
    var xdiv = document.getElementById("message_area");
    xdiv.scrollTop = xdiv.scrollHeight - xdiv.clientHeight;
  };

  useEffect(() => {
    if (!id) {
      store.toggleAlert(true, "There is an error opening a conversation");
      return;
    }

    if (!loadedData || loadedData[0].conversationId != id) {
      channel.bind("message", function (data) {
        setLoadedData((m) => [...m, JSON.parse(data)]);
        scrollMessageArea();
      });
      GetConversation(id).then((t) => {
        setLoadedData(t.data);
        dataRef.current = t.data;
        scrollMessageArea();
      });
    }
  });

  const sendMessage = (e) => {
    if (!e.target.value) return;
    if (e.keyCode != 13) return;
    var message = e.target.value;
    var getLoadedData = [...loadedData];
    getLoadedData.push({
      id: 0,
      text: e.target.value,
      userId: 1,
      status: 0,
      sentTimeStamp: "",
    });
    setLoadedData(getLoadedData);
    dataRef.current = getLoadedData;
    setTimeout(scrollMessageArea, 50);
    e.target.value = "";
    SendMessage(id, message).then((t) => {
      updateMessage(t.data);
    });
  };

  const updateMessage = (log) => {
    var getLoadedData = [...dataRef.current];
    //find the same message
    for (let i = getLoadedData.length - 1; i > -1; i--) {
      if (log.text == getLoadedData[i].text) {
        getLoadedData[i].sentTimeStamp = log.sentTimeStamp;
        getLoadedData[i].status = log.status;
        break;
      }
    }
    debugger;
    setLoadedData(getLoadedData);
  };

  const getconvoHeader = () => {
    return (
      <Box
        p={2}
        style={{ borderBottom: "1px solid #e9e9e9", position: "relative" }}
      >
        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          container
        >
          <Grid item>
            <Typography variant="h4">
              {name}
              {/* {convo.sendType == SendType.email ? (
                <EmailIcon style={{ fontSize: "16px" }} />
              ) : (
                <PhoneIphoneIcon style={{ fontSize: "16px" }} />
              )} */}
            </Typography>
            <Typography variant="caption">{contactInfo}</Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined">Send</Button>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <React.Fragment>
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          height: "100%",
          maxHeight: "100vh",
          position: "relative",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
          }}
        >
          {getconvoHeader()}
          <Box
            id="message_area"
            className={classes.scrollBar}
            style={{
              position: "relative",
              height: "calc(100vh - 62px - 75px - 89px)",
              minHeight: 0,
              overflowY: "auto",
            }}
          >
            <Box
              style={{
                height: "100%",
                // overflowY: "auto",
                transform: "translate3d(0px, 0px, 0px)",
              }}
              pr={2}
              pl={2}
            >
              {!loadedData && <LoadingSpinner />}
              {loadedData &&
                loadedData.length > 0 &&
                loadedData.map((t) => (
                  <Box
                    className={`${classes.bubble_container} ${
                      t.userId > 0 ? classes.textRight : classes.textLeft
                    }`}
                  >
                    <Box
                      className={`${classes.bubble} ${classes.textLeft} ${
                        t.userId > 0
                          ? classes.userMessage
                          : classes.visitorMessage
                      }`}
                    >
                      <Box
                        className={
                          t.userId > 0 ? classes.textWhite : classes.textBlack
                        }
                      >
                        {t.text}
                      </Box>
                    </Box>
                    <Box className={classes.timestamp}>
                      {t.userId == 0
                        ? formatDateInTimeZone(t.createdOn)
                        : !t.sentTimeStamp
                        ? "Sending"
                        : formatDateInTimeZone(t.sentTimeStamp)}
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
          <Box p={2} style={{ position: "relative" }}>
            <Box>
              <TextField
                size="medium"
                multiline
                fullWidth
                rowsMax={1}
                placeholder="Enter message"
                onKeyDown={sendMessage}
                //   value={value}
                //   onChange={handleChange}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                      />
                      <IconButton
                        aria-label="toggle password visibility"
                        // onClick={handlePasswordVisibility}
                        // onMouseDown={handleMouseDownPassword}
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};
