import React, { Component } from "react";
import {
  Backdrop,
  Box,
  Chip,
  Grid,
  Grow,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Button,
  Modal,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextareaAutosize,
  IconButton,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { SendType } from "../../enums/sendType";
import { Editor } from "@tinymce/tinymce-react";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import EmailIcon from "@material-ui/icons/Email";
import StarIcon from "@material-ui/icons/Star";
import { RequestType } from "../../enums/campaignType";
import PostAddIcon from "@material-ui/icons/PostAdd";
import {
  CreateReviewOrFeedbackRequest,
  LoadCustomersCombo,
  LoadTemplate,
  LoadTemplatesForCombo,
} from "../../services/appService";
import { useState } from "react";
import ForumIcon from "@material-ui/icons/Forum";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { GetMessageBubble } from "../../common/getMessageBubble";
import { useMst } from "../../models/store";
import { GetCustomersAutoCompleteCombo } from "../../common/getCustomerAutoCompleteCombo";
import { BeautifyShortLinks } from "../../common/beautifyShortLinks";
import { Messaging } from "./messaging";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  mainContent: {
    backgroundColor: "none",
  },
  leftPanel: {
    overflow: "hidden",
    position: "relative",
    height: "calc(100vh - 64px)",
  },
  scrollBar: {
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  searchBar: {
    border: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 50,
    "&::placeholder": {
      float: "right",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  listGutter: {
    paddingLeft: "45px",
  },
  textField: {
    paddingBottom: theme.spacing(2),
  },
  chip: {
    padding: "18px 2px",
    marginLeft: "15px",
  },
  emailCompose: {
    border: "1px solid #e9e9e9",
    borderRadius: "5px",
  },
  subjectLine: {
    borderBottom: "none",
    paddingBottom: "0",
  },
  textArea: {
    border: "1px solid rgb(196, 196, 196)",
    height: "75px",
    borderRadius: "3px",
    padding: "5px",
    "&::before": {
      color: "rgb(196, 196, 196)",
    },
  },
}));

export const Create = ({ triggerTab }) => {
  const classes = useStyles();
  const [templates, setTemplates] = useState(null);
  const store = useMst();
  const [open, setOpen] = useState(false);
  const [convo, setConvo] = useState({
    name: "",
    sendTo: "",
    sendType: 0,
    requestType: 0,
    templateId: 0,
    subject: "",
    preview: null,
    validated: null,
    customerId: 0,
  });
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [suggestion, setSuggestion] = useState([]);

  const send = () => {
    const conv = { ...convo };
    if (conv.sendType == SendType.email && !conv.subject) {
      conv.validated = false;
    } else {
      conv.validated = true;
    }
    if (conv.validated) {
      const dataModel = {
        name: convo.name,
        email: convo.sendType == SendType.email ? convo.sendTo : "",
        phone: convo.sendType == SendType.sms ? convo.sendTo : "",
        requestType: convo.requestType,
        customTemplate: convo.preview.html,
        subject: convo.subject,
        sendType: convo.sendType,
        customerId: convo.customerId,
      };
      try {
        CreateReviewOrFeedbackRequest(dataModel).then((t) => {
          if (t.data) {
            triggerTab(
              <Messaging
                id={t.data.id}
                name={t.data.name}
                contactInfo={t.data.email ?? t.data.phone}
              />,
              t.data
            );
          } else {
            store.toggleAlert(true, "There is an error executing your request");
          }
        });
      } catch (error) {
        store.toggleAlert(
          true,
          "There is an error while creating a new review request"
        );
      }
    }
    setConvo(conv);
  };

  const getconvoHeader = () => {
    return (
      <Box p={2} style={{ borderBottom: "1px solid #e9e9e9" }}>
        <Grid
          direction="row"
          justify="space-between"
          alignItems="flex-end"
          container
        >
          <Grid item>
            <Typography variant="h4">
              {convo.name}{" "}
              {convo.sendType == SendType.email ? (
                <EmailIcon style={{ fontSize: "16px" }} />
              ) : (
                <PhoneIphoneIcon style={{ fontSize: "16px" }} />
              )}
            </Typography>
            <Typography variant="caption">{convo.sendTo}</Typography>
          </Grid>
          <Grid item>
            {convo.sendType == SendType.email && (
              <Button variant="outlined" onClick={send}>
                Send
              </Button>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const previewTemplate = (id) => {
    setOpen(true);
    LoadTemplate(id).then((t) => {
      var conv = { ...convo };
      conv["preview"] = t.data;
      setConvo(conv);
    });
  };

  const selectRequest = (type) => {
    var conv = { ...convo };
    conv["requestType"] = type;
    setConvo(conv);
  };

  const handleEditorChange = (content, editor) => {
    const conv = { ...convo };
    conv.preview.html = content;
    setConvo(conv);
  };

  const compose = (key, value) => {
    var conv = { ...convo };
    if (key == "subject") {
      conv.subject = value;
    }

    setConvo(conv);
  };

  const onClose = () => {
    setOpen(false);
  };

  const loadTemplate = () => {
    debugger;
    if (!convo.preview && convo.templateId) {
      LoadTemplate(convo.templateId).then((t) => {
        debugger;

        var conv = { ...convo };
        conv["preview"] = t.data;
        setConvo(conv);
      });
    }
  };

  const change = (key, value) => {
    var conv = { ...convo };
    conv[key] = value;
    if (key == "sendTo") {
      conv["sendType"] = isNaN(value) ? SendType.email : SendType.sms;
      if (value.length > 2) {
        loadSuggestions(value);
      }
    }
    if (!value) {
      setSuggestion([]);
    }
    conv["requestType"] = 0;
    conv["templateId"] = 0;
    conv["subject"] = "";
    conv["preview"] = null;
    // setTemplates(null);
    setConvo(conv);
  };

  const selectType = (type) => {
    var conv = { ...convo };
    conv["sendType"] = type;
    setConvo(conv);
  };

  const loadSuggestions = (text) => {
    LoadCustomersCombo(text).then((t) => {
      debugger;
      setSuggestion(t.data);
    });
  };

  const getTemplates = () => {
    if (convo.requestType && !templates) {
      LoadTemplatesForCombo(convo.requestType, convo.sendType).then((t) => {
        setTemplates(t.data);
      });
    }

    return <React.Fragment></React.Fragment>;
  };

  const selectTemplate = (id) => {
    debugger;
    var conv = { ...convo };
    conv["templateId"] = id;
    setConvo(conv);
    setOpen(false);
  };

  const getCustomersList = async (e) => {
    var data = await LoadCustomersCombo(e.currentTarget.value);
    setCustomersList(data.data);
  };

  const selectCustomer = (c) => {
    const getConvo = { ...convo };
    getConvo.name = c.name;
    getConvo.sendTo = c.phone ?? c.emailAddress;
    getConvo.sendType = c.phone ? SendType.sms : SendType.email;
    getConvo.customerId = c.id;
    setConvo(getConvo);
  };

  const changeSmsTemplate = (e) => {
    debugger;
    const conv = { ...convo };
    conv.preview.html = e.currentTarget.value;
    setConvo(conv);
  };

  return (
    <React.Fragment>
      {convo.templateId > 0 && getconvoHeader()}
      <Box p={2}>
        {/* this is for header */}
        <Box style={{ display: convo.templateId ? "none" : "block" }}>
          <TextField
            className={classes.textField}
            required
            label="Send To"
            placeholder="Email/Phone"
            fullWidth
            size="medium"
            value={convo.sendTo}
            onChange={(e) => change("sendTo", e.target.value)}
          />
          <Grow
            in={convo.sendTo == "" ? false : true}
            style={{ transformOrigin: "0 0 0" }}
            {...(convo.sendTo ? { timeout: 1000 } : {})}
          >
            <TextField
              className={classes.textField}
              required
              label="Name"
              placeholder="Enter name"
              fullWidth
              size="medium"
              value={convo.name}
              onChange={(e) => change("name", e.target.value)}
            />
          </Grow>
          <Grow
            in={convo.sendTo == "" || convo.sendTo.length < 2 ? false : true}
            style={{ transformOrigin: "0 0 0" }}
            {...(convo.sendTo ? { timeout: 1000 } : {})}
          >
            <Box p={2} style={{ display: !convo.name ? "block" : "none" }}>
              {suggestion.length > 0 && (
                <Typography variant="h4">Suggestions:</Typography>
              )}
              <TableContainer component={Paper} elevation={0}>
                <Table className={classes.table} aria-label="simple table">
                  <TableBody>
                    {suggestion &&
                      suggestion.map((t) => (
                        <React.Fragment>
                          <TableRow
                            hover={true}
                            onClick={() => selectCustomer(t)}
                          >
                            <TableCell component="th" scope="row">
                              <Typography>{t.name}</Typography>
                              <Typography>
                                {t.phone || t.emailAddress}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grow>
        </Box>
        <Grow
          in={convo.name == "" ? false : convo.requestType ? false : true}
          style={{ transformOrigin: "0 0 0" }}
          {...(convo.name ? { timeout: 1000 } : {})}
        >
          <Box
            style={{
              display:
                convo.name == ""
                  ? "none"
                  : convo.requestType
                  ? "none"
                  : "block",
            }}
            alignContent="center"
            alignItems="center"
            textAlign="center"
            pt={2}
          >
            <Chip
              className={classes.chip}
              variant="outlined"
              label="Request a review"
              clickable={true}
              variant="outlined"
              color="primary"
              icon={<StarIcon />}
              size="medium"
              onClick={() => selectRequest(RequestType.review)}
            />
            <Chip
              className={classes.chip}
              icon={<ForumIcon />}
              variant="outlined"
              label="Request a feedback"
              button
              clickable={true}
              variant="outlined"
              color="primary"
              onClick={() => selectRequest(RequestType.feedback)}
            />
          </Box>
        </Grow>
        <Grow
          in={
            convo.requestType && convo.name && convo.sendTo && !convo.templateId
              ? true
              : false
          }
        >
          <Box
            alignContent="center"
            alignItems="center"
            textAlign="center"
            pt={2}
            style={{
              display:
                convo.requestType &&
                convo.name &&
                convo.sendTo &&
                !convo.templateId
                  ? "block"
                  : "none",
            }}
          >
            <Typography variant="h3">Select a template</Typography>
            <Box pt={2}>
              {convo.requestType > 0 &&
                convo.name &&
                convo.sendTo &&
                !templates &&
                getTemplates()}
              {convo.requestType > 0 &&
                convo.name &&
                convo.sendTo &&
                templates &&
                templates.map((t) => (
                  <Chip
                    className={classes.chip}
                    variant="outlined"
                    label={t.name}
                    clickable={true}
                    variant="outlined"
                    color="primary"
                    onClick={() => selectTemplate(t.id)}
                    onDelete={() => previewTemplate(t.id)}
                    deleteIcon={<VisibilityIcon />}
                  />
                ))}
            </Box>
          </Box>
        </Grow>
        {convo && convo.sendType == SendType.email && (
          <Grow in={convo.templateId > 0 ? true : false}>
            <Box pt={2}>
              {convo && convo.sendType == SendType.email && (
                <Box className={classes.emailCompose}>
                  <Box p={1}>
                    <TextField
                      className={`${classes.textField} ${classes.subjectLine}`}
                      required
                      placeholder="Subject Line:"
                      fullWidth
                      size="medium"
                      onChange={(e) => compose("subject", e.target.value)}
                      InputProps={{ disableUnderline: true }}
                      helperText={
                        convo.validated != null &&
                        !convo.validated &&
                        !convo.subject
                          ? "Please write a subject"
                          : ""
                      }
                      error={
                        convo.validated != null &&
                        !convo.validated &&
                        !convo.subject
                          ? true
                          : false
                      }
                    />
                  </Box>
                  {!convo.preview && loadTemplate()}
                  {convo.preview && (
                    <Editor
                      apiKey="eq6m05jebhzzr8fq6xyy0box83j41rk6mow3woyeufloa84p"
                      initialValue={
                        convo.preview && convo.preview.html
                          ? convo.preview.html
                          : ""
                      }
                      init={{
                        height: 370,
                        width: "100%",
                        menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | fontsizeselect formatselect forecolor | bold italic backcolor | \
 alignleft aligncenter alignright alignjustify | \
 bullist numlist outdent indent | removeformat image | help",
                        branding: false,
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  )}
                </Box>
              )}
            </Box>
          </Grow>
        )}

        {!convo.preview && loadTemplate()}
        {convo && convo.sendType == SendType.sms && (
          <Grow in={convo.templateId > 0 ? true : false}>
            <Box
              style={{
                height: "calc(100vh - 62px - 75px - 34px)",
                position: "relative",
              }}
            >
              <Box style={{ position: "absolute", bottom: 0, width: "100%" }}>
                <Box
                  style={{ border: "1px solid #c4c4c4", borderRadius: "3px" }}
                  p={2}
                >
                  <Box pb={2}>
                    <Box
                      contentEditable="true"
                      component="div"
                      placeholder="Type something"
                      className={classes.textArea}
                      dangerouslySetInnerHTML={{
                        __html:
                          convo.preview &&
                          convo.preview.html &&
                          BeautifyShortLinks(convo.preview.html ?? ""),
                      }}
                      onChange={changeSmsTemplate}
                    ></Box>
                  </Box>
                  <Box>
                    <Grid
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                      container
                    >
                      <Grid item>
                        <IconButton>
                          <PostAddIcon />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={send}
                        >
                          Send
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grow>
        )}
      </Box>
      <Modal
        disableEnforceFocus={true}
        open={open}
        closeAfterTransition
        // onClose={onClose}
        className={classes.modal}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper
          style={{
            width: "75%",
            height: "546px",
            outline: 0,
          }}
        >
          <Box
            style={{
              overflow: "auto",
              height: "493.5px",
              borderBottom: "1px solid #e9e9e9",
            }}
          >
            {!convo.preview && <LoadingSpinner />}
            {convo.preview &&
              ((convo.sendType == SendType.email && (
                <div
                  dangerouslySetInnerHTML={{ __html: convo.preview.html }}
                ></div>
              )) ||
                (convo.sendType == SendType.sms && (
                  <Box pt={8}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          style={{
                            width: "400px",
                            height: "150px",
                            margin: "0 auto",
                          }}
                        >
                          <Box
                            style={{ float: "right", display: "block" }}
                            pb={3}
                          >
                            <GetMessageBubble
                              text={convo.preview.html}
                              position="right"
                            />
                          </Box>
                          <Box style={{ float: "left", display: "block" }}>
                            <GetMessageBubble text="....." position="left" />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )))}
          </Box>
          <Box textAlign="right" p={1}>
            <Box mr={1} display="inline">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
            <Box mr={1} display="inline">
              <Button
                variant="contained"
                color="primary"
                onClick={() => selectTemplate(convo.preview.id)}
              >
                Select
              </Button>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
