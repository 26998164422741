import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { useMst } from "../models/store";
import {
  All,
  AllCampaigns,
  Completed,
  Drafts,
  InProgress,
} from "./campaigns/all";
import { LocationView } from "./locationView";
import Navbar from "./navbar";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    background: "#fff",
    minHeight: "100vh",
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  scrollBar: {
    overflow: "hidden",
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));
export const Campaigns = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.toolbar} />
          <List className={classes.scrollBar}>
            <ListItem key="Campaigns">
              <ListItemText
                primary={
                  <Typography>
                    <strong>Campaigns</strong>
                  </Typography>
                }
              />
            </ListItem>
            <Divider />
            <ListItem
              className={classes.nested}
              button
              key="All Campaigns"
              component={Link}
              to="/campaigns/all"
              selected={document.location.pathname == "/campaigns/all"}
            >
              <ListItemText primary="All Campaigns" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              key="Drafts"
              component={Link}
              to="/campaigns/drafts"
              selected={document.location.pathname == "/campaigns/drafts"}
            >
              <ListItemText primary="Drafts" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              key="In Progress"
              component={Link}
              to="/campaigns/inprogress"
              selected={document.location.pathname == "/campaigns/inprogress"}
            >
              <ListItemText primary="In Progress" />
            </ListItem>
            <ListItem
              className={classes.nested}
              button
              key="Completed"
              component={Link}
              to="/campaigns/completed"
              selected={document.location.pathname == "/campaigns/completed"}
            >
              <ListItemText primary="Completed" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/campaigns/all" component={All} />
            <Route exact path="/campaigns/drafts" component={Drafts} />
            <Route exact path="/campaigns/completed" component={Completed} />
            <Route exact path="/campaigns/inprogress" component={InProgress} />
            <Route exact path="/campaigns" component={AllCampaigns} />
          </Switch>
        </main>
      </div>
    </React.Fragment>
  );
};
