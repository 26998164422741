import React, { Component } from "react";
import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  TextField,
  Divider,
  InputAdornment,
  Button,
  Container,
  TextareaAutosize,
  Tabs,
  Tab,
  makeStyles,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  InputBase,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { StyledBreadcrumb } from "../common/breadcrumbs";
import Rating from "@material-ui/lab/Rating";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { useMst } from "../models/store";
import { observer } from "mobx-react";
import { StyledCardHeader } from "../common/styledCardHeader";
import { getSnapshot } from "mobx-state-tree";
import { SaveAccountSetup } from "../services/appService";
import CloseIcon from "@material-ui/icons/Close";
import { getIcon } from "../common/getIcon";
import { useState } from "react";
import { TabPanel } from "../common/tabPanel";
import { StyledHeader } from "../common/getHeader";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import Axios from "axios";
import qs from "querystring";
import { rotateToken } from "../services/accountService";
import { GetTemplatesCombo } from "../common/getTemplatesCombo";
import { SendType } from "../enums/sendType";
import { RequestType } from "../enums/campaignType";

const useStyles = makeStyles((theme) => ({
  emailTemplate: {
    height: "485px",
    overflowY: "auto",
  },
  smsTemplate: {
    height: "167px",
  },
  smsBubbleRight: {
    textAlign: "right",
    maxWidth: "300px",
    width: "100%",
    float: "right",
    padding: "15px",
    background: "#0a80fe",
    borderRadius: "15px",
    color: "#fff",
    marginBottom: "10px",
  },
  smsBubbleLeft: {
    marginTop: "10px",
    textAlign: "left",
    width: "300px",
    float: "left",
    padding: "15px",
    background: "#e9e9e9",
    borderRadius: "15px",
  },
  rightPanel: {
    paddingLeft: theme.spacing(12),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
}));
export const Setup = observer(() => {
  const classes = useStyles();
  const store = useMst();
  const [tabValue, setTabValue] = useState(0);
  const [passModal, setPassModal] = useState(0);
  const [pass, setPass] = useState("");

  const handleChange = (e, pageLink) => {
    pageLink.set("url", e.target.value);
  };
  const handleRate = (e, value) => {
    store.accountSetup.set("moderateBelow", value);
  };
  const handleDelete = (e, pageLink) => {
    if (pageLink.integrationType > 0) {
      pageLink.set("id", 0);
      pageLink.set("url", "");
    } else {
      pageLink.set("isDeleted", true);
    }
  };
  const handleSave = () => {
    const pageLinks = getSnapshot(store.accountSetup.pageLinks);
    store.accountSetup.set("pageLinks", pageLinks);
    SaveAccountSetup(store.accountSetup);
  };
  const renderEmailTemplate = (value) => {
    if (value) {
      if (value.indexOf("[Rating]") > -1) {
        value = value.replace(
          "[Rating]",
          '<svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" style="font-size:48px; color: #ffb400;" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" style="font-size:48px; color: #ffb400;" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" style="font-size:48px; color: #ffb400;" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" style="font-size:48px; color: #ffb400;" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeInherit" focusable="false" style="font-size:48px; color: #ffb400;" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path></svg>'
        );
      } else {
        store.toggleAlert(
          true,
          "You cannot remove [Rating] from your html code editor"
        );
      }

      value = value
        .replace("[BusinessName]", store.accountInfo.account.businessName)
        .replace("[ContactEmail]", store.accountInfo.account.emailAddress);
    }

    return value ?? "";
  };

  const renderSmsTemplate = (value) => {
    if (value) {
      if (value.indexOf("[ShortLink]") > -1) {
        value = value.replace("[ShortLink]", "rebrand.ly/ni878sklj");
      } else {
        store.toggleAlert(
          true,
          "You cannot remove [ShortLink] from your html code editor"
        );
      }
      value = value.replace(
        "[BusinessName]",
        store.accountInfo.account.businessName
      );
    }

    return value ?? "";
  };
  const addCustomPageLink = () => {
    store.accountSetup.addNew();
  };

  const handleEmailTemplate = (e, f) => {
    store.accountSetup.set("emailTemplate", e.currentTarget.value);
  };

  const handleSmsTemplate = (e, f) => {
    store.accountSetup.set("smsTemplate", e.currentTarget.value);
  };

  const handleTabChange = (e, value) => {
    setTabValue(value);
  };

  const handleRadioChange = (e) => {
    store.accountSetup.set("moderateCampaignBelow", parseInt(e.target.value));
  };

  const handleSwitch = (e) => {
    store.accountSetup.set(e.target.name, e.target.checked);
  };

  const generateToken = async (e) => {
    var request = Axios.create();
    request({
      method: "post",
      url: `${process.env.REACT_APP_ApiUrl}/token`,
      data: qs.stringify({
        username: store.accountInfo.account.emailAddress,
        password: pass,
        grant_type: "password",
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        "x-api-identifier": "CUMVX8XE5RE8C9E",
      },
    }).then((t) => {
      store.accountSetup.set("longLivedToken", t.data.access_token);
      rotateToken(t.data.access_token).then((t) => {
        if (t) {
          store.toggleAlert(true, "Successfully updated access token");
        } else {
          store.toggleAlert(true, "There is a problem updating token.");
        }
        handleModalClose();
      });
    });
  };
  const handleModalClose = () => {
    setPassModal(false);
  };

  const askPassword = () => {
    setPassModal(true);
  };

  const handleTemplateIdCombo = (type, e) => {
    store.accountSetup.set(type, e.target.value);
  };

  if (store.accountSetup) {
    return (
      <React.Fragment>
        <Container>
          {/* <StyledBreadcrumb label="Dashboard" link="/" /> */}
          <StyledHeader
            heading="Account Setup"
            actionButton={
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 500 }}
                to="/customers/create"
                onClick={handleSave}
              >
                <SaveAltIcon /> &nbsp;Save
              </Button>
            }
          />
          <Box>
            <Tabs value={tabValue} onChange={handleTabChange}>
              <Tab label="General" id="tab-0" />
              <Tab label="Customization" id="tab-1" />
              <Tab label="API Tokens" id="tab-2" />
            </Tabs>
            <TabPanel index={0} value={tabValue}>
              <Box pt={4} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Review Moderation Settings</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Moderate all reviews below your set rating criteria.
                      Social page links will be hidden from reviewer.
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <Rating
                        value={store.accountSetup.moderateBelow}
                        size="large"
                        onChange={handleRate}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Auto-Create Review Ticket</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Automatically create a ticket for moderated reviews
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <Switch
                        checked={store.accountSetup.autoCreateReviewTicket}
                        name="autoCreateReviewTicket"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={handleSwitch}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box pt={4} pb={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Campaign Moderation Settings</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Moderate all campaigns below your set criteria.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={12}>
                    <Box pl={2}>
                      <RadioGroup
                        row
                        name="campaignModeration"
                        defaultValue={5}
                        onChange={handleRadioChange}
                        value={store.accountSetup.moderateCampaignBelow}
                      >
                        <FormControlLabel
                          value={0}
                          control={<Radio color="primary" />}
                          label="0"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={1}
                          control={<Radio color="primary" />}
                          label="1"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio color="primary" />}
                          label="2"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={3}
                          control={<Radio color="primary" />}
                          label="3"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={4}
                          control={<Radio color="primary" />}
                          label="4"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={5}
                          control={<Radio color="primary" />}
                          label="5"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={6}
                          control={<Radio color="primary" />}
                          label="6"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={7}
                          control={<Radio color="primary" />}
                          label="7"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={8}
                          control={<Radio color="primary" />}
                          label="8"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={9}
                          control={<Radio color="primary" />}
                          label="9"
                          labelPlacement="top"
                        />
                        <FormControlLabel
                          value={10}
                          control={<Radio color="primary" />}
                          label="10"
                          labelPlacement="top"
                        />
                      </RadioGroup>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Auto-Create Campaign Ticket</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Automatically create a ticket for campaign if response is
                      below your set criteria
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <Switch
                        checked={store.accountSetup.autoCreateCampaignTicket}
                        name="autoCreateCampaignTicket"
                        inputProps={{ "aria-label": "secondary checkbox" }}
                        onChange={handleSwitch}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
              <Box pt={4} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Page Links</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Add your live page links where you want reviewer to post
                      the review.
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <Card elevation={1}>
                        <StyledCardHeader>
                          <Button
                            variant="outlined"
                            onClick={addCustomPageLink}
                          >
                            Add Custom Page Link
                          </Button>
                        </StyledCardHeader>
                        <CardContent>
                          {store.accountSetup.pageLinks.map(
                            (t) =>
                              !t.isDeleted && (
                                <Box pb={2}>
                                  <TextField
                                    fullWidth={true}
                                    label={t.title}
                                    // placeholder="Paste your facebook page link"
                                    value={t.url}
                                    variant="outlined"
                                    InputProps={{
                                      startAdornment: (
                                        <InputAdornment position="start">
                                          {getIcon(t.integrationType)}
                                        </InputAdornment>
                                      ),
                                      endAdornment: (
                                        <Button
                                          onClick={(e) => handleDelete(e, t)}
                                        >
                                          <CloseIcon />
                                        </Button>
                                      ),
                                    }}
                                    onChange={(e) => handleChange(e, t)}
                                  />
                                </Box>
                              )
                          )}
                        </CardContent>
                      </Card>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel index={1} value={tabValue}>
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Review Request Email Template</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Default template used for sending review request as email
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <GetTemplatesCombo
                        handleChange={(e) =>
                          handleTemplateIdCombo(
                            "defaultReviewEmailTemplateId",
                            e
                          )
                        }
                        templateType={RequestType.review}
                        sendType={SendType.email}
                        value={store.accountSetup.defaultReviewEmailTemplateId}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Box pt={2} pb={2}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography component="h2" variant="subtitle1">
                        <strong>Review Request Sms Template</strong>
                      </Typography>
                      <Typography component="p" variant="subtitle2">
                        Default template used for sending review request as sms
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box className={classes.rightPanel}>
                        <GetTemplatesCombo
                          handleChange={(e) =>
                            handleTemplateIdCombo(
                              "defaultReviewSmsTemplateId",
                              e
                            )
                          }
                          templateType={RequestType.review}
                          sendType={SendType.sms}
                          value={store.accountSetup.defaultReviewSmsTemplateId}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              <Divider />
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography component="h2" variant="subtitle1">
                      <strong>Feedback Request Email Template</strong>
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Default template used for sending feedback request as
                      email
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Box className={classes.rightPanel}>
                      <GetTemplatesCombo
                        handleChange={(e) =>
                          handleTemplateIdCombo(
                            "defaultFeedbackEmailTemplateId",
                            e
                          )
                        }
                        templateType={RequestType.feedback}
                        sendType={SendType.email}
                        value={
                          store.accountSetup.defaultFeedbackEmailTemplateId
                        }
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Box pt={2} pb={2}>
                <Box pt={2} pb={2}>
                  <Grid container>
                    <Grid item xs={4}>
                      <Typography component="h2" variant="subtitle1">
                        <strong>Feedback Request Sms Template</strong>
                      </Typography>
                      <Typography component="p" variant="subtitle2">
                        Default template used for sending feedback request as
                        sms
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Box className={classes.rightPanel}>
                        <GetTemplatesCombo
                          handleChange={(e) =>
                            handleTemplateIdCombo(
                              "defaultFeedbackSmsTemplateId",
                              e
                            )
                          }
                          templateType={RequestType.feedback}
                          sendType={SendType.sms}
                          value={
                            store.accountSetup.defaultFeedbackSmsTemplateId
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </TabPanel>
            <TabPanel index={2} value={tabValue}>
              <Box pt={6} pb={2}>
                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <Typography>
                      API tokens are used to access Squidily API. Once you
                      generate the token, make sure you securely transmit it
                      since this API token grants access to all your data in
                      Squidily.
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl
                      // className={clsx(classes.margin, classes.textField)}
                      variant="outlined"
                      fullWidth
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        placeholder="Generate API token"
                        disabled={true}
                        value={store.accountSetup.longLivedToken}
                        // type={values.showPassword ? "text" : "password"}
                        // value={values.password}
                        // onChange={handleChange("password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              // onClick={handleClickShowPassword}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                              onClick={askPassword}
                            >
                              <AutorenewIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Box>
        </Container>
        <Modal
          disableEnforceFocus={true}
          open={passModal}
          closeAfterTransition
          onClose={handleModalClose}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "400px", outline: 0 }}>
            <Box p={3}>
              <Box pb={2}>
                <Typography variant="h2">Enter your password</Typography>
                <Typography variant="p">
                  Please confirm your password. We're just being extra safe.
                </Typography>
              </Box>
              <Box pb={2}>
                <TextField
                  id="outlined-helperText"
                  label="Confirm password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setPass(e.target.value)}
                />
              </Box>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <Button variant="outlined" onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={generateToken}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  } else {
    store.loadAccountSetup();
    return <React.Fragment>Loading....</React.Fragment>;
  }
});
