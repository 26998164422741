import Axios from "axios";
import Cookies from "universal-cookie";

export const getAccountInfo = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/account/accountinfo/`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const resendConfirmEmail = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/account/resendemail/`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const confirmEmailAddress = async (userId, code) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/account/confirmemail/`,
    params: {
      userId: userId,
      code: code,
    },
  });
};

export const rotateToken = async (accessToken) => {
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/account/rotatetoken/`,
    params: {
      accessToken,
    },
  });
};
