import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const PageLink = t
  .model("pageLink", {
    id: t.optional(t.integer, 0),
    title: t.maybeNull(t.string, ""),
    url: t.maybeNull(t.string, ""),
    iconLink: t.maybeNull(t.string, ""),
    integrationType: t.optional(t.integer, 0),
    isDeleted: t.optional(t.boolean, false),
    createdOn: t.optional(t.string, ""),
    modifiedOn: t.optional(t.string, ""),
    accountId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
