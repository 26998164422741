import { Box, Grid, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { StyledBreadcrumb } from "./breadcrumbs";
export const StyledHeader = ({
  heading,
  subHeading,
  actionButton,
  alignText,
}) => {
  return (
    <React.Fragment>
      <Box pt={2} pb={2}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography
              variant="h2"
              component="h1"
              style={{ fontWeight: 600 }}
              align={alignText}
            >
              {heading}
            </Typography>
            {subHeading && (
              <Typography variant="caption">{subHeading}</Typography>
            )}
          </Grid>
          {actionButton ?? (
            <Grid item style={{ float: "right" }}>
              {actionButton}
            </Grid>
          )}
        </Grid>
      </Box>
    </React.Fragment>
  );
};
