import { types as t, flow, applySnapshot } from "mobx-state-tree";

export const TotalReviewsByPage = t.model("TotalReviewsByPage", {
    integrationType: t.optional(t.integer, 0),
    total: t.optional(t.integer, 0)
});

export const DataSetByPage = t.model("DataSetByPage", {
    star: t.optional(t.integer, 0),
    createdOn: t.optional(t.string, "")
});

export const ReviewsByPage = t.model("ReviewsByPage", {
    integrationType: t.optional(t.integer, 0),
    name: t.optional(t.string, ''),
    dataPoints: t.array(DataSetByPage, [])
});

export const Overview = t.model("Overview", {
    totalReviewsByPage: t.array(TotalReviewsByPage, []),
    totalReviewsByStar: t.array(t.integer, []),
    reviewsByPages: t.array(ReviewsByPage, [])
});
