import { FormControl, MenuItem, Select } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import { useMst } from "../models/store";
import { LoadTemplatesForCombo } from "../services/appService";
export const GetTemplatesCombo = observer(
  ({ handleChange, templateType, sendType, value, placeholder }) => {
    const store = useMst();
    const [comboData, setComboData] = useState(null);
    useEffect(() => {
      if (!comboData && templateType && sendType) {
        LoadTemplatesForCombo(templateType, sendType).then((t) => {
          setComboData(t.data);
        });
      }
    });
    debugger;
    return (
      <FormControl fullWidth>
        <Select
          displayEmpty
          variant="outlined"
          fullWidth
          defaultValue={0}
          value={value}
          onChange={handleChange}
        >
          {(!comboData || !comboData.length) && (
            <MenuItem disabled>No templates present</MenuItem>
          )}
          <MenuItem selected value={0}>
            {placeholder ?? "Select a template"}
          </MenuItem>
          {comboData &&
            comboData.length > 0 &&
            comboData.map((t) => <MenuItem value={t.id}>{t.name}</MenuItem>)}
        </Select>
      </FormControl>
    );
  }
);
