import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Component } from "react";
import { Link, Route, Switch } from "react-router-dom";
import { useMst } from "../models/store";
import { LocationView } from "./locationView";
import Navbar from "./navbar";
import { AverageRating } from "./overview/averateRating";
import { FeedbackInvites } from "./overview/feedbackInvites";
import { GetAvgNpsScore } from "./overview/npsScore";
import { RatingDistribution } from "./overview/ratingDistribution";
import { ReviewInvites } from "./overview/reviewInvites";
const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    // paddingTop: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
  active: {
    backgroundColor: theme.palette.action.selected,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  scrollBar: {
    overflow: "hidden",
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
}));
export const Overview = () => {
  const classes = useStyles();
  const store = useMst();
  if (!store.isAuthenticated) {
    store.authenticate();
  } else {
    return (
      <React.Fragment>
        <div className={classes.root}>
          <CssBaseline />
          <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.toolbar} />
            <List className={classes.scrollBar}>
              <ListItem
                button
                key="Overview"
                component={Link}
                to="/"
                selected={document.location.pathname == "/"}
              >
                <ListItemText primary="Overview" />
              </ListItem>
              <Divider />
              <ListItem key="Reviews">
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Reviews</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                key="Distribution"
                component={Link}
                to="/overview/rating-distribution"
                selected={
                  document.location.pathname == "/overview/rating-distribution"
                }
              >
                <ListItemText
                  className={classes.nested}
                  primary="Distribution"
                />
              </ListItem>
              <ListItem
                button
                key="Average Rating"
                component={Link}
                to="/overview/average-rating"
                selected={
                  document.location.pathname == "/overview/average-rating"
                }
              >
                <ListItemText
                  className={classes.nested}
                  primary="Average Rating"
                />
              </ListItem>
              <ListItem
                button
                key="Review Invites"
                component={Link}
                to="/overview/review-invites"
                selected={
                  document.location.pathname == "/overview/review-invites"
                }
              >
                <ListItemText
                  className={classes.nested}
                  primary="Review Invites"
                />
              </ListItem>
              <Divider />
              <ListItem key="Feedbacks">
                <ListItemText
                  primary={
                    <Typography>
                      <strong>Feedbacks</strong>
                    </Typography>
                  }
                />
              </ListItem>
              <ListItem
                button
                key="Feedback Invites"
                component={Link}
                to="/overview/feedback-invites"
                selected={
                  document.location.pathname == "/overview/feedback-invites"
                }
              >
                <ListItemText
                  className={classes.nested}
                  primary="Feedback Invites"
                />
              </ListItem>
              <ListItem
                button
                key="NPS Score"
                component={Link}
                to="/overview/nps"
                selected={document.location.pathname == "/overview/nps"}
              >
                <ListItemText
                  className={classes.nested}
                  primary="Avg NPS Score"
                />
              </ListItem>
            </List>
          </Drawer>
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <Switch>
              <Route
                exact
                path="/overview/average-rating"
                component={AverageRating}
              />
              <Route exact path="/overview/nps" component={GetAvgNpsScore} />
              <Route
                exact
                path="/overview/rating-distribution"
                component={RatingDistribution}
              />
              <Route
                exact
                path="/overview/review-invites"
                component={ReviewInvites}
              />
              <Route
                exact
                path="/overview/feedback-invites"
                component={FeedbackInvites}
              />
              <Route exact path="/" component={LocationView} />
            </Switch>
          </main>
        </div>
      </React.Fragment>
    );
  }
};
