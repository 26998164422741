import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const FeedbackRequest = t
  .model("feedbackRequest", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    identifier: t.optional(t.string, ""),
    emailTimeStamp: t.maybeNull(t.string, ""),
    hasEmailSent: t.optional(t.boolean, false),
    comment: t.maybeNull(t.string, ""),
    rating: t.optional(t.integer, 0),
    status: t.optional(t.integer, 0),
    isClicked: t.optional(t.boolean, false),
    modifiedOn: t.optional(t.string, ""),
    ticketId: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    ticketId: t.optional(t.integer, 0),
    customerId: t.optional(t.integer, 0),
    createdOn: t.optional(t.string, ""),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
