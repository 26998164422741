import React, { Component } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Grow,
  makeStyles,
  Grid,
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  TextField,
  Button,
} from "@material-ui/core";
import { useState } from "react";
import {
  GetRequest,
  PostCampaignRating,
  PostCampaignComment,
} from "../services/campaignService";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  root: {
    display: "flex",
    justifyContent: "left",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  chip: {
    backgroundColor: theme.palette.grey[200],
    border: "none",
    borderRadius: "6px",
  },
  ratingContainer: {
    display: "inline-block",
    padding: "5px 10px",
    border: "1px solid #03a9f4",
    textAlign: "center",
    borderRadius: "5px",
    fontSize: "18px",
    fontWeight: "bold",
    margin: "0 10px",
    color: "#03a9f4",
  },
  ratingInner: {
    display: "inline",
  },
}));
export const CampaignRequest = () => {
  const { identifier, rating } = useParams();
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [backDrop, setBackDrop] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [validated, setValidated] = useState(false);
  const [comment, setComment] = useState("");
  const checkStatus = (value) => {
    return status === value;
  };

  const validate = () => {
    GetRequest(identifier, rating)
      .then((t) => {
        setValidated(t.data.isExpired ? false : true);
        setLoaded(true);
        setStatus(rating > 0 ? 1 : t.data.status);
      })
      .catch((t) => {
        setLoaded(true);
      });
  };

  const handleClick = async (e, value) => {
    setBackDrop(true);
    PostCampaignRating(identifier, value).then((t) => {
      setStatus((s) => s + 1);
      setBackDrop(false);
    });
  };

  const handleCommentValue = (e) => {
    setComment(e.target.value);
  };
  const handleComment = () => {
    setBackDrop(true);
    PostCampaignComment(identifier, comment).then((t) => {
      setStatus((s) => s + 1);
      setBackDrop(false);
    });
  };

  const constructSection = (value) => {
    switch (value) {
      case 0:
        return (
          <React.Fragment>
            <Box style={{ display: checkStatus(0) ? "block" : "none" }}>
              <Box pb={4}>
                <Typography variant="h4">
                  <strong>How was your experience?</strong>
                </Typography>
                <Typography variant="h6">
                  How well would you rate our service?
                </Typography>
              </Box>
              <Box>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 1)}
                >
                  <div className={classes.ratingInner}>1</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 2)}
                >
                  <div className={classes.ratingInner}>2</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 3)}
                >
                  <div className={classes.ratingInner}>3</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 4)}
                >
                  <div className={classes.ratingInner}>4</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 5)}
                >
                  <div className={classes.ratingInner}>5</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 6)}
                >
                  <div className={classes.ratingInner}>6</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 7)}
                >
                  <div className={classes.ratingInner}>7</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 8)}
                >
                  <div className={classes.ratingInner}>8</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 9)}
                >
                  <div className={classes.ratingInner}>9</div>
                </div>
                <div
                  className={classes.ratingContainer}
                  component={Link}
                  onClick={(e) => handleClick(e, 10)}
                >
                  <div className={classes.ratingInner}>10</div>
                </div>
              </Box>
            </Box>
          </React.Fragment>
        );
      case 1:
        return (
          <Box>
            <Typography
              variant="h4"
              component="h1"
              style={{ paddingBottom: "16px" }}
            >
              <strong>You can leave us a comment here</strong>
            </Typography>
            <TextField
              multiline
              rows={4}
              fullWidth={true}
              placeholder="Write your comments here"
              variant="outlined"
              onChange={handleCommentValue}
            />
            <Box pt={2}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                value={comment}
                onClick={handleComment}
              >
                POST NOW
              </Button>
            </Box>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Typography variant="h4">
              Thank you for your feedback. We appreciate your time.
            </Typography>
          </Box>
        );
      default:
        break;
    }
  };
  if (loaded) {
    if (validated) {
      return (
        <React.Fragment>
          <Container>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "70vh", textAlign: "center" }}
              p={2}
            >
              <Grid item style={{ width: "600px", height: "250px" }}>
                <Grow in={checkStatus(0)}>{constructSection(0)}</Grow>
                <Grow
                  in={checkStatus(1)}
                  style={{ display: checkStatus(1) ? "block" : "none" }}
                >
                  {constructSection(1)}
                </Grow>
                <Grow
                  in={checkStatus(2)}
                  style={{ display: checkStatus(2) ? "block" : "none" }}
                >
                  {constructSection(2)}
                </Grow>
              </Grid>
            </Grid>
            <Backdrop open={backDrop}>
              <CircularProgress color="inherit" />
            </Backdrop>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Container>
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: "70vh", textAlign: "center" }}
              p={2}
            >
              <Typography variant="h6">
                This is no longer available or you already have responded to
                this request.
              </Typography>
            </Grid>
          </Container>
        </React.Fragment>
      );
    }
  } else {
    validate();
    return (
      <React.Fragment>
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </React.Fragment>
    );
  }
};
