import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const Template = t
  .model("template", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    html: t.optional(t.string, ""),
    createdOn: t.maybeNull(t.string, ""),
    isPublished: t.optional(t.boolean, false),
    templateType: t.optional(t.integer, 0),
    sendType: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
