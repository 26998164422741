import { makeStyles } from "@material-ui/core";
import React, { Component } from "react";
import MoodIcon from "@material-ui/icons/Mood";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
const useStyles = makeStyles((theme) => ({
  promoter: {
    borderColor: "#63ce03",
    color: "#63ce03",
    fontSize: "32px",
  },
  passive: {
    borderColor: "#ff8d00",
    color: "#ff8d00",
    fontSize: "32px",
  },
  detractor: {
    borderColor: "#ef4919",
    color: "#ef4919",
    fontSize: "32px",
  },
}));
export const GetNPSEmotion = ({ score, size }) => {
  const classes = useStyles();
  if (score >= 0 && score <= 6) {
    return (
      <SentimentVeryDissatisfiedIcon
        className={classes.detractor}
        style={{ fontSize: size ?? "18px" }}
      />
    );
  } else if (score >= 7 && score <= 8) {
    return (
      <SentimentDissatisfiedIcon
        className={classes.passive}
        style={{ fontSize: size ?? "18px" }}
      />
    );
  } else {
    return (
      <MoodIcon
        className={classes.promoter}
        style={{ fontSize: size ?? "18px" }}
      />
    );
  }
};

export const getNPSEmotionColor = (score) => {
  var color = "";
  if (score >= 0 && score <= 6) {
    color = "#ef4919";
  } else if (score >= 7 && score <= 8) {
    color = "#ff8d00";
  } else {
    color = "#63ce03";
  }

  return color;
};
