import {
  Backdrop,
  Box,
  Chip,
  Divider,
  Grid,
  Grow,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useLayoutEffect, useState } from "react";
import SearchIcon from "@material-ui/icons/Search";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import ForumIcon from "@material-ui/icons/Forum";
import StarIcon from "@material-ui/icons/Star";
import { RequestType } from "../enums/campaignType";
import { SendType } from "../enums/sendType";
import { observer } from "mobx-react";
import { useMst } from "../models/store";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ReplyIcon from "@material-ui/icons/Reply";
import { GetConversations } from "../services/appService";
import { LoadingSpinner } from "../common/loadingSpinner";
import { Create } from "./conversations/create";
import { Messaging } from "./conversations/messaging";
import { PagedResult } from "../models/pagedResult";
import { applySnapshot } from "mobx-state-tree";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { formatDateInTimeZone } from "../common/formatDate";
import { PusherService } from "../services/pusherService";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  mainContent: {
    backgroundColor: "none",
  },
  leftPanel: {
    overflow: "hidden",
    position: "relative",
    height: "calc(100vh - 64px)",
  },
  scrollBar: {
    "&:hover": {
      overflow: "auto",
    },
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
    },
  },
  searchBar: {
    border: "none",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    height: 50,
    "&::placeholder": {
      float: "right",
    },
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  listGutter: {
    paddingLeft: "45px",
  },
  textField: {
    paddingBottom: theme.spacing(2),
  },
  chip: {
    padding: "18px 2px",
    marginLeft: "15px",
  },
  emailCompose: {
    border: "1px solid #e9e9e9",
    borderRadius: "5px",
  },
  subjectLine: {
    borderBottom: "none",
    paddingBottom: "0",
  },
}));
var isLoading = false;
export const Conversations = observer(() => {
  const classes = useStyles();
  const store = useMst();
  const [domHeight, setDOMHeight] = useState(0);
  const [activeTab, setActiveTab] = useState(null);
  const [loadedData, setLoadedData] = useState(null);
  const pusherClient = PusherService();
  useEffect(() => {
    debugger;
    if (!loadedData) {
      loadData(1);
    }
    console.log(pusherClient);
  });

  const loadData = async (pageIndex) => {
    debugger;
    var response = await GetConversations(pageIndex, 25);
    var _pagedResult = PagedResult.create();
    applySnapshot(_pagedResult, response.data);
    if (!loadedData) {
      setLoadedData(_pagedResult);
    } else {
      response.data.data.map((t) => {
        loadedData.pushData(t);
      });
      loadedData.set("pageIndex", parseInt(pageIndex));
    }

    isLoading = false;
  };
  useLayoutEffect(() => {
    function resizeWindow() {
      try {
        var navBarHeight = document.getElementsByClassName(
          "makeStyles-toolbar-6"
        )[0].clientHeight;
        var contentDivHeight =
          document.getElementsByClassName("MuiBox-root")[0].clientHeight;
        var totalDOMHeight = window.innerHeight;
        var calcHeight = window.innerHeight - navBarHeight - 16 - 16;
        setDOMHeight(calcHeight);
      } catch (error) {
        // setDOMHeight(564);
      }
    }
    window.addEventListener("resize", resizeWindow);
    resizeWindow();
  });

  const openTab = (tab, newConvo) => {
    setActiveTab(tab);

    var channel = pusherClient.subscribe(
      `${store.accountInfo.account.id}-conversation-${tab.props.id}`
    );
    channel.bind("message", function (data) {
      debugger;
      console.log(data);
    });
    if (newConvo) {
      loadedData.unshiftData(newConvo);
      setLoadedData(loadedData);
    }
  };

  const loadMoreData = () => {
    if (!isLoading) {
      isLoading = true;
      loadData(loadedData.pageIndex + 1);
    }
  };
  return (
    <React.Fragment>
      <Box pt={8}>
        <Paper
          style={{
            height: `${domHeight ? domHeight + "px" : `calc(100vh - 64px)`}`,
          }}
        >
          <Grid container>
            <Grid
              item
              xs={4}
              className={`${classes.leftPanel}`}
              style={{ borderRight: "1px solid #e9e9e9" }}
            >
              <Box pl={2} pt={2} pb={2}>
                <Typography variant="h3">Conversations</Typography>
              </Box>
              <Box
                p={1}
                style={{
                  padding: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e9e9e9",
                  borderTop: "1px solid #e9e9e9",
                }}
              >
                <InputBase
                  className={classes.input}
                  placeholder="  Search anything"
                  inputProps={{ "aria-label": "search" }}
                />
                <IconButton
                  type="submit"
                  className={classes.iconButton}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <Divider className={classes.divider} orientation="vertical" />
                <IconButton
                  color="primary"
                  onClick={() => {
                    openTab(<Create triggerTab={openTab} />);
                  }}
                  className={classes.iconButton}
                  aria-label="directions"
                >
                  <Box
                    style={{
                      background: "#3f51b5",
                      padding: "4px 6px 1px 6px",
                      borderRadius: "3px",
                    }}
                  >
                    <CreateOutlinedIcon style={{ color: "#fff" }} />
                  </Box>
                </IconButton>
              </Box>
              <Box
                className={`${classes.leftPanel} ${classes.scrollBar}`}
                style={{ height: "calc(100vh - 64px - 64px - 54px)" }}
              >
                <List style={{ padding: "0" }}>
                  {!loadedData && <LoadingSpinner />}
                  {loadedData &&
                    loadedData.data.length > 0 &&
                    loadedData.data.map((t) => (
                      <ListItem
                        className={classes.listGutter}
                        onClick={() =>
                          openTab(
                            <Messaging
                              id={t.id}
                              name={t.name}
                              contactInfo={t.email ?? t.phone}
                            />
                          )
                        }
                        divider
                        button
                      >
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Grid
                                container
                                direction="row"
                                justify="space-between"
                                alignItems="flex-end"
                              >
                                <Grid item>
                                  <Typography variant="h3">{t.name}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography variant="caption">
                                    {formatDateInTimeZone(t.modifiedOn)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography>
                                {t.userId == 1 ? (
                                  <ReplyIcon fontSize="small" />
                                ) : (
                                  ""
                                )}{" "}
                                {t.text}
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    ))}
                  {loadedData &&
                    loadedData.data.length > 0 &&
                    loadedData.pageCount > 1 && (
                      <InfiniteScroll
                        pageStart={loadedData.pageIndex}
                        loadMore={loadMoreData}
                        hasMore={loadedData.pageIndex < loadedData.pageCount}
                        loader={
                          <Typography align="center">
                            <LoadingSpinner />
                          </Typography>
                        }
                      />
                    )}
                </List>
              </Box>
            </Grid>
            <Grid item xs={8}>
              {activeTab != null && activeTab}
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </React.Fragment>
  );
});
