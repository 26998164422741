import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { GetAverageRating } from "../common/getAverageRating";
import { Page } from "./page";
export const Location = t
  .model("location", {
    id: t.optional(t.integer, 0),
    locationName: t.optional(t.string, ""),
    locationIdentifier: t.optional(t.string, ""),
    address: t.optional(t.string, ""),
    city: t.optional(t.string, ""),
    state: t.optional(t.string, ""),
    zip: t.optional(t.string, ""),
    country: t.optional(t.string, ""),
    email: t.optional(t.string, ""),
    phone: t.optional(t.string, ""),
    linkedPages: t.array(Page, []),
  })
  .actions((self) => ({
    assignPages(pageList) {
      pageList.map((t) => {
        const clonedObj = { ...t };
        self.linkedPages.push(clonedObj);
      });
    },
    calculateAvgReviews: function () {
      var totalReviews = 0;
      var oneStarRating = 0;
      var twoStarRating = 0;
      var threeStarRating = 0;
      var fourStarRating = 0;
      var fiveStarRating = 0;

      self.linkedPages.map((t) => {
        totalReviews += t.reviewsCount;
        oneStarRating += t.oneStarRating;
        twoStarRating += t.twoStarRating;
        threeStarRating += t.threeStarRating;
        fourStarRating += t.fourStarRating;
        fiveStarRating += t.fiveStarRating;
      });

      if (!totalReviews) {
        return 0;
      }
      const score = GetAverageRating(
        [
          fiveStarRating,
          fourStarRating,
          threeStarRating,
          twoStarRating,
          oneStarRating,
        ],
        [{ total: totalReviews }]
      );
      return Number(score.toFixed(1));
    },
    calculateTotalReviews: function () {
      var totalReviews = 0;
      self.linkedPages.map((t) => {
        totalReviews += t.reviewsCount;
      });

      return totalReviews;
    },
    set(key, value) {
      self[key] = value;
    },
  }));
