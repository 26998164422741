import React, { Component } from "react";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  InputAdornment,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Container,
  withStyles,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
  Badge,
  IconButton,
} from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import AddIcon from "@material-ui/icons/Add";
import PublishIcon from "@material-ui/icons/Publish";
import { Link, useParams } from "react-router-dom";
import { StyledTableHead } from "../../common/table";
import { useState } from "react";
import { useMst } from "../../models/store";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "@material-ui/lab/Pagination";
import { observer } from "mobx-react";
import { formatDateInTimeZone } from "../../common/formatDate";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import { AudienceContact } from "../../models/audienceContact";
import { applySnapshot, getSnapshot } from "mobx-state-tree";
import { StyledHeader } from "../../common/getHeader";
import { GetCustomersTable } from "../../common/getCustomers";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  addButton: {
    background: "#e9e9e9",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
}));

export const AudienceInfo = observer(() => {
  const store = useMst();
  const { id } = useParams();
  if (!store.audiences) {
    window.location = "/audience";
  }
  const audience = store.audiences.data.find((e) => {
    return e.id == id;
  });

  const classes = useStyles();
  const [showAddContact, setShowAddContact] = useState(false);
  const [tableActionButtons, setTableActionButtons] = React.useState(true);
  const [selected, setSelected] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [pageIndex, setPageIndex] = useState(
    store.reviewRequests.pageIndex != 0 ? store.reviewRequests.pageIndex : 1
  );
  const [newRecord, setNewRecord] = useState({
    name: "",
    emailAddress: "",
    phone: "",
  });
  const handleAddContact = () => {
    setOpenModal(true);
  };
  const handlePagination = (event, value) => {
    setPageIndex(value);
    store.loadAllReviews(value, 50);
  };
  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setSelected(store.reviewRequests.data.map((t) => t.id));
      setTableActionButtons(false);
    } else {
      setSelected([]);
      setTableActionButtons(true);
    }
  };

  const handleNewRecordValue = (e, key) => {
    const obj = { ...newRecord };
    obj[key] = e.target.value;
    setNewRecord(obj);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };

  const addContact = (customer) => {
    for (let i = 0; i < audience.audienceContacts.data.length; i++) {
      if (
        audience.audienceContacts.data[i].emailAddress == customer.email &&
        audience.audienceContacts.data[i].phone == customer.phone
      ) {
        return;
      }
    }
    var model = AudienceContact.create();
    model.set("name", customer.name);
    model.set("emailAddress", customer.email);
    model.set("phone", customer.phone);
    model.set("customerId", customer.id);
    audience.audienceContacts.pushData(model);
  };

  const handleDelete = (e, obj, index) => {
    obj.set("isDeleted", true);
  };
  const handleSave = async () => {
    audience.saveAudience();
  };

  const getSelectedCustomers = (selectedCustomers) => {
    for (let i = 0; i < selectedCustomers.length; i++) {
      addContact(selectedCustomers[i]);
    }
  };

  if (audience.audienceContacts) {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <StyledHeader
            heading="Create Audience"
            actionButton={
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 500 }}
                onClick={handleSave}
              >
                <SaveAltIcon />
                &nbsp;Save
              </Button>
            }
          />
          <Box pt={2}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={handleAddContact}
                >
                  <AddIcon />
                  &nbsp;Add Contact
                </Button>
              </Grid>
            </Grid>
            <Box pt={2} pb={2}>
              <Fade
                in={showAddContact}
                style={{ display: showAddContact ? "flex" : "none" }}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      placeholder="Customer Name"
                      value={newRecord.name}
                      fullWidth
                      onChange={(e) => handleNewRecordValue(e, "name")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      placeholder="Email Address"
                      fullWidth
                      value={newRecord.emailAddress}
                      onChange={(e) => handleNewRecordValue(e, "emailAddress")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      variant="outlined"
                      placeholder="Sms Number"
                      fullWidth
                      value={newRecord.smsNumber}
                      onChange={(e) => handleNewRecordValue(e, "phone")}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <IconButton
                      color="secondary"
                      variant="contained"
                      className={classes.addButton}
                      onClick={addContact}
                    >
                      <AddIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Fade>
            </Box>
            <Paper variant="outlined">
              <Box p={2}>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <Grid item hidden={tableActionButtons}>
                    <Button variant="outlined">Delete</Button>
                  </Grid>
                  <Grid item hidden={!tableActionButtons}>
                    <TextField
                      placeholder="Search"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Select
                        displayEmpty
                        className={classes.selectEmpty}
                        variant="outlined"
                      >
                        <MenuItem>Sort By</MenuItem>
                        <MenuItem value={1}>Last Updated</MenuItem>
                        <MenuItem value={2}>Creation Date</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer component={Paper} elevation={0}>
                <Table aria-label="Table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox onChange={handleAllCheck} />
                      </TableCell>
                      <StyledTableHead>Name</StyledTableHead>
                      <StyledTableHead>Email Address</StyledTableHead>
                      <StyledTableHead>Sms Number</StyledTableHead>
                      <StyledTableHead>Added On</StyledTableHead>
                      <StyledTableHead></StyledTableHead>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audience.audienceContacts.data.length > 0 &&
                      audience.audienceContacts.data.map(
                        (t, i) =>
                          !t.isDeleted && (
                            <TableRow>
                              <TableCell padding="checkbox">
                                <Checkbox />
                              </TableCell>
                              <TableCell>{t.name}</TableCell>
                              <TableCell>{t.emailAddress}</TableCell>
                              <TableCell>{t.phone}</TableCell>
                              <TableCell>
                                {formatDateInTimeZone(t.createdOn)}
                              </TableCell>
                              <TableCell>
                                <IconButton
                                  onClick={(e) => handleDelete(e, t, i)}
                                >
                                  <DeleteSweepIcon />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </Table>
                {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={100}
            /> */}
              </TableContainer>
              {!audience.audienceContacts.data.length && (
                <Grid container alignItems="center" justify="center">
                  <Grid item>
                    <Box pt={5} pb={5}>
                      <Typography align="center" gutterBottom>
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={handleAddContact}
                        >
                          Add Contact
                        </Button>
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        component="h6"
                        align="center"
                      >
                        No contact present
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
              <Box p={2}>
                <Pagination
                  count={store.allReviews.pageCount}
                  page={pageIndex}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePagination}
                />
              </Box>
            </Paper>
          </Box>
        </Container>
        <Modal
          disableEnforceFocus={true}
          open={openModal}
          className={classes.modal}
          onClose={handleModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper
            style={{
              width: "720px",
              height: "600px",
              outline: "none",
              overflow: "auto",
            }}
          >
            <Box>
              <GetCustomersTable
                action={getSelectedCustomers}
                actionText="Push Customers"
                disableHrefs={true}
              />
            </Box>
          </Paper>
        </Modal>
      </React.Fragment>
    );
  } else {
    audience.loadContacts(audience.id, pageIndex);
    return <React.Fragment>Loading...</React.Fragment>;
  }
});
