import { Collapse, IconButton } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React, { Component, useState } from "react";
export const BarAlert = ({ icon, text, type }) => {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
      <Alert
        severity={type}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            {icon}
          </IconButton>
        }
      >
        {text}
      </Alert>
    </Collapse>
  );
};
