import { types as t, flow, applySnapshot, getParent } from "mobx-state-tree";
import React, { Component } from "react";
import { PostReply } from "../services/appService";
import { Comment } from "./comment";
import { Typography, Card, CardContent, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
export const Review = t
  .model("review", {
    id: t.optional(t.integer, 0),
    reviewId: t.maybeNull(t.string, ""),
    reviewerName: t.maybeNull(t.string, ""),
    reviewerPhoto: t.maybeNull(t.string, ""),
    starRating: t.optional(t.integer, 0),
    reviewerComment: t.maybeNull(t.string, ""),
    reviewReply: t.maybeNull(t.string, ""),
    createTime: t.optional(t.string, ""),
    integrationType: t.optional(t.integer, 0),
    integrationName: t.optional(t.string, ""),
    ticketId: t.optional(t.integer, 0),
    comments: t.maybeNull(t.array(Comment, [])),
    sentimentAnalysis: t.maybeNull(t.string, ""),
    socialPageId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    postReply: flow(function* (comment) {
      const response = yield PostReply(self.reviewId, comment);
      self.reviewReply = comment;
    }),
    set(key, value) {
      self[key] = value;
    },
    pushComment(comment) {
      self.comments.push({
        id: 0,
        reviewId: 0,
        commentedBy: "You responded",
        createTime: "",
        message: comment,
        parentId: 0,
      });
    },
    getSentiments: function () {
      if (self.sentimentAnalysis) {
        var data = JSON.parse(self.sentimentAnalysis);
        var sentiment;
        var sentimentScore = 0;
        var negative = [];
        var positive = [];
        var neutral = [];

        if (data.sentiment) {
          sentiment = data.sentiment.document.label;
          sentimentScore = data.sentiment.document.score;
        }
        if (data.keywords && data.keywords.length > 0) {
          data.keywords.map((t) => {
            if (t.sentiment.label == "positive") {
              if (t.relevance > 0.7) {
                positive.push(t.text);
              }
            } else if (t.sentiment.label == "negative") {
              if (t.relevance > 0.7) {
                negative.push(t.text);
              }
            } else {
              neutral.push(t.text);
            }
          });
        }
        return (
          <Box>
            {negative.length > 0 ? (
              <Alert severity="error">
                <AlertTitle>Negative Keywords</AlertTitle>
                <Typography>
                  {negative.map((t) => (
                    <span style={{ paddingRight: "15px" }}>
                      <strong>{t}</strong>
                    </span>
                  ))}
                </Typography>
              </Alert>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            {positive.length > 0 ? (
              <Alert severity="success">
                <AlertTitle>Positive Keywords</AlertTitle>
                <Typography>
                  {positive.map((t) => (
                    <span style={{ paddingRight: "15px" }}>
                      <strong>{t}</strong>
                    </span>
                  ))}
                </Typography>
              </Alert>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Box>
        );
      } else {
        return <React.Fragment></React.Fragment>;
      }
    },
  }));
