import React, { Component } from "react";
import {
  Typography,
  Divider,
  Button,
  Box,
  makeStyles,
  Container,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { useMst } from "../../models/store";
import { getIcon } from "../../common/getIcon";
import { StyledHeader } from "../../common/getHeader";
import { observer } from "mobx-react";
const styles = makeStyles((theme) => ({
  transparentButton: {
    color: "#000",
    backgroundColor: "#fff",
  },
  box: {
    textAlign: "center",
    height: "180px",
  },
}));

export const ThirdPartyIntegration = observer(() => {
  const { accountInfo } = useMst();
  const classes = styles();
  const openWindow = (e, uri, type) => {
    //define function to send data back to server
    window.integrationType = type;
    window.integrate = function (code) {
      const type = window.integrationType;
      accountInfo.pushIntegration(code, type);
    };
    window.open(uri, "_blank", "toolbar=0,status=0,width=548,height=325");
  };

  return (
    <React.Fragment>
      <Container>
        <StyledHeader
          heading="Third party integrations"
          subHeading="Connect squidly with all your social media platforms and see their
            daily activity within this dashboard."
        />
        <Divider />
        <Box pt={2}>
          <Grid container direction="row" spacing={2}>
            {accountInfo.integrations.map((t) => (
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <Box className={classes.box}>
                      <Typography gutterBottom>{getIcon(t.id)}</Typography>
                      <Typography component="h2" gutterBottom>
                        {t.name}
                      </Typography>
                      <Typography variant="caption">
                        Connect to your google my business account to manage
                        reviews on google maps
                      </Typography>
                      <Box pt={2}>
                        {t.alreadyIntegrated ? (
                          <Button variant="outlined">Remove</Button>
                        ) : (
                          <Button
                            variant="outlined"
                            onClick={(e) =>
                              openWindow(e, t.authorizationUrl, t.id)
                            }
                          >
                            Connect
                          </Button>
                        )}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
});
