import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Grow,
  makeStyles,
  Paper,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import { PropTypes } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import { formatDateInTimeZone } from "../../common/formatDate";
import { getCustomerActivity } from "../../common/getCustomerActivity";
import { getQueryString } from "../../common/getQueryString";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { GetCustomer, GetCustomerComments } from "../../services/appService";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import { GetNPSEmotion } from "../../common/getNPSEmotion";
import { Pagination } from "@material-ui/lab";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { PagedResult } from "../../models/pagedResult";
import { applySnapshot } from "mobx-state-tree";
import { getLineGraph } from "../../common/getGraph";
import { GetCustomerStats } from "../../services/customerService";
import ReactApexChart from "react-apexcharts";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  c_info: {
    display: "inline-block",
    width: "150px",
  },
  c_info_value: {
    display: "inline-block",
  },
  cardContent: {
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
}));

function StepperIcon(props) {
  return (
    <div style={{ paddingLeft: "4px" }}>
      <div
        style={{
          border: "1px solid #9a9a9a",
          borderRadius: "50%",
          padding: "2px",
        }}
      >
        <div
          style={{
            height: "10px",
            width: "10px",
            backgroundColor: "#9a9a9a",
            borderRadius: "50%",
          }}
        ></div>
      </div>
    </div>
  );
}

export const CustomerView = (props) => {
  const classes = useStyles();
  const [data, setData] = useState({
    customer: null,
    customerComments: null,
    customerCommentsIndex: 1,
    customerCommentsIndexLoaded: 0,
    customerFeedbacks: null,
  });

  const [stats, setStats] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const id = getQueryString(props.location.search, "id");
  const loadCustomerInfo = () => {
    GetCustomer(parseInt(id)).then((t) => {
      if (t.data) {
        const getData = { ...data };
        getData.customer = t.data;
        if (t.data.customerComments) {
          var _pagedResult = PagedResult.create();
          applySnapshot(_pagedResult, t.data.customerComments);
          getData.customerComments = _pagedResult;
        }

        setData(getData);
      }
    });
  };

  useEffect(() => {
    if (!data.customer) {
      loadCustomerInfo();
    }
    if (!stats) {
      loadCustomerStats();
    }
  });

  const loadCustomerStats = async () => {
    GetCustomerStats(id).then((t) => {
      setStats(t.data);
    });
  };

  const handleTabChange = (idx) => {
    setTabIndex(idx);
  };

  const loadMoreComments = async (pageIndex) => {
    if (data.customerCommentsIndexLoaded == pageIndex) {
      var getData = { ...data };
      getData.customerCommentsIndex = pageIndex;
      setData(getData);
      return;
    }
    GetCustomerComments(id, pageIndex, 5).then((t) => {
      var getData = { ...data };
      getData.customerCommentsIndex++;
      getData.customerCommentsIndexLoaded = getData.customerCommentsIndex;
      if (t.data.data.length > 0) {
        t.data.data.map((x) => {
          getData.customerComments.pushData(x);
        });

        setData(getData);
      }
    });
  };

  const loadPreviousComments = () => {
    var getData = { ...data };
    getData.customerCommentsIndex--;
    setData(getData);
  };

  const getComments = () => {
    if (data.customerComments && data.customerComments.data.length) {
      var elements = [];
      for (
        let i =
          data.customerComments.pageSize * (data.customerCommentsIndex - 1);
        i < data.customerComments.data.length;
        i++
      ) {
        elements.push(
          <Grow in={true}>
            <Box>
              <Box pt={2} pb={2}>
                <Grid container>
                  <Grid item xs={1}>
                    <Box textAlign="center">
                      <Box>
                        <GetNPSEmotion
                          score={data.customerComments.data[i].rating}
                        />
                      </Box>
                      <Box>
                        <Typography variant="h3">
                          {data.customerComments.data[i].rating}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={11}>
                    <Box pr={2} pl={2} style={{ height: "45px" }}>
                      {data.customerComments.data[i].comment ??
                        "No comment provided"}
                    </Box>
                    <Box pl={2}>
                      <Typography variant="caption">
                        {formatDateInTimeZone(
                          data.customerComments.data[i].createdOn
                        )}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Box>
          </Grow>
        );
      }
      return elements;
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };

  const getFeedbackStatsGraph = () => {
    var dataPoints = [];
    var xaxis = [];
    if (stats.feedbackStats && stats.feedbackStats.length > 0) {
      stats.feedbackStats.map((t) => {
        dataPoints.push(t.score);
        xaxis.push(t.createdOn);
      });
    }

    return getLineGraph("Feedback", xaxis, dataPoints, 0, 10, 2);
  };

  const getReviewStatsGraph = () => {
    var dataPoints = [];
    var xaxis = [];
    if (stats.reviewStats && stats.reviewStats.length > 0) {
      stats.reviewStats.map((t) => {
        dataPoints.push(t.score);
        xaxis.push(t.createdOn);
      });
    }
    return getLineGraph("Reviews", xaxis, dataPoints, 0, 10, 2);
  };

  if (data.customer) {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Paper variant="outlined">
            <Box p={2}>
              <Grid container justify="center" spacing={2}>
                <Grid item>
                  <Typography variant="h1">{data.customer.name}</Typography>
                </Grid>
              </Grid>
              <Grid container justify="center">
                <Grid item>
                  <Typography>
                    NPS Score: {data.customer.averageNPSScore}/10
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Paper>
          <Box pt={2}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card variant="elevation" elevation={2}>
                  <CardContent>
                    <Typography gutterBottom>
                      <strong>Customer Information</strong>
                    </Typography>
                    <Box pb={1}>
                      <Box className={classes.c_info}>Name</Box>
                      <Box className={classes.c_info_value}>
                        {data.customer.name}
                      </Box>
                    </Box>
                    <Box pb={1}>
                      <Box className={classes.c_info}>Business Name</Box>
                      <Box className={classes.c_info_value}>
                        {data.customer.businessName}
                      </Box>
                    </Box>
                    <Box pb={1}>
                      <Box className={classes.c_info}>Email</Box>
                      <Box className={classes.c_info_value}>
                        {data.customer.email}
                      </Box>
                    </Box>
                    <Box pb={1}>
                      <Box className={classes.c_info}>Phone</Box>
                      <Box className={classes.c_info_value}>
                        {data.customer.phone}
                      </Box>
                    </Box>
                    <Box pb={1}>
                      <Box className={classes.c_info}>Added on</Box>
                      <Box className={classes.c_info_value}>
                        {formatDateInTimeZone(data.customer.createdOn)}
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <Box pt={2}>
                  <Card variant="elevation" elevation={2}>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h3">
                        <strong>Comments</strong>
                      </Typography>
                      <Typography variant="caption">
                        {data.customerComments &&
                          data.customerComments.totalSize}{" "}
                        comments found
                      </Typography>
                      <Box pt={2}>{getComments()}</Box>
                      {data.customerComments &&
                        data.customerComments.pageCount > 1 && (
                          <Box textAlign="right" pt={2} pb={2}>
                            <Button
                              variant="outlined"
                              onClick={loadPreviousComments}
                              disabled={data.customerCommentsIndex == 1}
                            >
                              <ArrowBackIosIcon fontSize="small" />
                            </Button>{" "}
                            <Button
                              variant="outlined"
                              onClick={() =>
                                loadMoreComments(data.customerCommentsIndex + 1)
                              }
                              disabled={
                                data.customerCommentsIndex ==
                                data.customerComments.pageCount
                              }
                            >
                              <ArrowForwardIosIcon fontSize="small" />
                            </Button>
                          </Box>
                        )}
                    </CardContent>
                  </Card>
                </Box>
                <Box pt={2}>
                  <Card variant="elevation" elevation={2}>
                    <CardContent className={classes.cardContent}>
                      {stats && (
                        <React.Fragment>
                          <Typography gutterBottom variant="h3">
                            <strong>Feedback stats over time</strong>
                          </Typography>
                          {getFeedbackStatsGraph()}
                        </React.Fragment>
                      )}
                      {!stats && <LoadingSpinner />}
                    </CardContent>
                  </Card>
                </Box>
                <Box pt={2}>
                  <Card variant="elevation" elevation={2}>
                    <CardContent className={classes.cardContent}>
                      {stats && (
                        <React.Fragment>
                          <Typography gutterBottom variant="h3">
                            <strong>Reviews stats over time</strong>
                          </Typography>
                          {getReviewStatsGraph()}
                        </React.Fragment>
                      )}
                      {!stats && <LoadingSpinner />}
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Card variant="elevation" elevation={2}>
                  <CardContent>
                    <Typography>
                      <strong>Timeline</strong>
                    </Typography>
                    {data.customer &&
                      !data.customer.customerActivities.length && (
                        <Typography>No timeline present</Typography>
                      )}
                    <Stepper orientation="vertical">
                      {data.customer &&
                        data.customer.customerActivities.length > 0 &&
                        data.customer.customerActivities.map((t) => (
                          <Step active={true}>
                            <StepLabel StepIconComponent={StepperIcon}>
                              <strong>
                                {getCustomerActivity(t.activityType).message}
                              </strong>
                            </StepLabel>
                            <StepContent>
                              <Typography variant="caption">
                                {formatDateInTimeZone(t.createdOn)}
                              </Typography>
                            </StepContent>
                          </Step>
                        ))}
                    </Stepper>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <LoadingSpinner />
      </React.Fragment>
    );
  }
};
