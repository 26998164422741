import {
  Box,
  Button,
  Container,
  Grid,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import React, { Component, useState } from "react";
import { StyledHeader } from "../../common/getHeader";
import { AddCustomer } from "../../services/customerService";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
}));
export const CreateCustomer = (props) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    postal: "",
    country: "",
    businessName: "",
    set: function (key, value) {
      this[key] = value;
    },
  });
  const classes = useStyles();
  const saveCustomer = async () => {
    const response = await AddCustomer(formData);
    props.history.push({
      pathname: "/customers",
    });
  };
  return (
    <React.Fragment>
      <Container className={classes.container}>
        <StyledHeader
          heading="Add a customer"
          subHeading="Create a new customer to manage"
          actionButton={
            <Button variant="contained" color="primary" onClick={saveCustomer}>
              Create
            </Button>
          }
        />
        <Paper>
          <Box pt={5} pb={5}>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Customer Name"
                    onChange={(t) => formData.set("name", t.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Business Name"
                    onChange={(t) =>
                      formData.set("businessName", t.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Email Address"
                    onChange={(t) => formData.set("email", t.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="Phone Number"
                    onChange={(t) => formData.set("phone", t.target.value)}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Address"
                        onChange={(t) =>
                          formData.set("address", t.target.value)
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="City"
                        onChange={(t) => formData.set("city", t.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box p={1}>
              <Grid container justify="center">
                <Grid item sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Zip/Postal"
                        onChange={(t) => formData.set("postal", t.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        label="Country"
                        onChange={(t) =>
                          formData.set("country", t.target.value)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
};
