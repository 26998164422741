import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const AppData = t.model("socialPage", {
  id: t.optional(t.integer, 0),
  name: t.optional(t.string, ""),
  accountId: t.optional(t.integer, 0),
  integrationType: t.optional(t.integer, 0),
  email: t.optional(t.string, ""),
  phone: t.optional(t.string, ""),
  address: t.optional(t.string, ""),
  hours: t.optional(t.string, ""),
  website: t.optional(t.string, ""),
});
