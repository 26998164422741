import { types as t, flow } from "mobx-state-tree";
import { isLoggedIn } from "../services/authService";
import Cookies from "universal-cookie";
const User = t
  .model("User", {
    token: t.optional(t.string, ""),
    username: t.optional(t.string, ""),
    displayName: t.optional(t.string, ""),
    isAuthenticated: false,
    error: t.optional(t.string, "")
  })
  .actions(self => ({
    getUserInfo: flow(function*() {
      try {
        const response = yield isLoggedIn(self.getAccessToken());
        self.isAuthenticated = true;
        self.username = response.data["Email"];
        self.displayName = response.data["DisplayName"];
      } catch (error) {}
    }),
    onLoginSuccess(username, token) {
      if (username && token) {
        //add in store
        self.set("username", username);
        self.set("token", token);
        self.set("isAuthenticated", true);
        new Cookies().set("token", token, {
          maxAge: 60 * 60 * 24
        });
      }
    },
    set(key, value) {
      self[key] = value;
    }
  }))
  .views(self => ({
    getAccessToken() {
      let token = self.token;
      if (!token) {
        token = new Cookies().get("token");
        if (!token) {
          window.location = "/login";
          return;
        }
      }
      return token;
    }
  }));
export default User;
