import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { Review } from "./review";
import { LoadReviews, LoadPageOverview } from "../services/appService";
import { PagedResult } from "./pagedResult";
import { PageOverview, Chartjs } from "./pageOverview";
export const Page = t
  .model("Page", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    phone: t.maybeNull(t.string, ""),
    email: t.maybeNull(t.string, ""),
    address: t.maybeNull(t.string, ""),
    website: t.maybeNull(t.string, ""),
    integrationType: t.optional(t.integer, 0),
    postalCode: t.maybeNull(t.string, ""),
    regionCode: t.maybeNull(t.string, ""),
    administrativeArea: t.maybeNull(t.string, ""),
    locality: t.maybeNull(t.string, ""),
    reviews: t.optional(PagedResult, {}),
    pageOverview: t.maybeNull(PageOverview, {}),
    fiveStarRating: t.optional(t.integer, 0),
    fourStarRating: t.optional(t.integer, 0),
    threeStarRating: t.optional(t.integer, 0),
    twoStarRating: t.optional(t.integer, 0),
    oneStarRating: t.optional(t.integer, 0),
    reviewsCount: t.optional(t.integer, 0),
    createTime: t.maybeNull(t.string, ""),
    locationId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    loadReviews: flow(function* (pageIndex) {
      try {
        const response = yield LoadReviews(self.id, pageIndex);
        if (response && response.data) {
          const _pagedResult = PagedResult.create();
          applySnapshot(_pagedResult, response.data);
          self.set("reviews", _pagedResult);
        }
      } catch (error) {
        console.log(error);
      }
    }),
    loadOverview: flow(function* (offset) {
      var offset = -new Date().getTimezoneOffset();
      const response = yield LoadPageOverview(self.id, offset);
      if (response && response.data) {
        const _chart = PageOverview.create();
        applySnapshot(_chart, response.data);
        self.set("pageOverview", _chart);
        console.log(_chart);
      }
    }),
    getRating() {
      var calculatedValue =
        (1 * self.oneStarRating +
          2 * self.twoStarRating +
          3 * self.threeStarRating +
          4 * self.fourStarRating +
          5 * self.fiveStarRating) /
        self.reviewsCount;
      if (!calculatedValue) {
        return 0;
      }
      return Number(calculatedValue.toFixed(1));
    },
    set(key, value) {
      self[key] = value;
    },
  }));
