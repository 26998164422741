import React, { Component } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Divider,
  Grid,
  TextField,
  Button,
  Container,
  Paper,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Backdrop,
  makeStyles,
} from "@material-ui/core";
import { useState } from "react";
import {
  CreateReviewOrFeedbackRequest,
  LoadCustomersCombo,
  PostReviewRequest,
} from "../../services/appService";
import { useMst } from "../../models/store";
import { StyledHeader } from "../../common/getHeader";
import { Link } from "react-router-dom";
import { GetCustomersAutoCompleteCombo } from "../../common/getCustomerAutoCompleteCombo";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import { CampaignType, RequestType } from "../../enums/campaignType";
import { GetTemplatesCombo } from "../../common/getTemplatesCombo";
import { SendType } from "../../enums/sendType";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
}));

export const CreateRequest = (props) => {
  const classes = useStyles();
  const store = useMst();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [smsNumber, setSmsNumber] = useState("");
  const [emailTemplate, setEmailTemplate] = useState(0);
  const [smsTemplate, setSmsTemplate] = useState(0);
  const [sendType, setSendType] = useState(1);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [requestType, setRequestType] = useState(null);
  const handleChange = (e) => {
    var data = { ...selectedCustomer };
    if (e.target.name == "name") {
      data.name = e.target.value;
    } else if (e.target.name == "email") {
      data.email = e.target.value;
    } else if (e.target.name == "phone") {
      data.phone = e.target.value;
    }
    setSelectedCustomer(data);
  };
  const handleSubmit = (e) => {
    const dataModel = {
      name: selectedCustomer.name,
      email: selectedCustomer.email,
      phone: selectedCustomer.phone,
      customerId: selectedCustomer.id,
      requestType: requestType,
      emailTemplateId:
        emailTemplate && emailTemplate > 0
          ? emailTemplate
          : requestType == RequestType.review
          ? store.accountInfo.account.defaultReviewEmailTemplateId
          : store.accountInfo.account.defaultFeedbackEmailTemplateId,
      smsTemplateId:
        smsTemplate && smsTemplate > 0
          ? smsTemplate
          : requestType == RequestType.review
          ? store.accountInfo.account.defaultReviewSmsTemplateId
          : store.accountInfo.account.defaultFeedbackSmsTemplateId,
    };
    try {
      CreateReviewOrFeedbackRequest(dataModel).then((t) => {
        if (t.data) {
          window.location = "/requests";
        } else {
          store.toggleAlert(true, "There is an error executing your request");
        }
      });
    } catch (error) {
      store.toggleAlert(
        true,
        "There is an error while creating a new review request"
      );
    }
  };

  const changeRequestType = (e, value) => {
    setSendType(parseInt(value));
  };

  const getCustomersList = async (value) => {
    var data = await LoadCustomersCombo(value);
    setCustomersList(data.data);
    return data.data;
  };

  const handleRequestType = (type) => {
    setRequestType(type);
  };

  const handleModalClose = () => {
    if (requestType == null) {
      props.history.push({
        pathname: "/requests",
      });
    }
  };

  const handleEmailTemplateChange = (e) => {
    setEmailTemplate(e.target.value);
  };
  const handleSmsTemplateChange = (e) => {
    setSmsTemplate(e.target.value);
  };

  return (
    <React.Fragment>
      <Container style={{ display: !requestType ? "none" : "block" }}>
        <StyledHeader
          heading={`Create a ${
            requestType == RequestType.review ? "review" : "feedback"
          } request`}
          actionButton={
            <Button
              variant="contained"
              color="primary"
              style={{ fontWeight: 500 }}
              onClick={handleSubmit}
            >
              Create New
            </Button>
          }
        />
        <Paper>
          <Box p={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <GetCustomersAutoCompleteCombo
                  options={customersList}
                  handleInputChange={(e) =>
                    getCustomersList(e.currentTarget.value)
                  }
                  handleChange={(t) => {
                    setSelectedCustomer(t);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  component="p"
                  style={{ fontWeight: 600 }}
                >
                  Send{" "}
                  {requestType == RequestType.review ? "review" : "feedback"}{" "}
                  request to customer via:
                </Typography>
                Enter contact information of your customer. We will send out a{" "}
                {requestType == RequestType.review ? "review" : "feedback"}{" "}
                request
              </Grid>
            </Grid>
            <Box pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box hidden={sendType != 1}>
                    <TextField
                      variant="outlined"
                      margin="none"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      onChange={handleChange}
                      value={selectedCustomer.email ?? ""}
                      type="email"
                      autoComplete="off"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    Enter a valid email address to request a{" "}
                    {requestType == RequestType.review ? "review" : "feedback"}.
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Box>
                    <TextField
                      variant="outlined"
                      margin="none"
                      fullWidth
                      id="phone"
                      label="Phone Number"
                      name="phone"
                      onChange={handleChange}
                      value={selectedCustomer.phone ?? ""}
                      type="text"
                      autoComplete="off"
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box>
                    Enter a valid phone number to request a{" "}
                    {requestType == RequestType.review ? "review" : "feedback"}.
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box pt={2}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    style={{ fontWeight: 600 }}
                  >
                    Select a template
                  </Typography>
                  You can also create a new template. See{" "}
                  <Box component={Link} to="/templates">
                    templates
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box pb={2}>
                    <GetTemplatesCombo
                      templateType={requestType}
                      placeholder="Select email template"
                      sendType={SendType.email}
                      value={
                        requestType == CampaignType.review
                          ? store.accountInfo.account
                              .defaultReviewEmailTemplateId
                          : store.accountInfo.account
                              .defaultFeedbackEmailTemplateId
                      }
                      handleChange={handleEmailTemplateChange}
                    />
                  </Box>
                  <GetTemplatesCombo
                    templateType={requestType}
                    placeholder="Select sms template"
                    sendType={SendType.sms}
                    value={
                      requestType == CampaignType.review
                        ? store.accountInfo.account.defaultReviewSmsTemplateId
                        : store.accountInfo.account.defaultFeedbackSmsTemplateId
                    }
                    handleChange={handleSmsTemplateChange}
                  />
                </Grid>
              </Grid>
            </Box>
            <input type="hidden" value="prayer" />
          </Box>
        </Paper>
      </Container>
      <Modal
        disableEnforceFocus
        open={requestType == null}
        className={classes.modal}
        closeAfterTransition
        onClose={handleModalClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Paper style={{ width: "480px" }}>
          <Box p={4} textAlign="center">
            <Typography
              style={{
                fontSize: "18px",
                display: "inline-block",
                verticalAlign: "top",
              }}
            >
              Select a type
            </Typography>
            <Box pt={2}>
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    onClick={() => handleRequestType(2)}
                    style={{ cursor: "pointer" }}
                  >
                    <ChatOutlinedIcon style={{ fontSize: "64px" }} />
                    <Box>Feedback</Box>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box
                    onClick={() => handleRequestType(1)}
                    style={{ cursor: "pointer" }}
                  >
                    <StarBorderOutlinedIcon style={{ fontSize: "64px" }} />
                    <Box>Review</Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </React.Fragment>
  );
};
