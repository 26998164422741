import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const Request = t
  .model("request", {
    id: t.optional(t.integer, 0),
    requestType: t.optional(t.integer, 0),
    customerId: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    createdOn: t.optional(t.string, ""),
    status: t.optional(t.integer, 0),
    campaignId: t.optional(t.integer, 0),
    score: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
