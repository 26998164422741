import Axios from "axios";
import qs from "querystring";
export const loginRequest = async (email, password) => {
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/token/`,
    data: qs.stringify({
      grant_type: "password",
      username: email,
      password: password,
    }),
  });
};

export function isLoggedIn(token) {
  return Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/account/manageinfo/`,
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}

export const register = async (data) => {
  return await Axios({
    method: "post",
    data: data,
    url: `${process.env.REACT_APP_ApiUrl}/api/account/register/`,
  });
};

export const validateEmail = async (email) => {
  return await Axios({
    method: "get",
    params: {
      email: email,
    },
    url: `${process.env.REACT_APP_ApiUrl}/api/account/validateemail/`,
  });
};
