import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import OAuth2 from "./pages/oauth2";
import { ThemeProvider, createMuiTheme } from "@material-ui/core";
import { CampaignRequest } from "./pages/campaignrequest";
import { RateRequest } from "./pages/rateRequest";
const theme = createMuiTheme({
  overrides: {
    MuiFormControlLabel: {
      label: {
        fontSize: "14px",
      },
    },
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: "#f4f6f9",
        },
      },
    },
  },
  typography: {
    subtitle1: {
      lineHeight: 1.2,
    },
    h1: {
      fontSize: "2em",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5em",
      fontWeight: 600,
    },
    h3: {
      fontSize: "1.17em",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1em",
      fontWeight: 600,
    },
    h5: {
      fontSize: "0.83em",
      fontWeight: 600,
    },
    h6: {
      fontSize: "0.67em",
      fontWeight: 400,
    },
    button: {
      textTransform: "none",
    },
    body1: {
      fontSize: "0.9rem"
    },
    overline: {
      fontSize: "0.9rem",
      lineHeight: 1.5,
    },
    fontFamily: [
      'Open Sans', 'sans-serif'
    ].join(','),
  },
});

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/oauth2" component={OAuth2} />
        <Route path="/raterequest" component={RateRequest} />
        <Route
          path="/campaignrequest/:identifier/:rating?"
          component={CampaignRequest}
        />
        <Route path="/" component={App} />
        {/* <App /> */}
      </Switch>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
