import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { getAccountInfo as ga } from "../services/accountService";
import { pushIntegration } from "../services/integrationService";
import { AppData } from "./appData";
import { LoadAppData } from "../services/appService";
import { set } from "mobx";
import { ReviewSite } from "./reviewSite";

export const Integration = t
  .model("integration", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    logo: t.optional(t.string, ""),
    authorizationUrl: t.optional(t.string, ""),
    alreadyIntegrated: false,
    integrationType: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
export const Account = t
  .model("Account", {
    id: t.optional(t.integer, 0),
    emailAddress: t.maybeNull(t.string, ""),
    phoneNumber: t.maybeNull(t.string, ""),
    timeZone: t.maybeNull(t.string, ""),
    firstName: t.maybeNull(t.string, ""),
    lastName: t.maybeNull(t.string, ""),
    moderateBelow: t.maybeNull(t.integer, 0),
    businessName: t.maybeNull(t.string, ""),
    emailConfirmed: true,
    defaultFeedbackEmailTemplateId: t.optional(t.integer, 0),
    defaultFeedbackSmsTemplateId: t.optional(t.integer, 0),
    defaultReviewEmailTemplateId: t.optional(t.integer, 0),
    defaultReviewSmsTemplateId: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      set[key] = value;
    },
  }));

export const AccountInfo = t
  .model("AccountInfo", {
    account: t.maybeNull(Account, {}),
    integrations: t.array(Integration, []),
    availableReviewSites: t.array(ReviewSite, []),
    apps: t.array(AppData, []),
  })
  .actions((self) => ({
    pushIntegration: flow(function* (code, type) {
      const response = yield pushIntegration(code, type);
      if (response.data) {
        self.integrations.map((i) => {
          if (i.integrationType === type) {
            i.set("alreadyIntegrated", true);
          }
        });
      }
    }),
    // loadAppData: flow(function*(integrationType) {
    //   const response = yield LoadAppData(integrationType);
    // }),
    getAccountInfo: flow(function* () {
      const response = yield ga();
      if (response && response.data) {
        response.data.AvailableIntegrations.map((i) => {
          const _integration = Integration.create();
          applySnapshot(_integration, i);
          self.integrations.push(_integration);
        });

        response.data.AvailableReviewSites.map((t) => {
          const _reviewSite = ReviewSite.create();
          applySnapshot(_reviewSite, t);
          self.availableReviewSites.push(_reviewSite);
        });

        const _account = Account.create();
        applySnapshot(_account, response.data.Account);
        self.set("account", _account);
      }
    }),
    getSocialPage(intType) {
      const pages = [];
      if (self.socialPages.length > 0) {
        self.socialPages.map((i) => {
          if (i.integrationType == intType) {
            pages.push(i);
          }
        });
        return pages;
      }
    },
    pushData(data, key) {
      self[key].push(data);
    },
    set(key, value) {
      self[key] = value;
    },
  }));
