import Axios from "axios";
import Cookies from "universal-cookie";
import qs from "querystring";

export const GetCustomers = async () => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  });
};

export const AddCustomer = async (customer) => {
  const accessToken = new Cookies().get("token");
  return await Axios({
    method: "post",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/`,
    headers: {
      Authorization: "Bearer " + accessToken,
    },
    data: customer,
  });
};

export const GetCustomerStats = async (customerId) => {
  return await Axios({
    method: "get",
    url: `${process.env.REACT_APP_ApiUrl}/api/customer/stats/`,
    params: {
      customerId,
    },
  });
};
