import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { shortDate } from "../../common/formatDate";
import { AreaGraph } from "../../common/getGraph";
import { getQueryString, updateQueryString } from "../../common/getQueryString";
import { GetAverageRating } from "../../services/appService";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { SquidilyFilters } from "../../common/getFilters";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  formControl: {
    width: "200px",
    paddingRight: "10px",
  },
  positiveKeywords: {
    borderLeft: "4px solid #00e396",
  },
  negativeKeywords: {
    borderLeft: "4px solid red",
  },
  rating: {
    fontSize: "48px",
    fontWeight: "100",
  },
  ratingCount: {
    fontSize: "14px",
  },
  ratingStar: {
    position: "relative",
    verticalAlign: "text-bottom",
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  cardSubContent: {
    background: "#fafafa",
  },
}));
export const AverageRating = ({ ...props }) => {
  const classes = useStyles();
  const [loadedData, setLoadedData] = useState(null);
  useEffect(() => {
    async function getData() {
      let sourceId = getQueryString(props.location.search, "sourceId");
      let months = getQueryString(props.location.search, "months") ?? 3;
      const s = await GetAverageRating(
        sourceId == "all" || !sourceId ? 0 : sourceId,
        months ?? 3
      );
      setLoadedData(s);
    }
    if (loadedData == null) {
      getData();
    }
  });

  const handleSourceChange = (sourceId) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/overview/average-rating",
      search: updateQueryString(
        "sourceId",
        sourceId == 0 ? "all" : sourceId,
        props.location.search
      ),
    });
  };

  const handlePeriodChange = (days) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/overview/average-rating",
      search: updateQueryString("months", days, props.location.search),
    });
  };

  const getAverageRatingGraph = () => {
    var dataValues = [];
    var dataLabels = [];
    loadedData.data.map((t) => {
      dataValues.push(t.averageRating);
      dataLabels.push(t.statDate);
    });
    debugger;
    return (
      <AreaGraph
        name="Rating"
        data={{
          dataValues: dataValues,
          dataLabels: dataLabels,
        }}
        backgroundColor="#ffc107"
        minima={0}
        maxima={5}
        lineColor="#ffba3e"
      />
    );
  };

  if (!loadedData) {
    return <LoadingSpinner />;
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Box pb={1}>
                      <Typography variant="overline" component="h4">
                        <strong>Reviews</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <SquidilyFilters
                      sourceChange={handleSourceChange}
                      props={props}
                      periodChange={handlePeriodChange}
                      hideHeading={true}
                      defaultPeriod={3}
                    />
                  </Grid>
                </Grid>
              }
            />
            <CardContent
              className={classes.cardContent}
              style={{ paddingBottom: "0" }}
            >
              <Box p={2}>{getAverageRatingGraph()}</Box>
            </CardContent>
          </Card>
        </Container>
      </React.Fragment>
    );
  }
};
