import { makeStyles } from "@material-ui/core";
import React, { Component } from "react";
const useStyles = makeStyles((theme) => ({
  gooey: {
    position: "relative",
    width: "100px",
    height: "40px",
    background: "transparent",
    marginLeft: "auto",
    marginRight: "auto",
  },
  dot: {
    position: "absolute",
    width: "16px",
    height: "16px",
    left: "15px",
    background: "#ef4836",
    borderRadius: "50%",
    transform: "translateX(0)",
    animation: "$dot 2.8s infinite",
  },
  dots: {
    transform: "translateX(0)",
    marginTop: "12px",
    marginLeft: "31px",
    animation: "$dots 2.8s infinite",
  },
  dot2: {
    display: "block",
    float: "left",
    width: "16px",
    height: "16px",
    marginLeft: "16px",
    // filter: "blur(4px)",
    background: "#3498db",
    borderRadius: "50%",
  },
  dot3: {
    display: "block",
    float: "left",
    width: "16px",
    height: "16px",
    marginLeft: "16px",
    // filter: "blur(4px)",
    background: "#f5ab35",
    borderRadius: "50%",
  },
  "@keyframes dot": {
    "50%": {
      transform: "translateX(66px)",
    },
  },

  "@keyframes dots": {
    "50%": {
      transform: "translateX(-31px)",
    },
  },
}));
export const LoadingSpinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.gooey}>
      <span className={classes.dot}></span>
      <div className={classes.dots}>
        <span className={classes.dot2}></span>
        <span className={classes.dot3}></span>
      </div>
    </div>
  );
};
