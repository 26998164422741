import React, { Component } from "react";
export const BeautifyShortLinks = (text) => {
  //get all the links
  var matches = text.match(/\[(.*?)\]/);
  if (matches.length > 0) {
    for (let i = 0; i < matches.length; i++) {
      text = text
        .replace(
          matches[i],
          `<span contenteditable="false" style="background-color: #cacaca; color: #fff; border-radius: 5px; padding: 1px">${matches[i]}</span>`
        )
        .replace("[", "")
        .replace("]", "");
    }
  }

  return text;
};
