import React, { Component } from "react";
import { useMst } from "../models/store";
import {
  Grid,
  Typography,
  Button,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Checkbox,
  InputAdornment,
  TextField,
  Box,
  Modal,
  Backdrop,
  Fade,
  makeStyles,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Container,
  withStyles,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { Link } from "react-router-dom";
import { useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { observer } from "mobx-react";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { GetReviewRequestLogs } from "../services/appService";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { formatDateInTimeZone } from "../common/formatDate";
import { StyledTableHead } from "../common/table";
import { StyledHeader } from "../common/getHeader";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  stepperIconDone: {
    color: "#4caf50",
  },
  stepperIconUnDone: {
    color: "#e9e9e9",
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
}));

export const ReviewRequests = observer(() => {
  const store = useMst();
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const isSelected = (id) => selected.indexOf(id) !== -1;
  const [tableActionButtons, setTableActionButtons] = React.useState(true);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [selectedRowLogs, setSelectedRowLogs] = useState(null);
  const handleAllCheck = (e) => {
    if (e.target.checked) {
      setSelected(store.reviewRequests.data.map((t) => t.id));
      setTableActionButtons(false);
    } else {
      setSelected([]);
      setTableActionButtons(true);
    }
  };
  const [pageIndex, setPageIndex] = useState(
    store.reviewRequests.pageIndex != 0 ? store.reviewRequests.pageIndex : 1
  );
  const handlePagination = (event, value) => {
    setPageIndex(value);
    store.loadAllReviews(value, 50);
  };
  const handleCheck = (event, id) => {
    //show table action buttons
    if (event.target.checked) {
      setTableActionButtons(false);
    } else {
      setTableActionButtons(true);
    }
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const parseStatus = (status) => {
    if (status > 0) {
      return <DoneIcon style={{ color: "#4caf50" }} />;
    } else {
      return <CloseIcon style={{ color: "#f44336" }} />;
    }
  };

  const parseClicked = (value) => {
    if (value) {
      return <DoneIcon style={{ color: "#4caf50" }} />;
    } else {
      return <CloseIcon style={{ color: "#f44336" }} />;
    }
  };

  const handleRowClick = async (e, req) => {
    try {
      const response = await GetReviewRequestLogs(req.id);
      setSelectedRow(req);
      setSelectedRowLogs(response.data);
      setOpenModal(true);
    } catch (error) {
      store.toggleAlert(true, "Unable to fetch logs for the requested review.");
    }
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  if (store.reviewRequests.data) {
    return (
      <React.Fragment>
        <Container>
          <StyledHeader
            heading="Review Requests"
            subHeading="100 Records found."
            actionButton={
              <Button
                variant="contained"
                color="primary"
                style={{ fontWeight: 500 }}
                to="/reviews/request/create"
                component={Link}
              >
                <AddIcon /> ADD NEW
              </Button>
            }
          />
          <Typography component="p" variant="caption" gutterBottom></Typography>
          <Paper variant="outlined">
            <Box p={2}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
              >
                <Grid item hidden={tableActionButtons}>
                  <Button variant="outlined">Delete</Button>
                </Grid>
                <Grid item hidden={!tableActionButtons}>
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControl className={classes.formControl}>
                    <Select
                      displayEmpty
                      className={classes.selectEmpty}
                      variant="outlined"
                    >
                      <em>Sort By</em>
                      <MenuItem>Closed</MenuItem>
                      <MenuItem>Latest</MenuItem>
                      <MenuItem>Newest</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <TableContainer component={Paper} elevation={0}>
              <Table aria-label="Table">
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox onChange={handleAllCheck} />
                    </TableCell>
                    <StyledTableHead>Name</StyledTableHead>
                    <StyledTableHead>Email Address</StyledTableHead>
                    <StyledTableHead>Sms Number</StyledTableHead>
                    <StyledTableHead>Reviewed?</StyledTableHead>
                    <StyledTableHead>Clicked</StyledTableHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {store.reviewRequests.data.map((x) => (
                    <TableRow
                      hover
                      selected={isSelected(x.id)}
                      onClick={(e) => handleRowClick(e, x)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onChange={(e) => handleCheck(e, x.id)}
                          checked={isSelected(x.id)}
                        />
                      </TableCell>
                      <TableCell>{x.name}</TableCell>
                      <TableCell>{x.emailAddress}</TableCell>
                      <TableCell>{x.smsNumber}</TableCell>
                      <TableCell>{parseStatus(x.status)}</TableCell>
                      <TableCell>{parseClicked(x.clicked)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={100}
          /> */}
            </TableContainer>
            <Box p={2}>
              <Pagination
                count={store.allReviews.pageCount}
                page={pageIndex}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </Box>
          </Paper>
          <Modal
            disableEnforceFocus
            open={openModal}
            className={classes.modal}
            onClose={handleModalClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openModal}>
              <Card>
                <CardContent>
                  <Stepper orientation="vertical">
                    <Step active={true}>
                      <StepLabel
                        StepIconProps={{
                          classes: { root: classes.stepperIconDone },
                        }}
                        StepIconComponent={CheckCircleIcon}
                      >
                        Review request
                      </StepLabel>
                      <StepContent>
                        Created at{" "}
                        {selectedRow &&
                          selectedRow.createdOn &&
                          formatDateInTimeZone(selectedRow.createdOn)}
                      </StepContent>
                    </Step>
                    <Step active={true}>
                      <StepLabel
                        StepIconComponent={CheckCircleIcon}
                        style={{
                          color:
                            selectedRowLogs &&
                            selectedRowLogs.smsLogs.length > 1
                              ? "#4caf50"
                              : "#e9e9e9",
                        }}
                      >
                        Notify via SMS
                      </StepLabel>
                      {selectedRowLogs &&
                        selectedRowLogs.smsLogs.map((t) => (
                          <StepContent>
                            {t.smsStatus} at {formatDateInTimeZone(t.createdOn)}
                          </StepContent>
                        ))}
                    </Step>
                    <Step active={true}>
                      <StepLabel
                        StepIconComponent={CheckCircleIcon}
                        style={{
                          color:
                            selectedRow &&
                            selectedRow.emailSentTimeStamp != null
                              ? "#4caf50"
                              : "#e9e9e9",
                        }}
                      >
                        Notify via Email
                      </StepLabel>
                      <StepContent>
                        {selectedRow &&
                          selectedRow.emailSentTimeStamp != null && (
                            <span>
                              Sent at{" "}
                              {formatDateInTimeZone(
                                selectedRow.emailSentTimeStamp
                              )}
                            </span>
                          )}
                      </StepContent>
                    </Step>
                  </Stepper>
                </CardContent>
              </Card>
            </Fade>
          </Modal>
        </Container>
      </React.Fragment>
    );
  } else {
    store.loadReviewRequests(pageIndex, 50);
    return <React.Fragment>Loading...</React.Fragment>;
  }
});
