import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  makeStyles,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Fade,
} from "@material-ui/core";
import React, { Component, useEffect } from "react";
import { useMst } from "../../models/store";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import { useState } from "react";
import { getIcon } from "../../common/getIcon";
import { GetLocationApps, SaveReviewSite } from "../../services/appService";
import { getQueryStringFromUrl } from "../../common/getQueryString";
import {
  initApp,
  pushIntegration,
  saveSelectedPages,
} from "../../services/integrationService";
import CheckIcon from "@material-ui/icons/Check";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cardContent: {
    padding: 0,
    paddingBottom: 0,
  },
  cardSubContent: {
    background: "#fafafa",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
}));
var controlPages = [];
export const View = () => {
  const classes = useStyles();
  const store = useMst();
  const [openAppsModal, setOpenAppsModal] = useState(false);
  const [appsModalStage, setAppsModalStage] = useState(0);
  const [siteModal, setSiteModal] = useState({
    open: false,
    selectedReviewSite: null,
  });
  const [textField, setTextField] = useState(null);
  const closeAppsModal = () => {
    setOpenAppsModal(false);
  };
  const [includedPages, setIncludedPages] = useState([]);
  const handleSiteModal = () => {
    var getModalObj = { ...siteModal };
    getModalObj.open = true;
    setSiteModal(getModalObj);
  };

  const closeSitesModal = () => {
    var getModalObj = { ...siteModal };
    getModalObj.open = false;
    getModalObj.selectedReviewSite = null;
    setSiteModal(getModalObj);
  };

  const [loadedData, setLoadedData] = useState(null);
  const locationId = getQueryStringFromUrl(document.URL, "id");
  useEffect(() => {
    if (!loadedData || loadedData.id != locationId) {
      loadData();
    }
  });

  const loadData = () => {
    debugger;
    GetLocationApps(locationId).then((t) => {
      debugger;
      setLoadedData(t.data);
    });
  };

  const openWindow = (e, uri, type) => {
    //define function to send data back to server
    window.integrationType = type;
    document.getElementById(`loading_${type}`).style.display = "block";
    window.integrate = function (code) {
      debugger;
      const type = window.integrationType;
      pushIntegration(locationId, code, type)
        .then((t) => {
          if (t.data) {
            document.getElementById(`loading_${type}`).style.display = "none";
            document.getElementById(`loaded_${type}`).style.display = "block";
          } else {
            store.alertMessage(
              true,
              "There is some problem connecting your app."
            );
          }
        })
        .then((t) => {
          //check if there is any pre-requisites for connection
          return initApp(type, locationId);
        })
        .then((t) => {
          debugger;
          if (t.data.responseType == 1) {
            controlPages = t.data.data;
            setAppsModalStage(1);
          }
        })
        .finally(() => {
          loadData();
        });
    };
    window.open(uri, "_blank", "toolbar=0,status=0,width=700,height=700");
  };

  const getConnectedApps = () => {
    debugger;
    var appsArray = [];
    var gridChildren = [];
    if (loadedData) {
      if (loadedData.connectedApps.length > 0) {
        store.accountInfo.integrations.map((t) => {
          loadedData.connectedApps.map((u) => {
            if (t.id == u.integrationId) {
              gridChildren.push(
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Card variant="outlined" style={{ height: "160px" }}>
                      <CardContent>
                        <Box pt={2} pb={1}>
                          {getIcon(t.id)}
                        </Box>
                        <Typography variant="h4">{t.name}</Typography>
                        <Box pt={1}>
                          <Button variant="outlined">Disconnect</Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              );
            }
          });
        });
      } else {
        gridChildren.push(
          <Grid item xs={3}>
            <Box textAlign="center" onClick={() => setOpenAppsModal(true)}>
              <Card
                variant="outlined"
                style={{
                  height: "160px",
                  cursor: "pointer",
                  border: "2px dashed #d0d0d0",
                }}
              >
                <CardContent>
                  <Box pt={2} pb={1}>
                    <AddCircleOutlineIcon style={{ fontSize: "36px" }} />
                  </Box>
                  <Typography variant="h3">Connect an app</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        );
      }
    }

    appsArray.push(
      <Box p={2}>
        <Grid container spacing={2}>
          {gridChildren}
        </Grid>
      </Box>
    );
    return appsArray;
  };

  const selectReviewSite = (t) => {
    var getObj = { ...siteModal };
    getObj.selectedReviewSite = t;
    setSiteModal(getObj);
  };

  const handleSiteSave = (typeId, locationId) => {
    SaveReviewSite(textField, typeId, locationId).then((t) => {
      loadedData.pageLinks.push(t.data);
      closeSitesModal();
    });
  };

  const getConnectedSites = () => {
    var appsArray = [];
    var gridChildren = [];
    if (loadedData) {
      if (loadedData.pageLinks.length > 0) {
        store.accountInfo.availableReviewSites.map((t) => {
          loadedData.pageLinks.map((u) => {
            if (t.id == u.integrationType) {
              gridChildren.push(
                <Grid item xs={3}>
                  <Box textAlign="center">
                    <Card variant="outlined" style={{ height: "160px" }}>
                      <CardContent>
                        <Box pt={2} pb={1}>
                          <img src={u.iconLink} width="25" height="25" />
                        </Box>
                        <Typography variant="h4">{u.title}</Typography>
                        <Box pt={1}>
                          <Button variant="outlined">Disconnect</Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Grid>
              );
            }
          });
        });
      } else {
        gridChildren.push(
          <Grid item xs={3}>
            <Box textAlign="center" onClick={handleSiteModal}>
              <Card
                variant="outlined"
                style={{
                  height: "160px",
                  cursor: "pointer",
                  border: "2px dashed #d0d0d0",
                }}
              >
                <CardContent>
                  <Box pt={2} pb={1}>
                    <AddCircleOutlineIcon style={{ fontSize: "36px" }} />
                  </Box>
                  <Typography variant="h3">Add site</Typography>
                </CardContent>
              </Card>
            </Box>
          </Grid>
        );
      }
    }

    appsArray.push(
      <Box p={2}>
        <Grid container spacing={2}>
          {gridChildren}
        </Grid>
      </Box>
    );
    return appsArray;
  };

  const includePage = (e) => {
    var includedPagesList = [];
    if (e.target.checked) {
      includedPagesList = [...includedPages];
      includedPagesList.push(e.target.id);
    } else {
      for (let i = 0; i < includedPages.length; i++) {
        if (includedPages[i] != e.target.id) {
          includedPagesList.push(includedPages[i]);
        }
      }
    }
    setIncludedPages(includedPagesList);
  };

  const onClose = () => {
    closeAppsModal();
    setIncludedPages([]);
    setAppsModalStage(0);
  };
  const saveIncludedPages = () => {
    saveSelectedPages(locationId, window.integrationType, includedPages);
    onClose();
  };

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Box pb={2}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Box pb={1}>
                      <Typography variant="overline" component="h4">
                        <strong>Connected Apps</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => setOpenAppsModal(true)}
                    >
                      Connect App
                    </Button>
                  </Grid>
                </Grid>
              }
            />
            <CardContent
              className={classes.cardContent}
              style={{ paddingBottom: "0" }}
            >
              <Box className={classes.cardSubContent}>{getConnectedApps()}</Box>
            </CardContent>
          </Card>
        </Box>
        <Box pb={2}>
          <Card variant="outlined">
            <CardHeader
              title={
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-end"
                >
                  <Grid item>
                    <Box pb={1}>
                      <Typography variant="overline" component="h4">
                        <strong>Review Sites</strong>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={handleSiteModal}>
                      Add Site
                    </Button>
                  </Grid>
                </Grid>
              }
            />
            <CardContent
              className={classes.cardContent}
              style={{ paddingBottom: "0" }}
            >
              <Box className={classes.cardSubContent}>
                {getConnectedSites()}
              </Box>
            </CardContent>
          </Card>
        </Box>
        <Modal
          disableEnforceFocus={true}
          open={openAppsModal}
          closeAfterTransition
          onClose={closeAppsModal}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper style={{ width: "500px", height: "546px", outline: 0 }}>
            {appsModalStage == 0 && (
              <Box p={2}>
                <Box style={{ float: "right" }} p={1}>
                  <IconButton onClick={closeAppsModal}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box pt={1} pb={1}>
                  <Typography variant="h2">Available Apps</Typography>
                  <Typography variant="caption">
                    Connect squidily with your social accounts.
                  </Typography>
                </Box>
                <Box
                  style={{ height: "350px", overflow: "auto", width: "100%" }}
                  pt={2}
                  pb={2}
                  pl={1}
                  pr={1}
                >
                  <TableContainer component={Paper} elevation={0}>
                    <Table className={classes.table} aria-label="simple table">
                      <TableBody>
                        {store.accountInfo.integrations &&
                          store.accountInfo.integrations.map((t) => {
                            if (
                              loadedData &&
                              loadedData.connectedApps.filter(
                                (c) => c.integrationId == t.id
                              ).length == 0
                            ) {
                              return (
                                <Box
                                  onClick={(e) =>
                                    openWindow(e, t.authorizationUrl, t.id)
                                  }
                                  style={{ textDecoration: "none" }}
                                >
                                  <TableRow
                                    hover
                                    key={t.id}
                                    style={{ display: "table", width: "100%" }}
                                  >
                                    <TableCell style={{ width: "50px" }}>
                                      <Typography component="p">
                                        {getIcon(t.id)}
                                      </Typography>
                                    </TableCell>
                                    <TableCell>{t.name}</TableCell>
                                    <TableCell>
                                      <CircularProgress
                                        size={20}
                                        id={`loading_${t.id}`}
                                        style={{ display: "none" }}
                                      />
                                      <CheckIcon
                                        style={{ display: "none" }}
                                        id={`loaded_${t.id}`}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </Box>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            )}
            {appsModalStage == 1 && (
              <Fade in={appsModalStage == 1}>
                <Box p={2}>
                  <Box style={{ float: "right" }} p={1}>
                    <IconButton onClick={closeAppsModal}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box pt={1} pb={1}>
                    <Typography variant="h2">Confirm settings</Typography>
                    <Typography variant="caption">
                      Squidily will be connected to:
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      height: "414px",
                      borderBottom: "1px solid #e9e9e9",
                      overflow: "auto",
                      width: "100%",
                    }}
                    pt={2}
                    pb={2}
                    pl={1}
                    pr={1}
                  >
                    <List className={classes.scrollBar} button>
                      {controlPages.length > 0 &&
                        controlPages.map((t) => (
                          <ListItem key={t.id}>
                            <ListItemIcon>
                              <Checkbox
                                id={t.id}
                                checked={includedPages.indexOf(t.id) > -1}
                                onChange={includePage}
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={<Typography>{t.name}</Typography>}
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Box>
                  <Box textAlign="right" p={1}>
                    <Box mr={1} display="inline">
                      <Button variant="outlined" onClick={onClose}>
                        Cancel
                      </Button>
                    </Box>
                    <Box mr={1} display="inline">
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={saveIncludedPages}
                      >
                        Finish
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Fade>
            )}
          </Paper>
        </Modal>
        <Modal
          disableEnforceFocus={true}
          open={siteModal.open}
          closeAfterTransition
          onClose={closeSitesModal}
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Paper
            style={{
              width: "500px",
              height: "546px",
              outline: 0,
              overflow: "auto",
            }}
          >
            <Box p={2}>
              <Box style={{ float: "right" }} p={1}>
                <IconButton onClick={closeSitesModal}>
                  <CloseIcon />
                </IconButton>
              </Box>
              {!siteModal.selectedReviewSite && (
                <React.Fragment>
                  <Box pt={1} pb={1}>
                    <Typography variant="h2">Available Review Sites</Typography>
                    <Typography variant="caption">
                      Select a review site for sending review invites
                    </Typography>
                  </Box>
                  <Box
                    style={{ height: "350px", overflow: "auto", width: "100%" }}
                    pt={2}
                    pb={2}
                    pl={1}
                    pr={1}
                  >
                    <TableContainer component={Paper} elevation={0}>
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableBody>
                          {store.accountInfo.availableReviewSites &&
                            store.accountInfo.availableReviewSites.map((t) => {
                              if (
                                loadedData &&
                                loadedData.pageLinks.filter(
                                  (c) => c.integrationType == t.id
                                ).length == 0
                              ) {
                                return (
                                  <Box
                                    onClick={(e) => selectReviewSite(t)}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <TableRow
                                      hover
                                      key={t.id}
                                      style={{
                                        display: "table",
                                        width: "100%",
                                      }}
                                    >
                                      <TableCell style={{ width: "50px" }}>
                                        <Typography component="p">
                                          <img
                                            src={t.icon}
                                            height={24}
                                            width={24}
                                          />
                                        </Typography>
                                      </TableCell>
                                      <TableCell>{t.title}</TableCell>
                                      <TableCell align="right">
                                        <CircularProgress
                                          size={20}
                                          id={`rev_loading_${t.id}`}
                                          style={{ display: "none" }}
                                        />
                                        <CheckIcon
                                          style={{ display: "none" }}
                                          id={`rev_loaded_${t.id}`}
                                        />
                                        <ArrowForwardIosIcon
                                          style={{
                                            height: "16px",
                                            width: "16px",
                                            position: "relative",
                                            verticalAlign: "middle",
                                          }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  </Box>
                                );
                              }
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </React.Fragment>
              )}
              {siteModal.selectedReviewSite && (
                <React.Fragment>
                  <Box pt={4} pb={4} pl={1} pr={1}>
                    <Typography variant="h2" gutterBottom>
                      {siteModal.selectedReviewSite.title}
                    </Typography>
                    <Typography variant="p">
                      {siteModal.selectedReviewSite.description}
                    </Typography>
                    <Box pt={2}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        placeholder={siteModal.selectedReviewSite.placeholder}
                        onChange={(t) => setTextField(t.target.value)}
                      />
                    </Box>
                    <Box pt={4}>
                      <Typography variant="p">
                        {siteModal.selectedReviewSite.tipText}
                      </Typography>
                      <Box pt={2}>
                        <a
                          href={siteModal.selectedReviewSite.tipScreenshot}
                          target="_blank"
                        >
                          <img
                            src={siteModal.selectedReviewSite.tipScreenshot}
                            width="100%"
                          />
                        </a>
                      </Box>
                    </Box>
                    <Box pt={2}>
                      <Button
                        variant="contained"
                        fullWidth
                        color="primary"
                        onClick={() =>
                          handleSiteSave(
                            siteModal.selectedReviewSite.id,
                            parseInt(locationId)
                          )
                        }
                      >
                        Save
                      </Button>
                    </Box>
                  </Box>
                </React.Fragment>
              )}
            </Box>
          </Paper>
        </Modal>
      </Container>
    </React.Fragment>
  );
};
