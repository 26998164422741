import React, { Component, useContext, useState } from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { Checkbox, Card, CardContent, Box, Grid } from "@material-ui/core";
import { loginRequest } from "../services/authService";
import { useMst } from "../models/store";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { test } from "../services/accountService";

const Login = observer((props) => {
  const store = useMst();
  const [data, setData] = useState({
    username: "",
    password: "",
    error: "",
  });
  const handleChange = (e) => {
    const getData = { ...data };
    data[e.currentTarget.name] = e.currentTarget.value;
    setData(data);
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    try {
      const getData = { ...data };
      const username = data.username;
      const password = data.password;
      await loginRequest(username, password)
        .then((args) => {
          store.user.set("displayName", args.data["displayName"]);
          store.user.onLoginSuccess(username, args.data["access_token"]);
        })
        .then(async () => {
          debugger;
          await store.accountInfo.getAccountInfo();
          store.set("isAuthenticated", true);
        })
        .then(() => {
          props.history.push("/");
        });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        store.user.set(
          "error",
          "Username or password is incorrect. Please try again!"
        );
      } else {
        console.log(ex);
      }
    }
  };

  return (
    <Grid container>
      <Grid
        item
        xs={6}
        style={{ backgroundColor: "#e9e9e9", height: "100vh" }}
      ></Grid>
      <Grid item xs={6}>
        <Box p={6}>
          <Card elevation={0}>
            <CardContent>
              <Typography component="h1" variant="h2">
                Welcome back
              </Typography>
              <Typography component="h2" variant="caption">
                A place where you keep all your customers happy.
              </Typography>
              <Box pt={2}>
                <form>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="username"
                    autoComplete="email"
                    onChange={handleChange}
                    autoFocus
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    onChange={handleChange}
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember Me"
                  />
                  <Typography variant="body1" color="error">
                    {store.user.error}
                  </Typography>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="submit"
                    onClick={handleSubmit}
                  >
                    Sign In
                  </Button>
                </form>
              </Box>
              <Box
                component={Link}
                to="/register"
                style={{ textDecoration: "none" }}
              >
                <Typography variant="caption">
                  Don't have account? Sign Up Today.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
});
export default Login;
