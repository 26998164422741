import { PagedResult, anyModel } from "./pagedResult";
import { types as t, flow, applySnapshot } from "mobx-state-tree";
import {
  GetAudienceContacts,
  PostAudienceContacts,
} from "../services/appService";
import { AudienceContact } from "./audienceContact";

const r = t
  .model("pagedResult", {
    data: t.maybeNull(t.array(t.maybe(AudienceContact)), []),
    pageIndex: t.optional(t.integer, 0),
    pageSize: t.optional(t.integer, 0),
    totalSize: t.optional(t.integer, 0),
    pageCount: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    pushData: function (record) {
      self.data.unshift(record);
    },
    pop(index) {
      self.data = self.data.splice(index, 1);
    },
    set(key, value) {
      self[key] = value;
    },
  }));

export const Audience = t
  .model("audience", {
    id: t.optional(t.integer, 0),
    name: t.optional(t.string, ""),
    totalContacts: t.optional(t.integer, 0),
    modifiedOn: t.optional(t.string, ""),
    audienceContacts: t.maybeNull(r, null),
  })
  .actions((self) => ({
    loadContacts: flow(function* (id, pageIndex, pageSize) {
      const response = yield GetAudienceContacts(id, pageIndex, pageSize);
      const _pagedResult = r.create();
      applySnapshot(_pagedResult, response.data);
      self.set("audienceContacts", _pagedResult);
    }),
    saveAudience: flow(function* () {
      const response = yield PostAudienceContacts(
        self.id,
        self.audienceContacts.data
      );
      yield self.loadContacts(self.id, 1);
      self.totalContacts = self.audienceContacts.data.length;
    }),
    set(key, value) {
      self[key] = value;
    },
  }));
