import React, { Component } from "react";
import { useMst } from "../models/store";
import SettingsIcon from "@material-ui/icons/Settings";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  Box,
  Breadcrumbs,
  TextField,
  Container,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { StyledBreadcrumb } from "../common/breadcrumbs";
import { StyledCardHeader } from "../common/styledCardHeader";

const useStyles = makeStyles((theme) => ({
  grid: {
    textDecoration: "none",
  },
  card: {
    margin: "10px",
    border: "1px solid #e9e9e9",
  },
  cardTitle: {
    paddingLeft: "10px",
  },
  cardHeader: {
    fontWeight: "bold",
  },
}));

export const Settings = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container>
        <Typography color="textPrimary" component="h1" variant="h2">
          Account settings
        </Typography>
      </Grid>
      <Box component="div" pt={2}>
        <Grid container>
          <Grid
            item
            xs={4}
            component={Link}
            to="/settings/basic"
            className={classes.grid}
          >
            <Card elevation={0} className={classes.card}>
              <CardContent>
                <Grid container>
                  <Grid item xs={2}>
                    <SettingsIcon fontSize="large" />
                  </Grid>
                  <Grid item className={classes.cardTitle} xs={10}>
                    <Typography component="h2" variant="subtitle1">
                      Basic Settings
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Manage your account, change your password
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Card elevation={0} className={classes.card}>
              <CardContent>
                <Grid container>
                  <Grid item xs={2}>
                    <SettingsIcon fontSize="large" />
                  </Grid>
                  <Grid item className={classes.cardTitle} xs={10}>
                    <Typography
                      component="h2"
                      variant="subtitle1"
                      className={classes.cardHeader}
                    >
                      Reviews Settings
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Add or update where you want people to review, update
                      rules
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={4} className={classes.grid}>
            <Card elevation={0} className={classes.card}>
              <CardContent>
                <Grid container>
                  <Grid item xs={2}>
                    <ReceiptIcon fontSize="large" />
                  </Grid>
                  <Grid item className={classes.cardTitle} xs={10}>
                    <Typography
                      component="h2"
                      variant="subtitle1"
                      className={classes.cardHeader}
                    >
                      Billing & Subscription
                    </Typography>
                    <Typography component="p" variant="subtitle2">
                      Manage your billing information, change your subscription
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export const BasicSettings = () => {
  const { accountInfo } = useMst();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container>
        <StyledBreadcrumb label="Settings" component={Link} link="/settings" />
        <Box component="div" pt={2}>
          <Grid container>
            <Typography color="textPrimary" component="h1" variant="h2">
              Basic settings
            </Typography>
          </Grid>
        </Box>
        <Box component="div" pt={4}>
          <Grid container>
            <Grid item xs={4}>
              <Typography component="h2" variant="subtitle1">
                <strong>Personal Information</strong>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Keep your personal information up to date.
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Card elevation={1}>
                <StyledCardHeader text="Edit your personal info" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="firstName"
                        label="First Name"
                        defaultValue={accountInfo.account.firstName}
                        name="firstName"
                        helperText="Please provide your correct name"
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        defaultValue={accountInfo.account.lastName}
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="emailAddress"
                        label="Email Address"
                        name="emailAddress"
                        defaultValue={accountInfo.account.emailAddress}
                        helperText="Please make the email address you added is correct. This email is the point of contact for us"
                        // onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <Grid container>
            <Grid item xs={4}>
              <Typography component="h2" variant="subtitle1">
                <strong>Password</strong>
              </Typography>
              <Typography component="p" variant="subtitle2">
                Change the password you use to log into Squidly
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Card elevation={1}>
                <StyledCardHeader text="Update your password" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="currentPassword"
                        label="Current Password"
                        name="currentPassword"
                        helperText="Please provide your valid current password"
                        type="password"
                        // onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        name="newPassword"
                        type="password"
                        // onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
};
