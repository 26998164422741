import {
  Box,
  Button,
  Chip,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import { observer } from "mobx-react";
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetAverageRating } from "../../common/getAverageRating";
import { BarGraph } from "../../common/getGraph";
import { StyledHeader } from "../../common/getHeader";
import { getIcon } from "../../common/getIcon";
import { getIntegrationTypeById } from "../../common/getIntegrationTypeById";
import {
  getQueryStringFromUrl,
  updateQueryString,
} from "../../common/getQueryString";
import { SmallSelectMenu } from "../../common/select";
import { useMst } from "../../models/store";
import {
  GetReviewsStatsByLocation,
  GetReviewsStatsBySource,
  GetReviewsStatsByTime,
} from "../../services/appService";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  combo: {
    margin: 0,
    width: "200px",
  },
}));

export const ReviewsReport = observer(({ props }) => {
  const classes = useStyles();
  const store = useMst();
  const [tabIndex, setTabIndex] = useState(0);
  const [reviewsByTime, setReviewsByTime] = useState(null);
  const [reviewsByLocation, setReviewsByLocation] = useState(null);
  const [reviewsBySource, setReviewsBySource] = useState(null);
  const loadReviewsByTime = async () => {
    var response = await GetReviewsStatsByTime(
      getQueryStringFromUrl(document.URL, "months") || 3,
      getQueryStringFromUrl(document.URL, "sourceId") || 0
    );
    setReviewsByTime(response.data);
  };
  const loadReviewsByLocation = async () => {
    var response = await GetReviewsStatsByLocation();
    setReviewsByLocation(response.data);
  };

  const loadReviewsBySource = async () => {
    var response = await GetReviewsStatsBySource(
      getQueryStringFromUrl(document.URL, "months") || 3
    );
    setReviewsBySource(response.data);
  };

  useEffect(() => {
    loadData();
  });

  const loadData = async (forceLoad) => {
    if (tabIndex == 0 && (!reviewsByTime || forceLoad)) {
      loadReviewsByTime();
    } else if (tabIndex == 1 && (!reviewsByLocation || forceLoad)) {
      loadReviewsByLocation();
    } else if (tabIndex == 2 && (!reviewsBySource || forceLoad)) {
      loadReviewsBySource();
    }
  };

  const getPagesForCombo = () => {
    var comboOptions = [];
    if (store.pages.length > 0) {
      store.pages.map((t) => {
        comboOptions.push(
          <option aria-label={t.name} value={t.id}>
            {t.name}
          </option>
        );
      });
    } else {
    }

    return comboOptions;
  };

  const getStatsBySource = () => {
    var htmlElements = [];
    if (reviewsBySource && reviewsBySource.length > 0) {
      reviewsBySource.map((t) => {
        htmlElements.push(
          <Box pb={1}>
            <Grid container>
              <Grid item xs={3}>
                {getIcon(
                  t.integrationType,
                  getIntegrationTypeById(t.integrationType),
                  null,
                  null,
                  true
                )}{" "}
              </Grid>
              <Grid item xs={9}>
                <Chip
                  icon={<Rating value={1} max={1} />}
                  label={GetAverageRating(
                    [
                      t.fiveStars,
                      t.fourStars,
                      t.threeStars,
                      t.twoStars,
                      t.oneStars,
                    ],
                    [
                      {
                        total:
                          t.fiveStars +
                          t.fourStars +
                          t.threeStars +
                          t.twoStars +
                          t.oneStars,
                      },
                    ]
                  )}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Box>
        );
      });
    } else {
      htmlElements.push(
        <React.Fragment>Not enough data available</React.Fragment>
      );
    }

    return htmlElements;
  };
  const getStatsByLocation = () => {
    var stats = [];
    var labels = [];
    reviewsByLocation.map((t) => {
      stats.push(t.reviewsCount);
      labels.push(t.locationName);
    });

    if (stats.length < 10) {
      for (let i = stats.length; i < 10; i++) {
        stats.push(0);
        labels.push("");
      }
    }
    var data = {
      series: [
        {
          name: "Reviews",
          data: stats,
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: labels,
        },
        yaxis: {
          decimalsInFloat: 0,
        },
      },
    };

    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="bar"
        height={350}
      />
    );
  };

  const getStatByTime = () => {
    var reviewsCount = [];
    var labels = [];
    var rating = [];
    reviewsByTime.map((t) => {
      reviewsCount.push(t.reviewsCount);
      labels.push(t.statDate);
      rating.push(t.averageRating);
    });
    var data = {
      series: [
        {
          name: "Reviews",
          type: "column",
          data: reviewsCount,
        },
        {
          name: "Average Rating",
          type: "area",
          data: rating,
        },
      ],
      options: {
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: "smooth",
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: labels,
        markers: {
          size: 0,
        },
        xaxis: {
          type: "datetime",
          labels: {
            // formatter: function (value, timestamp, opts) {
            //   return opts.dateFormatter(new Date(timestamp)).format("MMM yy");
            // },
          },
        },
        yaxis: [
          {
            title: {
              text: "No of reviews",
            },
            decimalsInFloat: 0,
          },
          {
            opposite: true,
            title: {
              text: "Average Rating",
            },
            min: 0,
            max: 5,
            decimalsInFloat: 2,
          },
        ],
        tooltip: {
          shared: true,
          intersect: false,
        },
      },
    };

    return (
      <ReactApexChart
        options={data.options}
        series={data.series}
        type="line"
        height={350}
      />
    );
  };

  const handlePeriod = (e) => {
    props.history.push({
      pathname: "/reports",
      search: updateQueryString(
        "months",
        parseInt(e.target.value) ?? 2,
        props.location.search
      ),
    });
    loadData(true);
  };
  const handleTabChange = (e) => {
    setTabIndex(parseInt(e.target.value));
  };
  const handleSource = (e) => {
    props.history.push({
      pathname: "/reports",
      search: updateQueryString(
        "sourceId",
        parseInt(e.target.value) ?? 0,
        props.location.search
      ),
    });
    loadData(true);
  };

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <StyledHeader
          heading="Reviews"
          subHeading="See reports related to reviews by source, location or time"
        />
        <Paper variant="outlined">
          <Box p={2}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item>
                <FormControl className={classes.combo}>
                  <Select
                    native
                    defaultValue={0}
                    input={<SmallSelectMenu />}
                    onChange={handleTabChange}
                  >
                    <option aria-label="By Time" value={0}>
                      By Time
                    </option>
                    <option aria-label="By Location" value={1}>
                      By Location
                    </option>
                    <option aria-label="By Time" value={2}>
                      By Source
                    </option>
                  </Select>
                  <FormHelperText>See report by type</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item>
                {tabIndex == 0 && (
                  <FormControl className={classes.combo}>
                    <Select
                      native
                      input={<SmallSelectMenu />}
                      onChange={handleSource}
                      value={
                        parseInt(
                          getQueryStringFromUrl(document.URL, "sourceId")
                        ) || 0
                      }
                    >
                      <option value="0">All Sources</option>
                      {store.loadingPages && store.loadPages() && (
                        <React.Fragment></React.Fragment>
                      )}
                      {!store.loadingPages && getPagesForCombo()}
                    </Select>
                    <FormHelperText>Filter by Social pages</FormHelperText>
                  </FormControl>
                )}
              </Grid>
              <Grid item>
                {tabIndex == 0 && (
                  <FormControl className={classes.combo}>
                    <Select
                      native
                      input={<SmallSelectMenu />}
                      onChange={handlePeriod}
                      value={
                        parseInt(
                          getQueryStringFromUrl(document.URL, "months")
                        ) || 3
                      }
                    >
                      <option aria-label="Last 30 days" value={2}>
                        Last 30 days
                      </option>
                      <option aria-label="Last 6 months" value={3}>
                        Last 6 months
                      </option>
                      <option aria-label="Last 1 year" value={4}>
                        Last 1 year
                      </option>
                    </Select>
                    <FormHelperText>Filter by period</FormHelperText>
                  </FormControl>
                )}
              </Grid>
            </Grid>
            {tabIndex == 0 && (
              <Box>
                <Box width={700} style={{ margin: "0 auto" }}>
                  {reviewsByTime && getStatByTime()}
                </Box>
              </Box>
            )}
            {tabIndex == 1 && (
              <Box>
                <Box width={700} style={{ margin: "0 auto" }}>
                  {reviewsByLocation && getStatsByLocation()}
                </Box>
              </Box>
            )}
            {tabIndex == 2 && (
              <Box pt={4}>{reviewsBySource && getStatsBySource()}</Box>
            )}
          </Box>
        </Paper>
      </Container>
    </React.Fragment>
  );
});
