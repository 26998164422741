import React, { Component } from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import glogo from "../images/g-icon.png";
import { integrationType } from "../enums/integrationType";
import { Icon } from "@material-ui/core";
export const getIcon = (type, text, width, height, fullLogo) => {
  switch (type) {
    case integrationType.googleMyBusiness:
      return (
        <React.Fragment>
          {fullLogo && (
            <Icon
              style={{ width: "25px", height: "25px", verticalAlign: "middle" }}
            >
              <img
                src="https://squidilyimages.s3.us-east-2.amazonaws.com/google.png"
                alt="GSuite"
                height={height ?? 24}
                width={width ?? 70}
              />
            </Icon>
          )}
          {!fullLogo && (
            <Icon style={{ width: "35px", height: "35px" }}>
              <img
                src={glogo}
                alt="GSuite"
                height={height ?? 25}
                width={width ?? 25}
              />
            </Icon>
          )}
        </React.Fragment>
      );
    case integrationType.facebook:
      if (!width && !height) {
        width = height = 25;
      }
      if (width && !height) {
        height = width;
      }
      return (
        <React.Fragment>
          <FacebookIcon
            style={{
              color: "#115293",
              width: width,
              height: height,
            }}
          />{" "}
          {text}
        </React.Fragment>
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
};
