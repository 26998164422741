import { Box } from "@material-ui/core";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getQueryString,
  getQueryStringFromUrl,
} from "../common/getQueryString";
import { confirmEmailAddress } from "../services/accountService";
export const Confirm = ({ ...props }) => {
  const [confirm, setConfirm] = useState(null);
  const confirmEmail = async () => {
    const response = await confirmEmailAddress(
      getQueryStringFromUrl(document.URL, "userId"),
      getQueryStringFromUrl(document.URL, "code")
    );
    setConfirm(response.data);
  };
  confirmEmail();
  if (confirm == null) {
    return <React.Fragment>Hang on, we are confirming</React.Fragment>;
  } else if (confirm) {
    return (
      <React.Fragment>
        Thanks, its confirmed.{" "}
        <Box component={Link} to="/login">
          Log In
        </Box>
      </React.Fragment>
    );
  }
};
