import React, { Component } from "react";
import Pusher from "pusher-js";
var pusher;
export const PusherService = () => {
  if (!pusher) {
    console.log("new");
    pusher = new Pusher(process.env.REACT_APP_Pusher_Key, {
      cluster: process.env.REACT_APP_Pusher_Cluster,
      // encrypted: true,
    });
  }
  return pusher;
};
