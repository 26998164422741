import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { GetLogs } from "../services/appService";
import { TicketLog } from "./ticketLog";
export const Ticket = t
  .model("ticket", {
    id: t.optional(t.integer, 0),
    reviewId: t.optional(t.integer, 0),
    priority: t.optional(t.integer, 0),
    name: t.maybeNull(t.string, ""),
    emailAddress: t.maybeNull(t.string, ""),
    smsNumber: t.maybeNull(t.string, ""),
    status: t.optional(t.integer, 0),
    reviewerComment: t.maybeNull(t.string, ""),
    createdOn: t.optional(t.string, ""),
    starRating: t.optional(t.integer, 0),
    logs: t.maybeNull(t.array(TicketLog, [])),
    createTime: t.optional(t.string, ""),
    lastPreferredResponseType: t.maybeNull(t.integer, 0),
  })
  .actions((self) => ({
    loadLogs: flow(function* () {
      self.logs = [];
      const response = yield GetLogs(self.id);
      if (response.data.length > 0) {
        response.data.map((t) => {
          const _log = TicketLog.create();
          applySnapshot(_log, t);
          self.logs.push(_log);
        });
      }
      return self.logs;
    }),
    set(key, value) {
      self[key] = value;
    },
  }));
