import { Box, makeStyles } from "@material-ui/core";
import React, { Component } from "react";
const useStyles = makeStyles((theme) => ({
  bubble: {
    width: "270px",
    borderRadius: "15px 15px 15px 15px",
    padding: "10px 15px",
    position: "relative",
  },
  blue: {
    background: "#0476f8",
    color: "#fff",
  },
  grey: {
    backgroundColor: "#e2e1e4",
    color: "#000",
  },
  bubbleCornerRight: {
    textAlign: "right",
    "&::before": {
      content: '""',
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: `15px solid #0476f8`,
      borderRight: "15px solid transparent",
      borderTop: `15px solid #0476f8`,
      borderBottom: "15px solid transparent",
      right: "-16px",
      textAlign: "left",
      top: "0px",
    },
  },
  bubbleCornerLeft: {
    textAlign: "left",
    "&::before": {
      content: '""',
      width: "0px",
      height: "0px",
      position: "absolute",
      borderLeft: "15px solid transparent",
      borderRight: `15px solid #e2e1e4`,
      borderTop: "15px solid #e2e1e4",
      borderBottom: "15px solid transparent",
      left: "-16px",
      top: "0px",
    },
  },
}));
export const GetMessageBubble = ({ text, position, isTextArea }) => {
  const classes = useStyles();
  if (position == "right") {
    return (
      <Box
        component="div"
        className={`${classes.bubble} ${classes.bubbleCornerRight} ${classes.blue}`}
      >
        {isTextArea && text}
        {!isTextArea && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
      </Box>
    );
  } else {
    return (
      <Box
        component="div"
        className={`${classes.bubble} ${classes.bubbleCornerLeft} ${classes.grey}`}
      >
        {text}
      </Box>
    );
  }
};
