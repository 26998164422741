import { types as t, flow, applySnapshot } from "mobx-state-tree";
export const TicketLog = t
  .model("ticketLog", {
    text: t.optional(t.string, ""),
    createdOn: t.optional(t.string, ""),
    responseType: t.optional(t.integer, 0),
    userId: t.optional(t.integer, 0),
    html: t.maybeNull(t.string, ""),
    status: t.optional(t.integer, 0),
  })
  .actions((self) => ({
    set(key, value) {
      self[key] = value;
    },
  }));
