import { types as t, flow, applySnapshot } from "mobx-state-tree";
import { SubComment } from "./subComment";
export const Comment = t
  .model("comment", {
    id: t.optional(t.integer, 0),
    reviewId: t.optional(t.integer, 0),
    commentedBy: t.optional(t.string, ""),
    createTime: t.optional(t.string, ""),
    message: t.optional(t.string, ""),
    parentId: t.optional(t.integer, 0),
    subComments: t.maybeNull(t.array(SubComment, [])),
  })
  .actions((self) => ({
    pushComment(comment) {
      self.subComments.push({
        id: 0,
        reviewId: 0,
        commentedBy: "You responded",
        createTime: "",
        message: comment,
        parentId: 0,
      });
    },
  }));
