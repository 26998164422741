export const formatDate = (string) => {
  var options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(string).toLocaleDateString([], options);
};
export const shortDate = (string) => {
  var options = { month: "short", day: "numeric" };
  return new Date(string).toLocaleDateString([], options);
};

export const formatDateInTimeZone = (date) => {
  if (!date) {
    return "";
  }
  date = date.replace("Z", "");
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateTime = new Date(
    new Date(date) - new Date().getTimezoneOffset() * 60000
  );
  var hours = dateTime.getHours();
  var minutes = dateTime.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return (
    months[dateTime.getMonth()] +
    " " +
    dateTime.getDate() +
    ", " +
    dateTime.getFullYear() +
    "  " +
    strTime
  );
};

export const formatDateInTimeZoneInHrs = (date) => {
  if (!date) {
    return "";
  }
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const dateTime = new Date(
    new Date(date) - new Date().getTimezoneOffset() * 60000
  );
  var hours = dateTime.getHours();
  var minutes = dateTime.getMinutes();
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var seconds = dateTime.getSeconds();
  var strTime = hours + ":" + minutes + ":" + seconds;
  return (
    months[dateTime.getMonth()] +
    " " +
    dateTime.getDate() +
    ", " +
    dateTime.getFullYear() +
    "  " +
    strTime
  );
};
