import React, { Component, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { observer } from "mobx-react";
import {
  Paper,
  makeStyles,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Box,
  TextField,
  Grid,
  IconButton,
  Button,
  Chip,
  ListItemText,
  Container,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useMst } from "../models/store";
import { formatDate } from "../common/formatDate";
import { getIcon } from "../common/getIcon";
import { StyledMenu, StyledMenuItem } from "../common/dropdownMenu";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { CreateTicket } from "../services/appService";
import { ticketPriority } from "../enums/ticketPriority";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { CreateTicketButton } from "../common/createTicket";
import { ReviewTemplate } from "../common/reviewTemplate";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(3),
  },
  page: {
    margin: "15px 0",
  },
  rating: {
    verticalAlign: "middle",
  },
  title: {
    fontWeight: 600,
  },
  comment: {
    padding: "5px 16px 16px 16px",
  },
  input: {
    height: 50,
  },
  tabs: {
    backgroundColor: "#efefef",
  },
  mainContent: {
    backgroundColor: "none",
  },
}));

export const Reviews = observer((props) => {
  const store = useMst();
  const classes = useStyles();
  return (
    <React.Fragment>
      <Container className={classes.container}>
        <Box pt={2}>
          <ReviewTemplate reviews={store.allReviews} props={props} />
        </Box>
      </Container>
    </React.Fragment>
  );
});
