import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { AddCustomer, LoadCustomersCombo } from "../services/appService";
export const GetCustomersAutoCompleteCombo = ({
  handleChange,
  handleInputChange,
  options,
}) => {
  const [name, setName] = useState();
  const [customersList, setCustomersList] = useState([]);
  const ahref = () => {};
  const createNew = (e) => {
    AddCustomer({ name: name }).then((t) => {
      handleTextChange(name);
    });
    e.preventDefault();
  };
  const handleTextChange = (text) => {
    LoadCustomersCombo(text).then((t) => {
      setCustomersList(t.data);
    });
  };
  return (
    <Autocomplete
      options={customersList}
      getOptionLabel={(option) => option.name}
      fullWidth
      onChange={(e, v) => {
        handleChange(v);
      }}
      onInputChange={(e) => handleTextChange(e.currentTarget.value)}
      renderOption={(option) => (
        <React.Fragment>
          <div>
            {option.name}
            <br />
            <Typography variant="caption">
              {option.emailAddress ?? option.phone}
            </Typography>
          </div>
        </React.Fragment>
      )}
      noOptionsText={
        name && !options.length ? (
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <Box pb={1}>No match found</Box>
            </Grid>
            <Grid item>
              <Button color="primary" onMouseDown={createNew}>
                Create New
              </Button>
            </Grid>
          </Grid>
        ) : (
          <p>Type customer name</p>
        )
      }
      renderInput={(params) => {
        params.inputProps.autoComplete = "new-password";
        return (
          <TextField
            {...params}
            autoComplete="off"
            label="Customer Name*"
            variant="outlined"
            onChange={(e) => {
              setName(e.currentTarget.value);
            }}
          />
        );
      }}
    />
  );
};
