export const getCustomerActivity = (activityType) => {
  var message = "";
  var icon = "";
  switch (activityType) {
    case 1:
      message = "You emailed the customer";
      break;
    case 2:
      message = "Customer replied";
      break;
    case 3:
      message = "You texted customer";
      break;
    case 4:
      message = "Customer texted you";
      break;
    case 6:
      message = "Requested a feedback";
      break;
    case 7:
      message = "Customer submitted the feedback";
      break;
    case 8:
      message = "Requested a review";
      break;
    case 9:
      message = "Customer opened a review request";
      break;
    case 10:
      message = "Customer started a live chat";
      break;
    case 11:
      message = "Chat session has ended";
      break;
    default:
      message = "";
  }

  return { message, icon };
};
