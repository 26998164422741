import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  makeStyles,
  Table,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Divider,
} from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import React, { Component, useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { SquidilyFilters } from "../../common/getFilters";
import { getQueryString, updateQueryString } from "../../common/getQueryString";
import { LoadingSpinner } from "../../common/loadingSpinner";
import { GetReviewsDistribution } from "../../services/appService";
import StarIcon from "@material-ui/icons/Star";
import { StyledChip } from "../../common/chip";
import { getIntegrationTypeById } from "../../common/getIntegrationTypeById";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  ratingContainer: {
    display: "table",
  },
  totalRatings: {
    display: "table-cell",
    verticalAlign: "middle",
    "& p": {
      marginTop: "22px",
      marginBottom: "22px",
    },
  },
}));
export const RatingDistribution = ({ ...props }) => {
  const classes = useStyles();
  const [loadedData, setLoadedData] = useState(null);
  useEffect(() => {
    if (!loadedData) {
      let sourceId = getQueryString(props.location.search, "sourceId");
      let months = getQueryString(props.location.search, "months");
      GetReviewsDistribution(
        sourceId == "all" ? 0 : sourceId ?? 0,
        months ?? 0
      ).then((t) => {
        debugger;
        setLoadedData(t.data);
      });
    }
  });

  const reviewSources = () => {
    if (loadedData && loadedData.reviewsCount) {
      return (
        <React.Fragment>
          <Table>
            <TableBody>
              {loadedData.reviewsCount.map((t) => (
                <TableRow>
                  <TableCell>{getIntegrationTypeById(t.sourceType)}</TableCell>
                  <TableCell align="right">
                    <Chip
                      label={t.value}
                      color="primary"
                      icon={<StarIcon style={{ fontSize: "16px" }} />}
                      style={{
                        width: "75px",
                        height: "25px",
                        backgroundColor: "#27baf2",
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loadedData.reviewsCount.length > 5 && (
            <Box pt={1} pb={1}>
              <Typography align="center" color="textSecondary" variant="body1">
                See more
              </Typography>
            </Box>
          )}
        </React.Fragment>
      );
    }
    return <React.Fragment></React.Fragment>;
  };
  const starRatingGraph = () => {
    var dataTable = {
      series: [
        {
          name: "Reviews",
          data: loadedData.ratingByStar ?? [],
        },
      ],
      options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
            barHeight: "45%",
          },
        },
        colors: ["#01aef0", "#36b669", "#f4d41f", "#f99c1b", "#ee1c25"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          show: false,
        },
        grid: {
          show: false,
        },
        xaxis: {
          type: "category",
          categories: ["5 ★", "4 ★", "3 ★", "2 ★", "1 ★"],
          labels: {
            show: false,
            rotate: -45,
          },
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          decimalsInFloat: 0,
          axisBorder: {
            show: false,
          },
          labels: {
            style: {
              colors: ["#000"],
              fontSize: "16px",
              fontFamily: "Open Sans,sans-serif",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
      },
    };

    return (
      <Grid container>
        <Grid item xs={10}>
          <ReactApexChart
            options={dataTable.options}
            series={dataTable.series}
            type="bar"
            height={250}
          />
        </Grid>
        <Grid item xs={2} className={classes.ratingContainer}>
          <Box className={classes.totalRatings}>
            {loadedData.ratingByStar.map((t) => (
              <p>{t}</p>
            ))}
          </Box>
        </Grid>
      </Grid>
    );
  };

  const handleSourceChange = (sourceId) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/overview/rating-distribution",
      search: updateQueryString(
        "sourceId",
        sourceId == 0 ? "all" : sourceId,
        props.location.search
      ),
    });
  };

  const handlePeriodChange = (days) => {
    setLoadedData(null);
    props.history.push({
      pathname: "/overview/rating-distribution",
      search: updateQueryString("months", days, props.location.search),
    });
  };

  const reviewRatings = () => {
    if (loadedData && loadedData.reviewsRating) {
      return (
        <React.Fragment>
          <Table>
            <TableBody>
              {loadedData.reviewsRating.map((t) => (
                <TableRow>
                  <TableCell>{getIntegrationTypeById(t.sourceType)}</TableCell>
                  <TableCell align="right">
                    <Box style={{ padding: "3px 0" }}>
                      <Typography variant="h3">{t.value.toFixed(2)}</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {loadedData.reviewsRating.length > 5 && (
            <Box pt={1} pb={1}>
              <Typography align="center" color="textSecondary" variant="body1">
                See more
              </Typography>
            </Box>
          )}
        </React.Fragment>
      );
    }
    return <React.Fragment></React.Fragment>;
  };

  if (!loadedData) {
    return <LoadingSpinner />;
  } else {
    return (
      <React.Fragment>
        <Container className={classes.container}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardHeader
                title={
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                  >
                    <Grid item>
                      <Box pb={1}>
                        <Typography variant="overline" component="h4">
                          <strong>Distribution By Star</strong>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item>
                      <SquidilyFilters
                        sourceChange={handleSourceChange}
                        props={props}
                        periodChange={handlePeriodChange}
                        hideHeading={true}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <CardContent>
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "4px",
                          height: "402px",
                        }}
                      >
                        <Box p={2}>
                          <Typography variant="h3">Overall Rating</Typography>
                          <Typography variant="body1" color="textSecondary">
                            To Date
                          </Typography>
                          {starRatingGraph()}
                          <Divider />
                          <Box style={{ padding: "15.75px 0" }}>
                            <Grid container>
                              <Grid item xs={6}>
                                <Typography variant="h2" align="center">
                                  {loadedData.last30DaysReviewsCount}+
                                </Typography>
                                <Typography align="center">
                                  Last 30 days
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography variant="h2" align="center">
                                  {loadedData.reviewsCountSinceJoined}+
                                </Typography>
                                <Typography align="center">
                                  Since joined
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "4px",
                          height: "402px",
                        }}
                      >
                        <Box p={2}>
                          <Typography variant="h3">Review Rating</Typography>
                          <Typography variant="body1" color="textSecondary">
                            By sources
                          </Typography>
                        </Box>
                        {reviewRatings()}
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: "4px",
                          height: "402px",
                        }}
                      >
                        <Box p={2}>
                          <Typography variant="h3">Reviews Count</Typography>
                          <Typography variant="body1" color="textSecondary">
                            By sources
                          </Typography>
                        </Box>

                        {reviewSources()}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
};
