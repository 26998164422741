import {
  Box,
  Button,
  Checkbox,
  Chip,
  Container,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { applySnapshot } from "mobx-state-tree";
import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { formatDateInTimeZone } from "../common/formatDate";
import { StyledHeader } from "../common/getHeader";
import { StyledTableHead } from "../common/table";
import { CampaignType } from "../enums/campaignType";
import { PagedResult } from "../models/pagedResult";
import { GetRequests } from "../services/appService";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { LoadingSpinner } from "../common/loadingSpinner";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  cross: {
    color: "red",
  },
  check: {
    color: "#25d43b",
  },
}));

export const Requests = () => {
  const classes = useStyles();
  const [data, setData] = useState({
    pageIndex: 1,
    requests: null,
  });

  const loadRequests = (pageIndex, insertData) => {
    GetRequests(pageIndex, 50).then((t) => {
      var getData = { ...data };
      if (insertData) {
        t.data.map((x) => {
          getData.requests.pushData(x);
        });
      } else {
        var _pagedResult = PagedResult.create();
        applySnapshot(_pagedResult, t.data);
        getData.requests = _pagedResult;
      }

      setData(getData);
    });
  };

  const getTemplates = () => {
    if (data.requests && data.requests.data.length) {
      var content = [];
      const startIndex = data.requests.pageSize * (data.pageIndex - 1);
      const endIndex =
        data.requests.data.length < data.requests.pageSize
          ? data.requests.data.length
          : data.requests.pageSize * (data.pageIndex - 1) +
            data.requests.pageSize;

      data.requests.data.map((t) => {
        if (startIndex < endIndex) {
          content.push(
            <TableRow hover style={{ textDecoration: "none" }}>
              <TableCell>
                <Link to={`/customers/view?id=${t.customerId}`}>{t.name}</Link>
              </TableCell>
              <TableCell>{formatDateInTimeZone(t.createdOn)}</TableCell>
              <TableCell>
                {t.requestType == CampaignType.review ? (
                  <Chip variant="outlined" size="small" label="Review" />
                ) : (
                  <Chip variant="outlined" size="small" label="Feedback" />
                )}{" "}
                {t.campaignId > 0 ? (
                  <Chip variant="outlined" size="small" label="Campaign" />
                ) : (
                  <Chip variant="outlined" size="small" label="Direct" />
                )}
              </TableCell>
              <TableCell>
                {t.status == 0 ? (
                  <CloseIcon fontSize="small" className={classes.cross} />
                ) : (
                  <CheckIcon fontSize="small" className={classes.check} />
                )}
              </TableCell>
              <TableCell>
                {t.score}
                <Typography
                  style={{ fontSize: "10px", display: "inline-block" }}
                >
                  /{t.requestType == CampaignType.review ? 5 : 10}
                </Typography>
              </TableCell>
            </TableRow>
          );
        }
      });
      return content;
    } else {
      return (
        <Typography align="center">
          <LoadingSpinner />
        </Typography>
      );
    }
  };

  useEffect(() => {
    if (!data.requests) {
      loadRequests(data.pageIndex, false);
    }
  });

  return (
    <React.Fragment>
      <Container className={classes.container}>
        <StyledHeader
          heading="Requests"
          subHeading={
            <React.Fragment>
              Request a review or feedback from your customer. For bulk
              requests, see Campaigns.
            </React.Fragment>
          }
          actionButton={
            <Button
              variant="contained"
              color="primary"
              style={{ fontWeight: 500 }}
              to="/requests/create"
              component={Link}
            >
              Create
            </Button>
          }
        />
        <Box pb={2}>
          <Typography variant="caption">
            {data.requests && data.requests.totalSize
              ? data.requests.totalSize
              : 0}{" "}
            requests found
          </Typography>
        </Box>
        <Paper variant="outlined">
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="Table">
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <StyledTableHead>Customer</StyledTableHead>
                  <StyledTableHead>Date</StyledTableHead>
                  <StyledTableHead>Type</StyledTableHead>
                  <StyledTableHead>Status</StyledTableHead>
                  <StyledTableHead>Score</StyledTableHead>
                </TableRow>
              </TableHead>
              <TableBody>{getTemplates()}</TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Container>
    </React.Fragment>
  );
};
